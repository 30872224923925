import React, { Component } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Colors } from "../../constants/ui/colorScheme";
import { Formik} from "formik";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import FormikInput from "./FormikInput";
import { Card } from "native-base";

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

class AuthorizationPopUp extends Component {
    state = {
      };
    render() {
        const {
            isAuthorized,
            subjAuth,
            t,
            errorMessage,
            authorizeUser,
            userNameHeader,
            passwordHeader,
            userNamePlaceHolder,
            passwordPlaceHolder,
            cancelButton,
            submitButton,
            sendOtp,
            isLoading,
            validateFields,
            closePopup,
          } = this.props;

        return(
            <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
              margin: 5,
            }}
          >
            <Card
              style={{
                padding: 30,
                paddingLeft: 30,
                paddingRight: 30,
                flexShrink: 1,
                width: SCREEN_WIDTH >= 1030 ? "70%" : "90%",
              }}
            >
              {isAuthorized == false ? (
                <Text style={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </Text>
              ) : (
                <Text />
              )}
              <Formik
                initialValues={{ username: "", password: "" }}
                onSubmit={(values) => {
                  authorizeUser(values);
                }}
              >
                {(props) => (
                  <View>
                    {/* --------- user name label ----------------- */}
                    <Text
                      style={{
                        fontSize: fontType("BasicFont"),
                        fontFamily: "Inter",
                      }}
                    >
                      {t ? t(userNameHeader) : "Username"}
                    </Text>
                    <FormikInput
                      placeholderValue={
                        t ? t(userNamePlaceHolder) : "Enter Username"
                      }
                      formikProps={props}
                      formikKey={"username"}
                      secureTextEntry={false}
                      maxLength={50}
                    />

                    {isLoading == true ? (
                      <ActivityIndicator size="large" color="#0000ff" />
                    ) : (
                      <Text />
                    )}

                    {/* --------- password label ----------------- */}
                    <Text
                      style={{
                        fontSize: fontType("BasicFont"),
                        fontFamily: "Inter",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      {t ? t(passwordHeader) : "Password"}
                    </Text>

                    {/* ------------------ Password ---------------------- */}
                    <FormikInput
                      placeholderValue={
                        t ? t(passwordPlaceHolder) : "Enter Password"
                      }
                      formikProps={props}
                      formikKey={"password"}
                      secureTextEntry={true}
                      maxLength={50}
                    />

                    <View
                      style={{
                        flexDirection:
                          SCREEN_WIDTH < 350 ? "column" : "row",
                        justifyContent: "space-around",
                        marginTop: 15,
                      }}
                    >
                      {/* --------- Cancel  ----------------- */}
                      <TouchableOpacity
                        bordered
                        onPress={() => {
                          this.setState({ isAuthorized: true });
                          //this for subjAuth close
                          closePopup(false);
                        }}
                        style={[
                          constStyles.buttonStyle,
                          {
                            borderColor: "#0086ca",
                            margin: 3,
                            backgroundColor: Colors.errorButton,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            constStyles.buttonTxt,
                            { color: "white" },
                          ]}
                        >
                          {t ? t(cancelButton) : "CANCEL"}
                        </Text>
                      </TouchableOpacity>

                      {/* --------- Submit  ----------------- */}
                      <TouchableOpacity
                        disabled={validateFields(props.values)}
                        style={[
                          constStyles.buttonStyle,
                          {
                            margin: 3,
                            backgroundColor:
                              validateFields(props.values) == true
                                ? "grey"
                                : Colors.buttonwithbg,
                          },
                        ]}
                        onPress={props.handleSubmit}
                      >
                        <Text style={constStyles.buttonTxt}>
                          {t ? t(submitButton) : "SUBMIT"}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {/* ------------------ Forgot password ---------------------- */}
                    {subjAuth ? (
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          marginVertical: 20,
                        }}
                      >
                        <Text
                          style={{ color: "#0086ca" }}
                          onPress={() => {
                            sendOtp("email");
                          }}
                        >
                          Forgot Password?
                        </Text>
                      </View>
                    ) : null}

                  </View>
                )}
              </Formik>
            </Card>
          </View>

        );
    }
}

export default AuthorizationPopUp;