import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';
import { constStyles } from '../../styles/constStyles'
import React from 'react';
import { Container} from 'native-base';
import { View, Text, TouchableOpacity } from 'react-native';
const PrintNSignFlow = (props) => {
    const { navigation, screenProps: { t } } = props;
    return(
        <Container >
        <View style={{ flex: 1, justifyContent: 'center' }} >
            <Text style={{ fontSize: fontType('Heading1'), color: Colors.text, padding: 5, textAlign: 'center', fontFamily: 'Inter' }}>{t('PrintNSignFlow')}</Text>
            <Text style={{ fontSize: fontType('Heading1'), color: Colors.text, padding: 5, textAlign: 'center', fontFamily: 'Inter' }}>{t('OfficeThankYou')}</Text>
            <View style={{ padding: 4, justifyContent: 'center' }}>
                
                        <TouchableOpacity
                            onPress={() => navigation.navigate('Admin', {screen: 'SubjectDetails'})}
                            style={[constStyles.buttonStyle]}
                        >
                            <View style={{ flexDirection: 'row' }}>
                                <View >
                                    <Text style={[constStyles.buttonTxt]}>
                                        {t('Next')}
                                    </Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
            </View>
    </Container>
    )
}

export default PrintNSignFlow