import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export const styles = StyleSheet.create({
    placeholder: {
        color: Colors.error,
        fontSize: fontType('BasicFont'),
    },
    container: {
        flex:1,
        backgroundColor: Colors.background,
        padding: 20,
        paddingTop: 10,
        marginTop: 20,
        // justifyContent:'flex-start',
    },
    inputView: {
        width: 150,
        paddingLeft: 10,
        fontSize: fontType('subFont'),
    },
    input: {
        fontSize: fontType('subFont'),
    },
    errorinfo: {
        marginTop: wp('3%'),
        fontSize: fontType('BasicFont'),
    },
    errorinfoText: {
        color: Colors.error,
        fontSize: fontType('subFont'),
        fontFamily:'Inter'
    },
    labelFont: {
        fontSize: fontType('BasicFont'),
        color: Colors.reglabels,
        fontFamily:'Inter'
    },

});