import React, { Component } from "react";
import { Card } from "native-base";
import {
  Text,
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { styles } from "./styles/subjectValidateOtpScreenStyles";
import { KeyboardAvoidingView, Image } from "react-native";
import { createForm } from "rc-form";
import TextInputItem from "./TextInputItem";
import { Colors } from "../../constants/ui/colorScheme";
import api from "../../utils/api";
import showToast from "../../utils/toast";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import MyLoader from "../../utils/webLoader";
import tryalLogo from "../../../assets/base64/tryalLogoBox";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants

class SubjectValidateOtp extends Component {
  state = { loading: false, otpId: null };

  componentDidMount() {
    const {
      validationObj: { otpId },
    } = this.props;
    this.setState({ otpId });
  }

  verifyOtp = async () => {
    const {
      validationObj,
      validationObj: {
        userName,
        subjectId,
        from,
        subDocSignNavProps,
        isRemoteConsent,
        signatureType,
      },
      navigation,
      form,
    } = this.props;
    const { otpId } = this.state;
    form.validateFields(async (error, values) => {
      if (!error) {
        this.setState({ loading: true });
        try {
          const obj = {
            userName: userName,
            otp: {
              id: otpId,
              value: values.otp,
            },
          };
          // await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/otps/validate`
          //   , obj);
          await api.post('validateOtp'
            , obj);
          this.setState({ loading: false });
          const navobj = JSON.stringify({
            subjectId: subjectId,
            from,
            subDocSignNavProps,
            isRemoteConsent: isRemoteConsent,
            signatureType: signatureType,
            userName: userName,
          });

          const validObj = JSON.stringify({
            ...validationObj,
          });

          navigation.replace("SubjectResetPasswordPage", {
            navobj,
            validationObj: validObj,
            transactionId: otpId,
          });
          // navigation.replace('SubjectAuthStack',
          //   {
          //     screen: 'SubjectResetPassword',
          //     params: {
          //       subjectId: subjectId
          //     }
          //   });
        } catch (error) {
          this.setState({ loading: false });
          if (error.response) {
            if (error.response.status == 404) {
              showToast("Invalid OTP.", "danger", 3000);
            } else if (error.response.data) {
              showToast(error.response.data, "danger", 2000);
            } else {
              showToast("Something went wrong.", "danger", 2000);
            }
          } else {
            showToast("Network Error.", "danger", 2000);
          }
        }
      } else {
        // console.log(error);
      }
    });
  };

  resendOtp = async () => {
    const {
      validationObj: { subjectId, otpType },
      currentUserType,
    } = this.props;
    const { otpId } = this.state;
    this.setState({ loading: true });
    try {
      const obj = {
        subjectId: subjectId,
        otpType: otpType,
        otp: {
          id: otpId,
        },
        userType: currentUserType,
      };
      const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/otps`
        , obj);
      showToast("OTP has been sent to Email.", "success", 2000);
      this.setState({ loading: false, otpId: res.data.id });
      return true;
    } catch (error) {
      this.setState({ loading: false });
      if (error.response) {
        if (error.response.status == 404) {
          showToast("Failed to resend OTP.", "danger", 3000);
        } else {
          showToast("Something went wrong.", "danger", 2000);
        }
      } else {
        showToast("Network Error.", "danger", 2000);
      }
      console.log(error);
      return false;
    }
  };

  render() {
    const { loading } = this.state;
    const {
      form: { getFieldDecorator, getFieldError },
    } = this.props;
    return (
      <ScrollView>
        <KeyboardAvoidingView behavior="padding" enabled>
          <MyLoader active={loading} />
          {/* --------------------- Logo --------------------------- */}
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Image
              source={{
                uri: `data:image/png;base64,${tryalLogo}`,
              }}
              style={{ height: hp("10%"), width: wp("30%") }}
              resizeMode="contain"
            />
          </View>

          {/* --------------------- Card ---------------------------- */}
          <View style={{ justifyContent: "center", marginTop: 10, flex: 1 }}>
            <Card
              style={[
                styles.container,
                Platform.OS !== "web" && { marginTop: 30 },
              ]}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {/* ------------------ Label ---------------------- */}
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingVertical: Platform.OS !== "web" ? 15 : 0,
                  }}
                >
                  <Text style={styles.heading}>Enter OTP</Text>
                </View>

                {/* ------------------ otp ---------------------- */}
                <View style={{ marginVertical: 15 }}>
                  {getFieldDecorator("otp", {
                    rules: [{ required: true, message: "Please enter OTP." }],
                  })(
                    <TextInputItem
                      error={getFieldError("otp")}
                      placeholderValue={"Enter OTP"}
                    />
                  )}
                </View>

                {/* ------------- Validate button --------------------- */}
                <View>
                  <TouchableOpacity
                    success
                    style={constStyles.buttonStyle}
                    onPress={this.verifyOtp}
                  >
                    <Text style={constStyles.buttonTxt}>VALIDATE</Text>
                  </TouchableOpacity>
                </View>

                {/* ------------------ Resend otp ---------------------- */}
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    marginVertical: 20,
                  }}
                >
                  <Text style={{ fontFamily: "Inter" }}>
                    Didn't receive the code?
                  </Text>
                  <Text
                    style={{
                      color: "#9155FD",
                      padding: 5,
                      marginTop: 5,
                      fontFamily: "Inter",
                    }}
                    onPress={this.resendOtp}
                  >
                    Resend OTP
                  </Text>
                </View>

                <View style={{ flex: 1 }}></View>
              </View>
            </Card>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    );
  }
}

export default createForm()(SubjectValidateOtp);
