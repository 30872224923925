import React, { Component } from 'react';
import Dialog, { DialogContent, DialogFooter, DialogButton, ScaleAnimation, SlideAnimation, FadeAnimation } from 'react-native-popup-dialog';
import { MaterialIcons, FontAwesome, AntDesign, Feather } from '@expo/vector-icons';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { View, Text, Platform, Dimensions, TouchableOpacity} from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font'
import {constStyles,chapterButtonStyles} from '../../styles/constStyles'
import * as WebBrowser from 'expo-web-browser';
import Constants from '../../utils/constants';
import api from '../../utils/api';
import { connect } from 'react-redux';
import HeaderProfile from './HeaderProfile';


const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

class LogoutDialogue extends Component {

    onLogoutPress = () => {
        const { navigation, handleHeaderMenuDialoge } = this.props;
        handleHeaderMenuDialoge();
        const loginRequestId =  window.sessionStorage.getItem('loginRequestId');
        delete api.defaults.headers.common.Authorization;
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('loginRequestId');
        
        setTimeout(() => {
            window.location.assign(`${Constants.ADMIN_PORTAL_BASE_URL}/saml/logout/${loginRequestId}?redirectUrl=${Constants.ADMIN_PORTAL_BASE_URL}/api/webapp/logout`);
            // navigation.original.reset({
            //     index: 0,
            //     routes: [
            //       {
            //         name: 'AdminAuthStack',
            //       },
            //     ],
            //   })
        }, 100);
    }

    render() {
        const { handleHeaderMenuDialoge, showDialog, navigation, user, deviceNo,organizationName, switchOrgPopUp, clientOrganizations} = this.props
        return (
            <Dialog
                onTouchOutside={() => handleHeaderMenuDialoge()}
                containerStyle={{ justifyContent: 'flex-start',alignItems:'flex-end' }}
                dialogStyle={{ marginTop: hp('2.5%'),
                marginRight:SCREEN_WIDTH <= 1030 ? 10 : SCREEN_WIDTH/2-(230+wp('10%')),
                }}
                rounded={true}
                visible={showDialog}
                // width={SCREEN_WIDTH >= 1030 ? 0.2 : 0.5}
       
            // footer={
            //     <DialogFooter style={{ backgroundColor: '#f5f5f5' }}>
            //         <DialogButton
            //             text='Close'
            //             onPress={() => handleHeaderMenuDialoge()}>
            //         </DialogButton>
            //     </DialogFooter>
            // }
            >
                <DialogContent>
                    {/* <View style={{ paddingTop: 10, }}>
                    <View style={{flexDirection: 'row', alignContent: 'space-between'}}>
                        <View  style ={{ flexDirection:'row', justifyContent:'flex-start', padding:5, marginVertical:5}}>
                            <FontAwesome name='user-circle' style={{ fontSize: fontType('StatusIcon'), color: Colors.iconColor, alignSelf: 'center' }} />
                            <Text style={{ fontSize: fontType('BasicFont'), fontFamily:'Inter', color: Colors.iconColor, alignSelf: 'center', marginLeft:5 }}>{user && user.fullName}</Text>                            
                        </View>
                    </View>

                        { deviceNo != '' && Platform.OS != 'web'
                            ?   <Text style={{paddingTop:10, paddingLeft:10, fontSize: fontType('subFont'),  color: Colors.iconColor}}>Device S.NO: {deviceNo}</Text>
                            : <View></View>
                        }
                        <Text style={{paddingTop:wp('1%'), paddingLeft:10, fontSize: fontType('subFont'),  color: Colors.iconColor}}>App Version : {Constants.APP_VERSION}</Text>
                        <View style ={{flexDirection:'row', width:'100%', marginTop:Platform.OS == 'web' ? 0 :10}}>
                        {clientOrganizations != undefined && clientOrganizations.length > 0 ? 
                        <View style={{flexDirection:'row'}}>
                            <Text style={{paddingTop:wp('1%'), paddingLeft:10, fontSize: fontType('subFont'),  color: Colors.iconColor}}>Site Name : {organizationName}</Text>
                            <TouchableOpacity
                            style={{paddingTop:wp('0.7%'), paddingLeft:10}}
                                onPress={() => {switchOrgPopUp()
                                handleHeaderMenuDialoge()}}
                                title="Info"
                                color={Colors.tobg}
                            >
                                <FontAwesome name='exchange' style={{ fontSize: 20, marginLeft: 8 }} />
                            </TouchableOpacity>
                        </View> : 
                        <View />}
                        </View>
                        <View style ={{flexDirection:'row', justifyContent:'center', width:'100%', marginTop:Platform.OS == 'web' ? 10 :10}}>
                            <TouchableOpacity bordered onPress={() => handleHeaderMenuDialoge()}
                                style = {[constStyles.buttonStyle, { padding:5}]}
                            >
                                <Text style={[constStyles.buttonTxt, {backgroundColor: Colors.radioGroup}]}>
                                    CLOSE
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity 
                                style={[constStyles.buttonStyle, {backgroundColor: Colors.errorButton, flexDirection:'row',padding:5,marginHorizontal:5}]}
                                onPress={() => this.onLogoutPress()}
                            >                               
                                <Text style={constStyles.buttonTxt}>
                                    <AntDesign name='logout' style={{ fontSize:fontType('subFontSize'), color: 'white'}} />
                                    &nbsp;LOGOUT
                                </Text>
                            </TouchableOpacity>
                        </View>        
                               
                    </View> */}
                    <HeaderProfile 
                    userFullName={user && user.fullName ? user.fullName : "Anonymous User"}
                    appVersion={Constants.APP_VERSION}
                    organizationName={clientOrganizations != undefined && clientOrganizations.length > 0  ? organizationName: 'NO ORG'}
                    title={user && user.title ? user.title : 'Title'}
                    showDeviceNo={ deviceNo != '' && Platform.OS != 'web' ? true : false}
                    deviceNo={deviceNo != '' ? deviceNo : 'No device'}
                    onLogoutPress={this.onLogoutPress} 
                    handleHeaderMenuDialoge={() =>handleHeaderMenuDialoge()}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    organizationName: state.clientUser.organizationName,
    clientOrganizations: state.clientUser.selectedUser.clientOrganizations,
});

export default connect( mapStateToProps)(LogoutDialogue);
