import createActionType from '../utils/action';
import _ from 'lodash';
import api from '../utils/api';

export const STORE_CONSENT_TOKEN_DATA = createActionType('STORE_CONSENT_TOKEN_DATA');
export const INC_SUB_SERIAL_DEVICEN0 = createActionType('INC_SUB_SERIAL_DEVICEN0');
export const SAVE_DEVICE_SNO_REQUEST = createActionType('SAVE_DEVICE_SNO_REQUEST');
export const SAVE_DEVICE_SNO_SUCCESS = createActionType('SAVE_DEVICE_SNO_SUCCESS');
export const SAVE_DEVICE_SNO_FAILURE = createActionType('SAVE_DEVICE_SNO_FAILURE');
export const CLEAR_DEVICE_SNO = createActionType('CLEAR_DEVICE_SNO');


export const storeConsentTokenData = (consentTokenId) => ({
    type:  STORE_CONSENT_TOKEN_DATA,
    consentTokenId,
});

export const incrementSubSerialDevNo = (subSerialDeviceNo) => ({
    type: INC_SUB_SERIAL_DEVICEN0,
    subSerialDeviceNo,
});

export const saveDeviceSNoSuccess = (siteDevice) => ({
    type: SAVE_DEVICE_SNO_SUCCESS,
    deviceNo: siteDevice.deviceSno,
});

const saveDeviceSNoRequest = () => ({
    type: SAVE_DEVICE_SNO_REQUEST
});

const saveDeviceSNoFailure = () => ({
    type: SAVE_DEVICE_SNO_FAILURE
});

export const clearDeviceSNo = () => ({
    type: CLEAR_DEVICE_SNO,
    deviceNo: '',
});

export const saveDeviceSNo = (siteDevice, navigation) => async dispatch => {    
    dispatch(saveDeviceSNoRequest());
    try {
        // api.defaults.headers.common["Access-Code"] = siteDevice.studySite.accessCode;
        const res = await api.post('/siteDevice/save', siteDevice);
        dispatch(saveDeviceSNoSuccess(res.data));
        navigation.navigate('UsersListScreen');
        return 'NOERROR';
    } catch(error) {
        dispatch(saveDeviceSNoFailure());
        return error.response ? error.response.status : 'NetworkError';
    }
}