import {StyleSheet} from 'react-native';
import { Colors } from '../../../constants/ui/colorScheme';
export const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'space-between',
        backgroundColor: Colors.whiteBg,
    },
    text: {
        fontWeight: "bold",
        color: Colors.textColor,
    },
});
