import showToast from "../utils/toast";

export const checkErrorStatusAndShowToast = (error, message) => {
    if (error.response) {
        switch (error.response.status) {
            case 404:
                showToast(message, 'danger', 2000);
                break;
            case 500:
                showToast("Something went wrong.", 'danger', 2000);
                break;
            case 401 || 403:
                showToast("Invalid PIN.", 'danger', 2000);
                break;
            case 423:
                showToast("User locked. Please contact your Administrator.", 'danger', 4000);
                break;
            default:
                showToast("Network Error.", 'danger', 2000);
        }
    } else {
        showToast("Network Error.", 'danger', 2000);
    }
}

