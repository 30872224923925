import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Image,
  View,
  Text,
  TouchableOpacity
} from 'react-native';

export default class RadioButton extends Component{
    constructor(props, context){
        super(props, context);
    }

    getRadioStyle(){
        return {
            height: 20,
	        width: 20,
	        borderRadius: 5,
	        borderWidth: 1,
	        borderColor: 'black',
        }
    }

    getRadioDotStyle(){
        return {
            height: this.context.size / 2,
            width: this.context.size / 2,
            borderRadius: this.context.size / 4,
            backgroundColor: this.props.color || this.props.activeColor,
        }
    }

    renderTick(){
        const {isSelected} = this.props;
        if(isSelected){   
            return (       
                <View style={[styles.radio, this.getRadioStyle()]}>
            <Image
            style={{width:30,height:30,marginLeft:10,marginBottom:10}}
          source={require('../../../assets/image1.png')}  
        />
            </View>
            ) 
        }
    }

    render(){
        const {isSelected, onOptionSelected, isCurrentFieldDisabled} = this.props;
        return(
            <View style={{opacity: this.props.disabled?0.4:1,}}>
                <TouchableOpacity
                    disabled={isCurrentFieldDisabled}
                     onPress={onOptionSelected}
                >
                    <View style={[styles.container, this.props.style, isSelected?{backgroundColor: this.context.highlightColor}:null]}>
                         <View style={[styles.radio, this.getRadioStyle()]}>
                            {this.renderTick()}
                        </View> 
                    </View>
            </TouchableOpacity>
          </View>
        )
    }
}

let styles = StyleSheet.create({
  container:{
	  flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'center',
	//   padding: 10,
  },
  radio:{
	  alignItems: 'center',
	  justifyContent: 'center',
  },
  item: {
    marginLeft: 5,
    alignItems: 'center',
	justifyContent: 'center',
  }
})
