import createActionType from "../utils/action";
import api from "../utils/api";
import Constants from "../utils/constants";
import { updateSessionUserType } from "./session";
const { ContextProperties } = Constants;

export const STORE_SELETED_SUBJECT = createActionType("STORE_SELETED_SUBJECT");
export const STORE_SUBJECT_DETAILS = createActionType("STORE_SUBJECT_DETAILS");
export const UPDATE_SELETED_SUBJECT_STATUS = createActionType(
  "UPDATE_SELETED_SUBJECT_STATUS"
);
export const STORE_SUBJECT_ID = createActionType("STORE_SUBJECT_ID");
export const UPDATE_CONSENT_COMPLETION_STATUS = createActionType(
  "UPDATE_CONSENT_COMPLETION_STATUS"
);
export const SET_SUBJECT_TO_EMPTY = createActionType("SET_SUBJECT_TO_EMPTY");
export const STORE_SUBJECT_STATUS = createActionType("STORE_SUBJECT_STATUS");
export const CLEAR_SUBJECT_DATA = createActionType("CLEAR_SUBJECT_DATA");
export const STORE_SELETED_SUBJECT_DETAILS_IN_SUBJECT = createActionType(
  "STORE_SELETED_SUBJECT_DETAILS_IN_SUBJECT"
);
export const STORE_SIGNATURE_TYPE = createActionType("STORE_SIGNATURE_TYPE");
export const STORE_ORDINAL_FOR_NAVIGATIONS = createActionType(
  "STORE_ORDINAL_FOR_NAVIGATIONS"
);
export const STORE_SUBJECT_NAVTOUR = createActionType("STORE_SUBJECT_NAVTOUR");
export const CLEAR_SUBJECT_NAVTOUR = createActionType("CLEAR_SUBJECT_NAVTOUR");
export const UPDATE_SUBJECT_NAVTOUR = createActionType(
  "UPDATE_SUBJECT_NAVTOUR"
);
export const STORE_IS_AGREE_FOR_CHAPTERS = createActionType(
  "STORE_IS_AGREE_FOR_CHAPTERS"
);
export const STORE_IS_SUBSIGNS_DONE = createActionType(
  "STORE_IS_SUBSIGNS_DONE"
);
export const CLEAR_SELECT_SUBJECT = createActionType("CLEAR_SELECT_SUBJECT");
export const UPDATE_DOCS_REVIEW_IN_SELECT_SUBJECT = createActionType(
  "UPDATE_DOCS_REVIEW_IN_SELECT_SUBJECT"
);
export const CLEAR_SUBJECT_DOCUMENT_SIGNATURES = createActionType(
  "CLEAR_SUBJECT_DOCUMENT_SIGNATURES"
);
export const STORE_CLIENT_USER_DETAILS = createActionType(
  "STORE_CLIENT_USER_DETAILS"
);
export const STORE_SUBJECT_RESOLVEDCOMMENTS = createActionType(
  "STORE_SUBJECT_RESOLVEDCOMMENTS"
);

export const clearSelectedSubject = () => ({
  type: CLEAR_SELECT_SUBJECT,
});

export const updateDocsReview = () => ({
  type: UPDATE_DOCS_REVIEW_IN_SELECT_SUBJECT,
});

export const storeSelectedSubject = (subject) => ({
  type: STORE_SELETED_SUBJECT,
  subject,
});

export const storeSubjectDetails = (subjectDetails) => ({
  type: STORE_SUBJECT_DETAILS,
  subjectDetails,
});

export const storeSelectedSubjectDetailsinSubject = (subject) => ({
  type: STORE_SELETED_SUBJECT_DETAILS_IN_SUBJECT,
  subject,
});

export const updateSelectedSubjectStatus = (status) => ({
  type: UPDATE_SELETED_SUBJECT_STATUS,
  status,
});

export const storeSubjectID = (usubjectId) => ({
  type: STORE_SUBJECT_ID,
  usubjectId,
});

export const updateConsentStatus = (consentCompleted) => ({
  type: UPDATE_CONSENT_COMPLETION_STATUS,
  consentCompleted,
});

export const storeSubjectStatus = (status) => ({
  type: STORE_SUBJECT_STATUS,
  status,
});

export const setSubjectToEmpty = (subject) => ({
  type: SET_SUBJECT_TO_EMPTY,
  subject,
});

export const clearSubjectData = () => ({
  type: CLEAR_SUBJECT_DATA,
  subject: {
    id: null,
    formData: [],
    subjectDocumentSignatures: [],
    larDetails: {},
    quizAttempts: [],
    consentId: "",
    isRegistered: false,
    isSubSignsDone: false,
    consentCompleted: false,
    signatureType: "",
    isKnowledgeReviewDone: false,
    visitedChapters: 1,
    navTour: [],
    isAgreedForChapters: {
      studyInfo: false,
      studyVideo: false,
      consentDocuments: false,
    },
    isRemoteConsent: false,
  },
});

export const clearSubjectDocumentSignatures = () => ({
  type: CLEAR_SUBJECT_DOCUMENT_SIGNATURES,
});

export const storeSignatureType = (signatureType) => ({
  type: STORE_SIGNATURE_TYPE,
  signatureType,
});

export const storeVisitedChapters = (ordinal) => ({
  type: STORE_ORDINAL_FOR_NAVIGATIONS,
  ordinal,
});

export const storeSubjectNavTour = (subNavTour) => ({
  type: STORE_SUBJECT_NAVTOUR,
  subjectNavTour: subNavTour,
});

export const clearSubjectNavTour = () => ({
  type: CLEAR_SUBJECT_NAVTOUR,
});

export const updateSubjectNavTour = () => ({
  type: UPDATE_SUBJECT_NAVTOUR,
});

export const storeisAgreedForChapters = (isAgreedForChapters) => ({
  type: STORE_IS_AGREE_FOR_CHAPTERS,
  isAgreedForChapters,
});

export const isSubjectSignsDone = () => ({
  type: STORE_IS_SUBSIGNS_DONE,
});

export const storeClientUserDetails = (clientUserDetails) => ({
  type: STORE_CLIENT_USER_DETAILS,
  data: clientUserDetails,
});

export const updateCommentsStatus = (isCommentsResolved) => ({
  type: STORE_SUBJECT_RESOLVEDCOMMENTS,
  isCommentsResolved,
});

export const inOfficeLogout = () => async (dispatch) => {
  try {
    const loginRequestId = window.sessionStorage.getItem("loginRequestId");
    delete api.defaults.headers.common.Authorization;
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("loginRequestId");
    setTimeout(() => {
      window.location.assign(
        `${Constants.ADMIN_PORTAL_BASE_URL}/saml/logout/${loginRequestId}?redirectUrl=${Constants.ADMIN_PORTAL_BASE_URL}/api/webapp/logout`
      );
    }, 100);
  } catch (error) {
    console.log(error);
  }
};

export const generateToken = (subject, userType) => async (dispatch) => {
  try {
    const res = await api.post(
      `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subject.id}/token?userType=${userType}`
    );
    api.defaults.headers.common.Authorization = res.headers["authorization"];
    const authToken = res.headers["authorization"];
    window.sessionStorage.setItem("token", authToken);
    dispatch(updateSessionUserType(userType))
  } catch (error) {
    console.log(error);
    throw error
  }
};

export const retrieveSubjectDetails = (subjectId) => async (dispatch) => {
  try {
    const res = await api.get(
      `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}`
    );
    dispatch(storeSubjectDetails(res.data))
  } catch (error) {
    console.log(error);
    throw error
  }
};

export const logSubjectAudit = (subjectId) => async () => {
  try {
    await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/logAudit`);
  } catch (error) {
    console.log(error);
    throw error
  }
};
