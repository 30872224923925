import initialState from '../store/initialState';
import { INC_DOC_SIGN_POSITION, CLEAR_DOC_SIGN_POSITION} from '../actions/update'


const currentSubDocPosition = (state = initialState.currentSubDocPosition, action) => {
  switch (action.type) {
    case INC_DOC_SIGN_POSITION:
    return state+1;
    case CLEAR_DOC_SIGN_POSITION:
      return 0;
    default:
      return state;
  }
};

export default currentSubDocPosition;