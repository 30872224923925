import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const Mobile = (props) => { 
    const isMobile = screenWidth < 768;
    if(isMobile){
        return props.children;
    }
    return null
}

export default Mobile;