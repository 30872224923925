import React, { Component } from 'react';
import { View } from 'react-native';
import fieldTypes from "../constants/fieldTypes";
import TextInputDecorator from './fields/TextInputDecorator';
import TextAreaDecorator from './fields/TextAreaDecorator';
import NumericInputDecorator from './fields/NumericInputDecorator';
import SingleSelectDecorator from './fields/SingleSelectDecorator';

const { TEXT, TEXTAREA, NUMBER, RADIO } = fieldTypes;

export const fieldType = (field, form, attempts, t, handleWrongIcon, showWrongIcon) => {
    switch (field.answerType) {
        case TEXT:
            return <TextInputDecorator t={t} field={field} form={form} />;
        case TEXTAREA:
            return <TextAreaDecorator t={t} field={field} form={form} />;
        case NUMBER:
            return<NumericInputDecorator t={t} field={field} form={form} attempts={attempts} />;
        case RADIO:
            return <SingleSelectDecorator t={t} field={field} form={form} attempts={attempts} handleWrongIcon={handleWrongIcon} showWrongIcon={showWrongIcon}/>;
        default:
            return <View />;
    }
};
