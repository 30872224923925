import _ from 'lodash';

export const createFormFieldsWithValues = (formFields, formDataList) => {

    let fieldNumber = 0;
    let loFormFields = _.map(formFields, (formField) => {
        // let fieldType = formField.fieldType;
        if(formField.fieldType === 'radio') {
            fieldNumber = fieldNumber + 1;
            // fieldType = 'radioini'
        }
        let loFormData = _.filter(formDataList, formData => formData.fieldOid === formField.fieldOid)
        loFormData = _.isEmpty(loFormData) ? { fieldValue: null, fieldOid:formField.fieldOid, optionOid: null } : loFormData[0];
        let dictionary = formField.dictionary ? JSON.parse(formField.dictionary) :null;
        if(dictionary != null){
        dictionary = _.map(dictionary, (option, index) => ({
            ...option,
            index
        }))
        }
        
        return {
            ...formField,
            // fieldType,
            dictionary: _.isEmpty(dictionary) ? null : dictionary,
            formData: loFormData,
            fieldNumber: fieldNumber,
        }
    });
    return loFormFields;
}

export const buildFormDataList = (formFields, formData, chapterPageId, documentSignatureId) => {
    const formDataList = []; 
    _.forEach(formFields, formField => {
        let subjectConsentId = formField.formData.subjectConsent ? formField.formData.subjectConsent.id : null;
        // let formData = _.filter(formDataList, formData => Object.keys(formData)[0] === formField.fieldOid)
        let documentSignature = null;
        if(documentSignatureId != null) {
            documentSignature = {
                id: documentSignatureId
            }
        }
        if(formField.fieldType === 'radio') {
            let fieldValue = formField.dictionary.filter(dic => {
                if(dic.oid === formData[formField.fieldOid]) {
                    return true;
                } return false;
            });
            fieldValue = fieldValue.length > 0 ? fieldValue[0].option : null;
            formDataList.push({
                fieldOid: formField.fieldOid,
                fieldValue: fieldValue,
                optionOid: formData[formField.fieldOid],
                chapterPage: {
                    id: chapterPageId,
                },
                formField: {
                    id: formField.id,
                },
                subjectConsent: {
                    id: subjectConsentId,
                },
                documentSignature
            });
        }
        else if (formField.fieldType === 'radioini') {
            const fieldValueJson = formData[formField.fieldOid];
            const fv = JSON.parse(fieldValueJson);


            formDataList.push({
                fieldOid: formField.fieldOid,
                fieldValue: fieldValueJson,
                optionOid: fv?.value1?.optionOid,
                chapterPage: {
                    id: chapterPageId,
                },
                formField: {
                    id: formField.id,
                },
                subjectConsent: {
                    id: subjectConsentId,
                },
                documentSignature
            });
        }
        else if (formField.fieldType === 'subjini') {
            formDataList.push({
                fieldOid: formField.fieldOid,
                fieldValue: formData[formField.fieldOid],
                optionOid: null,
                chapterPage: {
                    id: chapterPageId,
                },
                formField: {
                    id: formField.id,
                },
                subjectConsent: {
                    id: subjectConsentId,
                },
                documentSignature
            });
        }
    });
    if(formDataList.length > 0){
        return {
            chapterPageId: chapterPageId,
            documentSignatureId: documentSignatureId,
            formDataList,
        }
    } return false;
}
