import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChapterPages from '../components/documents/ChapterPages';
import {getConsentChapter} from '../utils/consentData';
import {storeSubjectNavTour, updateSubjectNavTour} from '../actions/subject';
import {
    TouchableOpacity, View, Text
} from "react-native";
import { Icon } from 'native-base';
import {storeVisitedChapters, storeisAgreedForChapters } from '../actions/subject';
import {ConsentDataContext} from '../providers/ConsentDataProvider';


class StudyInfoScreen extends Component {
    static contextType = ConsentDataContext;

    state = {};

    onNextChapter = () => {
        const { navigation } = this.props;
        navigation.navigate('StudyVideoListScreen');
    }

    render() {
        const { navigation, isRegistered, subjectData, screenProps, krDoneBy,
            storeVisitedChapters, isAgreedForChapters, storeisAgreedForChapters,
            subjectNavTour, storeSubjectNavTour, updateSubjectNavTour,selectedSubject
        } = this.props;
        const studyInfoChapter = getConsentChapter(this.context.languages, subjectData.language, 'STUDY_INFORMATION');
        return (
            <View style={{ flex: 1 }}>
                <ChapterPages  isRegistered={isRegistered}
                storeVisitedChapters={storeVisitedChapters}
                screenProps={screenProps}
                onNextChapter={this.onNextChapter}
                navigation={navigation}
                chapter={studyInfoChapter}
                subjectNavTour={subjectNavTour}
                storeSubjectNavTour={storeSubjectNavTour}
                updateSubjectNavTour={updateSubjectNavTour}
                signatureType={subjectData.signatureType}
                isAgreedForChapters ={isAgreedForChapters}
                storeisAgreedForChapters = {storeisAgreedForChapters}
                subjectData = {subjectData}
                location={"StudyInfo"}
                selectedSubject = {selectedSubject}
                krDoneBy = { krDoneBy }
                />
            </View>
        );
    }
}
const mapStateToProps = state => ({
    isRegistered: state.subject.isRegistered,
    subjectData: state.subject,
    subjectNavTour: state.subject.navTour,
    isAgreedForChapters: state.subject.isAgreedForChapters,
    selectedSubject: state.selectedSubject,
    krDoneBy: state.consentFlow.krDoneBy,

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSubjectNavTour,
        updateSubjectNavTour,
        storeVisitedChapters,
        storeisAgreedForChapters
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudyInfoScreen);