import createActionType from '../utils/action';
import _ from 'lodash';
import api from '../utils/api';
import { getConsentVersionId } from '../utils/consentData';
import { fromBinary } from 'uuid-js';
import constants from "../utils/constants.js";
const { ContextProperties } = constants

export const SUB_DOCUMENTS_DATA_REQUEST = createActionType('SUB_DOCUMENTS_DATA_REQUEST')
export const SUB_DOCUMENTS_DATA_SUCCESS = createActionType('SUB_DOCUMENTS_DATA_SUCCESS')
export const SUB_DOCUMENTS_DATA_FAILURE = createActionType('SUB_DOCUMENTS_DATA_FAILURE')
export const NO_LANGUAGE_ID = createActionType('NO_LANGUAGE_ID_FAILURE')
export const STORE_DOCUMENT_FORM_DATA_LIST = createActionType('STORE_DOCUMENT_FORM_DATA_LIST')
export const STORE_DECLARATION_FORM_DATA = createActionType('STORE_DECLARATION_FORM_DATA')
export const RETRIEVE_FORM_DATA_LIST_SUCCESS = createActionType('RETRIEVE_FORM_DATA_LIST_SUCCESS')
export const CLEAR_FORM_DATA = createActionType('CLEAR_FORM_DATA')
export const STORE_CONSENT_VERSIONS_METADATA = createActionType('STORE_CONSENT_VERSIONS_METADATA')


const retrieveSubDocsDataRequest = () => ({
    type: SUB_DOCUMENTS_DATA_REQUEST,
});

const retrieveSubDocsDataFailure = () => ({
    type: SUB_DOCUMENTS_DATA_FAILURE,
});

const retrieveSubDocsDataSuccess = (res) => ({
    type: SUB_DOCUMENTS_DATA_SUCCESS,
    subjectDocData: res.data
});

const noLanguageId = () => ({
    type: NO_LANGUAGE_ID,
});

export const storeDocumentFormDataList = (formDataList) => ({
    type: STORE_DOCUMENT_FORM_DATA_LIST,
    formDataList: formDataList
});

export const storeDeclarationFormData = (formDataList) => ({
    type: STORE_DECLARATION_FORM_DATA,
    formDataList: formDataList
});


export const retrieveFormDataSuccess = (formDataList) => ({
    type: RETRIEVE_FORM_DATA_LIST_SUCCESS,
    formDataList: formDataList
});

export const clearFormData = () => ({
    type: CLEAR_FORM_DATA,
});

export const storeConsenVersionMetaData = (consentVersionId, metadata) => ({
    type: STORE_CONSENT_VERSIONS_METADATA,
    consentVersionId: consentVersionId,
    metadata: metadata
});


export const retrieveSubDocsData = (languageName,subjectConsentId, consentVersionId) => async (dispatch, getState )=> {
    dispatch(retrieveSubDocsDataRequest());
    // const consentVersionId = getConsentVersionId();
    try {
        if(languageName && !_.isEmpty(getState()?.selectedSubject?.id)) {
            const res = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${getState().selectedSubject.id}/subjectConsents/${getState().selectedSubject?.subjectConsent?.id}/subjectSignedDocuments?languageName=${getState().selectedSubject.locale}&consentVersionId=${ContextProperties.CONSENT_VERSION_ID}`);
            dispatch(retrieveSubDocsDataSuccess(res));
            const formDataRes = await api.get(`${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${getState().selectedSubject.id}/subjectConsents/${subjectConsentId}/formData`);
            let formDataList = formDataRes.data;
            formDataList = _.map(formDataList, fd => {
                return {
                    ...fd,
                    chapterPageId: fd.chapterPage?.id,
                    subjectConsent: {
                        id: subjectConsentId,
                    }
                }
            });
            formDataList = _.filter(formDataList, fd => fd.chapterPageId ? true : false);
            formDataList = _.groupBy(formDataList, 'chapterPageId');
            dispatch(retrieveFormDataSuccess(formDataList))
        } else {
            dispatch(noLanguageId());
        }
    } catch(error) {
        console.log(error)
        dispatch(retrieveSubDocsDataFailure());
    }
    
}

{/*To return all historic documents of a subject irrespective of Subject's Status and subjectConsent Version
    Note: Subject Consent Version is unique for each version and it's documents.
*/}
export const retrieveAllVersionsSubDocsData = (subject) => async (dispatch, getState) => {
    dispatch(retrieveSubDocsDataRequest());
    try {
        if(subject.locale) {
            const res = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subject.id}/subjectConsents/subjectSignedDocuments`);
            dispatch(retrieveSubDocsDataSuccess(res));
        } else {
            dispatch(noLanguageId());
        }
    } catch(error) {
        console.log(error)
        dispatch(retrieveSubDocsDataFailure());
    }
    
}

{/* 
To return the metadata for a reconsented Subject whose status is Needs Signature.
To open all version's historic document, we need the version's metadata for a Needs Signature subject
Note: This method is not require for " Needs Counter Sign subject" & "consented subject"
 For a Needs Counter Sign subject, we are showing only version's documents always
 For a consented subject, we are not opening documents to show signature,
*/}
export const getConsentVersionMetaData = (consentVersionId)  => async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/consentVersions/${consentVersionId}/metaData`
      );
      if(res.data){
        dispatch(storeConsenVersionMetaData(consentVersionId, res.data));
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
};


export const submitConsentWhenAllDocumentsAreOptional = (subjectId, subjectConsentId)  => async (dispatch, getState) => {
    try {
        const subject = {id: subjectId};
        const subjectConsentData = {
           id: subjectConsentId
        };
        const res = await api.post( `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/subjectConsents/${subjectConsentId}/documents/submit?withoutDocuments=${true}`,
              { subject, subjectConsentData });
        return res;
    } catch (error) {
      console.log(error);
    }
};