import React, {Component} from 'react';
import { StyleSheet, View, Text } from 'react-native';
import {Item, Input} from 'native-base';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font'
import { constStyles } from '../../../styles/constStyles';

export default class NumericInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };

      onChange = (value) => {
        const {onChange} = this.props
        onChange(value.replace(/[^0-9]/g,''));
     }

      getCancelIcon(option, value) {
        const { attempts, field } = this.props;
        if (attempts === 2 || field.answer === field.options[value] ) {
          return <Text></Text>
        }
        else {
          if (attempts === 1 && option === field.options[value]) {
            return <MaterialIcons name="cancel" size={28} color={Colors.warning} />
          }
          else
            if (attempts === 0 && option === field.options[value])
              return <MaterialIcons name="cancel" size={28} color={Colors.error} />
        }
      }
    render() {
    const { error, value, onChange, t} = this.props;
        return (
            <View >
            <View style={{ flex: 1}}  >
              <Item regular
              style={{ height:50, borderRadius:5, borderColor:Colors.textInputBorder, marginTop:5 }}
              >
               <Input
                autoFocus 
                placeholder={t('EnterHere')}
                placeholderTextColor={Colors.textdisabled}
                // style={{borderRadius:5, borderColor:Colors.textInputBorder, borderWidth:0.5}}
                value = {value || ''}
                maxLength={500}
               // value={this.state.text}
                onChangeText = {this.onChange}
                style={[constStyles.inputStyles, {fontSize: '18px'}]}
               />
             </Item>
             <View style={styles.errorinfoText}>{this.getError(error)}</View>
         </View>
             </View>
          )
    }

}
const styles = StyleSheet.create({
    errorinfoText: {
        color: Colors.error,
        fontSize: fontType('subFont'), 
      },
})