import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

export default class FormikInput extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
  render() {
    const { error, value, onChange, placeholderValue, maxLength, secureTextEntry, formikProps, formikKey } = this.props;
    return (
      <View style={{flex:1,flexDirection:'column'}}>
        <TextInput
             placeholder={placeholderValue}
             style={styles.inputText}
             onChangeText={formikProps.handleChange(formikKey)}
             onBlur={formikProps.handleBlur(formikKey)}
             fontSize={fontType('BasicFont')}
             maxLength={maxLength || null}
             secureTextEntry={ secureTextEntry || false}
           />
           <Text style={styles.errorinfoText} key={formikProps.touched[formikKey] && formikProps.errors[formikKey]}>{formikProps.touched[formikKey] && formikProps.errors[formikKey]}</Text>
        </View>
    )
  }

}
const styles = StyleSheet.create({

  inputView:{
    //  width:"50%",
     backgroundColor:'blue',
     flexWrap:'wrap',
     flexShrink:1
    // height:50,
    // marginBottom:20,
    // justifyContent:"center",
  },

  inputText:{
    borderColor: '#bdbdbd',
    // borderRadius: 7,
    borderBottomWidth: 2,
    paddingVertical:5,
    textAlign:'left',
    fontFamily:'Inter',
    marginLeft: 3
  },

  errorinfoText: {
    color: Colors.error,
   // width:SCREEN_WIDTH/2,
    fontFamily:'Inter',
    fontSize: fontType('subFont'), 
    marginTop:3,
    marginBottom:3,
    // alignSelf:'left'
  }
})
