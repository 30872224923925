import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardItem,
  Body,
} from "native-base";
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import moment from "moment-timezone";
import {
 MaterialCommunityIcons,
} from "@expo/vector-icons";
import { documentStyles } from "./styles/counterSignDocStyles";
import { Colors } from "../../constants/ui/colorScheme";
import { formatDateTime } from "../../utils/dateFormatUtils";
import _ from "lodash";
import ChapterPage from "../documents/ChapterPage";
import DialogBox from "./DialogBox";
import { chapterButtonStyles } from "../../styles/constStyles";
import { fontType } from "../../styles/font";
import MyLoader from "../../utils/webLoader";
import Authorize from "../common/Authorize";
import privilege from "../constants/privilege";


class CounterSignDocument extends Component {
  state = {
    showSigns: false,
    loading: false,
    isEnableNext: false,
    scrollRef: null,
  };

  componentDidMount() {
    const { navigation } = this.props;
    this._unsubscribe = navigation.addListener("focus", () => {
      //To close the signature section when the document has been opened
      this.hideListOfSigns();
      this.checkIfAllSignsDone();
    });
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  showListOfSigns = () => {
    this.setState({
      showSigns: true,
    });
  };

  hideListOfSigns = () => {
    this.setState({
      showSigns: false,
    });
  };

  renderNode(node) {
    if (node.name == "img") {
      const a = node.attribs;
      return (
        <Image style={{ width: 1000, height: 1000 }} source={{ uri: a.src }} />
      );
    }
  }

  formatDate = (actualDate) => {
    if (actualDate) {
      var timeZone = moment.tz.guess();
      return moment
        .tz(actualDate, timeZone)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    }
    return null;
  };

  checkIfAllSignsDone = () => {
    const { selectedDocument } = this.props;
    // check if all signs are done
    const docSignature = _.filter(selectedDocument?.documentSignatures, {
      signatureUrl: null,
    });
    if (docSignature.length == 0) {
      this.setState({ isEnableNext: true });
      this.hideListOfSigns();
    }
  };

  onNextPress = () => {
    const { scrollRef } = this.state;
    const {
      documentPages,
      selectedDocument,
      documents,
      constructSelectedDocument,
      navigation,
    } = this.props;
    let remainingDocumentPages = _.reject(documentPages, {
      id: selectedDocument?.id,
    });
    if (remainingDocumentPages.length > 0) {
      let navToDocId = null;
      remainingDocumentPages.forEach((doc) => {
        // verify if it has signatures or not
        if (_.has(documents, doc.id)) {
          const subjSignDocument = constructSelectedDocument(doc.id);
          const docSignature = _.filter(subjSignDocument.documentSignatures, {
            signatureUrl: null,
            isOptional: false,
            isDeleted: false,
          });
          if (docSignature.length > 0 && navToDocId == null) {
            navToDocId = doc.id;
          }
        }
      });
      // if no id found navigate to documetlist otherwise to document
      if (navToDocId !== null) {
        navigation.navigate("CounterSignDoc", { documentId: navToDocId });
      } else {
        navigation.navigate("SubjectDetails");
      }
    } else {
      // if no documents navigate to documents list.
      navigation.navigate("SubjectDetails");
    }
  };

  getButtonName = (selectedDocument) => {
    const docSignature = _.filter(selectedDocument?.documentSignatures, {
      signatureUrl: null,
    });
    if (docSignature.length == 0) {
      return "NEXT";
    } else return "SIGNATURES";
  };

  isNextButtonEnable = (selectedDocument) => {
    const docSignature = _.filter(selectedDocument?.documentSignatures, {
      signatureUrl: null,
    });
    let filteredSignaturesList = _.filter(docSignature, (ds) => {
      return ds.isDeleted == false;
    });
    if (filteredSignaturesList.length == 0) {
      return true;
    } else return false;
  };

  setScrollRefToDocs = (ref) => {
    const { scrollRef } = this.state;
    if (scrollRef == null) {
      this.setState({
        scrollRef: ref,
      });
    }
  };

  buildSignaturesWithSorting = (signList) => {
    let signaturesList = _.map(signList, (signature) => {
      if (signature.signatureType == 3) {
        return {
          ...signature,
          signSortNumber: 4,
        };
      } else if (signature.signatureType == 4) {
        return {
          ...signature,
          signSortNumber: 3,
        };
      } else if (signature.signatureType == 2) {
        return {
          ...signature,
          signSortNumber: 2,
        };
      }
      return {
        ...signature,
        signSortNumber: 1,
      };
    });

    signaturesList = _.orderBy(signaturesList, ["signSortNumber"], ["asc"]);
    return signaturesList;
  };

  render() {
    const signs = [1, 2, 3, 4];
    const {
      showDialog,
      closePopup,
      selectedDocumentSignatureId,
      selectedDocSignDeclaration,
      documentID,
      declarationFormFields,
      docId,
      docSignId,
      storeDeclarationFormData,
      formDataList,
      documentLoading,
      subjectDocumentSignatureId,
      siteId,
      studyId,
    } = this.props;
    const {
      selectedDocument,
      navigation,
      navigateToSign,
      selectedSubject,
      hasSigns,
      t,
      selectedUser,
    } = this.props;
    const { showSigns, loading, isEnableNext, scrollRef } = this.state;
    const signatureTypes = [
      "",
      "Subject Signature",
      "LAR Signature",
      "Clinician Counter Signature",
      "Witness Signature",
    ];
    let signaturesList =
      selectedDocument?.documentSignatures
        ? [...selectedDocument.documentSignatures]
        : [];
    signaturesList = this.buildSignaturesWithSorting(signaturesList);
    return (
      <View style={{ flex: 1 }}>
        {!showDialog && (
          <>
            <MyLoader active={documentLoading} />
            <View style={{ flex: showSigns ? 3.7 : 5.5 }}>
              <View style={{ backgroundColor: Colors.canvasbg, zIndex: 2 }}>
                {selectedDocument?.pageTitle ? (
                  <Text style={documentStyles.documentTitle}>
                    {selectedDocument.pageTitle}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </View>
              {/* <Content contentContainerStyle={{flex:5.1}}> */}
              <View style={{ flex: 1, paddingBottom: 5, marginBottom: 30 }}>
                {/* <ScrollView>
                  <HtmlParserEg currentDoc={selectedDocument.content}/>
                </ScrollView> */}
                {/* <HtmlParserEg currentDoc={selectedDocument.content}/> */}
                {/* <HtmlParser currentDoc={selectedDocument.content}/> */}
                <ChapterPage
                  animateVisible={true}
                  currentDoc={selectedDocument}
                  t={t}
                  location="CounterSignDocument"
                  setScrollRefToDocs={this.setScrollRefToDocs}
                />
              </View>
            </View>
            {/* {showSigns && <TouchableOpacity onPress={()=>{this.hideListOfSigns()}} style={{width:wp('100%'),height:hp('7.5%')}}><MaterialCommunityIcons  size={wp('5.5%')}/></TouchableOpacity>} */}
            <View
              style={{
                flex: showSigns ? 2.2 : 0.4,
                justifyContent: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              {!showSigns && (
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      backgroundColor: "rgb(145, 85, 253)",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedDocument?.irbApprovedOn ? (
                      <Text style={{ color: "white" }}>
                        {t("IRBApprovedDate")}: {selectedDocument.irbApprovedOn}{" "}
                      </Text>
                    ) : (
                      <Text />
                    )}
                    {selectedDocument?.documentVersion ? (
                      <Text style={{ color: "white" }}>
                        {" "}
                        {t("Version")}: {selectedDocument.documentVersion}{" "}
                      </Text>
                    ) : (
                      <Text />
                    )}
                  </View>

                  <View
                    style={{
                      // height: hp('6.5%'),
                      backgroundColor: "rgb(145, 85, 253)",
                      flexDirection: "row",
                      justifyContent: this.isNextButtonEnable(selectedDocument)
                        ? "flex-end"
                        : "center",
                    }}
                  >
                    <Authorize hasAnyGrant={[privilege.VIEW_ENCRYPTED_DATA]}>
                      {hasSigns && signaturesList.length != 0 && (
                        <TouchableOpacity
                          transparent
                          onPress={() => {
                            setTimeout(() => {
                              this.showListOfSigns();
                            }, 0);
                          }}
                          style={chapterButtonStyles.button}
                        >
                          <Text style={chapterButtonStyles.buttonTxt}>
                            SIGNATURES
                          </Text>
                        </TouchableOpacity>
                      )}
                    </Authorize>
                    {this.isNextButtonEnable(selectedDocument) && (
                      <TouchableOpacity
                        transparent
                        onPress={() => {
                          scrollRef._root.scrollToPosition(0, 0);
                          setTimeout(() => {
                            this.onNextPress();
                          }, 0);
                        }}
                        style={chapterButtonStyles.button}
                      >
                        <Text style={chapterButtonStyles.buttonTxt}>Next</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
              {showSigns && (
                <View
                  style={{
                    height: Platform.OS == "web" ? hp("60%") : hp("40.5%"),
                    backgroundColor: Colors.background,
                  }}
                >
                  <View
                    style={{
                      height: hp("5%"),
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 20,
                      backgroundColor: Colors.buttonwithbg,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontType("BasicFont"),
                        fontFamily: "Inter",
                        color: Colors.textColorwithbg,
                      }}
                    >
                      Signatures
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        this.hideListOfSigns();
                      }}
                    >
                      <MaterialCommunityIcons
                        name="close"
                        color={Colors.textColorwithbg}
                        size={fontType("ChapterIcon")}
                      />
                    </TouchableOpacity>
                  </View>
                  <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={Platform.OS == "web"}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        padding: wp("1%"),
                      }}
                    >
                      {signaturesList.map((sign) => (
                        <View style={{ flex: 1, marginHorizontal: 4 }}>
                          <Card
                            style={
                              Platform.OS == "web"
                                ? { flex: 1, padding: wp("1%") }
                                : {
                                    flex: 1,
                                    width: wp("55%"),
                                    padding: wp("1%"),
                                  }
                            }
                          >
                            <CardItem header bordered style={{ flex: 1 }}>
                              <Text
                                style={{
                                  fontSize: fontType("BasicFont"),
                                  fontFamily: "Inter",
                                }}
                              >
                                {signatureTypes[sign.signatureType]}
                              </Text>
                            </CardItem>
                            <CardItem bordered style={{ flex: 3 }}>
                              <Body
                                style={{
                                  // flex: 1,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // backgroundColor: 'yellow'
                                }}
                              >
                                {sign.signatureUrl ? (
                                  <Image
                                    source={{
                                      uri: `data:image/png;base64,${sign.signatureUrl}`,
                                    }}
                                    resizeMode="contain"
                                    onLoadStart={() => {
                                      this.setState({
                                        [`${sign.signatureType}loading`]: true,
                                      });
                                    }}
                                    onLoadEnd={() => {
                                      this.setState({
                                        [`${sign.signatureType}loading`]: false,
                                      });
                                    }}
                                    style={{
                                      width: wp("35%"),
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigateToSign(
                                        sign.signatureType,
                                        sign.documentSignatureId,
                                        sign.subjectDocumentSignatureId
                                      )
                                    }
                                    style={{
                                      flex: 1,
                                      width: wp("45%"),
                                      height: hp("17%"),
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        alignSelf: "center",
                                        padding: 30,
                                        color: Colors.buttondisabled,
                                        fontSize: fontType("subFont"),
                                      }}
                                    >
                                      Tap to sign
                                    </Text>
                                  </TouchableOpacity>
                                )}
                                {sign.signatureUrl &&
                                  this.state[`${sign.signatureType}loading`] ===
                                    true && (
                                    <View
                                      style={{
                                        position: "absolute",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <Text style={{ color: "gray" }}>
                                        Loading...
                                      </Text>
                                    </View>
                                  )}
                              </Body>
                            </CardItem>
                            <CardItem
                              footer
                              style={{
                                flexDirection: "column",
                                flex: 2,
                                alignItems: "flex-start",
                                paddingLeft: 10,
                                paddingHorizontal: 5,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: fontType("subFont"),
                                  color: Colors.coloredText,
                                  fontFamily: "Inter",
                                }}
                              >
                                {" "}
                                {sign.signedBy}
                              </Text>
                              {sign.signatureType === 2 && (
                                <Text
                                  style={{
                                    fontSize: fontType("subFont"),
                                    color: Colors.signDetails,
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Relation: {sign.larRelation}
                                </Text>
                              )}
                              <Text
                                style={{
                                  fontSize: fontType("subFont"),
                                  fontFamily: "Inter",
                                  color: Colors.signDetails,
                                }}
                              >
                                Signed On: {formatDateTime(sign.signatureDate)}
                              </Text>
                              {(sign.signatureType === 1 ||
                                sign.signatureType === 2) && (
                                <Text
                                  style={{
                                    fontSize: fontType("subFont"),
                                    color: Colors.signDetails,
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Consent ID: {selectedSubject.consentId}
                                </Text>
                              )}
                              {(sign.signatureType === 1 ||
                                sign.signatureType === 2) && (
                                <Text
                                  style={{
                                    fontSize: fontType("subFont"),
                                    color: Colors.signDetails,
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Subject ID: {selectedSubject.usubjectId}
                                </Text>
                              )}
                            </CardItem>
                          </Card>
                        </View>
                      ))}
                    </View>
                  </ScrollView>
                </View>
              )}
            </View>
          </>
        )}
        {showDialog && (
          <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
            <DialogBox
              currentUserType={3}
              documentID={documentID}
              selectedDocumentSignatureId={selectedDocumentSignatureId}
              navigation={navigation}
              showDialog={showDialog}
              closePopup={closePopup}
              selectedDocSignDeclaration={selectedDocSignDeclaration}
              selectedUser={selectedUser}
              declarationFormFields={declarationFormFields}
              docId={docId}
              docSignId={docSignId}
              t={t}
              storeDeclarationFormData={storeDeclarationFormData}
              formDataList={formDataList}
              selectedSubject={selectedSubject}
              subjectId={selectedSubject.id}
              subjectConsentId={selectedSubject.subjectConsentId}
              subjectDocumentSignatureId={subjectDocumentSignatureId}
              siteId={siteId}
              studyId={studyId}
            />
          </View>
        )}
      </View>
    );
  }
}

CounterSignDocument.propTypes = {
  t: PropTypes.string,
  selectedDocument: PropTypes.shape({
    id: PropTypes.string,
    documentSignatures: PropTypes.arrayOf(
      PropTypes.shape({
        signatureType: PropTypes.number,
        signatureUrl: PropTypes.string,
        documentSignatureId: PropTypes.number,
        subjectDocumentSignatureId: PropTypes.number,
        signedBy: PropTypes.string,
        signatureDate: PropTypes.string,
        isOptional: PropTypes.bool,
        isDeleted: PropTypes.bool,
        larRelation: PropTypes.string,
      })
    ),
    pageTitle: PropTypes.string,
    content: PropTypes.string,
    irbApprovedOn: PropTypes.string,
    documentVersion: PropTypes.string,
  }),
  navigation: PropTypes.object.isRequired,
  navigateToSign: PropTypes.func,
  selectedSubject: PropTypes.shape({
    consentId: PropTypes.string,
    usubjectId: PropTypes.string,
    id: PropTypes.string,
    subjectConsentId: PropTypes.string,
  }),
  hasSigns: PropTypes.bool,
  selectedUser: PropTypes.object,
  documentPages: PropTypes.arrayOf(PropTypes.object),
  documents: PropTypes.object,
  constructSelectedDocument: PropTypes.func,
  showDialog: PropTypes.bool,
  closePopup: PropTypes.func,
  selectedDocumentSignatureId: PropTypes.number,
  selectedDocSignDeclaration: PropTypes.string,
  documentID: PropTypes.string,
  declarationFormFields: PropTypes.array,
  docId: PropTypes.string,
  docSignId: PropTypes.string,
  storeDeclarationFormData: PropTypes.func,
  formDataList: PropTypes.array,
  documentLoading: PropTypes.bool,
  subjectDocumentSignatureId: PropTypes.string,
  siteId: PropTypes.string,
  studyId: PropTypes.string,
};

CounterSignDocument.defaultProps = {
  t: "",
  selectedDocument: {},
  navigateToSign: () => {},
  selectedSubject: {},
  hasSigns: false,
  selectedUser: {},
  documentPages: [],
  documents: {},
  constructSelectedDocument: () => {},
  showDialog: false,
  closePopup: () => {},
  selectedDocumentSignatureId: null,
  selectedDocSignDeclaration: "",
  documentID: "",
  declarationFormFields: [],
  docId: "",
  docSignId: "",
  storeDeclarationFormData: () => {},
  formDataList: [],
  documentLoading: false,
  subjectDocumentSignatureId: "",
  siteId: "",
  studyId: "",
};
export default CounterSignDocument;
