import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetAppToInitialState } from '../actions/rootResetAction';
import Constants from '../utils/constants';
import { isValidString } from '../utils/util';
import api from '../utils/api';
import { PropTypes } from 'prop-types';

const LoginPage = ({ navigation }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLoginRequest = async () => {
      window.sessionStorage.clear();

      try {
        const orgId = navigation.getParam('primaryOrgCode');
        const siteId = navigation.getParam('orgCode');
        const res = await api.get(`/${orgId}/${siteId}/loginRequest`);
        if(res.data === "") {
          navigation.navigate('Admin', { 
            screen: 'ClientInactivated'})
        }
        if (isValidString(orgId) && isValidString(siteId) && isValidString(res.data.id)) {
          const redirectUrl = `${Constants.ADMIN_PORTAL_BASE_URL}/webappLanding`;
          window.sessionStorage.setItem('loginRequestId', res.data.id);
          window.location.assign(`${Constants.ADMIN_PORTAL_BASE_URL}/login?requestId=${res.data.id}&redirectUrl=${redirectUrl}`);
        } else {
          dispatch(resetAppToInitialState());
        }
      } catch (error) {
        console.error('Error fetching login request:', error);
      }
    };

    fetchLoginRequest();
  }, [navigation]);

  return null;
};
LoginPage.propTypes = {
  navigation: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default LoginPage;
