import { SUB_DOCUMENTS_DATA_REQUEST, 
         SUB_DOCUMENTS_DATA_SUCCESS, 
         SUB_DOCUMENTS_DATA_FAILURE,
         NO_LANGUAGE_ID,
         STORE_DOCUMENT_FORM_DATA_LIST,
         RETRIEVE_FORM_DATA_LIST_SUCCESS,
         CLEAR_FORM_DATA,
         STORE_DECLARATION_FORM_DATA,
         STORE_CONSENT_VERSIONS_METADATA,
       } from '../actions/documents';
import { DOCUMENT_SIGN_SAVE_SUCCESS } from '../actions/documentSignature';
import {UPDATE_PRINT_AND_SIGN_DATA} from '../actions/printNSign'
import initialState from '../store/initialState';
import _ from 'lodash';

export default (state = initialState.documents, action) => {
  switch (action.type) {
    case SUB_DOCUMENTS_DATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case SUB_DOCUMENTS_DATA_SUCCESS:
    return {
      ...state,
      documents: action.subjectDocData,
      loading: false,
    };
  case SUB_DOCUMENTS_DATA_FAILURE:
    return {
      ...state,
      loading: false,
    };
  case DOCUMENT_SIGN_SAVE_SUCCESS:
    const documentId = action.documentId;
    const dsType = action.dsType;
    const subDocSign = action.subDocSign;
    let documentSigns = {...state.documents};
    let oldSignatures = documentSigns[documentId];
    const oldDocSign = oldSignatures.filter(os => os.signatureType === dsType && os.documentId === documentId)[0];
    const newDocSign = {
      ...oldDocSign,
      signatureUrl: subDocSign.signatureFilePath,
      signedBy: subDocSign.signedBy,
      signatureDate: subDocSign.signatureDate
    };
    _.remove(oldSignatures,(os => os.signatureType === oldDocSign.signatureType));
    const newSigns = [...oldSignatures, newDocSign];
    documentSigns[documentId] = newSigns;
    return {
      ...state,
      documents: documentSigns,
      loading: false,
    };
    case NO_LANGUAGE_ID:
    return {
      ...state,
      documents: {},
      loading: false,
    };
    case STORE_DOCUMENT_FORM_DATA_LIST:
      return {
        ...state,
        formDataList: {
          ...state.formDataList,
          [action.formDataList.chapterPageId]: action.formDataList.formDataList,
        }
      }
      case STORE_DECLARATION_FORM_DATA:
        return {
          ...state,
          formDataList: {
            ...state.formDataList,
            [action.formDataList.documentSignatureId]: action.formDataList.formDataList,
          }
        }    
     case RETRIEVE_FORM_DATA_LIST_SUCCESS: {
      return {
        ...state,
        formDataList: {
          ...action.formDataList,
        }
      }
     }
     case CLEAR_FORM_DATA: {
      return {
        ...state,
        formDataList: {}
      }
     }
     case STORE_CONSENT_VERSIONS_METADATA: {
      return {
        ...state,
        consentVersionsMetadata: {
          ...state.consentVersionsMetadata,
          [action.consentVersionId]: action.metadata
        }
      }
     }
    case UPDATE_PRINT_AND_SIGN_DATA:
      let docLanguages = [...state.documents?.[action.documentId]]
      docLanguages =  _.map(docLanguages, language => {
        return {
          ...language,
          blankPdfUrl: action.blankPdfUrl!=null? action.blankPdfUrl: language.blankPdfUrl,
          isSignatureCompleted: action.signatureCompleted!=null? action.signatureCompleted: language.isSignatureCompleted
        }
      })  
        return {
          ...state,
        documents: {
          ...state.documents,
          [action.documentId]: docLanguages
        },  
    }   
  default:
    return state;
  }

};

