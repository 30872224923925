import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from 'native-base';
import {
    TouchableOpacity, View, Text
} from "react-native";
import {getConsentChapter} from '../utils/consentData';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import DownloadVideosAndStoreLanguages from '../components/DownloadVideosAndStoreData';

class DownloadVideosAndStoreData extends Component {
    static contextType = ConsentDataContext;

    render() {
      const {navigation, deviceNo} = this.props;
      const studySiteId = navigation.getParam('studySiteId');
        return (
            <DownloadVideosAndStoreLanguages
                navigation = {navigation}
                studySiteId = {studySiteId}
                deviceNo = {deviceNo}
            />
        );
    }
}

const mapStateToProps = state => ({
   deviceNo: state.consentTokenData.deviceNo,
});

// const mapDispatchToProps = dispatch => bindActionCreators(
//     {
               
//     },
//     dispatch,
//   );

export default connect(mapStateToProps, null)(DownloadVideosAndStoreData);