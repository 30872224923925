import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';


export const styles = StyleSheet.create({
    text: {
        fontWeight: 'bold',
        color: Colors.textColorwithbg,
    },
    container: {
        flex: 1,
        backgroundColor: Colors.canvasbg,
        overflow: 'visible',
    },
    scrollView: {
        backgroundColor: Colors.canvasbg,
        // marginHorizontal: 20,
      },
    bodyView: {
        width: wp('100%'),
        flex: 4
    },
    cancelButton: {
        borderRadius: 5,
        backgroundColor: Colors.cancelButton,
        justifyContent:'center',
        alignSelf: 'center',
        elevation: 0,
        padding: 30,
        marginBottom:50
    },
    textNoteStyle :{
        alignItems:'flex-end',
        textAlign:'left',
        justifyContent:'flex-end',
        alignContent:'flex-end',
        fontSize: wp('2.5%'),
        paddingBottom:hp('5%'),
        paddingLeft:wp('5%'), 
        color:'grey',
    },
  msgContainer: {flex: 0.5, alignSelf: 'center', marginTop: 20, marginBottom: 20},
  msgText: {
    fontFamily: 'Inter',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginBottom: 20,
    color: Colors.error,
  },
});

export const canvasStyles = `
.m-signature-pad--footer
.button {   
    font-size: ${hp('2.5%')}px;
    color: ${Colors.button};
    background-color: transparent;
}
body{
    background: ${Colors.canvasbg};
}
.m-signature-pad--footer
.button.clear {
background-color: transparent;
color: ${Colors.textColor};

}
.m-signature-pad--footer
.button.save {
background-color: transparent;
color: ${Colors.fieldSubmit};
border-radius: 5px;
}`;