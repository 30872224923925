import React, { Component } from 'react';
import { Container, Content, CardItem, Card, Body, Button } from 'native-base';
import { View, Text, Alert, ScrollView, TouchableOpacity, TouchableHighlight } from 'react-native';
import SearchInput, { createFilter } from 'react-native-search-filter';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { RadioGroup, RadioButton } from 'react-native-flexi-radio-button';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { styles } from './styles/pickSignatureTypeStyle';
import LARForm from './LARForm';
import { createForm, } from "rc-form";
import { Colors } from '../../constants/ui/colorScheme';
import {fontType } from '../../styles/font';
import {cosntStyles, constStyles} from '../../styles/constStyles';

class PickSignature extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showLARForm: false,
         selectedType: '',
         value: '',
      }
   }

   onSelect = (index, value) => {
      this.setState({ selectedType: value });
      if (value == 'lar') {
         this.setState({ showLARForm: true });
      } else if (value == 'subject') {
         this.setState({ showLARForm: false });
      }
   };

   onSubmit = () => {
      const { navigation, storeLARData, form,storeSignatureType } = this.props;
      const { selectedType } = this.state;
      form.validateFields((error, values) => {
         if (error) {
         } else {
            storeLARData(values);
            if (selectedType == 'subject') {
               storeSignatureType(1);
               navigation.navigate('SubDocsPage', { signatureType: 1 });
            } else if (selectedType == 'lar') {
               storeSignatureType(2);
               navigation.navigate('SubDocsPage', { signatureType: 2 });
            }
         }
      });

   }

   render() {
      const { selectedType, showLARForm } = this.state;
      const { form, storeLARData, screenProps } = this.props;
      const {t} = screenProps;
      return (
         <ScrollView  style={{ backgroundColor:'#fff' }}>
            <View style={{ padding:7, flex: 1, flexWrap:'wrap'}} >
               <View style={{padding:5, margin:15}}>
                  <Text style={styles.qnText}>{t('WhoInfC')}</Text>
                  <RadioGroup
                     size={fontType('BasicFont')}
                     thickness={3}
                     color= {Colors.radioGroup}
                     onSelect={(index, value) => this.onSelect(index, value)}
                  >
                     <RadioButton style={{ }}
                        value='subject'
                        color={Colors.radioGroup}
                     >
                        {/* <TouchableHighlight> */}
                           <Text style={styles.optTxt}>{t('SubjInfc')}</Text>
                        {/* </TouchableHighlight> */}
                     </RadioButton>
                     <RadioButton
                        value='lar'
                        color={Colors.radioGroup}
                     >
                        {/* <TouchableOpacity> */}
                           <Text style={styles.optTxt}>{t('LarInf')}</Text>
                        {/* </TouchableOpacity> */}
                     </RadioButton>
                  </RadioGroup>
               </View>
               {/* --------------SHOW LAR FORM------------------------------- */}
               {showLARForm && <LARForm form={form} storeLARData = {storeLARData} screenProps={screenProps} />}

               {/* //-------------------Button------------------------// */}
               
            </View>
            <View style ={{marginTop:10}}>
               {selectedType == '' ?
                  <TouchableOpacity disabled
                     onPress={() => this.onSubmit()}
                     style={[constStyles.buttonStyle,{backgroundColor: Colors.buttondisabled}]}
                  >
                     <Text style={[constStyles.buttonTxt,]} >{t('Submit')}</Text>
                  </TouchableOpacity>
                  :
                  <TouchableOpacity
                     onPress={() => this.onSubmit()}
                     style={constStyles.buttonStyle}
                  >
                     <Text style={constStyles.buttonTxt} >{t('Submit')}</Text>
                  </TouchableOpacity>
               }
               </View>
         </ScrollView>
      );
   }
}

export default createForm()(PickSignature);
