import SubjectEdit from "../components/subjectList/SubjectEdit";
import React, { Component } from "react";
import AddSubject from "../components/subjectList/AddSubject";
import { ConsentDataContext } from "../providers/ConsentDataProvider";
import { getLanguageList } from "../utils/consentData";
import SubjectSignUp from "../components/subjectList/SubjectSignUp";
import SubjectSignIn from "../components/subjectList/SubjectSignIn";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  storeSelectedSubject,
  storeSelectedSubjectDetailsinSubject,
  storeClientUserDetails,
} from "../actions/subject";
import { storeSubjectRemoteConsentDto } from "../actions/subjectRemoteConsentDto";
import { setDocuments } from "../actions/subjectConsentDocument";
import { retrieveSubDocsData } from "../actions/documents";

class SubjectSignUpScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectList: [],
    };
  }
  componentDidMount = () => {
    // updateConsentStatus(true);
  };
  render() {
    const {
      selectedSubject,
      subjectRemoteConsentDto,
      storeSelectedSubjectDetailsinSubject,
      storeSelectedSubject,
      navigation,
      screenProps,
      selectedUser,
      currentUserType,
      setDocuments,
      storeSubjectRemoteConsentDto,
      retrieveSubDocsData,
      storeClientUserDetails,
      sponsorName,
    } = this.props;
    let isRemoteConsent = false;
    if (
      typeof navigation.getParam("isRemoteConsent") == "boolean" &&
      navigation.getParam("isRemoteConsent") == true
    ) {
      isRemoteConsent = true;
    }
    if (
      typeof navigation.getParam("isRemoteConsent") == "string" &&
      navigation.getParam("isRemoteConsent") == "true"
    ) {
      isRemoteConsent = true;
    }
    let data = {};
    if (isRemoteConsent) {
      data = {
        subjectName: subjectRemoteConsentDto.fullName,
        email: subjectRemoteConsentDto.email,
        siteName: subjectRemoteConsentDto.siteName,
      };
    } else {
      data = {
        subjectName: selectedSubject.fullName,
        email: selectedSubject.email,
        siteName: selectedUser.client.name,
      };
    }
    let subjectId = null;
    if (isRemoteConsent) {
      subjectId = subjectRemoteConsentDto.subjectId;
    } else {
      subjectId = selectedSubject.id;
    }
    return (
      <SubjectSignUp
        setDocuments={setDocuments}
        subjectId={subjectId}
        data={data}
        navigation={navigation}
        screenProps={screenProps}
        storeSelectedSubjectDetailsinSubject={
          storeSelectedSubjectDetailsinSubject
        }
        storeSelectedSubject={storeSelectedSubject}
        studySiteId={subjectRemoteConsentDto.studySiteId}
        currentUserType={currentUserType}
        subjectRemoteConsentDto={subjectRemoteConsentDto}
        storeSubjectRemoteConsentDto={storeSubjectRemoteConsentDto}
        retrieveSubDocsData={retrieveSubDocsData}
        storeClientUserDetails={storeClientUserDetails}
        sponsorName={sponsorName}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedSubject: state.selectedSubject,
    selectedUser: state.clientUser.selectedUser,
    subjectRemoteConsentDto: state.subjectRemoteConsentDto,
    currentUserType: state.consentFlow.currentUserType,
    sponsorName: state.subjectRemoteConsentDto.sponsorName,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeSelectedSubjectDetailsinSubject,
      storeSelectedSubject,
      setDocuments,
      storeSubjectRemoteConsentDto,
      retrieveSubDocsData,
      storeClientUserDetails,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectSignUpScreen);
