import {StyleSheet,Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';

const {height, width} = Dimensions.get('window');
export const styles = StyleSheet.create(
    {
      listItemStyle: {
        flex:1,
        flexDirection:"column",
        alignItems:"flex-start",
      },
      searchbarStyle: {
          paddingLeft: 25,
          backgroundColor: Colors.searchBg,
          height: 45,
          width: width-70,
          fontSize:25,
          justifyContent: "center",
          borderRadius: 10,
      },
      userName: {
        fontSize: 30,
      },
      roleName: {
        fontSize: 20,
      },
      searchbarContainer: {
        margin: 2,
        backgroundColor: Colors.searchBg,
      },
      list: {
        paddingLeft: 10,
      },
      holderStyle: {
        paddingLeft: 20
      },
      title: {
        // padding:10,
        backgroundColor: Colors.background
      }

    }
)
