
import initialState from '../store/initialState';
  
  export default (state = initialState.loading, action) => {
     if (action.type.match(/.*SUCCESS/)) {
        return false;
      } else if (action.type.match(/.*FAILURE/)) {
        // showToast(localeStore.FailedRetrieve, 'danger', 3000);
        return false;
      } else if (action.type.match(/.*AILURE/)) {
        return false;
      }
      else if (action.type.match(/.*REQUEST/)) {
        return true;
      } else {
        return state;
      }
  };