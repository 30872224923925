import React, { Component } from 'react';
import { View, Item, Input, Text } from 'native-base';
import RadioInitialsFormInput from './RadioInitialsFormInput';

export default class RadioInitialsFieldDecorator extends Component{

  constructor(props) {
    super(props);
    this.inputRef = {
      focus: () => {},
    }
  }

    setFieldValue = async (value) => {
        const { form, formField, onValueChange } = this.props;
        await form.setFieldsValue({
            [formField.fieldOid]:value
        });
        onValueChange();
    }

    validateRadioInitials = (rule, value, callback) => {
        const { form, formField, t } = this.props;
        
        let fv = form.getFieldValue(formField.fieldOid);
        fv = JSON.parse(fv)
        if(fv && (!fv.value1 || !fv.value2)) {
            callback (t('RadioInitialsVal'))
        }
        callback ();
      };
    

    render() {
      const { form, formField, t, location, scrollToField, fieldKey, focusInvalidField, currentUserType} = this.props;
      if(form.getFieldError(`${formField.fieldOid}`) && focusInvalidField) {
        scrollToField(fieldKey)
      }
      return (
        <View 
            ref={ele => this.inputRef = ele}
            style={{ paddingBottom: 5}}>
          {form.getFieldDecorator(`${formField.fieldOid}`, {
            initialValue: formField.formData.fieldValue,
            rules: [
                { 
                    required: formField.isRequired,
                    message: t('RadioInitialsVal'),
                }, {
                    validator: this.validateRadioInitials,
                }
            ],
        })(
          <RadioInitialsFormInput
            formField = {formField}
            error={form.getFieldError(`${formField.fieldOid}`)}
            setFieldValue={this.setFieldValue}
            location={location}
            t={t}
            key={formField.fieldOid}
            currentUserType={currentUserType}
          />
        )}
        {form.getFieldError(`${formField.fieldOid}`) ? <Text style={{
          fontSize: 12,
          color: '#f44336',
          alignSelf: 'flex-start',
          marginLeft: 4,
          borderRadius: 5,
          padding: form.getFieldError(`${formField.fieldOid}`) ? 5 : 0
        }}>{form.getFieldError(`${formField.fieldOid}`)}</Text> : <Text/>}
        </View>
      )
    }
}
