import React, { Component } from "react";
import { Card } from "native-base";
import {
  Text,
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  TextInput,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { styles } from "./styles/subjectValidateOtpScreenStyles";
import { KeyboardAvoidingView, Image } from "react-native";
import FormikInput from "./FormikInput";
import { Colors } from "../../constants/ui/colorScheme";
import api from "../../utils/api";
import showToast from "../../utils/toast";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import MyLoader from "../../utils/webLoader";
import { th } from "date-fns/locale";
import { passwordRulesArr } from "../common/constants";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants

class SubjectResetPassword extends Component {
  state = { loading: false };

  onSubmit = async (values) => {
    const {
      navigation,
      subjectId,
      from,
      subDocSignNavProps,
      isRemoteConsent,
      signatureType,
      transactionId,
      currentUserType,
      userName,
    } = this.props;
    const subject = {
      id: subjectId,
      loginPassword: values.newPassword,
    };
    this.setState({ loading: true });
    try {
      const res = await api.post(`${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subject.id}/resetPassword`
        , {
        subject,
        transactionId,
        userName,
        userType: currentUserType,
      });
      this.setState({ loading: false });
      showToast("Saved successfully.", "success", 2000);
      if (from == "SUBJECT_SIGNIN") {
        navigation.replace("SubjectSignInScreen", {
          isRemoteConsent: isRemoteConsent,
        });
      } else if (from == "SUBJCET_AUTH_POPUP") {
        // navigation.replace("SubjectDocumentSignScreen");
        navigation.navigate("Consent", {
          screen: "SubDocsPage",
          params: {
            documentSignatureId: subDocSignNavProps.documentSignatureId,
            signatureType: signatureType,
          },
        });
      } else if (from == "SUBJECT_SIGNIN_PASSWORDEXPIRED") {
        if (isRemoteConsent) {
          navigation.navigate("Consent", { screen: "MetaDataLoading" });
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      if (error.response) {
        showToast("Failed to save.", "danger", 2000);
      } else {
        showToast("Network Error.", "danger", 2000);
      }
      console.log(error);
    }
  };

  render() {
    const { loading } = this.state;
    const { siteDetails, userName } = this.props;
    const email = siteDetails.email;
    const name = siteDetails.fullName;
    return (
      <ScrollView>
        <KeyboardAvoidingView behavior="padding" enabled>
          <MyLoader active={loading} />

          {/* --------------------- Card ---------------------------- */}
          <View style={{ justifyContent: "center", marginTop: 10 }}>
            <Card
              style={[
                styles.container,
                Platform.OS !== "web" && { marginTop: 30 },
              ]}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {/* ------------------ Label ---------------------- */}
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Text style={styles.heading}>Reset Password</Text>
                </View>

                <Formik
                  initialValues={{
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={yup.object().shape({
                    newPassword: yup
                      .string()
                      .label("Password")
                      .required("Please enter New Password.")
                      .min(8, "Minimum 8 characters.")
                      .max(64, "Maximum 64 characters.")
                      .test(
                        siteDetails.siteName,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              siteDetails.siteName.toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        userName,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              userName.toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        name,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              name.split(" ")[0].toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        name,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              name.split(" ")[1].toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        siteDetails.sponsorName,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              siteDetails.sponsorName.toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        email,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              email.split("@")[0].toLowerCase()
                            )
                          );
                        }
                      )
                      .test(
                        email,
                        "Password did not match criteria.",
                        function (value) {
                          return (
                            value &&
                            !_.includes(
                              value.toLowerCase(),
                              email.toLowerCase()
                            )
                          );
                        }
                      )
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1{4})(?!.*(abcde|bcdef|cdefg|defgh|efghi|fghij|ghijk|hijkl|ijklm|jklmn|klmno|lmnop|mnopq|nopqr|opqrs|pqrst|qrstu|rstuv|stuvw|tuvwx|uvwxy|vwxyz|01234|12345|23456|34567|45678|56789|43210|54321|65432|76543|87654|98765)+)(?=.*[`~!@#$%^&*()_\-+={}[\];:"',.\/<>?\\|])[A-Za-z\d\S]{8,64}$/,
                        "Password did not match criteria."
                      ),
                    confirmPassword: yup
                      .string()
                      .required("Please re-enter New Password.")
                      .label("Confirm password")
                      .test(
                        "passwords-match",
                        "Two passwords that you enter are inconsistent.",
                        function (value) {
                          return this.parent.newPassword === value;
                        }
                      ),
                  })}
                >
                  {(formikProps) => (
                    <React.Fragment>
                      {/* ------------------ New password ---------------------- */}
                      <FormikInput
                        placeholderValue={"New Password"}
                        formikProps={formikProps}
                        formikKey={"newPassword"}
                        secureTextEntry={true}
                      />

                      {/* ------------------ Confirm password ---------------------- */}
                      <FormikInput
                        placeholderValue={"Confirm Password"}
                        formikProps={formikProps}
                        formikKey={"confirmPassword"}
                        secureTextEntry={true}
                      />

                      {/* ------------- Submit --------------------- */}
                      <View style={{ flex: 1 }}>
                        <TouchableOpacity
                          success
                          style={constStyles.buttonStyle}
                          onPress={formikProps.handleSubmit}
                        >
                          <Text style={constStyles.buttonTxt}>SUBMIT</Text>
                        </TouchableOpacity>
                      </View>
                    </React.Fragment>
                  )}
                </Formik>

                {/* ------------------ Password rules ---------------------- */}
                <View style={{ justifyContent: "center", marginTop: 10 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      paddingBottom: 5,
                      fontSize: 12,
                      fontFamily: "Inter",
                    }}
                  >
                    Password Rules :
                  </Text>
                  <View style={{ marginLeft: 20 }}>
                    {passwordRulesArr.map((data) => (
                      <Text style={styles.passwordRules}>
                        &#8226; {data.label}
                      </Text>
                    ))}
                  </View>
                </View>
              </View>
            </Card>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    );
  }
}

export default SubjectResetPassword;
