import React, { Component } from 'react';
import { Card, Content } from 'native-base';
import { View, Text, Dimensions, Platform } from 'react-native';
import { fieldType } from './fieldTypes';
import _ from 'lodash';
import AnimatedHideView from 'react-native-animated-hide-view';
import HintScreen from './Hint';
import { styles } from './styles/knowledgeReviewStyles';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')


export default class QuestionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }       
    }
    render() {
        const { form, currentOrdinal, handleShowHintModalVisibility, knowledgeReview,
            hintLableVisible, attempts, t, handleWrongIcon, showWrongIcon, setScrollRef, scrollRef, getCurrentField } = this.props;
        const {currenFieldPosition} = this.state;

        if(Platform.OS == 'web') {
            return(
                <View style={{ flex: 1 }}>

                <View>
                    {/* Render Hint Label */}
                    {hintLableVisible ? <HintScreen t={t} handleShowHintModalVisibility={handleShowHintModalVisibility} attempts={attempts} getCurrentField={() => getCurrentField()}/> : <Text></Text>}
                </View>

                <Content
                    ref={c => {
                        this.component = c;
                        if (setScrollRef && scrollRef == null) {
                            setScrollRef(c);
                        }
                    }}
                >
                    <View>
                        {knowledgeReview.quizItems.map(currentField =>
                            <AnimatedHideView
                                duration={150}
                                visible={currentField.ordinal === currentOrdinal ? true : false}
                                // style={ [{zIndex: currentOrdinal === currentField.ordinal ? 3 : 0 },Platform.OS !== 'web' &&{flex: 1, paddingLeft: wp('3%'), paddingRight: wp('3%'), width: Dimensions.get('window').width, position: 'absolute'}]}>
                                style={SCREEN_WIDTH > 1024 ? { position: 'absolute', zIndex: currentOrdinal === currentField.ordinal ? 3 : 0, width: '100%' } :
                                    { zIndex: currentOrdinal === currentField.ordinal ? 3 : 0, flex: 1, paddingLeft: wp('3%'), paddingRight: wp('3%'), width: Dimensions.get('window').width, position: 'absolute' }}>

                                {/* Render Question */}
                                <Card style={{ flex: 1, paddingVertical: 30, marginLeft: 30, marginRight: 30 }}>
                                    <Text includeFontPadding key={currentField.id} style={styles.question}>{currentField.question}</Text>
                                    {/* Render Field */}
                                    <View style={styles.field}>
                                        {fieldType(currentField, form, attempts, t, handleWrongIcon, showWrongIcon, this.scrollToField, this.setCurrentFieldPosition)}
                                    </View>
                                </Card>
                            </AnimatedHideView>)}
                    </View>
                </Content>
            </View>
            );
        } else {
            return(
                <View >
                    {knowledgeReview.quizItems.map(currentField =>
                    <AnimatedHideView
                        duration={150}
                        visible={currentField.ordinal === currentOrdinal ? true : false}
                        // style={ [{zIndex: currentOrdinal === currentField.ordinal ? 3 : 0 },Platform.OS !== 'web' &&{flex: 1, paddingLeft: wp('3%'), paddingRight: wp('3%'), width: Dimensions.get('window').width, position: 'absolute'}]}>
                        style={SCREEN_WIDTH > 1024 ? { position: 'absolute', zIndex: currentOrdinal === currentField.ordinal ? 3 : 0, width: '100%' } :
                            { zIndex: currentOrdinal === currentField.ordinal ? 3 : 0, flex: 1, paddingLeft: wp('3%'), paddingRight: wp('3%'), width: Dimensions.get('window').width, position: 'absolute' }}>

                        {/* Render Hint Label */}
                        {hintLableVisible ? <HintScreen t={t} handleShowHintModalVisibility={handleShowHintModalVisibility} attempts={attempts} /> : <Text></Text>}
                        {/* Render Question */}
                        <Card style={{ flex: 1, paddingVertical: 30, marginLeft: 30, marginRight: 30 }}>
                            <Text includeFontPadding key={currentField.id} style={styles.question}>{currentField.question}</Text>
                            {/* Render Field */}
                            <View style={styles.field}>
                                {fieldType(currentField, form, attempts, t, handleWrongIcon, showWrongIcon)}
                            </View>
                        </Card>
                    </AnimatedHideView>)}
                </View>
            );
        }       
    }
}