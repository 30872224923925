import { AsyncStorage } from "react-native";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import AppReducer from "../reducers/appReducer";
import { createTransform } from "redux-persist";
import { stringify, parse } from "flatted";
import { composeWithDevTools } from "redux-devtools-extension";
import sessionStorage from "redux-persist/lib/storage/session";

const initialState = {};

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
);

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "root",
  keyPrefix: "",
  // Storage Method (React Native)
  storage: sessionStorage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    "subject",
    "documents",
    "clientUser",
    "studySite",
    "selectedSubject",
    "consentTokenData",
    "hasUpdate",
    "hasAppUpdate",
    "currentSubDocPosition",
    "documentChapter",
    "study",
    "subjectRemoteConsentDto",
    "currentSubDocPosition",
    "selectedLanguage",
    "subjectConsentDocument",
    "consentFlow",
    "clientUserDetails",
    "printNSign",
    "session",
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    // 'subject'
  ],
  transforms: [transformCircular],
};

const persistedReducer = persistReducer(persistConfig, AppReducer);

const middleware = [thunk];

const composedEnhancers = composeWithDevTools(
  // applyMiddleware(...middleware),
  { trace: true, traceLimit: 25 }
);

const store = createStore(
  persistedReducer,
  initialState,
  composedEnhancers(applyMiddleware(...middleware))
);

let persistor = persistStore(store);

export { store, persistor };
