import SubjectEdit from '../components/subjectList/SubjectEdit';
import React, { Component } from "react";
import AddSubject from '../components/subjectList/AddSubject';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { getLanguageList } from '../utils/consentData';
import SubjectSignIn from '../components/subjectList/SubjectSignIn';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { storeSelectedSubject, storeSelectedSubjectDetailsinSubject,  storeClientUserDetails} from '../actions/subject';
import { setDocuments } from '../actions/subjectConsentDocument';
import { retrieveSubDocsData } from '../actions/documents';

class SubjectSignInScreen extends Component {
   constructor(props) {
      super(props);
      this.state = {
         subjectList: [],
      };
   }
   componentDidMount = () => {
    
  }
   render() {

      const { navigation, screenProps,selectedSubject,selectedUser, subjectRemoteConsentDto, storeSelectedSubjectDetailsinSubject, 
         storeSelectedSubject, setDocuments, currentUserType, retrieveSubDocsData, storeClientUserDetails} = this.props;
      let isRemoteConsent = false;
      if(typeof navigation.getParam('isRemoteConsent') == 'boolean' && navigation.getParam('isRemoteConsent') == true) {
        isRemoteConsent = true;
      } 
      if(typeof navigation.getParam('isRemoteConsent') == 'string' && navigation.getParam('isRemoteConsent') == "true") {
        isRemoteConsent = true;
      } 
      let data = {};
      if(isRemoteConsent){
         data = {
            subjectName: subjectRemoteConsentDto.fullName,
            email : subjectRemoteConsentDto.email,
            siteName: subjectRemoteConsentDto.siteName,
        }
      } else {
         data = {
            subjectName: selectedSubject.fullName,
            email : selectedSubject.email,
            siteName: selectedUser.client.name,
         }
      }
      let subjectId = null;
      let userName = null;
      if(isRemoteConsent){
         subjectId = subjectRemoteConsentDto.subjectId;
         userName = subjectRemoteConsentDto.userName;
      } else {
         subjectId = selectedSubject.id;
      }
      return (
        <SubjectSignIn
        setDocuments={setDocuments}
        subjectId = {subjectId}
        data={data}      
        navigation = {navigation}
        screenProps={screenProps}
        storeSelectedSubjectDetailsinSubject={storeSelectedSubjectDetailsinSubject}
        storeSelectedSubject={storeSelectedSubject}
        currentUserType = {currentUserType}
        userName = {userName}
        retrieveSubDocsData = { retrieveSubDocsData }
        storeClientUserDetails={storeClientUserDetails}
        subjectDetails={subjectRemoteConsentDto}
        />
      );
   }
}

const mapStateToProps = state => {
   return {
      selectedSubject: state.selectedSubject,
      selectedUser: state.clientUser.selectedUser,
      subjectRemoteConsentDto: state.subjectRemoteConsentDto,
      currentUserType: state.consentFlow.currentUserType
   };
 };

 const mapDispatchToProps = dispatch => bindActionCreators(
   {
     storeSelectedSubjectDetailsinSubject,
     storeSelectedSubject,
     setDocuments,
     retrieveSubDocsData,
     storeClientUserDetails
   },
   dispatch,
 );

 export default connect(mapStateToProps, mapDispatchToProps)(SubjectSignInScreen);





