import { FIELD_NAME, excludedKeysInReasonForChange } from "./Constants";
import _ from 'lodash'

export const isAnyValueUpdated = (oldObj, newObj, getValueOfField ) => {
    const keys = Object.keys(newObj)
    for(let index = 0; index <= keys.length ; index++) {
        const loKey = keys[index]
        if(!_.includes(excludedKeysInReasonForChange, loKey)){
            const newValue = getValueOfField(loKey, newObj[loKey])
            const oldValue = getValueOfField(loKey, oldObj[loKey])
            if(!_.isEqual(newValue, oldValue) && !_.isEmpty(oldValue)){
                return true;
            }
        }
    }
    return false;
}


export const computeChanges = (oldObj, newObj, getValueOfField) => {
    let changes = []
    const keys = Object.keys(newObj)
    _.map(keys, loKey => {
        if(!_.includes(excludedKeysInReasonForChange, loKey)){
            const newValue = getValueOfField(loKey, newObj[loKey])
            const oldValue = getValueOfField(loKey, oldObj[loKey])
            if(!_.isEqual(newValue, oldValue) && !_.isEmpty(oldValue)){
               const changeObj = {
                key: FIELD_NAME[loKey],
                previousValue: oldValue,
                currentValue: newValue
            }
            changes.push(changeObj)
            }
        }
      })
      return changes;
}