import React, { Component } from 'react';
import Dialog, { DialogContent, DialogFooter, DialogButton, ScaleAnimation, SlideAnimation, FadeAnimation, DialogTitle } from 'react-native-popup-dialog';
import { MaterialIcons, FontAwesome, AntDesign, Feather } from '@expo/vector-icons';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { View, Text, Platform, Dimensions, TouchableOpacity, ScrollView, Image } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font'
import { constStyles, chapterButtonStyles } from '../../styles/constStyles'
import * as WebBrowser from 'expo-web-browser';
import Constants from '../../utils/constants';
import api from '../../utils/api'
import { Linking } from 'react-native'

import { Icon } from 'native-base';


const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

export default class AppSwitcher extends Component {

    onLogoutPress = () => {
        const { navigation, handleHeaderMenuDialoge } = this.props;
        handleHeaderMenuDialoge();
        delete api.defaults.headers.common.Authorization;
        setTimeout(() => {
            navigation.original.reset({
                index: 0,
                routes: [{
                    name: 'AdminAuthStack',
                }, ],
            })
        }, 100);
    }

    render() {
            const { handleHeaderMenuDialoge, showDialog, navigation, user, deviceNo, selectedUser } = this.props
            const appLinks = selectedUser.appLinks !== undefined && selectedUser.appLinks !== null ? selectedUser.appLinks.productLink : [];
            return ( <
                Dialog onTouchOutside = {
                    () => handleHeaderMenuDialoge() }
                containerStyle = {
                    { justifyContent: 'flex-start', alignItems: 'flex-start' } }
                dialogStyle = {
                    {
                        marginTop: hp('2.5%'),
                        width: SCREEN_WIDTH <= 1030 ? '55%' : SCREEN_WIDTH / 2 - (314 + wp('10%')),
						right: SCREEN_WIDTH <= 1030 ? 10 : SCREEN_WIDTH / 2 - (138 + wp('10%')),
						position: 'absolute',
                    }
                }
                rounded = { false }
                visible = { showDialog } >
                { /* <DialogContent> */ }

                <
                View style = {
                    { alignContent: 'space-between', backgroundColor: "#d9d9d9", padding: 10 } } >
                <
                                    Image source = { require(`../../../assets/jCollaborateLogo.png`) }
                                    style = {
                                        { height: 100, marginTop: -40, marginBottom: -40 } }
                                    resizeMode = 'contain' /
                                    > <
                /View> <
                ScrollView style = {
                    { height: 300 } } > {
                    appLinks.map((value, index) => {

                            if (value !== undefined) {
                                return ( <
                                    View style = {
                                        { padding: 5 } } >
                                    <
                                    View style = {
                                        { backgroundColor: 'white', flexDirection: 'row', justifyContent: "flex-start", padding: 10 } } >
                                    <
                                    Image source = { require(`../../../assets/${value.icon}`) }
                                    style = {
                                        { height: 25, width: 25 } }
                                    resizeMode = 'contain' /
                                    >
                                    <
                                    Text style = {
                                        { textAlign: 'center', alignSelf: 'center', marginLeft: 10, fontSize: 15 } } > { value.productName } < /Text> <
                                    /View>

                                    <
                                    View style = {
                                        { flexDirection: 'row', backgroundColor: 'white', flexWrap: 'wrap', paddingBottom: 5 } } >

                                    {
                                        value.links.map((value, index) => {

                                                return (

                                                    <
                                                    View style = {
                                                        { width: '33%', alignItems: 'center', padding: 5 } } >
                                                    <
                                                    TouchableOpacity onPress = {
                                                        () => {
                                                            Linking.openURL(`${value.url}`)
                                                        }
                                                    }
                                                    style = {
                                                        { flexDirection: 'column' } } >
                                                    <
                                                    Image source = { require(`../../../assets/${value.icon}`) }
                                                    style = {
                                                        { height: 100, width: 85, alignItems: 'center', alignSelf: 'center' } }
                                                    resizeMode = 'contain' /
                                                    > { /* <Text style={{ alignSelf: 'center', width: 80, textAlign: 'center', fontSize: 10, padding:10 }}>{value.name}</Text> */ } <
                                                    /TouchableOpacity> <
                                                    /View>);
                                                })
                                        } <
                                        /View> <
                                        View style = {
                                            { backgroundColor: '#d9d9d9', height: 1, padding: 1, borderRadius: 10, width: '90%', alignSelf: 'center', marginTop: 5 } } >
                                        <
                                        Text >

                                        <
                                        /Text> <
                                        /View> <
                                        /View>);
                                    }
                                })
                        } <
                        /ScrollView> <
                        /Dialog>
                    );
                }
            }