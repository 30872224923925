import React, { Component } from "react";
import {  Card } from "native-base";
import { Text, View, ScrollView, Platform, TouchableOpacity,  TextInput } from "react-native";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { styles } from "./styles/validateOtpScreenStyles";
import { KeyboardAvoidingView, Image } from "react-native";
import FormikInput from './FormikInput';
import { Colors } from "../../constants/ui/colorScheme";
import api from "../../utils/api";
import showToast from '../../utils/toast';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .label('Password')
    .required('Please enter New Password.')
    .min(8, 'Minimum 8 characters.')
    .max(16, 'Maximum 16 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+={}[\];:"',.\/<>?\\|])[A-Za-z\d\S]{8,16}$/,
      "Password needs to include at least one number, one special character, one uppercase letter, and no spaces."
    ),
  confirmPassword: yup
    .string()
    .required('Please re-enter New Password.')
    .label('Confirm password')
    .test('passwords-match', 'Two passwords that you enter are inconsistent.', function(value) {
      return this.parent.newPassword === value;
    }),
});

class ResetPassword extends Component {

  state = { loading: false };

  onSubmit = async values => {
    const { navigation, userName, OtpId } = this.props;

    const clientUser = {
        userName: userName,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword
    };

    const transactionId = OtpId;

    this.setState({ loading: true });
    
    try {
      
      const res = await api.post("/resetPassword", { clientUser, transactionId });

      this.setState({ loading: false });

      showToast(
        "Saved successfully.",
        "success",
        2000
      );

      navigation.replace("LoginPage");
    } catch (error) {
      this.setState({ loading: false });
      if (error.response) {        
        showToast("Failed to save.", "danger", 2000);
      } else {
        showToast("Network Error.", "danger", 2000);
      }
      console.log(error);
    }
  };  

  render() {
    const { loading } = this.state;
    return (
    
        <ScrollView>
            <KeyboardAvoidingView behavior="padding" enabled>
            <MyLoader active={loading}/>
                {/* --------------------- Logo --------------------------- */}
                <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20
                    }}
                >
                    <Image
                        source={require("../../../assets/bio.png")}
                        style={{ height: hp("7%"), width: wp("40%") }}
                        resizeMode='contain'
                    />
                </View>

                {/* --------------------- Card ---------------------------- */}
                <View style={{ justifyContent: "center", marginTop:10 }}>
                     <Card style={[styles.container, Platform.OS !== 'web' && {marginTop:30}]}>
                        <View
                            style={{
                                flex: 1
                            }}
                        >
                  

                            {/* ------------------ Label ---------------------- */}
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: "center"
                                }}
                            >
                                <Text style={styles.headerStyle}>Reset Password</Text>
                            </View>

                            <Formik
                              initialValues={{
                                newPassword: '',
                                confirmPassword: '',
                              }}
                              onSubmit={this.onSubmit}
                              validationSchema={validationSchema}
                            >
                              {formikProps => (
                                <React.Fragment>

                                    {/* ------------------ New password ---------------------- */}
                                    <FormikInput
                                        placeholderValue={'New Password'}
                                        formikProps={formikProps}
                                        formikKey={"newPassword"}
                                        secureTextEntry={true}
                                    />

                                    {/* ------------------ Confirm password ---------------------- */}
                                    <FormikInput
                                        placeholderValue={'Confirm Password'}
                                        formikProps={formikProps}
                                        formikKey={"confirmPassword"}
                                        secureTextEntry={true}
                                    />

                                    {/* ------------- Submit --------------------- */}
                                    <View style={{flex: 1}}>
                                        <TouchableOpacity
                                            success
                                            style={constStyles.buttonStyle}
                                            onPress={formikProps.handleSubmit}
                                        >
                                            <Text style={constStyles.buttonTxt}>SUBMIT</Text> 
                                        </TouchableOpacity>
                                    </View>
                                  
                                </React.Fragment>
                              )}
                            </Formik>

                            {/* ------------------ Password rules ---------------------- */}                                    
                            <View style={{flex: 2 }}>
                                <Text style={[styles.passwordRules,{fontWeight: "bold", paddingBottom: 5}]}>Password Rules :</Text>
                                <Text style={styles.passwordRules}>&#8226; Password should consist at least 8 characters and not exceeding sixteen.</Text>
                                <Text style={styles.passwordRules}>&#8226; At least 1 numeric.</Text>
                                <Text style={styles.passwordRules}>&#8226; At least 1 Special Character</Text>
                                <Text style={styles.passwordRules}>  "!#$%&amp;'()*+,-/:;&lt;=>?@[\]^_`&#123;|&#125;~.</Text>
                                <Text style={styles.passwordRules}>&#8226; At least 1 Uppercase.</Text>
                                <Text style={styles.passwordRules}>&#8226; At least 1 Lowercase.</Text>
                                <Text style={styles.passwordRules}>&#8226; Space not allowed.</Text>
                            </View>
                        </View>
                    </Card>
                </View>
            </KeyboardAvoidingView>
        </ScrollView>
    );
  }
}

export default ResetPassword;