import {StyleSheet, Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export default StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
    },
    buttonContainer: {
      // flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    textContainer: {
      // marginTop: 5,
      marginBottom: 20,
      left: 15,
      alignItems: 'flex-start',
      width: Dimensions.get('window').width - 40
    },
    fieldContainer: {
      flexGrow: 1,
      justifyContent: 'flex-start'
    },
    p: {
      fontSize: 18,
      color: Colors.textdisabled,
      fontFamily: 'Raleway'
    },
    submitButtonContainer: {
      flexDirection: 'column',
      // flex: 1,s
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: Dimensions.get('window').width - 30,
      paddingVertical: 15,
      borderRadius: 5,
      backgroundColor: Colors.fieldSubmit,
    },
    errorMessage: {
      alignItems: 'center',
    },
    errorText: {
      fontSize: 15,
      color: Colors.error,
    },
    animatedView: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute'
  },
  inputTextFont: {
    padding: 20
  },
  textArea: {
    borderWidth: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    fontSize: 15,
    justifyContent: 'flex-start'
  },
  textInput: {
    borderBottomWidth: 2,
  },
  textBoxBorderColor: {
    borderColor: Colors.borderColor,
  },
  errorinfoText: {
    color:Colors.error,
    fontSize: fontType('subFont'), 
    paddingLeft:5,
    marginVertical:3,
    fontFamily:'Inter'
  },
  fieldNameStyle: {
    flex:0.1,
    color: Colors.fieldNameStyle,
    fontSize: fontType('BasicFont'),
    paddingLeft: 5,
    justifyContent:'center',
    alignSelf:'center',
    alignItems:'center',
    marginTop: 2,
    position:'relative',
    marginRight:10,
    marginLeft: 15,
    borderRadius: 100,
    fontFamily:'Inter'
  }
  });
