import Constants from 'expo-constants'

export default {
    "ADMIN_PORTAL_BASE_URL": Constants.manifest.extra.baseUrl,
    "PREFIX":`${Constants.manifest.extra.baseUrl}/webapp/`,
    "CONTEXT_PATH": "webapp",
    "APP_VERSION": "5.2.0",
    "PERSISTENCE_KEY": "NAVIGATION_STATE",
    ContextProperties: {
        PRIMARY_ORG_CODE: '{{PRIMARY_ORG_CODE}}',
        STUDY_ID: '{{STUDY_ID}}',
        SITE_ID: '{{SITE_ID}}',
        CONSENT_VERSION_ID: '{{CONSENT_VERSION_ID}}',
        CONSENT_ID: '{{CONSENT_ID}}'
      }
}
