export default {
  loading: false,
  session: {
    timeout: false,
    primaryClient: null,
    primaryOrgCode: null,
    site: null,
    study: null,
    userType: 3,
  },
  selectedLanguage: "",
  subject: {
    id: null,
    formData: [],
    firstName: null,
    lastName: null,
    dob: null,
    gender: null,
    phoneNo: null,
    isRegistered: false,
    isSubSignsDone: false,
    subjectDocumentSignatures: [],
    larDetails: {
      name: "",
      larRelation: "",
    },
    quizAttempts: [],
    isKnowledgeReviewDone: false,
    consentId: "",
    isRegistered: false,
    consentCompleted: false,
    navTour: [],
    language: null,
    languageId: null,
    status: null,
    signatureType: "",
    visitedChapters: 1,
    isAgreedForChapters: {
      studyInfo: false,
      studyVideo: false,
      consentDocuments: false,
    },
    isRemoteConsent: false,
    clientUserEmail: "",
    clientUserPhNo: "",
  },
  documents: {
    signatureUrl: null,
    signedBy: null,
    signatureDate: null,
    formDataList: {},
    consentVersionsMetadata: {}
  },
  studySite: {
    list: [],
    accessCode: "",
    country: null,
    firstName: "",
    fullName: "",
    lastName: "",
    newVersionAvailable: false,
    newVersionUrl: null,
    region: "",
    siteId: "",
    siteName: "",
    siteNumber: "",
    sponsorName: "",
    studyId: "",
    studyNumber: "",
    studySiteId: "",
    username: "",
    newConsentVersion: "",
    stdSite: {},
  },
  clientUser: {
    list: [],
    selectedUser: {
      userName: "",
    },
    isValid: null,
    organizationName: null,
    organizationId: null,
  },
  study: {
    list: [],
    selectedStudy: {
      userName: "",
    },
  },
  selectedSubject: {
    subjectConsent: {
      isKrDone: false,
      isDocReviewed: false,
    },
  },
  consentTokenData: {
    siteNo: "",
    deviceNo: "",
    subSerialDeviceNo: "0",
  },
  hasUpdate: false,
  hasAppUpdate: false,
  currentSubDocPosition: 0,
  documentChapter: {},
  subjectRemoteConsentDto: {
    subjectId: "",
    hasUsername: "",
    isOtpExpired: "",
    type: "",
    fullName: "",
    email: "",
    siteName: "",
    currentConsentVersionId: "",
    status: "",
    phoneNo: "",
    otp: "",
  },
  consentFlow: {
    signatureTypes: [],
    currentUserType: null,
    krDoneBy: null,
  },
  clientUserDetails: {
    email: "",
    phoneNo: "",
  },
  subjectConsentDocument: {
    list: [],
  },
  printNSign: {
    data: {},
  },
};
