import React, { Component } from 'react';
import { StyleSheet, TextInput, Text } from 'react-native'
import { View, Item, Input } from 'native-base';
import { styles } from './fieldStyles';
import TextAreaItem  from './TextAreaItem';


export default class TextAreaDecorator extends Component {
    constructor(props) {
        super(props);
        this.state = { text: '' };
    }
   
    render() {
      const { form: { getFieldDecorator, getFieldError}, field:{ id }, t  } = this.props;
        return (
          <View style={{width:'100%'}}>
          {getFieldDecorator(`${id}.value`, {
          rules: [
            { required: true, message: t('FillWarn') },
          ],
        })(
          <TextAreaItem
            autoFocus
            error={getFieldError(`${id}.value`)}
            t={t}
          />
        )}
          {getFieldDecorator(`${id}.quizAttempts`)}
     
        </View>
            // </View>
        )
    }
}