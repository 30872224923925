import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';


export default class TextInputItem extends Component{

    
     
  render() {
    const { value, onChange, placeholderValue, maxLength, secureTextEntry } = this.props;
    return (
      <View style={styles.inputView} >
          <TextInput 
            placeholder={placeholderValue}
            style={styles.inputText}
            value={value}
            onChangeText={onChange}
            fontSize={fontType('BasicFont')}
            // height={wp('5.5%')}
            maxLength={maxLength}
            secureTextEntry={ secureTextEntry || false}
          /> 
          
      </View>
    )
  }

}
const styles = StyleSheet.create({

  inputView:{
    // width:"80%",
    // height:50,
    // marginBottom:20,
    // justifyContent:"center",
  },

  inputText:{
    borderColor: '#bdbdbd',
    // borderRadius: 7,
    borderBottomWidth: 2,
    width:300,
    maxWidth:300,
    padding:5,
    textAlign:'left',
    fontFamily:'Inter',
    marginLeft: 3
  },

  errorinfoText: {
    color: Colors.error,
    fontFamily:'Inter',
    fontSize: fontType('subFont'), 
    marginTop:2,
    marginBottom:2,
    // alignSelf:'left'
  }
})
