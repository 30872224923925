import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';

export default StyleSheet.create(
    {
        heddingView: {
            borderColor:Colors.background,
            borderWidth:3,
            borderStartColor:'white',
            borderEndColor:'white',
            flexDirection: 'row',
            // // flex: 0.3,
            // backgroundColor: '#fff',
            justifyContent: 'flex-end',
        },
        menuView: {
            flexDirection: 'row',
            padding: wp('0.4%'),
            paddingLeft:wp('1.5%'),
            marginLeft:wp('0.5%')
        },
        addSubjectView: {
            backgroundColor:Colors.buttonwithbg,
            // borderWidth:1,
            // borderColor: Colors.textColor,
            borderRadius: 5,
            padding: 4,
            margin:4,
            // width:wp('27%'),
            alignContent:'center',
        },
        searchBarView: {
            // flex: 0.3,
            margin:4,
            backgroundColor: Colors.searchBg,
        },
        subjectListView: {
            flex: 3.9,
            justifyContent: 'center'
        },
        title: {
            flexDirection:'row',
            alignItems:'center',
            paddingLeft:4,
            padding:2,
            justifyContent:'center',
            backgroundColor:Colors.background,
            justifyContent:'space-between'
          }


    }
)