import createActionType from '../utils/action';
import _ from 'lodash';

export const HAS_NEW_UPDATE = createActionType('HAS_UPDATE')
export const HAS_UPDATED = createActionType('HAS_UPDATED')
export const HAS_NEW_APP_UPDATE = createActionType('HAS_NEW_APP_UPDATE')
export const HAS_NEW_APP_UPDATED = createActionType('HAS_NEW_APP_UPDATED')
export const INC_DOC_SIGN_POSITION = createActionType('INC_DOC_SIGN_POSITION')
export const CLEAR_DOC_SIGN_POSITION = createActionType('CLEAR_DOC_SIGN_POSITION')

export const hasNewUpdate = () => ({
    type:  HAS_NEW_UPDATE,
});

export const hasUpdated = () => ({
    type:  HAS_UPDATED,
});

export const hasNewAppUpdate = () => ({
    type:  HAS_NEW_APP_UPDATE,
});

export const hasNewAppUpdated = () => ({
    type:  HAS_NEW_APP_UPDATED,
});

export const incDocSignPosition = () => ({
    type:  INC_DOC_SIGN_POSITION,
});

export const clearDocSignPosition = () => ({
    type:  CLEAR_DOC_SIGN_POSITION,
});