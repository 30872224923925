import React, { Component } from "react";
import {
  Container,
  ListItem,
  Text,
  Item,
  Input,
  Body,
  Icon
} from "native-base";
import {
  View,
  ScrollView,
  FlatList,
  Dimensions,
  ActivityIndicator
} from "react-native";
import { styles } from "./styles/studyListStyle";
import SearchInput, { createFilter } from "react-native-search-filter";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import showToast from '../../utils/toast';
import api from '../../utils/api';
import { Colors } from "../../constants/ui/colorScheme";
import {fontType} from '../../styles/font';
import { constStyles } from "../../styles/constStyles";
import { updateStudyInSession } from '../../actions/session'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const KEYS_TO_FILTERS = [
  "study.studyNumber",
];



class StudyListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      loading: false
    };
  }

    componentDidMount() {
        const { navigation, screenProps: { validateSession } } = this.props;
        this._unsubscribe = navigation.addListener('focus', () => {
            this.getStudyList();
        });
       
        // this._unsubscribe = navigation.addListener('focus', () => {
        //     // validateSession(false);
        // });
    }

    componentWillUnmount() {
         this._unsubscribe();
    }

  getStudyList = async () => {
    const { selectedUser, storeStudySiteList, organizationId } = this.props;
    try {
      this.setState({loading: true})
      const res = await api.get(`/clients/${organizationId}/studySites`)
      storeStudySiteList(res.data);
      this.setState({loading: false})
    } catch(error) {
        console.log(error);
        this.setState({ loading: false });
        storeStudySiteList([]);
        if (error.response) {
            showToast('Failed to retrieve study list.', 'danger', 3000);
        } else {
            showToast("Network Error.", "danger", 2000);
        }     
    }
  }

    searchUpdated(term) {
        this.setState({ searchTerm: term })
    }

    onSelectClient = (studySite) => {
        const { navigation, storeStudySite, handleHeaderMenuDialoge, updateStudyInSession: updateSessionStudy  } = this.props;   
        storeStudySite(studySite); 
        updateSessionStudy({study: studySite?.study?.id})
        // to handle through edit study modal    
        if(handleHeaderMenuDialoge) {
            handleHeaderMenuDialoge();
        }
        setTimeout(() => {
            navigation.navigate('Admin', { 
                screen: 'DownloadVideosAndStoreData',
                params: {
                    studySiteId: studySite.id,
                },
            });
        }, 100);        
    }

  render() {
    const { studySiteList, screenProps: { validateSession } } = this.props;
    const { loading } = this.state;
    const loStudyList = studySiteList ? studySiteList.filter( createFilter(this.state.searchTerm, KEYS_TO_FILTERS)) : [];
    return (
        // <ScrollView style={{backgroundColor:'#fff'}}>
          <Container style={{backgroundColor:'#fff', height:'100%'}}>
            {/* <NavigationEvents
                onWillFocus={() => {
                    validateSession(false);
                }}
            /> */}

            {/* -------------- screen title --------------------- */}
            <View style={{...styles.title, paddingBottom: 15, paddingTop: 15, margin: 3}}>
                <Text style={constStyles.pageTitleStyle}>
                Informed Consent: Select Study
                </Text>
            </View>

            {/* ------------- search bar ------------------------ */}
            <View 
            style={styles.searchbarContainer} 
            >
                <Item
                    searchBar
                    rounded
                    style={{ backgroundColor: Colors.searchBg }}
                >
                  <Icon name="ios-search" style={{fontSize:fontType('BasicFont'), padding: 2}}/>
                    <Input
                        placeholder="Search"
                        style={{ fontSize: fontType('BasicFont'), fontFamily:'Inter' }}
                        autoCorrect={false}
                        onChangeText={term => {
                        this.searchUpdated(term);
                        }}
                    />
                     <Icon name="ios-people" style={{fontSize:fontType('BasicFont')}}  />
                </Item>
            </View>

            {/* ------------- Loading ------------------------ */}
            {
                loading
                    ? <ActivityIndicator
                        size="large"
                        color={Colors.ActivityIndicator}
                        animating={true}
                        hidesWhenStopped={true}
                    />
                    : loStudyList.length > 0 
                    /* ------------- Render list ------------------------ */
                    ? <ScrollView alwaysBounceVertical={true} showsVerticalScrollIndicator={loStudyList.length> 5 ? true : false} >
                        <FlatList
                            data={loStudyList}
                            keyExtractor={item => item.key}
                            contentContainerStyle={{ paddingBottom: 20 }}
                            renderItem={({ item }) =>
                            <ListItem
                                thumbnail
                                onPress={() => {
                                this.onSelectClient(item);
                                }}
                            >
                                <Body>
                                    <View style={{ justifyContent: "flex-start" }}>
                                        <Text
                                        style={{
                                            fontSize: fontType('BasicFont'),
                                            fontFamily: "Inter"
                                        }}
                                        >
                                        {item.study.studyNumber}
                                        </Text>
                                        {/* <Text
                                        style={{
                                            color: Colors.signDetails,
                                            fontFamily: "Inter",
                                            fontSize: wp("2%")
                                        }}
                                        >
                                        {item.roleNames}
                                        </Text> */}
                                    </View>
                                    
                                </Body>
                            </ListItem>}
                        />
                        </ScrollView>
                    : 
                    /* ------------- Render No data ------------------------ */
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: wp("15%"),
                        }}
                        >
                        <Text
                            style={{
                            fontSize: fontType('Heading1'),
                            fontFamily: "Inter",
                            color: Colors.textdisabled
                            }}
                        >
                            No data.
                        </Text>
                    </View>
                }        
           </Container>
        // {/* </ScrollView> */}
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        updateStudyInSession,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(StudyListScreen);
