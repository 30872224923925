import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  View,
  Text,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import {
  ListItem,
  Body,
  Item,
  Icon,
  Input,
} from "native-base";
import {
  MaterialCommunityIcons,
  AntDesign,
} from "@expo/vector-icons";
import _ from "lodash";
import styles from "./styles/subjectListStyles";
import api from "../../utils/api";
import { createFilter } from "react-native-search-filter";
import showToast from "../../utils/toast";
import { Colors } from "../../constants/ui/colorScheme";
import { getConsentVersionId } from "../../utils/consentData";
import { buildSubjectList } from "../../utils/subjectUtils";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import { constStyles } from "../../styles/constStyles";
import { fontType } from "../../styles/font";
import { formatDate } from "../../utils/dateFormatUtils";
import MyLoader from "../../utils/webLoader";
import constants from "../../utils/constants";
import { connect } from "react-redux";
import { logSubjectAudit } from "../../actions/subject";
import { bindActionCreators } from "redux";
import privilege from "../constants/privilege";
const { ContextProperties } = constants;

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");
const KEYS_TO_FILTERS = ["firstName", "lastName", "consentId"];

const RenderReconsentIcon = (props) => {
  const { consentTab, isReconsent } = props;
    if(consentTab && isReconsent) {
      return(
        <MaterialCommunityIcons
        name="flag"
        size={20}
        color={Colors.needSign}
      />
      )
    }
    else{
      return(
        <div/>
      )
    }
}

const RenderSubjectList = (props) => {
const {sortedSubjects, onSubjectSelect, renderIcons, consentTab} = props
if(!_.isEmpty(sortedSubjects)) {
  return (
    _.map(sortedSubjects, (item, index) => {
      return(
      <ListItem thumbnail>
        <View>
          <Text>{renderIcons(item)}</Text>
        </View>
        <Body>
          <TouchableOpacity
            delayLongPress={5000}
            delayPressIn={5000}
            delayPressOut={5000}
            activeOpacity={0.6}
            underlayColor="#D3D3D3"
            hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}
            onPress={() => {
              onSubjectSelect(item);
            }}
          >
            <View style={{ justifyContent: "flex-start" }}>
              <Text
                style={{
                  fontSize: fontType("subFont"),
                  fontWeight: "600",
                  fontFamily: "Inter",
                }}
              >
                {item.fullName}&nbsp; <RenderReconsentIcon consentTab={consentTab} isReconsent={item.isReconsent} />
              </Text>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 3, paddingRight: 10 }}>
                  <Text
                    style={{
                      color: Colors.iconColor,
                      fontSize: fontType("subFont"),
                      fontFamily: "Inter",
                    }}
                  >
                    Language:&nbsp;{item.locale}
                  </Text>
                  <Text
                    style={{
                      color: Colors.iconColor,
                      fontSize: fontType("subFont"),
                      fontFamily: "Inter",
                    }}
                  >
                    Consent ID:&nbsp;{item.consentId}
                  </Text>
                  {item.usubjectId ? (
                    <Text
                      style={{
                        color: Colors.iconColor,
                        fontSize: fontType("subFont"),
                        fontFamily: "Inter",
                      }}
                    >
                      Subject ID:&nbsp;{item.usubjectId}
                    </Text>
                  ) : null}
                </View>
                <View style={{ flex: 3, paddingRight: 50 }}>
                  {item.consentedOn ? (
                    <Text
                      style={{
                        color: Colors.iconColor,
                        fontSize: fontType("subFont"),
                        fontFamily: "Inter",
                      }}
                    >
                      Last Consented On:&nbsp;
                      {formatDate(
                        item.consentedOn,
                        item.originatingTimezone
                      )}
                    </Text>
                  ) : null}
                  {item.lastConsentedIrbVersion ? (
                    <Text
                      style={{
                        color: Colors.iconColor,
                        fontSize: fontType("subFont"),
                        fontFamily: "Inter",
                      }}
                    >
                      Last Consented IRB Version:&nbsp;
                      {item.lastConsentedIrbVersion}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </Body>
      </ListItem>
      )
    })
  )
}
else{
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        marginTop: 70,
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontType("Heading1"),
          color: Colors.textdisabled,
        }}
      >
        No data.
      </Text>
    </View>
  )

}


}

class SubjectList extends Component {
  static contextType = ConsentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      subjectList: [],
      loading: true,
      metaDataLoading: false,
    };
  }

  componentDidMount() {
    const {
      navigation,
      clearSubjectData,
      clearFormData,
      clearDocumentChapter,
      clearSelectedSubject,
    } = this.props;
    this._unsubscribe = navigation.addListener("focus", () => {
      this.getSubjectsList();
      clearSubjectData();
      clearFormData();
      clearDocumentChapter();
      clearSelectedSubject();
    });
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  async getSubjectsList() {
    const { studySite, isReconsent } = this.props;
    const consentVersionId = getConsentVersionId(this.context.languages);
    this.setState({ subjectList: [] });
    try {
      this.setState({ loading: true });
      if (isReconsent) {
        const res = await api.get(
          `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjectConsents?isReconsent=true`
        );
        const subjects = buildSubjectList(res.data);
        this.setState({ subjectList: subjects, loading: false });
      } else {
        const res = await api.get(
          `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjectConsents?isReconsent=false`
        );
        const subjects = buildSubjectList(res.data);
        this.setState({ subjectList: subjects, loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      showToast("Failed to retrieve subjects.", "danger", 3000);
      console.log(error);
    }
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  // Render Icons Based On Status Of Subject
  renderIcons = (item) => {
    const { status } = item;
    if (item.status === "DISCONTINUED") {
      return (
        <MaterialCommunityIcons
          name="close-circle-outline"
          size={fontType("StatusIcon")}
          color={Colors.discontinued}
        />
      );
    } else if (item.status === "NEEDS_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="dots-horizontal-circle"
          size={fontType("StatusIcon")}
          color={Colors.needSign}
        />
      );
    } else if (item.status === "NEEDS_COUNTER_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="circle-slice-4"
          size={fontType("StatusIcon")}
          color={Colors.needsCounterSign}
        />
      );
    } else if (item.status === "CONSENTED")
      return (
        <MaterialCommunityIcons
          name="checkbox-marked-circle"
          size={fontType("StatusIcon")}
          color={Colors.consentedColor}
        />
      );
    return null;
  };
  // Sort the subjects
  sortSubjectsByName = () => {
    const { subjectList } = this.state;
    this.setState({
      subjectList: _.sortBy(subjectList, [(subject) => subject.firstName]),
    });
  };

  onSubjectSelect = async (subject) => {
    const {
      storeSelectedSubject,
      storeSelectedSubjectDetailsinSubject,
      privileges,
      logSubjectAudit,
    } = this.props;
    const selSubject = {
      firstName: subject.firstName,
      lastName: subject.lastName,
      fullName: subject.fullName,
      middleName: subject.middleName,
      id: subject.id,
      isRegistered: true,
      subjectConsentId: subject.subjectConsentId,
      consentVersionId: subject.consentVersionId,
      status: subject.status,
      isReconsent: subject.isReconsent,
      lastConsentedIrbVersion: subject.lastConsentedIrbVersion,
      consentedOn: subject.consentedOn,
      navTour: [],
      isRemoteConsent: false,
      isKnowledgeReviewDone: subject.isKnowledgeReviewDone,
      larRelation: subject.larRelation,
    };
    if (privileges.indexOf(privilege.VIEW_ENCRYPTED_DATA) != -1) {
      await logSubjectAudit(subject.id);
    }
    storeSelectedSubjectDetailsinSubject(selSubject);
    storeSelectedSubject(subject);
    this.checkCVAndGetMD(subject.currentConsentVersionId, "SubjectDetails");
  };

  checkCVAndGetMD = async (subjectConsentVersionId, navigationString) => {
    const { navigation } = this.props;
    const metaDataConsentVersionId = getConsentVersionId(
      this.context.languages
    );
    if (
      metaDataConsentVersionId != subjectConsentVersionId &&
      !_.isEmpty(subjectConsentVersionId)
    ) {
      try {
        this.setState({ metaDataLoading: true });
        const res = await api.get(
          `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/consentVersions/${subjectConsentVersionId}/metaData`
        );
        const languagesdata = JSON.stringify(res.data);
        this.context.updateLanguages(languagesdata);
        this.setState({ metaDataLoading: false });
        navigation.navigate(navigationString);
      } catch (err) {
        console.log(err);
        this.setState({ metaDataLoading: false });
        if (err.response) {
          showToast("Something went wrong.", "danger", 4000);
        } else {
          showToast("Network Error.", "danger", 2000);
        }
      }
    } else {
      navigation.navigate(navigationString);
    }
  };

  onAddSubject = () => {
    // clearSubjectData();
    // navigation.navigate("AddSubjectScreen");
    this.checkCVAndGetMD(this.context.latestBuildVersionId, "AddSubjectScreen");
  };

  render() {
    const { searchTerm, subjectList, loading, metaDataLoading } = this.state;
    const { isReconsent, consentTab } = this.props;
    const filteredSubjects = subjectList.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    const sortedSubjects = _.sortBy(filteredSubjects, (fs) =>
      fs?.fullName?.toUpperCase()
    );
    // const sortedSubjects = _.orderBy(filteredSubjects, ['firstName'],['asc']);
    const viewStyles = { flex: 1, backgroundColor: "#fff" };
    return (
      <View onStartShouldSetResponder={() => true} style={viewStyles}>
        {/* metadata loding */}
        <MyLoader active={metaDataLoading} />
        {/* Header View */}
        {!isReconsent && (
          <View style={styles.heddingView}>
            {/* Add Subject View */}
            <View>
              <TouchableOpacity
                style={styles.addSubjectView}
                onPress={this.onAddSubject}
              >
                <Text
                  // style={{ color: Colors.textColor, fontSize: wp('3%'), paddingLeft: wp('0.5%'), textAlign: 'center', fontFamily:'Inter_Bold' }}
                  style={[constStyles.buttonTxt, { color: Colors.header }]}
                >
                  <AntDesign name="adduser" size={20} color={Colors.header} />{" "}
                  ADD SUBJECT
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* Search bar view */}
        <View style={{ ...styles.title, backgroundColor: "#F9F9F9" }}>
          <Text
            style={{
              fontSize: fontType("Heading2"),
              fontFamily: "Inter",
              color: Colors.text,
              padding: "1%",
            }}
          >
            SUBJECT LIST
          </Text>
        </View>
        <View style={styles.searchBarView}>
          <Item searchBar rounded>
            <Icon
              name="ios-search"
              style={{ fontSize: fontType("BasicFont"), padding: 2 }}
            />
            <Input
              placeholder="Search"
              style={{ fontSize: fontType("BasicFont"), fontFamily: "Inter" }}
              autoCorrect={false}
              onChangeText={(term) => {
                this.searchUpdated(term);
              }}
            />
            <Icon
              name="ios-people"
              style={{ fontSize: fontType("BasicFont") }}
            />
          </Item>
        </View>

        {/* Subject List */}

        <View style={styles.subjectListView}>
          {/* -------------------loading logic  ---------------------------- */}
          {loading ? (
            <ActivityIndicator
              size="large"
              color={Colors.ActivityIndicator}
              animating={true}
              hidesWhenStopped={true}
            />
          ) : <RenderSubjectList consentTab = {consentTab} renderIcons={this.renderIcons} onSubjectSelect={this.onSubjectSelect} sortedSubjects ={sortedSubjects}/>
          }
        </View>
      </View>
    );
  }
}

RenderReconsentIcon.defaultProps = {
  isReconsent: false,
  consentTab: false,
}
RenderReconsentIcon.propTypes = {
  isReconsent: PropTypes.bool,
  consentTab: PropTypes.bool,
}
RenderSubjectList.defaultProps = {
  consentTab: PropTypes.bool,
  sortedSubjects: [], 
  onSubjectSelect: () => {},
  renderIcons: () => {},
}
RenderSubjectList.propTypes = {
  sortedSubjects: PropTypes.array,
  onSubjectSelect: PropTypes.func,
  renderIcons: PropTypes.func,
  consentTab: PropTypes.bool
}

SubjectList.defaultProps = {
  isReconsent: false,
  consentTab: false,
  navigation: {},
  storeSelectedSubjectDetailsinSubject: () => {},
  storeSelectedSubject: () => {},
  privileges: [],
};

SubjectList.propTypes = {
  isReconsent: PropTypes.bool,
  consentTab: PropTypes.bool,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    addListener: PropTypes.func.isRequired,
  }),
  storeSelectedSubjectDetailsinSubject: PropTypes.func,
  storeSelectedSubject: PropTypes.func,
  privileges: PropTypes.arrayOf(PropTypes.string),
  logSubjectAudit: PropTypes.func.isRequired,
  clearSubjectData: PropTypes.func.isRequired,
  clearFormData: PropTypes.func.isRequired,
  clearDocumentChapter: PropTypes.func.isRequired,
  clearSelectedSubject: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  privileges: state.clientUser.selectedUser.privileges,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logSubjectAudit,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SubjectList);


