import React from "react";
import { Dimensions, Text, View } from "react-native";
import ToolTip from "../common/tooltip/tooltip";
import { TOOLTIP_PLACEMENT } from "./constants";
import _ from "lodash";


const screenWidth = Dimensions.get('window').width;
/*
    Get header width based on screen width
*/
let headerWidth = 0.2*screenWidth;
if(screenWidth < 469){
  headerWidth = 0.6*screenWidth;
}else if(screenWidth < 768) {
  headerWidth = 0.4*screenWidth;
} else if(screenWidth < 992) {
  headerWidth = 0.5*screenWidth;
} else if(screenWidth < 1030){
  headerWidth = 0.4*screenWidth;
} else {
  headerWidth = 0.3*screenWidth; //This is for desktop browsers

}

const getMaxCharacters = () => {
    if(screenWidth < 469){
        return 15;
    }else if(screenWidth < 768) {
        return 20;
    }else if(screenWidth < 1030){
        return 80
    } return 60;
      
}

const EllipsisText = (props) => {
    const { maxLength = getMaxCharacters()} = props;
    const showEllipsis = _.size(props?.text) > maxLength;
    return (
        <ToolTip tip = {showEllipsis ? props.text: ''} placement = {TOOLTIP_PLACEMENT.BOTTOM} tooltipWidth = {headerWidth}>
            <View>  
                <Text
                    numberOfLines={1 || props.numberOfLines}
                    ellipsizeMode={showEllipsis ? "tail" || props.ellipsizeMode : ''}
                    style={props.style}
                    accessible={true}
                >
                    {props.text}
                </Text>
            </View>
        </ToolTip>
    )
}

export default EllipsisText;