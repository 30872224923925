import SubjectRegistrationForm from '../components/registrationForm/SubjectRegistrationForm';
import React, { Component } from "react";
import { Icon } from 'native-base';
import {
    TouchableOpacity, View, Text
} from "react-native";
import { storeRegistrationData, storeRegisteredSubjectData, failedToRegisterSubject } from '../actions/subjectRegistrationForm';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getConsentChapter, getAskForLAR } from '../utils/consentData';
import { storeSignatureType } from '../actions/subject';
import {storeSubjectStatus, clearSubjectNavTour} from '../actions/subject';
import {ConsentDataContext} from '../providers/ConsentDataProvider';

class SubjectRegistrationScreen extends Component {
    static contextType = ConsentDataContext;

    state = {
        menuVisible: false,
        modalVisible: false,
    };

    render() {
        const { storeRegistrationData, storeSubjectStatus,
             navigation, subjectData, isRegistered, screenProps, subject, studySite, storeRegisteredSubjectData,
              user, storeSignatureType, subjectNavTours, 
              clearSubjectNavTour, formDataList, failedToRegisterSubject, deviceNo } = this.props;
        const { menuVisible } = this.state;
        const askForLAR = getAskForLAR(this.context.languages,subjectData.language);
        const data = getConsentChapter(this.context.languages, subjectData.language, 'REGISTRATION_FORM');

        return (
            <SubjectRegistrationForm storeSignatureType={storeSignatureType}
                subjectData={subjectData}
                isRegistered={isRegistered}
                navigation={navigation}
                data={data}
                storeRegistrationData={storeRegistrationData}
                storeSubjectStatus={storeSubjectStatus}
                askForLAR={askForLAR}
                screenProps={screenProps}
                subject = {subject}
                studySite = {studySite}
                storeRegisteredSubjectData = {storeRegisteredSubjectData}
                user ={user}
                subjectNavTours = {subjectNavTours}
                clearSubjectNavTour = {clearSubjectNavTour}
                formDataList = {formDataList}
                failedToRegisterSubject={failedToRegisterSubject}
                deviceNo={deviceNo}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: state.clientUser.selectedUser,
    formFieldsList: state.subject,
    isRegistered: state.subject.isRegistered,
    subjectData: state.subject,
    subject: state.subject,
    studySite: state.studySite,
    subjectNavTours: state.subject.navTour,
    formDataList: state.documents.formDataList,
    deviceNo: state.consentTokenData.deviceNo
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeRegistrationData,
        storeRegisteredSubjectData: storeRegisteredSubjectData,
        storeSubjectStatus,
        storeSignatureType,
        clearSubjectNavTour,
        failedToRegisterSubject
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(SubjectRegistrationScreen);