import React, { Component } from "react";
import SubjectResetPassword from "../components/subjectValidation/SubjectResetPassword";
import { connect } from "react-redux";

class SubjectResetPasswordPage extends Component {
  state = {};

  render() {
    const { navigation, currentUserType, siteDetails } = this.props;
    const navobj = JSON.parse(navigation.getParam("navobj"));
    const transactionId = navigation.getParam("transactionId");
    const userName = navobj.userName;
    const subjectId = navobj.subjectId;
    const from = navobj.from;
    const subDocSignNavProps = navobj.subDocSignNavProps;
    const isRemoteConsent = navobj.isRemoteConsent;
    const signatureType = parseInt(navobj.signatureType);
    return (
      <SubjectResetPassword
        subjectId={subjectId}
        navigation={navigation}
        from={from}
        subDocSignNavProps={subDocSignNavProps}
        isRemoteConsent={isRemoteConsent}
        signatureType={signatureType}
        currentUserType={currentUserType}
        transactionId={transactionId}
        userName={userName}
        siteDetails={siteDetails}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserType: state.consentFlow.currentUserType,
    siteDetails: state.subjectRemoteConsentDto,
  };
};

export default connect(mapStateToProps)(SubjectResetPasswordPage);
