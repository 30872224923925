import _ from "lodash";
import api from "../utils/api";
import constants from "../utils/constants.js";
const { ContextProperties } = constants;

function getBasePath(subjectId) {
  const { PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} = ContextProperties;
  return `/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/sites/${SITE_ID}/subjects/${subjectId}/comments`;
}

export const getCommentsCount = (subjectId) => async (dispatch) => {
  try {
    const url = `${getBasePath(subjectId)}?unresolved=true`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const retrieveComments = (subjectId) => async (dispatch) => {
  try {
    if (!subjectId) {
      throw new Error("Subject Id is required");
    }
    const url = getBasePath(subjectId);
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addNewComment = (subjectId, comment, isRemoteConsent) => async (dispatch) => {
  const loRequest = {
    ...comment,
    subjectId: subjectId,
  };
  try {
    const url = `${getBasePath(subjectId)}?isRemoteConsent=${isRemoteConsent}`;
    const response = await api.post(url, loRequest);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = (subjectId, commentId) => async (dispatch) => {
  try {
    const url = `${getBasePath(subjectId)}/${commentId}`;
    await api.delete(url);
  } catch (error) {
    console.log(error);
  }
};

export const addResponse = (subjectId, commentDetails) => async (dispatch) => {
  const loRequest = {
    response: commentDetails.response,
    subjectComment: {
      id: commentDetails?.subjectComment?.id,
      subjectId: subjectId,
      subjectConsentId: commentDetails.subjectConsentId,
      comment: commentDetails.subjectComment.comment,
    },
    id: commentDetails.id ? commentDetails.id : null,
  };
  try {
    const url = `${getBasePath(subjectId)}/${commentDetails?.subjectComment?.id}/response`;
    const response = await api.post(url, loRequest);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteResponse = (subjectId, responseId, commentId) => async (dispatch) => {
  try {
    const url = `${getBasePath(subjectId)}/${commentId}/response/${responseId}`;
    await api.delete(url);
  } catch (error) {
    console.log(error);
  }
};

export const resolveComment = (subjectId, subjectComment, isRemoteConsent) => async (dispatch) => {
  try {
    const url = `${getBasePath(subjectId)}/${subjectComment.id}?isRemoteConsent=${isRemoteConsent}`;
    const response = await api.put(url, subjectComment);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
