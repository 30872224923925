import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { Colors } from "../constants/ui/colorScheme";
import { fontType } from "./font";

export const constStyles = StyleSheet.create({
  buttonStyle: {
    padding: 4,
    backgroundColor: Colors.buttonwithbg,
    borderRadius: 5,
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonTxt: {
    // margin:'5%',
    // marginHorizontal:10,
    minWidth: 70,
    padding: 5,
    marginHorizontal: 16,
    color: Colors.textColorwithbg,
    fontSize: fontType("ButtonTxt"),
    fontFamily: "Inter_Bold",
    // alignSelf: 'center',
    textAlign: "center",
  },
  pageTitleStyle: {
    fontSize: fontType("Heading1"),
    fontFamily: "Inter",
    color: Colors.text,
    fontWeight: '500',
  },
  inputStyles: {
    borderRadius: 4,
    borderColor: "#c9c9c9",
    borderWidth: 1,
    transition: "#c9c9c9",
    fontSize: 14,
    fontStyle: "normal",
    fontFamily: "Inter",
    color: "rgba(0,0,0,.85)",
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 3,
    paddingRight: 3,
    outlineColor: "#c9c9c9",
    outlineStyle: "normal",
    outlineWidth: 1,
  },
  errorPageText: {
    fontFamily: "Inter",
    color: "rgba(58, 53, 65, 0.87)",
    fontSize: 20,
    margin: 0,
    textAlign: "center",
  },
  errorHeadingText: {
    fontFamily: "Inter",
    color: "rgba(58, 53, 65, 0.87)",
    fontSize: 20, 
    fontWeight: 'bold',
  },
  textStyle: {
    textAlign: 'center',
    fontFamily: 'Inter',
    marginBottom: 20,
    fontSize: 16,
  },
  containerStyle: {
    flex: 1, 
    justifyContent: 'center', 
    backgroundColor: '#f2f2f2'
  },
  contentCenterStyle: {
    alignContent: 'center', 
    alignSelf: 'center',
  },
  paddingStyle:{
    alignContent: 'center', 
    alignSelf: 'center',
    paddingTop:10,
    paddingBottom: 10
  },
  contentViewStyle: {
     flex: 1, 
     justifyContent: 'center'
  }
});

export const chapterButtonStyles = StyleSheet.create({
  button: {
    backgroundColor: Colors.button,
    margin: 5,
    padding: 5,
    borderRadius: 25,
    borderColor: "white",
    borderWidth: 1,
    justifyContent: "center",
  },

  buttonNextDisabled: {
    backgroundColor: Colors.button,
    margin: 2,
    margin: 5,
    padding: 5,
    borderRadius: 25,
  },
  nextTxtDisabled: {
    color: "#ffffff",
    minWidth: 90,
    textAlign: "center",
    fontSize: fontType("ButtonTxt"),
    marginHorizontal: 10,
    padding: 5,
    fontFamily: "Inter_Bold",
  },
  buttonTxt: {
    color: "white",
    minWidth: 90,
    textAlign: "center",
    fontSize: fontType("ButtonTxt"),
    marginHorizontal: 10,
    padding: 5,
    fontFamily: "Inter_Bold",
  },
  buttonTxtDisabled: {
    color: "white",
    opacity: 0.5,
    minWidth: 90,
    textAlign: "center",
    fontSize: fontType("ButtonTxt"),
    marginHorizontal: 10,
    padding: 5,
    fontFamily: "Inter_Bold",
  },
  footer: {
    backgroundColor: "rgb(145, 85, 253)",
    justifyContent: "flex-end",
    alignItems: "center",
    // height: wp('10%')
  },

  documentTitle: {
    textAlign: "center",
    justifyContent: "center",
    color: Colors.text,
    fontSize: fontType("Heading1"),
    fontFamily: "Inter",
  },
  //   buttonTxt: {
  //     color: 'white',
  //     fontSize: fontType('ButtonTxt'),
  //   },
});

export const signatureButtonStyles = StyleSheet.create({
  buttonStyle: {
    padding: 4,
    backgroundColor: Colors.radioGroup,
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonTxt: {
    // margin:'5%',
    // marginHorizontal:10,
    minWidth: 70,
    padding: 5,
    marginHorizontal: 16,
    color: "#ffffff",
    fontSize: fontType("ButtonTxt"),
    fontFamily: "Inter_Bold",
    borderColor: Colors.radioGroup,
    borderRadius: "15%",
  },
});
