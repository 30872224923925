import React, { Component } from "react";
import SubjectValidateOtp from "../components/subjectValidation/SubjectValidateOtp";
import { connect } from "react-redux";

class SubjectValidateOtpPage extends Component {
  state = {};

  render() {
    const { navigation,currentUserType } = this.props;
    const validationObj = JSON.parse(navigation.getParam("validationObj"));
    return (
      <SubjectValidateOtp
        validationObj = {validationObj}
        navigation={navigation}
        currentUserType={currentUserType}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
     
     currentUserType: state.consentFlow.currentUserType
  };
};


export default connect(mapStateToProps)(SubjectValidateOtpPage);
