import {StyleSheet} from 'react-native';


export default StyleSheet.create(
    {
        listInline:{
            paddingLeft: 0,
            listStyle: 'none',
        },
        listUnstyled:{
            paddingLeft: 0,
            listStyle: 'none',
        },
        profileLink: {
            position: 'absolute',
            top: '70%',
            right: 0,
            borderRadius: '50%',
            width: 12,
            height: 12,
            borderColor:'#fff',
            borderWidth: 2,
            backgroundColor: '#56ca00'
        },
        statusBox: {
            position: 'absolute',
            top: '70%',
            right: 0,
            borderRadius: '50%',
            width: 12,
            height: 12,
            borderColor:'#fff',
            borderWidth: 2,
            backgroundColor: '#56ca00'
        },
        userActive: {
            backgroundColor: '#56ca00'
        },
        userInfo: {
            gap: 10
        },
        dFlex: {
            display: 'flex'
        },
        AlignItemsCenter: {
            alignItems: 'center',
        },
        flexShrink0: {
            flexShrink: 0,
        },
        positionRelative: {
            position: 'relative' 
        },
        flexGrow1: {
            flexGrow: 1,
        },
        userData: {
            fontSize: 14,
            lineHeight: 1.25,
            fontWeight: '600',
            color: 'rgba(58,53,65,.87)',
            margin: 0,
        },
        colorGray800: {
            color: 'rgba(58,53,65,.68)',
        },
        h2: {
            fontSize: 14,
            lineHeight: 1.25,
            fontWeight: '600',
            color: 'rgba(58,53,65,.87)',
            margin: 0,
        },
        ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
           
        },
        p0: {
            padding: 0,
        },
        profileMenu: {
        //     marginTop: 0,
        //     marginBottom: 0,
        //     marginLeft: -20,
        //     marginRight: -20,
        //    lineHeight: 1.42,
        //    borderColor: 'transparent',
        //    paddingTop: 9,
        //     paddingBottom: 9,
        //     paddingLeft: 20,
        //     paddingRight: 20,
        //     borderTopWidth: 1,
        //     borderTopColor: 'rgba(58,53,65,.12)',
        //     minWidth: 20,
        //     fontSize: 17,
        },
        li: {
            borderColor: 'transparent',
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 12,
            paddingRight: 20,
            borderTopWidth: 1,
            borderTopColor: 'rgba(58,53,65,.12)',
            minWidth: 20,
            fontSize: 17,
        },
        profileMenuLiA: {
            color: 'rgba(58,53,65,.87)',
        },
        a: {
        textDecorationStyle: 'none',
        },
        iconHolder: {
            minWidth: 20,
            fontSize: 17,
        },
        icon: {
            fontFamily: 'icomoon',
            speak: 'none',
            fontStyle: 'normal',
            fontWeight: '400',
            fontVariant: 'normal',
            textTransform: 'none',
            lineHeight: 1,
        },
        me5: {
             marginRight: '5%',
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        dInlineFlex : {
            display: 'flex',
        },
        H2FontSize: {
            fontSize: 14,
            fontFamily: 'Inter',
            fontWeight: '600',
        },
        userDataInfo: {
            marginLeft: '5%'
        },
        subTitle: {
            color: 'rgba(58,53,65,.38)' ,
            fontSize: 10,
            fontFamily: "Inter" 
        },
        userDataWrapper: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 5,
            marginTop: 15
        },
        profileMenuItems: {
            minWidth: 20,
            color: 'rgba(58,53,65,.87)',
            fontSize: 14,
            justifyContent: 'center',
            fontFamily: 'Inter'
        },
        userData: {
            fontFamily: "Inter", 
            color: 'rgba(58,53,65,.38)',
            marginBottom: '2%',
            fontSize: 12,
        }


    }
)





