import {StyleSheet, Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import { black } from 'ansi-colors';
import {fontType} from '../../../styles/font'

export default StyleSheet.create(
    {
        container:{
            backgroundColor: Colors.background,
            // paddingTop: 4,
            // height: Dimensions.get('window').height,
            // width: Dimensions.get('window').width,
            flex: 1
        },
        heddingView: {
            backgroundColor: 'white',
            padding: '1%',
            marginBottom:3,
            justifyContent:'center',
        },
        childView:{
            flexDirection:'row',
            flex:1,
            paddingLeft:wp('2%'),
        },
        contentView:{
             flexDirection: 'row',
             backgroundColor: Colors.searchBg,
        },
        component:{
            flex:1,
            flexWrap:'wrap',
            justifyContent:'center',
        },
        numberOfSubjects:{
            textAlign:'center',
            textAlignVertical:'center',
            color: Colors.text,
            fontSize: 60,
            // fontWeight: 'bold',
            alignSelf:'center',
            fontFamily:'Inter_Bold' 
        },
        nameOfHospitalText:{
            color: Colors.noOfSubjects,
            fontSize: fontType('BasicFont'),
            textAlign:'center',
            justifyContent:'center',
            fontFamily:'Inter',
            paddingHorizontal: hp('0.5%'),

        },
        title: {
            padding:wp('2%'),
            backgroundColor: Colors.dashboardTitleBg,
          }
      
    }
)