import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DocumentStatus, SubjectStatus } from '../../common/constants';
import { generatePDF } from '../../../actions/documents';
import DocumentRightChildren from '../DocumentRightChildren';
import { Colors } from '../../../constants/ui/colorScheme';
import { widthPercentageToDP as wp} from "react-native-responsive-screen";
import { fontType } from '../../../styles/font';
import { Body, ListItem, Right } from 'native-base';

const styles= StyleSheet.create({
    documentTxt: {
        fontSize: fontType("BasicFont"),
        color: Colors.text,
        fontFamily: "Inter",
      },
    documentVersionText: {
        fontSize: wp("2%"),
        color: Colors.statusText,
        marginTop: wp("1.5%"),
        fontSize: fontType("subFont"),
        fontFamily: "Inter",
    }
});

const SubjectDocument = (props)  => {
    const {document: item, index, onDocumentSelected} = props
    const { selectedSubject } = useSelector(state => state);
    
  return (
    <View>
          <ListItem
              button
              onPress={() => {
                  if (selectedSubject.status !== SubjectStatus.CONSENTED || item.actionList[0].documentStatus == DocumentStatus.NOT_FULLY_SIGNED) {
                      onDocumentSelected(item.documentId, DocumentStatus.NOT_FULLY_SIGNED);
                  }
              }}
              disabled={(selectedSubject.status == SubjectStatus.CONSENTED|| selectedSubject.status == SubjectStatus.DISCONTINUED) && item.actionList[0].documentStatus == DocumentStatus.FULLY_SIGNED}
          >
              <Body>
                  <Text children={true} style={styles.documentTxt}>{item.documentName}</Text>
                  {item.documentVersion ? (<Text style={styles.documentVersionText}>Version: {item.documentVersion}</Text>) : (<Text />)}
              </Body>
              <Right>
                  <View style={{ flexDirection: "row" }}>
                      <DocumentRightChildren
                          actionList={item.actionList}
                          index={index}
                          isSignatureCompleted={item.isSignatureCompleted}
                      />
                  </View>
              </Right>
          </ListItem>
    </View>
  )
}
export default SubjectDocument