export const Colors = {
     'header': '#ffffff',
     'button': 'transparent',
     'buttonwithbg': '#9155FD',
     'canvasbg': '#eaeaea',
     'errorButton': '#f44336',
     'textColorwithbg': '#FFF',
     'textColor': '#6c98d4',
     'coloredText': '#0086ca',
     'background': '#ffffff',
     'ActivityIndicator': '#0086ca',
     'aiwithbg': '#FFF',
     'ConsentIcon': '#0086ca',
     'bubbleColor': '#0086ca',
     'discontinued': '#656565',
     'consentedColor': '#5fae41',
     'needsCounterSign': '#ff8e00',
     'needSign': 'red',
     'subjectListBackground': '#eeeeee',
     'highLightText': '#000',
     'fieldSubmit': '#5fae41',
     'error': '#f44336',
     'warning': '#ff8e00',
     'radioGroup': 'rgb(145, 85, 253)',
     'borderColor': '#bdbdbd',
     'question': '#0086ca',
     'spinner': '#0086ca',
     'backToDocBt': '#6c98d4',
     'tick': '#5fae41',
     'checkCircle': '#5fae41',
     'searchBg': 'white',
     'pinInput': '#f0f2f5',
     'pinInputNotEmpty': '#0086ca',
     'appName': 'black',
     'appBackground': '#f0f2f5',
     'buttondisabled': '#c9c9c9',
     'text': '#3f3f3f',
     'signDetails': '#656565',
     'noOfSubjects': '#656565',
     'iconColor': '#656565',
     'optionSelect': '#eaeaea',
     'reglabels': '#656565',
     'cancelButton': '#FFF',
     'borderColor': '#c9c9c9',
     'fieldNameStyle': '#3f3f3f',
     'textdisabled': '#c9c9c9',
     'textInputBorder': '#c9c9c9',
     'touchableOpacity': '#fff',
     'chapterPage': '#fff',
     'dashboardTitleBg': '#f8f8f8',
     'htmlparserText': '#fff8e1',
     'whiteBg': '#fff',
     'signHeading': '#3f3f3f',
     'topicLabel': '#333',
     'footer': '#0086ca',
     'tobg': '#fff',
     'textFormatColor': '#37474f',
     'tagAColor': '#007AFF',
     'hintText': '#3f3f3f',
     'consentTextColor': '#3f3f3f',
     'consentIconColor': '#3f3f3f',
     'statusText': '#656565',
     'headerTitle': '#0086CA',
     'dangerColor': '#FF4C51',
     'borderGreyColor': '#E3E3E3',
     'tableHeader': '#E7E3FC',
     'whiteSmoke': '#F5F5F5',
     'somethingWentWrong': '#9155FD',
     'loginAgain':'#1C3177',
}