import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React, { Component } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform, FlatList, TouchableOpacity, Dimensions } from 'react-native';
import { Colors } from '../constants/ui/colorScheme';
import { fontType, buttonFont } from '../styles/font'
import { constStyles } from '../styles/constStyles'
import {
  Container, Body, Right, List, ListItem, Content, Card, FooterTab, Footer, Button, Title, Left, Switch,
} from "native-base";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Octicons from 'react-native-vector-icons/Octicons';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5'
import api from '../utils/api';
import MyLoader from '../utils/webLoader';
import NoEmailPopUp from '../utils/NoMailPopUp';
import _ from 'lodash';
import showToast from '../utils/toast';
import { AsyncStorage } from 'react-native';
import EmailConsentPopup from './subjectList/EmailConsentPopup';
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')
import constants from "../utils/constants.js";
const { ContextProperties } = constants



export default class DocumentSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      witnessClr: false,
      larClr: "#ddeefe",
      subjectClr: "#ddeefe",
      subjectConsentDocuments: [],
      showNoDataPopup: false,
      isShowEmailPopUp: false,
      disabled: false,
      loading:false,
      isAllDocsOptional: false
    };
  }

  componentDidMount() {
    this.getDocuments(false);
  }

  getDocuments = async (byReset) => {
    const {
      selectedSubject,
      navigation,
      setDocumentsAndSignatures,
    } = this.props;
    try {
      this.setState({ loading: true });
      const res = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/subjectConsents/${selectedSubject?.subjectConsent.id}/documentDetails` );
      let modifiedData = _.map(res.data, (scd) => {
        
        if (scd.id == null || byReset) {
          // setting doc selected or not
          if (scd.chapterPage.isDefaultDocument) {
            scd.isDeleted = false;
          } else {
            scd.isDeleted = true;
          }
          // setting signatures as required
          scd.subjectDocumentSignatures = _.map(
            scd.subjectDocumentSignatures,
            (sds) => {
              if (sds.id == null || byReset) {
                if (
                  (sds.documentSignature.required &&
                    !selectedSubject.isReconsent) ||
                  (sds.documentSignature.isReconsentRequired &&
                    selectedSubject.isReconsent) || (selectedSubject.isReconsent && scd.chapterPage.isNewDocument) || (selectedSubject.isReconsent && sds.documentSignature.required)
                ) {
                  // check for other sign
                  let currentSignType = sds.documentSignature.signatureType;
                  if(currentSignType == 1 || currentSignType == 2 ) {
                      const otherSdsIndex = _.findIndex(scd.subjectDocumentSignatures, {
                        documentSignature: { 
                          signatureType: currentSignType == 1 ? 2 : 1               
                        },
                      });
                      if (otherSdsIndex != null && otherSdsIndex != undefined ) {
                        let otherDs = scd.subjectDocumentSignatures[otherSdsIndex].documentSignature;            
                        if ( (otherDs.required && !selectedSubject.isReconsent)
                          || (otherDs.isReconsentRequired && selectedSubject.isReconsent) 
                        || (selectedSubject.isReconsent && scd.chapterPage.isNewDocument) || 
                        (selectedSubject.isReconsent && sds.documentSignature.required)
                        ) {
                          if(currentSignType == 1) {
                            sds.isDeleted = false;
                          } else {
                            sds.isDeleted = true;
                          }            
                        } else {
                          sds.isDeleted = false;
                        }
                      } else {
                        sds.isDeleted = false;
                      }
                  } else {
                    sds.isDeleted = false;
                  }                  
                } else {
                  sds.isDeleted = true;
                }
              }
              return sds;
            }
          );
        }
        return scd;
      });
      this.setState({
        subjectConsentDocuments: modifiedData,
        loading: false,
        disabled: this.isSubmitDisabled(modifiedData),
      });
    //check if all documents are optional
    this.isAllDocumentsOptional(modifiedData);
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  isSubmitDisabled = (documents) => {
    let isSubmitDisabled = false;
    const docs = _.filter(documents, { isDeleted: false });
    // if no selected docs present
    if (docs.length != 0) {
      // if no selected signs present
      let isSignsPresent = [];
      _.forEach(documents, function (doc) {
        const sds = _.filter(doc.subjectDocumentSignatures, {
          isDeleted: false,
        });
        if (sds.length > 0) {
          isSignsPresent.push(sds);
        }
      });
      if (isSignsPresent.length > 0) {
        isSubmitDisabled = false;
      } else {
        isSubmitDisabled = true;
      }
    } else {
      isSubmitDisabled = true;
    }
    return isSubmitDisabled;
  };

  getBackGroundColor = (documentId, signType) => {
    const { subjectConsentDocuments } = this.state;
    const scd = _.find(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    const sds = _.find(
      scd.subjectDocumentSignatures,
      { documentSignature: { signatureType: signType } },
      "documentSignature"
    );
    if (this.checkDocSelection(documentId)) {
      if (sds && !sds.isDeleted) return "#ddeefe";
      else return "#fff";
    } else return "#fafdff";
  };

  getGridDisabled = (subjectDocumentSignatures, signType) => {
    var filterSinsDocuments = _.filter(subjectDocumentSignatures,(docs) => {
      if(docs.documentSignature.signatureType == signType) {
        return docs
      }
    });
    if(filterSinsDocuments==null){
      return false;
    } else

    if(filterSinsDocuments.length!=0 && filterSinsDocuments[0].signatureFilePath == null){
      return false;
    } else {
      return true;
    }
  }

  getSelectedDisabled = (subjectDocumentSignatures) => {
     var signedDocuments = _.filter(subjectDocumentSignatures,(docs) => {
        return docs.signatureFilePath != null;
    });
    if(signedDocuments.length>0){
      return true;
    } else  {
      return false;
    }
  }

  getIconColor = (documentId, signType) => {
    const { subjectConsentDocuments } = this.state;
    const { selectedSubject } = this.props;
    const scd = _.find(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    const sds = _.find(
      scd.subjectDocumentSignatures,
      { documentSignature: { signatureType: signType } },
      "documentSignature"
    );
    if (this.checkDocSelection(documentId)) {
      if (sds && !sds.isDeleted) {
        return "#2566b8";
      }
      if (
        sds &&
        (
          (sds.documentSignature.required && !selectedSubject.isReconsent) ||
          (sds.documentSignature.isReconsentRequired && selectedSubject.isReconsent) ||
          sds.documentSignature.optional || (selectedSubject.isReconsent && scd.chapterPage.isNewDocument) || (selectedSubject.isReconsent && sds.documentSignature.required)
        )
      ) {
        return "#808080";
      }
    }
    return "#e5edf7";
  };

  checkIsSkippable = (documentId) => {
    const { subjectConsentDocuments } = this.state;
    const scd = _.find(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    if (!scd.isDeleted) {
      if (scd.chapterPage.isOptionalDocument) return "gray";
      else return "#fff";
    } else return "#fff";
  };

  checkDocSelection = (documentId) => {
    const { subjectConsentDocuments } = this.state;
    const scd = _.find(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    if (scd.id !== null) {
      if (scd.isDeleted == false) {
        return true;
      } else {
        return false;
      }
    } else if (scd.chapterPage.isDefaultDocument) {
      return true;
    } else {
      return false;
    }
  };

  onDocChange = (documentId) => {
    const { subjectConsentDocuments } = this.state;
    const objIndex = _.findIndex(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    let newArray = [...this.state.subjectConsentDocuments];
    newArray[objIndex] = {
      ...newArray[objIndex],
      isDeleted: !newArray[objIndex].isDeleted,
      chapterPage: {
        ...newArray[objIndex].chapterPage,
        isDefaultDocument: !newArray[objIndex].chapterPage.isDefaultDocument,
      },
    };
    this.setState({
      disabled: this.isSubmitDisabled(newArray),
      subjectConsentDocuments: newArray,
    });
  };

  checkEmailAndUpdateSigns = (documentId, signType) => {
    this.updateSigns(documentId, signType);
  };

  updateSigns = (documentId, signType) => {
    const { subjectConsentDocuments } = this.state;
    const { selectedSubject } = this.props;
    const scd = _.find(subjectConsentDocuments, {
      chapterPage: { id: documentId },
    });
    const sdsIndex = _.findIndex(scd.subjectDocumentSignatures, {
      documentSignature: { signatureType: signType },
    });
    if (sdsIndex != -1) {
      const scdIndex = _.findIndex(subjectConsentDocuments, {
        chapterPage: { id: documentId },
      });
      let newArray = [...this.state.subjectConsentDocuments];
      let ds =
        newArray[scdIndex].subjectDocumentSignatures[sdsIndex]
          .documentSignature;
      let sds = newArray[scdIndex].subjectDocumentSignatures[sdsIndex];

      // check if sign exists in admin portal
      if (ds.optional
        || (ds.required && !selectedSubject.isReconsent)
        || (ds.isReconsentRequired && selectedSubject.isReconsent) || (selectedSubject.isReconsent && scd.chapterPage.isNewDocument) || (selectedSubject.isReconsent && sds.documentSignature.required)) {
        if (signType == 1 || signType == 2) {
          const otherSdsIndex = _.findIndex(scd.subjectDocumentSignatures, {
            documentSignature: { 
              signatureType: signType == 1 ? 2 : 1               
            },
          });

          if (otherSdsIndex != null && otherSdsIndex != undefined ) {
            let otherDs =
              newArray[scdIndex].subjectDocumentSignatures[otherSdsIndex]
                .documentSignature;

            if (
              otherDs.optional
              || (otherDs.required && !selectedSubject.isReconsent)
              || (otherDs.isReconsentRequired && selectedSubject.isReconsent) || (selectedSubject.isReconsent && scd.chapterPage.isNewDocument) || (selectedSubject.isReconsent && sds.documentSignature.required)
            ) {

              // check if both signs are optional
              if(ds.optional && otherDs.optional) {
                // check if clicked sign is active
                if(newArray[scdIndex].subjectDocumentSignatures[sdsIndex].isDeleted) {
                  newArray[scdIndex].subjectDocumentSignatures[sdsIndex].isDeleted = false;
                  newArray[scdIndex].subjectDocumentSignatures[otherSdsIndex].isDeleted = true;
                } else {
                  newArray[scdIndex].subjectDocumentSignatures[sdsIndex].isDeleted = true;
                  newArray[scdIndex].subjectDocumentSignatures[otherSdsIndex].isDeleted = false;
                }  
              } else {
                  let clickedState = newArray[scdIndex].subjectDocumentSignatures[sdsIndex].isDeleted; 
                  newArray[scdIndex].subjectDocumentSignatures[sdsIndex].isDeleted = !clickedState;
                  newArray[scdIndex].subjectDocumentSignatures[otherSdsIndex].isDeleted = clickedState;
              }             

            } else {
              if(ds.optional) { 
                newArray[scdIndex].subjectDocumentSignatures[
                  sdsIndex
                ].isDeleted = !newArray[scdIndex].subjectDocumentSignatures[sdsIndex]
                  .isDeleted;
              }
            }
          } else {
            if(ds.optional) {
              newArray[scdIndex].subjectDocumentSignatures[
                sdsIndex
              ].isDeleted = !newArray[scdIndex].subjectDocumentSignatures[sdsIndex]
                .isDeleted;
            }
          }
        } else {
          if(ds.optional){
            newArray[scdIndex].subjectDocumentSignatures[
              sdsIndex
            ].isDeleted = !newArray[scdIndex].subjectDocumentSignatures[sdsIndex]
              .isDeleted;
          }
        }

        this.setState({
          disabled: this.isSubmitDisabled(newArray),
          subjectConsentDocuments: newArray,
        });
      }
    }
  };

  onSubmit = async () => {
    const { subjectConsentDocuments } = this.state;
    const {
      consentType,
      selectedSubject,
      navigation,
      setDocumentsAndSignatures,
    } = this.props;
    try {
      showToast("Saved successfully.", "success", 3000); //code for POPUP MESSAGE

      this.setState({ loading: true });
      const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/subjectConsents/${selectedSubject?.subjectConsent.id}/documents`,
        subjectConsentDocuments
      );
    setDocumentsAndSignatures(res.data, selectedSubject.larAsWitness);
    this.getDocuments(false);
      this.setState({ loading: false });

      // for in-office
      if (consentType != "remote") {
        let isMailsOrDataPresent = this.checkDetailsConfigured(false);
        if(isMailsOrDataPresent) {
          navigation.navigate("HandoverTabTosub");
        } else {
          this.setState({ showNoDataPopup: true });
        }
      } 
      
      // for remote
      if (consentType == "remote") {
        let isMailsOrDataPresent = this.checkDetailsConfigured(true);
        if (isMailsOrDataPresent) {
          setTimeout(() => {
            this.handleEmailConsentPopup();
          }, 3000);
         
        } else {
          this.setState({ showNoDataPopup: true });
        }
      } 
    } catch (error) {
      this.setState({ loading: false });
      showToast("Failed to initiate the consent process.", "danger", 4000);
      console.log(error);
    }
  };

  checkDetailsConfigured = (isRemote) => {
    const { selectedSubject } = this.props;
    const { subjectConsentDocuments } = this.state;
    let isMailsOrDataPresent = true;
    const reqScd = _.filter(subjectConsentDocuments, { isDeleted: false });
    _.map(reqScd, (scd) => {
      _.map(scd.subjectDocumentSignatures, (sds) => {
        if (
          // subject email check only if remote
          (sds.documentSignature.signatureType == 1 && sds.isDeleted == false && 
            isRemote ? (selectedSubject.email == null || selectedSubject.email == "") : false
          )
          || 
          // verify lar details
          (sds.documentSignature.signatureType == 2 && sds.isDeleted == false 
            && (
                // verify names of lar in both in-office or remote               
                selectedSubject.larFirstName == null || selectedSubject.larFirstName == "" ||
                selectedSubject.larLastName == null || selectedSubject.larLastName == ""   ||
                // check email only if remote
                isRemote ? (selectedSubject.larEmail == null || selectedSubject.larEmail == "") : false 
              )
          )           
          ||
          // verify witness details
          (sds.documentSignature.signatureType == 4 && sds.isDeleted == false 
            && (
              // verify names of witness in both in-office or remote    
              selectedSubject.witnessFirstName == null || selectedSubject.witnessFirstName == "" ||
              selectedSubject.witnessLastName == null || selectedSubject.witnessLastName == "" ||
              // check email only if remote
              isRemote ? (selectedSubject.witnessEmail == null || selectedSubject.witnessEmail == "") : false 
            )
          )
        ) {
          isMailsOrDataPresent = false;
        }
      });
    });
    return isMailsOrDataPresent;    
  };

  toggleIsNoMailPopUp = () => {
    this.setState((prevState) => ({
      ...prevState,
      showNoDataPopup: !prevState.showNoDataPopup,
    }));
  };

  onResetClicks = async () => {
    this.setState({ loading: true });

    const { subjectConsentDocuments } = this.state;
    
    let modifiedData = _.map(subjectConsentDocuments, (scd) => {      
        // setting doc selected or not
        if (scd.chapterPage.isDefaultDocument) {
          scd.isDeleted = false;
        } else {
          scd.isDeleted = true;
        }
        // setting signatures as required
        scd.subjectDocumentSignatures = _.map(
          scd.subjectDocumentSignatures,
          (sds) => {
            if (sds.id == null) {
              if (
                (sds.documentSignature.required &&
                  !selectedSubject.isReconsent) ||
                (sds.documentSignature.isReconsentRequired &&
                  selectedSubject.isReconsent)
              ) {
                sds.isDeleted = false;
              } else {
                sds.isDeleted = true;
              }
            }
            return sds;
          }
        );
      }
     );
  };

  disableResetButton = () => {
    const  {subjectConsentDocuments} = this.state;
    var buttonDisabled = false;
    if(subjectConsentDocuments!=undefined){
    subjectConsentDocuments.map((scd)=>{
      var filterSinsDocuments = _.filter(scd.subjectDocumentSignatures,(docs) => {
        if(docs.signatureFilePath != null) {
          return docs;
        }
      });
      if(filterSinsDocuments.length > 0){
        buttonDisabled = true;
      }
    })
    return buttonDisabled;
  } else  {
    return false;
  }
  }

  handleEmailConsentPopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      isShowEmailPopUp: !prevState.isShowEmailPopUp,
    }));
  };

  isAllDocumentsOptional = (subjectConsentDocuments) =>{
    const nonOptionalDocuments = _.filter(subjectConsentDocuments, scd => !scd.chapterPage.isOptionalDocument)
    if( _.isEmpty(subjectConsentDocuments) || (!_.isEmpty(subjectConsentDocuments) &&_.isEmpty(nonOptionalDocuments))){
      this.setState({isAllDocsOptional : true})
    }
  }

  //Showing submit consent button when all documents are optional and they are not selected
  submitConsentWhenNoDocs = async () => {
    const {submitConsentWhenAllDocumentsAreOptional, selectedSubject, navigation, updateSubjectStatus} = this.props
    this.setState({ loading: true });
    const res = await submitConsentWhenAllDocumentsAreOptional(selectedSubject.id, selectedSubject?.subjectConsent.id)
    if(res.status == 200){
      updateSubjectStatus("CONSENTED");
      this.setState({ loading: false });
      navigation.navigate('SubjectDetails');
    } 
  }

  render() {
    const {
      subjectConsentDocuments,
      loading,
      showNoDataPopup,
      isShowEmailPopUp,
      disabled,
      isAllDocsOptional
    } = this.state;
    const { consentType, navigation, selectedSubject,disableRemoteConsent, t} = this.props;
    return (
      <View style = {{ backgroundColor: '#ffffff'}}>
        <MyLoader active={loading} />

        <View
          style={[
            styles.title,
            { flexDirection: "row", justifyContent: "space-between",backgroundColor: '#ffffff' },
          ]}
        >
          <View>
            <Text
              style={{
                fontSize: fontType("BasicFont"),
                fontFamily: "Inter",
                fontWeight: "500",
              }}
            >
              Documents
            </Text>
            <Text style={{ fontSize: fontType("subFont"), color: "#9e9e9e", fontFamily: 'Inter' }}>
                Choose Required Documents
            </Text>
          </View>
          <View style={{ justifyContent: "center" }}>
            <TouchableOpacity
            disabled={this.disableResetButton()}
              onPress={() => {
                this.getDocuments(true);
              }}
            >
              <Text style={{ fontSize: 9, color: "#9e9e9e" , fontFamily: 'Inter'}}>
                RESET DEFAULTS
              </Text>
            </TouchableOpacity>
          </View>
        </View>
          <FlatList
          style={{height:SCREEN_HEIGHT/2.5}}
            data={subjectConsentDocuments}
            progressViewOffset={30}
            keyExtractor={(item) => item.key}
            renderItem={({ item, index }) => (
              <ScrollView>
                <View
                  style={{
                    flexDirection: "row",
                    // borderBottomColor: "lightgray",
                    // borderBottomWidth: 0.5,
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      justifyContent: "center",
                      borderBottomColor: "lightgray",
                      borderBottomWidth: 0.5,
                    }}
                  >
                    <Text children={true} style={styles.documentTxt}>
                      {item.documentName}
                    </Text>
                  </View>
                  <View
                    style={{
                      // flex: 3,
                      width: "50%",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* ------------------------ Skipable ---------------------------------- */}
                    <View style={[styles.skipableOption]}>
                      <Icon
                        name="redo"
                        color={this.checkIsSkippable(item.chapterPage.id)}
                        size={fontType("ChapterIcon")}
                      />
                      <Text
                        style={[
                          styles.optionsText,
                          { color: this.checkIsSkippable(item.chapterPage.id) },
                        ]}
                      >
                        Document is optional
                    </Text>
                      <Text
                        style={[
                          styles.optionsText,
                          { color: this.checkIsSkippable(item.chapterPage.id) },
                        ]}
                      >
                        and can be skipped
                    </Text>
                    </View>
                    {/* --------------------------Subject ------------------------------ */}
                    <TouchableOpacity
                      disabled= {this.getGridDisabled(item.subjectDocumentSignatures, 1) || this.getGridDisabled(item.subjectDocumentSignatures, 2) }
                      style={[
                        styles.options,
                        {
                          backgroundColor: this.getBackGroundColor(
                            item.chapterPage.id,
                            1
                          ),
                        },
                      ]}
                      onPress={() => {
                        this.checkEmailAndUpdateSigns(item.chapterPage.id, 1);
                      }}
                    >
                      <FontAwesome5Icon
                        name="user-alt"
                        color={this.getIconColor(item.chapterPage.id, 1)}
                        size={fontType("ChapterIcon")}
                      />
                      <Text
                        style={[
                          styles.optionsText,
                          { color: this.getIconColor(item.chapterPage.id, 1) },
                        ]}
                      >
                        Subject
                    </Text>
                    </TouchableOpacity>
                    {/* ----------------------------LAR --------------------------------- */}
                    <TouchableOpacity
                     disabled= {this.getGridDisabled(item.subjectDocumentSignatures, 1) || this.getGridDisabled(item.subjectDocumentSignatures, 2) }
                      style={[
                        styles.options,
                        {
                          backgroundColor: this.getBackGroundColor(
                            item.chapterPage.id,
                            2
                          ),
                        },
                      ]}
                      onPress={() => {
                        this.checkEmailAndUpdateSigns(item.chapterPage.id, 2);
                      }}
                    >
                      <FontAwesome5Icon
                        name="hammer"
                        color={this.getIconColor(item.chapterPage.id, 2)}
                        size={fontType("ChapterIcon")}
                      />
                      <Text
                        style={[
                          styles.optionsText,
                          { color: this.getIconColor(item.chapterPage.id, 2) },
                        ]}
                      >
                        LAR
                    </Text>
                    </TouchableOpacity>
                    {/* -------------------------------------Witness--------------------------- */}
                    <TouchableOpacity
                    disabled= {this.getGridDisabled(item.subjectDocumentSignatures, 4)}
                      style={[
                        styles.options,
                        {
                          backgroundColor: this.getBackGroundColor(
                            item.chapterPage.id,
                            4
                          ),
                        },
                      ]}
                      onPress={() => {
                        this.checkEmailAndUpdateSigns(item.chapterPage.id, 4);
                      }}
                    >
                      <FontAwesome5Icon
                        name="user-friends"
                        color={this.getIconColor(item.chapterPage.id, 4)}
                        size={fontType("ChapterIcon")}
                      />
                      <Text
                        style={[
                          styles.optionsText,
                          { color: this.getIconColor(item.chapterPage.id, 4) },
                        ]}
                      >
                        Witness
                    </Text>
                    </TouchableOpacity>
                    {/* -----------------------Selection ------------------------------- */}
                    <TouchableOpacity
                     disabled= {this.getSelectedDisabled(item.subjectDocumentSignatures)}
                      
                    style={[
                        styles.options,
                        {
                          backgroundColor: this.checkDocSelection(
                            item.chapterPage.id
                          )
                            ? "#fff0dd"
                            : "#fff",
                        },
                      ]}
                      onPress={() => {

                        this.onDocChange(item.chapterPage.id);
                      }}
                    >
                      {this.checkDocSelection(item.chapterPage.id) ? (
                        <FontAwesome5Icon
                          name="star-of-life"
                          color={"#fa8c3b"}
                          size={fontType("ChapterIcon")}
                        />
                      ) : (
                          <Octicons
                            name="primitive-dot"
                            color={"gray"}
                            size={fontType("ChapterIcon")}
                          />
                        )}
                      <Text
                        style={[
                          styles.optionsText,
                          {
                            color: this.checkDocSelection(item.chapterPage.id)
                              ? "#fa8c3b"
                              : "black",
                          },
                        ]}
                      >
                        {this.checkDocSelection(item.chapterPage.id)
                          ? "Selected"
                          : "Not Selected"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </ScrollView>
            )}
          />
        <NoEmailPopUp
          isNoMail={showNoDataPopup}
          toggleIsNoMailPopUp={this.toggleIsNoMailPopUp}
          navigation= {navigation}
        />
        {/*--------------- For Email Consent Pop up --------------------  */}
        <EmailConsentPopup
          isShowEmailPopUp={isShowEmailPopUp}
          handleEmailConsentPopup={this.handleEmailConsentPopup}
          navigation={navigation}
          selectedSubject={selectedSubject}
          subjectConsentDocuments = {subjectConsentDocuments}
        />
        <View style={{ margin:10, flexDirection: 'row', alignSelf: 'flex-end'}}>
          <View style={{marginRight: 10}}>
          <TouchableOpacity
            onPress={() => this.onSubmit()}
            style={[
              constStyles.buttonStyle,
              (disabled || disableRemoteConsent) && { backgroundColor: Colors.buttondisabled},
            ]}
            disabled={disabled || disableRemoteConsent}
          >
            <Text style={[constStyles.buttonTxt]}>
              {consentType == "remote" ? "Send" : "Begin"}
            </Text>
          </TouchableOpacity>
          </View>
          <View>
          {
            isAllDocsOptional && (<TouchableOpacity
              onPress={() => this.submitConsentWhenNoDocs()}
              style={[
                constStyles.buttonStyle,{alignSelf:'flex-end'},
                (!disabled) && { backgroundColor: Colors.buttondisabled},
              ]}
              disabled={!disabled}
            >
              <Text style={[constStyles.buttonTxt]}>
                {t("SubmitConsent")}
              </Text>
            </TouchableOpacity>

            )
          } 
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    color: Colors.text,
    fontSize: fontType('Heading1'),
    padding: 5,
    textAlign: 'center',
    fontFamily: 'Inter'
  },
  title: {
    padding: 10,
    backgroundColor: '#e0d7d714',
    borderBottomColor: 'lightgray',
    borderBottomWidth: 0.2,
    borderTopWidth: 0.2,
    borderTopColor: 'lightgray'
  },
  documentTxt: {
    fontSize: fontType('BasicFont'),
    color: Colors.text,
    fontFamily: 'Inter',
    padding: 5,
    marginLeft: 5
  },
  options: {
    alignItems: 'center',
    padding: 5,
    width: '20%',
    borderLeftWidth: 0.2,
    borderBottomWidth: 0.2,
    borderColor: 'lightgray',
    justifyContent: 'center'
  },
  skipableOption: {
    alignItems: 'center',
    padding: 5,
    minWidth: '20%',
    borderBottomWidth: 0.1,
    borderColor: 'lightgray',
    justifyContent: 'center'
  },
  optionsText: {
    marginTop: 4,
    fontSize: 9
  }

});

