import React, { Component } from "react";
import { Header, Button, Card, Input, Item, CardItem } from 'native-base';
import { StyleSheet, Text, TextInput, View, Platform } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { styles } from '../studySiteAccess/styles/StudyAccessStyle';
import {KeyboardAvoidingView, ActivityIndicator, Image} from 'react-native';
import { createForm, } from "rc-form";
import TextInputItem from '../studySiteAccess/TextInputItem';
import api from '../../utils/api';
import {Colors} from '../../constants/ui/colorScheme';
import showToast from '../../utils/toast';
import Constants from 'expo-constants';
import { NavigationEvents, ScrollView } from 'react-navigation';
import { AsyncStorage } from 'react-native';
import {fontType} from '../../styles/font'
import MyLoader from '../../utils/webLoader';

class CaptureDeviceSNo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            askDeviceSNo: false,
            studySiteAccessCode: "",
            devNoAlreadyExists: false,
            count: 0,
        }
     }

     showDeviceSNoModal = () => {
        this.setState({askDeviceSNo: true});
     }

     closeDeviceSNoModal = () => {
        this.setState({askDeviceSNo: false});
     }


     allowOnlyAlphanumeric = (rule, value, callback) => {
         const { form } = this.props;
         const { count } = this.state;
         const validValue = value.replace(/\s/g, '');
         form.setFieldsValue({
            deviceSno: validValue,
         })
         if (validValue.length !== 3 ) {
            callback ('Device Serial Number should be 3 characters.');
         } else if (validValue.length === 0) {
            callback ();
         }
         if (validValue.length === 3) {
             this.setState({
                count: 1,
             })
         }
         callback ();
     }

    onSubmit = () => {
        const { navigation, form, saveDeviceSNo } = this.props;
        // const studySiteAccessCode = navigation.getParam('studySiteAccessCode');
        const studySiteId = navigation.getParam('studySiteId');
        form.validateFields(async (error, values) => {
            if (!error) {
                const deviceSno = values.deviceSno;
                try {
                    const loSiteDevice = {
                        "deviceSno": deviceSno,
                        "deviceName": Constants.deviceName,
                        "studySite": {
                            "id": studySiteId,
                        }
                    };
                    const res = await saveDeviceSNo(loSiteDevice, navigation);
                    if(res === 406) {
                        this.setState({ devNoAlreadyExists: true});
                        setTimeout(() => {
                            this.setState({
                                devNoAlreadyExists: false,
                            });
                          }, 5000);
                    } else if (res === 'NetworkError') {
                        showToast('Network Error.', 'danger', 2000);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        });

     }

    render() {
        const { form: { getFieldDecorator, getFieldError}, loading } = this.props;
        const { devNoAlreadyExists} = this.state;
        return (
            <ScrollView>
            <KeyboardAvoidingView behavior="padding" enabled>
            <NavigationEvents
                onWillFocus={() => {
                    // this.getLanguageData();
                }}
                />
                <MyLoader  active={loading}/>
            <View style={{justifyContent:'center', alignItems: 'center', marginTop:wp('10%')}}>
                {/* <Image source={require('../../../assets/bio.png')}
                style={{ height: hp('40%'), width: wp('40%')}} 
                resizeMode="center"
                /> */}

<Text style={{textAlign:'center',fontSize:fontType('Heading1'), color:'#006699'}}>jConcur</Text>

            </View>
            <View style={{ justifyContent:'center', marginTop:wp('10%')}}>
            <Card style={styles.container}>
                <View style={{ flex: 1, 
                 }}>
                                   {/* <Text style={{textAlign:'center',fontSize:wp('5%'), color:'#006699'}}>ez-Consent</Text> */}

                        <View style={{ 
                             flex: 2.5, justifyContent: 'center'}}>
                            <Text style={styles.headerStyle}>Enter Device Serial Number</Text>
                            <View 
                            style={{ paddingTop: 20, paddingHorizontal: 15}}
                            >
                            <Text style={styles.infoText}>{"\u2022"} The Device Serial Number should be 3 characters.</Text>
                            <Text style={styles.infoText}>{"\u2022"} It must be unique across the Study Site.</Text>
                            <Text style={styles.infoText}>{"\u2022"} It is used as prefix while generating Consent ID.</Text>
                            </View>
                        </View>
                    <View style={{ justifyContent: "center",
                    // backgroundColor: 'yellow',
                    flex: 1.5,
                    marginBottom:wp('10%'),
                    //  paddingHorizontal: 50 
                     }}>
                        {getFieldDecorator('deviceSno', {
                            rules: [
                                { 
                                    required: true, message: 'Please enter Device Serial Number.',
                                },
                                {
                                    validator: this.allowOnlyAlphanumeric,
                                },
                            ],
                            validateTrigger: 'falsy'
                        })(
                            <TextInputItem
                                autoFocus
                                error={getFieldError('deviceSno')}
                                placeholderValue={'Tap here to type Device Serial Number'}
                                maxLength={3}
                            />
                        )}
                       { devNoAlreadyExists && <Text style={styles.errorText}>This Device Number is already taken.</Text> }
                    </View>
                    <View style={{ flex: 2}} >
                        <Button 
                        style={styles.buttonStyle} 
                          onPress={this.onSubmit}
                          >
                         <Text 
                         style={styles.textStyle}
                         >Submit</Text>
                        </Button>
                    </View>
                </View>
            </Card>
            </View>
        </KeyboardAvoidingView>
        </ScrollView>
        );
    }
}


export default createForm()(CaptureDeviceSNo);
