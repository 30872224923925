import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Image,
  View,
  Text,
  TouchableWithoutFeedback
} from 'react-native';
import RadioButton from './RadioButton';
import HtmlParserEg from '../htmlView/htmlparserEg';

export default class RadioFormInput extends Component{

    // onRadioSelection = (option) => {
    //     const { field, setFieldValue, sectionId, isCheckBoxChecked, values } = this.props;
    //         setFieldValue(field.dcffOid, option.optionId);
    // }

    isHTML = (str) => {
      const fragment = document.createRange().createContextualFragment(str);
      
      // remove all non text nodes from fragment
      fragment.querySelectorAll('*').forEach(el => el.parentNode.removeChild(el));
      
      // if there is textContent, then not a pure HTML
      return !(fragment.textContent || '').trim();
    }
    render(){

        const { formField, value, setFieldValue, location, currentUserType } = this.props;
        return (
          <View style={{
              flexDirection: "row",
              // paddingHorizontal: 10,
              // paddingTop: 5,
            }}
          >
            <View style={{flex: 5.8}}>
              <View style={{paddingLeft: 10, paddingRight: 10}}>
              <HtmlParserEg currentDoc={this.isHTML(formField.fieldName) ? `<span>${formField.fieldName}</span>` : `<span><p>${formField.fieldName}</p></span>`}/>

              </View>

              {formField.dictionary.map((option, index) => (
                <View
                  style={{ flexDirection: "row", paddingLeft: 5}}
                  key={index + "radioView"}
                >
                  <View style={{flex: 0.25 }}>
                    <RadioButton
                      isSelected={value === option.oid}
                      isCurrentFieldDisabled={
                        (location === "CounterSignDocument" || currentUserType == 4) ? true : false
                      }
                      onOptionSelected={() => setFieldValue(option.oid)}
                    />
                  </View>
                  <View style={{flex: 5.75,paddingLeft:10, marginTop: '-16px'}}>
                    <HtmlParserEg currentDoc={ this.isHTML(option.option) ? `<span>${option.option}</span>` : `<span><p>${option.option}</p></span>`}/>
                  </View>
                </View>
              ))}
            </View>
          </View>
        );
    }
}

RadioButton.contextTypes = {
        onSelect: PropTypes.func.isRequired,
        size: PropTypes.number.isRequired,
        thickness: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
        highlightColor: PropTypes.string
    }

let styles = StyleSheet.create({
    error :{
        color:'red',
        fontSize:15,
    },
  container:{
	  flexGrow: 1,
	  flexDirection: 'row',
	   padding: 10,
  },
  radio:{
	  alignItems: 'center',
	  justifyContent: 'center',
  },
  item: {
    marginLeft: 5,
    alignItems: 'center',
	justifyContent: 'center',
  }
})
