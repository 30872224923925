import _ from 'lodash';

export const buildSubjectList = (subjectConsents) => {
    const subjectList = _.map(subjectConsents, (subjectConsent) => {
        return {
            ...subjectConsent.subject,
            subjectConsentId: subjectConsent.id,
            consentVersionId: subjectConsent.consentVersion.id,
            status: subjectConsent.status,
            isReconsent: subjectConsent.reconsent,
            isKnowledgeReviewDone: subjectConsent.isKrDone,
        }
    });
    return subjectList;
}