export const FIELD_NAME = {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    dob: 'Date of Birth',
    phoneNo: 'Phone Number',
    email: 'Email',
    locale: 'Consent Language',
    usubjectId: 'Subject ID',
    larFirstName: 'LAR First Name',
    larLastName: 'LAR Last Name',
    larRelation: 'Relation',
    larEmail: 'LAR Email',
    witnessFirstName: 'Witness First Name',
    witnessLastName: 'Witness Last Name',
    witnessEmail: 'Witness Email',
    larAsWitness: 'LAR acts as witness'
}

export const ReasonForChangeColumns = ['Field Name', 'Current Value', 'Previous Value']

export const excludedKeysInReasonForChange = ['larAsWitness']