import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CommentsPage from './commentsContainer';
import { Colors } from '../../constants/ui/colorScheme';
import TryalButton from '../common/button';
import { connect, useSelector } from 'react-redux';
import { width } from '../common/constants';
import {printNSignIconVisible} from '../../utils/consentData'
import { generateToken, retrieveSubjectDetails } from '../../actions/subject';
import { clearClientUserDetails } from '../../actions/clientUser';
import { bindActionCreators } from "redux";
import showToast from  '../../utils/toast'
import PropTypes from 'prop-types'

const SCREEN_WIDTH = width();
const SiteStaffCommentsStyles= StyleSheet.create({
    containerStyle:{
        display: 'flex',
        backgroundColor: Colors.background,
        flex: 1,
    },
    bodyStyle: {
        padding: 10
    },
    textStyle: {
        paddingLeft: 15,
        fontFamily: 'Inter',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    }
});


const SiteStaffComments = (props) => {
    const { screenProps, navigation, signatureTypes, generateToken, clearClientUserDetails, retrieveSubjectDetails } = props;
    const unresolvedComments = navigation.getParam('unresolvedComments');
    const isRemoteConsent = 'remote' === navigation.getParam('consentType');
    const { selectedSubject, subject } = useSelector(state => state)
    const [commentsResolved, setCommentsResolved] = useState(false)
    const onNext = async() => {
        try{
            await generateToken(selectedSubject, signatureTypes[0])
            clearClientUserDetails()
            await retrieveSubjectDetails(selectedSubject.id)
            const isAllChaptersVisited = printNSignIconVisible(screenProps?.languages, "English", subject.isKnowledgeReviewDone, selectedSubject.subjectConsent)
            if(isAllChaptersVisited){
                navigation.navigate('Consent', {screen: 'CommentsReviewScreen'})
            }else{
                navigation.navigate('Consent', {screen: 'ConsentOverviewScreen'})
            }
        }
        catch(error) {
            console.log(error);
            showToast(screenProps.t('ProccedFail'), 'danger', 3000);
        }
    }

    useEffect(() => {
        setCommentsResolved(Number(unresolvedComments) == 0 ? true : false)
    }, [unresolvedComments])
    
    return (
        <View style = {SiteStaffCommentsStyles.containerStyle}>
            {
                commentsResolved &&
                <React.Fragment>
                    {isRemoteConsent && 
                    <React.Fragment>
                        <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('AllCommentsAddressed')}</Text>
                        <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('CommentsEmailSent')}</Text>
                    </React.Fragment>}
                    { !isRemoteConsent && 
                    <React.Fragment>
                    <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('AllCommentsAddressed')}</Text>
                    <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('ContinueWithSubject')}</Text>
                </React.Fragment>
                    }
            
            {
                !isRemoteConsent && 
                <View>
                <TryalButton title={screenProps.t('Continue')} onClick = {onNext}/>
                </View>
            }          
            </React.Fragment>
            }
            <Text style = {[SiteStaffCommentsStyles.textStyle, , {textAlign: 'left', fontWeight: 'bold', paddingLeft: SCREEN_WIDTH*0.01}]}>Comments</Text>
            <CommentsPage 
            addNewComment = {false} 
            isSubjectView = {false}
            setCommentsResolved={(val) => setCommentsResolved(val)}
            screenProps={screenProps}
           />
        </View>
    )
}

SiteStaffComments.defaultProps = {
    screenProps: {},
    navigation: {},
    signatureTypes: [],
};

SiteStaffComments.propTypes = {
    screenProps: PropTypes.object,
    navigation: PropTypes.object,
    signatureTypes: PropTypes.array,
    generateToken: PropTypes.func.isRequired,
    clearClientUserDetails: PropTypes.func.isRequired,
    retrieveSubjectDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  signatureTypes: state.consentFlow.signatureTypes,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearClientUserDetails: () => dispatch(clearClientUserDetails()),
      generateToken,
      retrieveSubjectDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteStaffComments);
