import createActionType from '../utils/action';
// import api from '../utils/api';
import _ from 'lodash';

export const STORE_REGISTRATION_DATA = createActionType('STORE_REGISTRATION_DATA')
export const STORE_SUBJECT_REGISTERED_DATA = createActionType('STORE_SUBJECT_REGISTERED_DATA')
export const SUBJECT_REGISTRATION_FAILED = createActionType('SUBJECT_REGISTRATION_FAILED')


export const storeRegistrationData = (formFields, subjectDetails) => ({
    type: STORE_REGISTRATION_DATA,
    subject: { formFields, subjectDetails }
});

export const storeRegisteredSubjectData = (subject) => ({
    type: STORE_SUBJECT_REGISTERED_DATA,
    id: subject.id,
    registered: subject.registered,
    subjectConsentId:subject.subjectConsent?.id,
    isRemoteConsent: subject.isRemoteConsent? true : false,
})

export const failedToRegisterSubject = () => ({
    type: SUBJECT_REGISTRATION_FAILED,
});

