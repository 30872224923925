import { Dimensions } from "react-native";

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

export const ButtonTypes = {
  FILLED: "filled",
  OUTLINED: "outlined",
};

export const IconType = {
  MATERIAL: "material",
  MATERIAL_COMMUNITY: "materialCommunity",
};

export const ChapterType = {
  STUDY_INFORMATION: {
    key: "STUDY_INFORMATION",
    value: "Study Information",
  },
  STUDY_VIDEO: {
    key: "STUDY_VIDEO",
    value: "Study Video",
  },
  ICF_DOCUMENTS: {
    key: "ICF_DOCUMENTS",
    value: "Documents",
  },
  KNOWLEDGE_REVIEW: {
    key: "KNOWLEDGE_REVIEW",
    value: "Knowledge Review",
  },
  REGISTRATION_FORM: {
    key: "REGISTRATION_FORM",
    value: "Registration Form",
  },
  SIGNATURE: {
    key: "SIGNATURE",
    value: "Signature",
  },
};

export const width = () => {
  if (SCREEN_WIDTH >= 1030) {
    return 800;
  }
  return SCREEN_WIDTH;
};

export const PrintNSignSignatureType = {
  NOT_ALLOWED: {
    key: "NOT_ALLOWED",
    value: 1,
  },
  REQUIRED: {
    key: "REQUIRED",
    value: 2,
  },
};

export const DocumentRightIcons = {
  CONSENTED_OR_DISCONTINUED: "consentedRDiscontinued",
  ARE_SIGNATURES_DONE: "Signatures",
  PRINT_AND_SIGN: "PrintNSign",
  VERIFY_PRINT_AND_SIGN: "VerifyPrintNSign",
  UNVERIFY_PRINT_AND_SIGN: "UnverifyPrintNSign",
};

export const DocumentStatus = {
 FULLY_SIGNED: "FullySigned",
 NOT_FULLY_SIGNED: "NotFullySigned",
};

export const passwordRulesArr = [
  {
    label: "Password must be at least 8 and not exceed 64 characters.",
  },
  {
    label: "Password needs to include at least one number.",
  },
  {
    label: "Password needs to include at least one special character.",
  },
  {
    label: "Password needs to include at least one uppercase letter.",
  },
  {
    label: "Password should not include spaces.",
  },
  {
    label: "Password should not be sequential and repetitive characters.",
  },
  {
    label: "Password should not include name of the user.",
  },
  {
    label: "Password should not include email account name.",
  },
  {
    label: "Password should not include site name.",
  },
  {
    label: "Password should not include organization name.",
  },
];

export const SubjectStatus = {
  DISCONTINUED: "DISCONTINUED",
  NEEDS_SIGNATURE: "NEEDS_SIGNATURE",
  CONSENTED: "CONSENTED",
  NEEDS_COUNTER_SIGNATURE: "NEEDS_COUNTER_SIGNATURE"
 };

 export const TOOLTIP_PLACEMENT = {
  TOP: "top",
  BOTTOM: "bottom",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
}

export const SIGNATURE_TYPE = {
  SUBJECT: "SUBJECT",
  LAR: "LAR",
  WITNESS: "WITNESS",
  COUNTER: "COUNTER"
 };