import React, { Component } from "react";
import { Container } from "native-base";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { constStyles } from "../../styles/constStyles";
import { styles } from './styles/serverErrorStyles';
import PropTypes from 'prop-types'

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      loginRequestId :  window.sessionStorage.getItem('loginRequestId'),
    }
  }

  onLoginClick = async () => {
    const { navigation } = this.props;
    const primaryOrgCode = window.sessionStorage.getItem('organizationCode');
  navigation.navigate('AdminAuthStack', { 
    screen: 'LoginPage', 
      params: { 
        primaryOrgCode: primaryOrgCode, 
        orgCode: primaryOrgCode 
      } 
  });
  }

  render() {
    const {screenProps: { t }} = this.props
    return (
      <Container>
        <View style={{ flex: 1, justifyContent: "center", backgroundColor: '#f2f2f2' }}>
          {/* -------------- Lock Icon ------------------ */}
          <View style={{ alignContent: "center", alignSelf: "center", marginBottom: 10 }}>
            <Image
              source={require("../../../assets/pageNotFound.svg")}
              style={styles.serverErrorImg}
              resizeMode="contain"
            />
          </View>
          {/* -------------- Message ------------------ */}
          <View style={{ alignContent: "center", alignSelf: "center" }}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={styles.someThingWentWrong}>
                {t('SomeThingWentWrong')}
              </Text>
            </View>
          </View>
          { this.state.loginRequestId != null && this.state.loginRequestId != '' &&
          <View>
             <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={styles.loginAgain}>
              {t('PleaseLoginAgain')}
              </Text>
            </View>
          <View style={ { justifyContent:'center',flexDirection: "row",flexGrow : 1,
              marginVertical: 4,
              padding: 2,
              alignItems: 'center' }}>
                <TouchableOpacity
                  onPress={() => this.onLoginClick()}
                  style={[constStyles.buttonStyle, styles.buttonstyle]}
                >
                  <Text style={[constStyles.buttonTxt, { marginHorizontal: 50 }]} >
                  {t('ClickHereToLogin')}
                  </Text>
                </TouchableOpacity>
            </View>
            </View> 
            }
        </View>
      </Container>
    );
  }
}

PageNotFound.propTypes = {
  navigation: PropTypes.object,
  screenProps: PropTypes.any,
};

export default PageNotFound;
