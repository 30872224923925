import React, { Component } from 'react';
import { View, Dimensions, Platform } from 'react-native';
import AppNavigation from './src/containers/AppNavigator';
import { store, persistor } from './src/store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from './src/utils/localizationUtils';
import * as Font from 'expo-font';
import UserInactivity from 'react-native-user-inactivity';
import ConsentDataProvider, { ConsentDataContext } from './src/providers/ConsentDataProvider';
import Toast from 'react-native-toast-message';
import toasters from './src/constants/ui/toasters';
import * as RootNavigation from './src/containers/RootNavigation.js';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

const toastConfig = {
  error: (internalState) => toasters(internalState, 'error'),
  success: (internalState) => toasters(internalState, 'success'),
  info: (internalState) => toasters(internalState, 'info'),
};

const { width: SCREEN_WIDTH } = Dimensions.get('window');


class AppContent extends Component {
  
  state = {
    fontLoaded: false,
    locale: 'English',
    isSessionActive: true,
    navigateToUsersList: false,
    sessionExpiryDialog: false,
    dialogvisible: true,
    isLoggedin: false,
    navigationState: null,
  };

  static contextType = ConsentDataContext;

  async componentDidMount() {
    await Font.loadAsync({
      Roboto: require('native-base/Fonts/Roboto.ttf'),
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      Combay_Regular: require('./assets/fonts/Cambay-Regular.ttf'),
      Combay_Bold: require('./assets/fonts/Cambay-Bold.ttf'),
      Combay_Italic: require('./assets/fonts/Cambay-Italic.ttf'),
      Yantramanav_Regular: require('./assets/fonts/yantramanav-regular.otf'),
      Yantramanav_Bold: require('./assets/fonts/yantramanav-bold.otf'),
      Inter: require('./assets/fonts/Inter/Inter-Regular.ttf'),
      Inter_bold: require('./assets/fonts/Inter/Inter-Bold.ttf'),
    });

    console.disableYellowBox = true;

    this.setState({
      fontLoaded: true,
      navigateToUsersList: false,
    });
    
  }

  setLocale = (locale) => {
    this.setState({ locale });
  };

  t = (scope, options) =>  {
    const {selectedSubject,userType}= this.props
    if(userType == 3){
    return i18n.t(scope, { locale: this.state.locale, ...options });
    } else if(selectedSubject !=null){
    return i18n.t(scope, { locale: selectedSubject?.locale, ...options });
    }
  };

  

  onAction = (newSessionStatus) => {
    const { isLoggedin, isSessionActive } = this.state;
    if (isSessionActive === null) {
      this.setState({
        isSessionActive: true,
      });
    } else if (
      newSessionStatus == false &&
      isSessionActive === true &&
      isLoggedin == true
    ) {
      this.setState({
        isLoggedin: false,
        isSessionActive: newSessionStatus,
      });
      RootNavigation.navigate('Admin', {
        screen: 'SessionExpiryPage',
        params: { t: this.t },
      });
    }
  };

  validateSession = (isLoggedin) => {
    this.setState({
      isLoggedin,
      isSessionActive: true,
    });
  };

  resetSession = () => {
    this.setState({
      isSessionActive: true,
      navigateToUsersList: false,
    });
  };

  render() {
    const {
      fontLoaded,
      locale,
      navigateToUsersList,
      isSessionActive,
      isLoggedin,
      navigationState,
    } = this.state;
    return (
      <UserInactivity timeForInactivity={3600000} onAction={this.onAction}>
       
            <View
              style={
                SCREEN_WIDTH >= 1030
                  ? {
                      flex: 1,
                      alignItems: 'center',
                      backgroundColor: 'rgba(58, 53, 65, 0.3)',
                      overflow: 'hidden',
                    }
                  : { flex: 1 }
              }>
              <View
                style={
                  Platform.OS == 'web'
                    ? {
                        backgroundColor: '#ffffff',
                        width: SCREEN_WIDTH >= 1030 ? 800 : '100%',
                        height: SCREEN_WIDTH >= 1030 ? '99%' : '99%',
                      }
                    : { backgroundColor: '#ffffff', flex: 1 }
                }>
                {/* --------this fix is due to safari browser ------------ */}
                <View
                  style={{
                    top: -50,
                    zIndex: 5,
                  }}>
                  {/* <Toast ref={(ref) => Toast.setRef(ref)} /> */}
                  <Toast
                    config={toastConfig}
                    ref={(ref) => Toast.setRef(ref)}
                  />
                </View>

                <ConsentDataProvider>
                  {fontLoaded && (
                    <AppNavigation
                      screenProps={{
                        navigateToUsersList,
                        t: this.t,
                        locale: locale,
                        setLocale: this.setLocale,
                        isSessionActive,
                        resetSession: this.resetSession,
                        validateSession: this.validateSession,
                        isLoggedin: isLoggedin,
                        navigationState: navigationState,
                      }}
                    />
                  )}
                </ConsentDataProvider>
              </View>
            </View>
         
      </UserInactivity>
    );
  }
}
AppContent.defaultProps = {
  selectedSubject: {},
  userType: 3,
};

AppContent.propTypes = {
  selectedSubject: PropTypes.object,
  userType: PropTypes.number,
};
const mapStateToProps = (state) => ({
  selectedSubject: state.selectedSubject,
  userType: state.session.userType,
});


const ConnectedApp = connect(mapStateToProps)(AppContent);
export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedApp />
      </PersistGate>
    </Provider>
  );
}
