import React, { Component } from "react";
import { Button, Card } from "native-base";
import {
  Text,
  View,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  Image,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { styles } from "../login/styles/loginScreenStyles";
import TextInputItem from "./TextInputItem";
import { Colors } from "../../constants/ui/colorScheme";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import api from "../../utils/api";
import { generateBasicAuthToken } from "../../utils/util";
import showToast from "../../utils/toast";
import Constants from "expo-constants";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
// import LoadingOverlay from 'react-loading-overlay';
// import { NavigationEvents } from "react-navigation";
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogButton,
} from "react-native-popup-dialog";
import MyLoader from "../../utils/webLoader";
import { Formik } from "formik";
import * as Yup from "yup";
import Constant from "../../utils/constants";
import { passwordRulesArr } from "../common/constants";
import constants from "../../utils/constants";
import _ from "lodash";

class SubjectSignUp extends Component {
  state = {
    isloading: false,
    buttonDisabled: true,
    showSuccessPopup: false,
    loginUser: "Subject",
    username: null,
  };

  componentDidMount() {}

  setInitialValues = () => {
    const { data } = this.props;
    const initialValues = {
      userName: "",
      password: "",
      confirmPassword: "",
    };
    return initialValues;
  };

  submitValues = async (values) => {
    const {
      subjectId,
      navigation,
      storeSelectedSubjectDetailsinSubject,
      storeSelectedSubject,
      studySiteId,
      currentUserType,
      setDocuments,
      subjectRemoteConsentDto,
      storeSubjectRemoteConsentDto,
      retrieveSubDocsData,
      storeClientUserDetails,
    } = this.props;
    let isRemoteConsent = false;
    if (
      typeof navigation.getParam("isRemoteConsent") == "boolean" &&
      navigation.getParam("isRemoteConsent") == true
    ) {
      isRemoteConsent = true;
    }
    if (
      typeof navigation.getParam("isRemoteConsent") == "string" &&
      navigation.getParam("isRemoteConsent") == "true"
    ) {
      isRemoteConsent = true;
    }

    const subjectDetails = {
      id: subjectId,
      userName: values.userName,
      loginPassword: values.password,
      studySite: {
        id: studySiteId,
      }
    };

    const details = {
      subject: subjectDetails,
      userType: currentUserType,
    };

    try {
      this.setState({
        isloading: true,
      });
      const res = await api.post(`/${subjectRemoteConsentDto.primaryOrganizationCode}/studies/${subjectRemoteConsentDto.studyId}/sites/${subjectRemoteConsentDto.siteId}/subjects/signup`, details);
      api.defaults.headers.common.Authorization = res.headers["authorization"];
      if (Platform.OS == "web") {
        const authToken = res.headers["authorization"];
        window.sessionStorage.setItem("token", authToken);
      }
      const loSubject = res.data.subject;
      const loClientUserDetails = res.data.clientUserDetails;

      this.setState({
        isloading: false,
      });
      const selSubject = {
        firstName: loSubject.firstName,
        lastName: loSubject.lastName,
        fullName: loSubject.fullName,
        middleName: loSubject.middleName,
        id: loSubject.id,
        isRegistered: true,
        subjectConsentId: loSubject.subjectConsent.id,
        consentVersionId: loSubject.currentConsentVersionId,
        status: loSubject.status,
        isReconsent: loSubject.subjectConsent.reconsent,
        lastConsentedIrbVersion: loSubject.lastConsentedIrbVersion,
        consentedOn: loSubject.consentedOn,
        navTour: [],
        language: loSubject.locale,
        languageId: loSubject.languageId,
        subjectConsent: loSubject.subjectConsent,
        isRemoteConsent: isRemoteConsent,
        larAsWitness: loSubject.larAsWitness,
        larFirstName: loSubject.larFirstName,
        larLastName: loSubject.larLastName,
        witnessFirstName: loSubject.witnessFirstName,
        witnessLastName: loSubject.witnessLastName,
        larRelation: loSubject.larRelation,
        isKnowledgeReviewDone: loSubject.subjectConsent.isKrDone,
        isRemoteConsent: isRemoteConsent,
      };

      const clientUserDetials = {
        email: loClientUserDetails.email,
        phoneNo: loClientUserDetails.phoneNo,
      };

      //code for seperate messages for Subject,Lar and Witness users
      if (currentUserType == 1) {
        this.setState({ loginUser: "Subject" });
      } else if (currentUserType == 2) {
        this.setState({ loginUser: "LAR" });
      } else if (currentUserType == 4) {
        this.setState({ loginUser: "Witness" });
      }
      const losubjectRemoteConsentDto = { ...subjectRemoteConsentDto };
      losubjectRemoteConsentDto.userName = values.userName;
      storeSubjectRemoteConsentDto(losubjectRemoteConsentDto,subjectRemoteConsentDto.otp);
      storeSelectedSubjectDetailsinSubject(selSubject);
      storeClientUserDetails(clientUserDetials);
      storeSelectedSubject({
        ...loSubject,
        isKnowledgeReviewDone: loSubject.subjectConsent.isKrDone,
      });
      this.setState({ showSuccessPopup: true });
      let res1 = null;
      if (
        selSubject.larAsWitness &&
        (currentUserType == 2 || currentUserType == 4)
      ) {
        res1 = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${loSubject.id}/subjectConsents/${loSubject.subjectConsent.id}/documents/all`);
      } else {
        res1 = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${loSubject.id}/subjectConsents/${loSubject.subjectConsent.id}/documents/remote?userType=${currentUserType}`);
      }

      setDocuments(res1.data, res.data.larAsWitness);

      // to retrive form Data for the subject/lar
      retrieveSubDocsData(
        loSubject.id,
        loSubject.languageId,
        loSubject.subjectConsent.id,
        loSubject.currentConsentVersionId
      );
      // showToast("Signed in successfully.", "success", 2000);
      // if (isRemoteConsent) {
      //     navigation.navigate('Consent', { screen: 'MetaDataLoading' })
      // } else {
      //     navigation.navigate('Consent', { screen: 'ConsentOverviewScreen' })
      // }
    } catch (err) {
      this.setState({
        isloading: false,
      });
      console.log(err);
      if (err.response) {
        const result = err.response.status;
        if (result === 302) {
          showToast(
            "The username you selected is already in use. Please select a different username and try again.",
            "danger",
            4000
          );
        } else {
          showToast("Failed to set password.", "danger", 2000);
        }
      } else {
        showToast("Network Error.", "danger", 2000);
      }
    }
  };

  onOk = () => {
    const { navigation } = this.props;
    this.setState({ showSuccessPopup: false });
    let isRemoteConsent = false;

    if (
      typeof navigation.getParam("isRemoteConsent") == "boolean" &&
      navigation.getParam("isRemoteConsent") == true
    ) {
      isRemoteConsent = true;
    }
    if (
      typeof navigation.getParam("isRemoteConsent") == "string" &&
      navigation.getParam("isRemoteConsent") == "true"
    ) {
      isRemoteConsent = true;
    }

    setTimeout(() => {
      if (isRemoteConsent) {
        navigation.navigate("Consent", { screen: "MetaDataLoading" });
      } else {
        navigation.navigate("Consent", { screen: "ConsentOverviewScreen" });
      }
    }, 100);
  };

  checkIfFieldsAreEmpty = (props, e) => {
    // props.setFieldValues('confirmPassword',props.values.confirmPassword);
    // handleChange('confirmPassword');
    if (props.values.userName == "" || props.values.password == "" || e == "") {
      this.setState({
        buttonDisabled: true,
      });

      return true;
    } else {
      this.setState({
        buttonDisabled: false,
        username: props.values.userName,
      });
      return false;
    }
  };

  render() {
    const { navigation, data, sponsorName } = this.props;
    const { isloading, showSuccessPopup, username } = this.state;
    const email = data.email;
    const name = data.subjectName;

    return (
      <ScrollView>
        {/* <NavigationEvents
                    onDidFocus={() => {
                        validateSession(false);
                    }}
                /> */}
        <MyLoader active={isloading} />
        <KeyboardAvoidingView behavior="padding" enabled>
          {/* --------------------- Logo --------------------------- */}
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {/* <Image
                            source={require("../../../assets/bio.png")}
                            style = {{ height: hp("7%"), width: wp("25%") }}
                            // resizeMode="center"
                            resizeMode='contain'
                        /> */}
            <Text
              style={{
                alignSelf: "center",
                justifyContent: "center",
                fontFamily: "Inter",
              }}
            >
              Informed Consent Account Setup
            </Text>
          </View>

          {/* --------------------- Card ---------------------------- */}
          <View style={{ justifyContent: "center", marginTop: 10 }}>
            <Card
              style={[
                styles.container,
                Platform.OS !== "web" && { marginTop: 30 },
              ]}
            >
              <Formik
                initialValues={this.setInitialValues()}
                validationSchema={Yup.object({
                  userName: Yup.string().required("Please enter Username."),
                  password: Yup.string()
                    .label("password")
                    .required("Please enter Password.")
                    .min(8, "Minimum 8 characters.")
                    .max(64, "Maximum 64 characters.")
                    .test(
                      data.siteName,
                      "Password did not match criteria.",
                      function (value) {
                        return (
                          value &&
                          !_.includes(
                            value.toLowerCase(),
                            data.siteName.toLowerCase()
                          )
                        );
                      }
                    )
                    .test(
                      username,
                      "Password did not match criteria.",
                      function (value) {
                        return (
                          value &&
                          username &&
                          !_.includes(
                            value.toLowerCase(),
                            username.toLowerCase()
                          )
                        );
                      }
                    )
                    .test(
                      name,
                      "Password did not match criteria.",
                      function (value) {
                        const nameWithoutSpaces = data?.subjectName?.match(/[^ ]+/g) || [];
                        const matchedName = _.find(nameWithoutSpaces, nameStr => _.includes(_.lowerCase(value), _.lowerCase(nameStr)))
                        return value && !matchedName;
                      }
                    )
                    
                    .test(
                      sponsorName,
                      "Password did not match criteria.",
                      function (value) {
                        return (
                          value &&
                          !_.includes(
                            value.toLowerCase(),
                            sponsorName.toLowerCase()
                          )
                        );
                      }
                    )
                    .test(
                      email,
                      "Password did not match criteria.",
                      function (value) {
                        return (
                          value &&
                          !_.includes(
                            value.toLowerCase(),
                            email.split("@")[0].toLowerCase()
                          )
                        );
                      }
                    )
                    .test(
                      email,
                      "Password did not match criteria.",
                      function (value) {
                        return (
                          value &&
                          !_.includes(value.toLowerCase(), email.toLowerCase())
                        );
                      }
                    )
                    .matches(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1{4})(?!.*(abcde|bcdef|cdefg|defgh|efghi|fghij|ghijk|hijkl|ijklm|jklmn|klmno|lmnop|mnopq|nopqr|opqrs|pqrst|qrstu|rstuv|stuvw|tuvwx|uvwxy|vwxyz|01234|12345|23456|34567|45678|56789|43210|54321|65432|76543|87654|98765)+)(?=.*[`~!@#$%^&*()_\-+={}[\];:"',.\/<>?\\|])[A-Za-z\d\S]{8,64}$/,
                      "Password did not match criteria."
                    ),
                  confirmPassword: Yup.string()
                    .required("Please re-enter Password.")
                    .label("Confirm password")
                    .test(
                      "passwords-match",
                      "Two passwords that you enter are inconsistent.",
                      function (value) {
                        return value == this.parent.password;
                      }
                    ),
                })}
                onSubmit={(values, formikActions) => {
                  this.submitValues(values);
                  setTimeout(() => {
                    formikActions.setSubmitting(false);
                  }, 500);
                }}
              >
                {(props) => (
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    {/* ------------------ Label ---------------------- */}
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        marginTop: Platform.OS !== "web" ? 0 : -25,
                        paddingVertical: Platform.OS !== "web" ? 10 : 0,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: fontType("Heading"),
                          fontFamily: "Inter",
                          fontWeight: "400",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {data.siteName}
                      </Text>
                      <Text
                        style={{
                          fontSize: fontType("Heading1"),
                          fontFamily: "Inter",
                          fontWeight: "400",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {data.subjectName}
                      </Text>
                      <Text
                        style={{
                          fontSize: fontType("subFont"),
                          fontFamily: "Inter",
                          fontWeight: "400",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {data.email}
                      </Text>
                    </View>

                    {/* ------------------ Username ---------------------- */}
                    <View
                      style={{
                        justifyContent: "center",
                        alignSelf: "center",
                        marginVertical: 10,
                      }}
                    >
                      <TextInputItem
                        value={props.values.userName}
                        placeholderValue={"Create Username"}
                        onChange={(e) => {
                          props.handleChange("userName")(e);
                          this.checkIfFieldsAreEmpty(props, e);
                        }}
                        maxLength={50}
                      />
                      {props.errors.userName && props.touched.userName && (
                        <Text
                          style={{
                            color: "red",
                            fontSize: fontType("subFont"),
                          }}
                        >
                          {props.errors.userName}
                        </Text>
                      )}
                    </View>

                    {/* ------------------ Password ---------------------- */}
                    <View
                      style={{
                        justifyContent: "center",
                        alignSelf: "center",
                        marginVertical: 10,
                      }}
                    >
                      <TextInputItem
                        value={props.values.password}
                        placeholderValue={"Create Password"}
                        secureTextEntry={true}
                        onChange={(e) => {
                          this.checkIfFieldsAreEmpty(props, e);
                          props.handleChange("password")(e);
                        }}
                        maxLength={100}
                      />
                      {props.errors.password && props.touched.password && (
                        <Text
                          style={{
                            color: "red",
                            fontSize: fontType("subFont"),
                            width: 300,
                          }}
                        >
                          {props.errors.password}
                        </Text>
                      )}
                    </View>

                    {/* ------------------confirm Password ---------------------- */}
                    <View
                      style={{
                        justifyContent: "center",
                        alignSelf: "center",
                        marginVertical: 10,
                      }}
                    >
                      <TextInputItem
                        value={props.values.confirmPassword}
                        placeholderValue={"Confirm Password"}
                        secureTextEntry={true}
                        onChange={(e) => {
                          this.checkIfFieldsAreEmpty(props, e);
                          props.handleChange("confirmPassword")(e);
                        }}
                        maxLength={100}
                      />
                      {props.errors.confirmPassword &&
                        props.touched.confirmPassword && (
                          <Text
                            style={{
                              color: "red",
                              fontSize: fontType("subFont"),
                            }}
                          >
                            {props.errors.confirmPassword}
                          </Text>
                        )}
                    </View>

                    {/* ------------- Password requirements --------------------- */}
                    <View style={{ justifyContent: "center", marginTop: 10 }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          paddingBottom: 5,
                          fontSize: 12,
                          fontFamily: "Inter",
                        }}
                      >
                        Password Rules :
                      </Text>
                      <View style={{ marginLeft: 20 }}>
                        {passwordRulesArr.map((data) => (
                          <Text style={styles.passwordRules}>
                            &#8226; {data.label}
                          </Text>
                        ))}
                      </View>
                    </View>

                    {/* ------------- Login button --------------------- */}
                    <View
                      style={{
                        marginVertical: Platform.OS == "web" ? 5 : 40,
                        marginTop: 10,
                      }}
                    >
                      <Button
                        // disabled={this.state.buttonDisabled}
                        success
                        style={{
                          justifyContent: "center",
                          backgroundColor: this.state.buttonDisabled
                            ? "grey"
                            : Colors.buttonwithbg,
                        }}
                        onPress={props.handleSubmit}
                      >
                        <Text style={{ textAlign: "center", color: "white" }}>
                          SUBMIT
                        </Text>
                      </Button>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Text
                        style={{
                          marginTop: 15,
                          fontSize: 12,
                          color: Colors.iconColor,
                        }}
                      >
                        App Version : {Constant.APP_VERSION}
                      </Text>
                    </View>
                  </View>
                )}
              </Formik>
            </Card>
            <Dialog
              containerStyle={{ justifyContent: "center" }}
              rounded={true}
              visible={showSuccessPopup}
            >
              <DialogContent>
                <View
                  style={{
                    flexDirection: "column",
                    alignSelf: "center",
                    alignItems: "center",
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: fontType("Heading1"),
                        fontFamily: "Inter",
                        color: Colors.iconColor,
                      }}
                    >
                      {this.state.loginUser} credentials created successfully.
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      style={[
                        constStyles.buttonStyle,
                        {
                          backgroundColor: Colors.fieldSubmit,
                          marginHorizontal: 15,
                        },
                      ]}
                      onPress={this.onOk}
                    >
                      <Text style={[constStyles.buttonTxt]}> OK </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </DialogContent>
            </Dialog>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    );
  }
}

export default SubjectSignUp;
