import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import tryalLogoWithName from "../../../assets/base64/tryalLogoWithName";
import { constStyles } from "../../styles/constStyles";
import { Colors } from "../../constants/ui/colorScheme";
import Constants from "../../utils/constants";
import api from "../../utils/api";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {updateSessionUserType} from '../../actions/session'

const UnauthorizedScreen = (props) => {
  const {
    screenProps: { t },updateSessionUserType
  } = props;
  const handleLogin = async() => {
    await updateSessionUserType(3)
    const loginRequestId = window.sessionStorage.getItem("loginRequestId");
    delete api.defaults.headers.common.Authorization;
    window.location.assign(
      `${Constants.ADMIN_PORTAL_BASE_URL}/saml/logout/${loginRequestId}?redirectUrl=${Constants.ADMIN_PORTAL_BASE_URL}/api/webapp/logout`
    );
  };

  return (
    <View style={styles.container}>
      <Image
        source={{
          uri: `data:image/png;base64,${tryalLogoWithName}`,
        }}
        resizeMode="contain"
        style={styles.logo}
      />
      <Text style={styles.message}>{t("UnAuthorizedMessage")}</Text>
      <TouchableOpacity style={styles.addSubjectView} onPress={handleLogin}>
        <Text style={[constStyles.buttonTxt, { color: Colors.header }]}>
          LOGIN
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.whiteSmoke,
  },
  logo: {
    width: 195,
    height: 150,
    marginBottom: 100,
  },
  message: {
    fontSize: 18,
    marginBottom: 100,
  },
  addSubjectView: {
    backgroundColor: Colors.buttonwithbg,
    borderRadius: 5,
    padding: 4,
    margin: 4,
    alignContent: "center",
  },
});

UnauthorizedScreen.defaultProps = {
  screenProps: {},
};

UnauthorizedScreen.propTypes = {
  screenProps: PropTypes.object,
  updateSessionUserType: PropTypes.func.isRequired
};


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSessionUserType: () => dispatch(updateSessionUserType()),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UnauthorizedScreen);
