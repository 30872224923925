import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api';
import constants from '../utils/constants';

export const findVideoUrlById = (studyId, consentId, versionId, id, vedioUrl) => async dispatch => {
    const multiMediaContent = {
        url:vedioUrl
    }
    try {
        const response = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${studyId}/sites/${constants.ContextProperties.SITE_ID}/consents/${constants.ContextProperties.CONSENT_ID}/consentVersions/${constants.ContextProperties.CONSENT_VERSION_ID}/studyvideos`, multiMediaContent);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}