import React, { Component } from 'react';
import { View, Text, StyleSheet, ScrollView, Platform } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubjectDetails from '../components/subjectList/subjectDetails';
import { getConsentChapter } from '../utils/consentData';
import { getAskForLAR, getLanguageById, getConsentVersionId } from "../utils/consentData";
import { setSelectedLanguage } from "../actions/language";
import { NavigationEvents } from "react-navigation";
import { storeRegisteredSubjectData } from '../actions/subjectRegistrationForm';
import {updateSelectedSubjectStatus} from '../actions/subject';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { retrieveSubjectConsentDocuments } from '../actions/subjectConsentDocument';
import {  retrieveComments } from '../actions/comments';
import {getSignatureType} from '../actions/printNSign'
import { getSignatureTypeOfSubject } from '../selectors/printNSign';
class SubjectDetailsScreen extends Component {
   static contextType = ConsentDataContext;

   componentDidMount() {
         const { navigation, selectLanguage, subjectData, 
            retrieveSubjectConsentDocuments: loRetrieveSubjectConsentDocuments, selectedSubject } = this.props;
         const language = {
            id: subjectData.languageId,
            language: subjectData.locale
          };
         this._unsubscribe = navigation.addListener('focus', () => {
            selectLanguage(language);
         });
         loRetrieveSubjectConsentDocuments(subjectData.id, selectedSubject);
   }

   componentWillUnmount() {
         this._unsubscribe();
   }

   render() {
      const {navigation, selectedSubject, subjectData, getConsentVersionId,
             selectLanguage, storeRegisteredSubjectData, updateSelectedSubjectStatus, screenProps: { isSessionActive , t}, retrieveComments, studyId, siteId, getSignatureType, printNSignType} = this.props;
      // const lan = getLanguageById(subjectData.languageId);
      const lan = subjectData.locale;
      const askForLAR = getAskForLAR(this.context.languages,lan);
      // const language = {
      //    id: subjectData.languageId,
      //    language: lan
      //  };
       if(Platform.OS === 'web') {
          return (
            <View style={{flex:1}}>
            {/* <NavigationEvents
               onWillFocus={() => {
                  selectLanguage(language);
               }}
            /> */}
            <SubjectDetails
               askForLAR = {false}
               selectedSubject = {selectedSubject}
               storeRegisteredSubjectData = {storeRegisteredSubjectData}
               navigation = {navigation}
               updateSelectedSubjectStatus = {updateSelectedSubjectStatus}
               getConsentVersionId = {getConsentVersionId}
               isSessionActive={isSessionActive}
               studyId={studyId}
               siteId={siteId}
               t={t}
               getSignatureType={getSignatureType}
               printNSignType={printNSignType}
               retrieveComments = { retrieveComments }
            />
         </View>
          )
       }
      return (
         <View style={{flex:1}}>
            {/* <NavigationEvents
               onWillFocus={() => {
                  selectLanguage(language);
               }}
            /> */}
            <SubjectDetails
               askForLAR = {false}
               selectedSubject = {selectedSubject}
               storeRegisteredSubjectData = {storeRegisteredSubjectData}
               navigation = {navigation}
               updateSelectedSubjectStatus = {updateSelectedSubjectStatus}
               getConsentVersionId = {getConsentVersionId}
               isSessionActive={isSessionActive}
               t={t}
               getSignatureType={getSignatureType}
               printNSignType={printNSignType}
               retrieveComments = { retrieveComments }
            />
            </View>
      );
   }
}

const mapStateToProps = state => ({
   selectedSubject: state.selectedSubject,
   subjectData: state.selectedSubject,
   studyId: state.studySite.studyId,
   siteId: state.studySite.studySiteId,
   printNSignType: getSignatureTypeOfSubject(state)
});

const mapDispatchToProps = dispatch =>
   bindActionCreators(
      {
         selectLanguage: setSelectedLanguage,
         storeRegisteredSubjectData,
         updateSelectedSubjectStatus,
         getConsentVersionId,
         retrieveSubjectConsentDocuments,
         getSignatureType,
         retrieveComments,
      },
      dispatch
   );

export default connect(mapStateToProps, mapDispatchToProps)(SubjectDetailsScreen);