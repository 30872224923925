import {StyleSheet,Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font'

export const styles = StyleSheet.create(
    {
      listItemStyle: {
        flex:1,
        flexDirection:"column",
        alignItems:"flex-start",
      },
      language: {
        fontSize: fontType('BasicFont'),
        fontFamily:'Inter'
      },
      searchbarContainer: {
        margin:4,
        backgroundColor: Colors.searchBg,
      },
      list: {
        paddingLeft: wp('2%'),
      },
      holderStyle: {
        paddingLeft: wp('6%')
      },
      title: {
        padding:9,
        backgroundColor: Colors.background,
      }
    }
);
