import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
} from "react-native";
import {Container, Content} from "native-base";
import _ from "lodash";
import CaptureSubjectNumberScreen from "../../containers/captureSubjectNumberPage";
import api from "../../utils/api";
import {
  buildCounterDocumentSignatures,
} from "../../utils/signatureUtils";
import * as WebBrowser from "expo-web-browser";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import PrintAndSignDocPopup from "../consentSignature/PrintAndSignDocPopup";
import { DocumentRightIcons, DocumentStatus, SubjectStatus } from "../common/constants";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants;
import SubjectDocumentDetails from "./SubjectDocumentDetails/DocumentDetails.js";
import SubmitButton from "./SubjectDocumentDetails/SubmitButton.js";

export default class SubjectDocumentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfLoading: false,
      pdf: null,
      printNSign: {
        modalVisible: false,
        messageType: "",
        selectedDocId: null,
      },
    };
  }

  onDocumentSelected = async (
    documentId,
    documentStatus = DocumentStatus.FULLY_SIGNED
  ) => {
    const { selectedSubject, navigation, documents } = this.props;
    //Getting document pdf based on document's subject consent Id
    const loDocument = [...documents.documents[documentId]];
    const loSubjectConsentId = !_.isEmpty(loDocument)
      ? loDocument[0].subjectConsentId
      : selectedSubject?.subjectConsent?.id;
    if (
      (selectedSubject.status === "CONSENTED" || selectedSubject.status === "DISCONTINUED") &&
      documentId != null && 
      documentStatus == DocumentStatus.FULLY_SIGNED
    ) {
      try {
        this.setState({
          pdfLoading: true,
        });
        const result = await api.get(
          `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/subjectConsents/${loSubjectConsentId}/documents/${documentId}/pdfDetails`
        );
        const pdf = await WebBrowser.openBrowserAsync(
          result.data.signedPdfPath
        );
        this.setState({
          pdf: pdf,
          pdfLoading: false,
        });
      } catch (error) {
        // waring toaster
        console.log(error);
        this.setState({
          pdf: null,
          pdfLoading: false,
        });
      }
    } else {
      navigation.navigate("CounterSignDoc", { documentId: documentId });
    }
  };

  checkIfAllSignaturesAreDone = () => {
    const { printNSignType, documents } = this.props;
    if (_.isEmpty(documents?.documents)) {
      return true;
    } else if (!_.isEmpty(documents)) {
      if (!_.has(documents, "documents")) {
        return true;
      } else {
        let docsList = [];
        const loDocs = { ...documents.documents };
        Object.values(loDocs).forEach((docs) => {
          docsList = [...docsList, ...buildCounterDocumentSignatures(docs)];
        });
        if (printNSignType == 2) {
          const docsNotVerified = _.filter(
            docsList,
            (doc) => !doc.isSignatureCompleted
          );
          return docsNotVerified.length > 0 ? true : false;
        }
        const docsNotSigned = docsList.filter(
          (doc) => doc.signatureUrl === null && !doc.isDeleted
        );
        return docsNotSigned.length > 0 ? true : false;
      }
    }
  };

  checkIfSignaturesOfADocisDone = (documentId) => {
    const { documents, selectedSubject } = this.props;
    if (!_.isEmpty(documents)) {
      if (_.has(documents.documents, documentId)) {
        let documentsList = [...documents.documents[documentId]];
        if(selectedSubject.status == SubjectStatus.DISCONTINUED){
          return this.checkDiscontinuedDocSigns(documentsList);
        }
        let docsSigned = documentsList.filter(
          (doc) =>
            doc.signatureUrl === null &&
            (!doc.isDeleted || (doc.signatureType == 4 && doc.isOptional))
        );
        let docsSignedWithoutOptional = documentsList.filter(
          (doc) => doc.signatureUrl === null && !doc.isDeleted
        );

        var isOptionalSignaturesPresent = false;
        documentsList.map((documents) => {
          if (documents.signatureType == 4 && documents.isOptional) {
            if (documents.signatureUrl == null) {
              isOptionalSignaturesPresent = true;
            }
          }
        });
        if (
          isOptionalSignaturesPresent &&
          docsSignedWithoutOptional.length == 0
        ) {
          return { name: "checkcircleo" };
        } else if (
          docsSigned.length == 0 &&
          isOptionalSignaturesPresent == false
        ) {
          return { name: "checkcircle" };
        } else {
          const docRequiredSigns = docsSigned.filter(
            (doc) => doc.signatureUrl === null
          );
          if (docRequiredSigns.length > 0) {
            return { name: "arrow-forward" };
          }
          return { name: "checkcircle" };
        }
      }
      return { name: "checkcircle" };
    }
 }

 checkDiscontinuedDocSigns = (documentList) => {
  if(documentList[0].consentStatus == SubjectStatus.CONSENTED){
    return { name: "checkcircle" };
  }else{
    return { name: "arrow-forward" };
  }  
 }

  showPrintAndSignDocuments = (val, type = "", documentId = null) => {
    const { printNSign } = this.state;
    this.setState({
      printNSign: {
        ...printNSign,
        modalVisible: val,
        messageType: type,
        selectedDocId: documentId,
      },
    });
  };

  handlePrintNSign = async () => {
    const { generatePdfToSign } = this.props;
    const { printNSign } = this.state;
    try {
      this.setState({ pdfLoading: true });
      const pdfPath = await generatePdfToSign(printNSign.selectedDocId);
      console.log(pdfPath);
      if (pdfPath) {
        console.log(pdfPath);
        const pdf = await WebBrowser.openBrowserAsync(pdfPath);
        this.setState({ pdf: pdf, pdfLoading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ pdf: null, pdfLoading: false });
    }
    this.showPrintAndSignDocuments(
      false,
      "PrintNSign",
      printNSign.selectedDocId
    );
  };

  verifyPrintNSign = () => {
    const { printNSign } = this.state;
    const { updateSignatureStatus } = this.props;
    updateSignatureStatus(
      printNSign.selectedDocId,
      _.isEqual(
        printNSign.messageType,
        DocumentRightIcons.VERIFY_PRINT_AND_SIGN
      )
        ? true
        : false
    );
    this.showPrintAndSignDocuments(
      false,
      "VerifyPrintNSign",
      printNSign.selectedDocId
    );
  };

  render() {
    const {
      submitConsent,
      subjectIdModal,
      handleSubjectIdModal,
      selectedSubject,
      isSessionActive,
      t,
      commentsResolved,
      showPrintIcon,
    } = this.props;
    const { pdf, printNSign } = this.state;

    return (
      <Container>
      <Content>
        <Text style={{ position: "absolute", zIndex: -2 }}> {pdf && JSON.stringify(pdf)}</Text>
        {/* ----------------------Subject Documents List ----------------------- */}
        <SubjectDocumentDetails commentsResolved={commentsResolved} showPrintAndSignDocuments={this.showPrintAndSignDocuments} showPrintIcon={showPrintIcon} checkIfSignaturesOfADocisDone={this.checkIfSignaturesOfADocisDone} onDocumentSelected={this.onDocumentSelected}/>
        {/* --------------------Capturing SubjectId after Submission ------------- */}
        <CaptureSubjectNumberScreen
          subjectIdModal={subjectIdModal}
          handleSubjectIdModal={handleSubjectIdModal}
          selectedSubject={selectedSubject}
          isSessionActive={isSessionActive}
        />
      </Content>
      {/* ---------------------Submit Button -------------------------------- */}
      <SubmitButton checkIfAllSignaturesAreDone={this.checkIfAllSignaturesAreDone} submitConsent={submitConsent}/>
      <View>
        <View>
      {/* ---------------------Print And Sign Flow -------------------------- */}
          <PrintAndSignDocPopup
            visible={printNSign.modalVisible}
            handleClose={() => this.showPrintAndSignDocuments(false)}
            handlePrintAndSign={
              printNSign.messageType == "PrintNSign"
                ? () => this.handlePrintNSign()
                : () => this.verifyPrintNSign()
            }
            t={t}
            messageType={printNSign.messageType}
          />
        </View>
        <View></View>
      </View>
    </Container>

    );
  }
}

var styles = StyleSheet.create({
  buttonSbmt: {
    backgroundColor: Colors.button,
  },
  buttonTxt: {
    color: Colors.textColorwithbg,
    textAlign: "center",
    fontWeight: "200",
    fontSize: fontType("ButtonTxt"),
    fontFamily: "Inter_Bold",
  },
  Footer: {
    backgroundColor: Colors.footer,
    alignItems: "center",
    margin: 10,
  },
  documentTxt: {
    fontSize: fontType("BasicFont"),
    color: Colors.text,
    fontFamily: "Inter",
  },
  title: {
    padding: 10,
    backgroundColor: Colors.background,
  },
  legendText: {
    fontSize: fontType("subFont"),
    fontFamily: "Inter",
    paddingLeft: 15,
    color: Colors.reglabels,
  },
  printReviewIconContainerStyle: {
    display: "flex",
    height: 20,
    width: 15,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.buttonwithbg,
  },
});
