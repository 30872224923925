import React, { Component } from "react";
import { View, Text, Platform,  TouchableOpacity,Dimensions} from "react-native";
import { Colors } from "../constants/ui/colorScheme";
import Dialog, { DialogContent, DialogFooter, DialogButton, DialogTitle } from 'react-native-popup-dialog';
import {constStyles} from '../styles/constStyles';
import {fontType} from '../styles/font';
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')
class NoEmailPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNoMail:false
    };
  }

  clickOnOkay = () => {
    const { navigation, toggleIsNoMailPopUp} = this.props;
    toggleIsNoMailPopUp();
    setTimeout(() => {
      navigation.navigate('SubjectEditScreen', {fromDocSelection: true})     
    }, 500);    
  }

  onClose = () => {
    const { toggleIsNoMailPopUp} = this.props;
    toggleIsNoMailPopUp();
  }


  dynamicWidth= () => {
    if (SCREEN_WIDTH >= 1024) {
        return SCREEN_WIDTH/2;
    } else if (SCREEN_WIDTH >= 750) {
        return SCREEN_WIDTH/1.5
    } if (SCREEN_WIDTH > 350) {
        return SCREEN_WIDTH/1.5
    } else {
        return SCREEN_WIDTH/1.5;
    }
}

  render() {
    const viewStyles = Platform.OS === 'web' ? {  backgroundColor: Colors.background, flex:1 } : {flex:1,  backgroundColor: Colors.background};
    const { isNoMail, toggleIsNoMailPopUp} = this.props;
    const popupWidth = this.dynamicWidth();
    return (
        <Dialog
          containerStyle={{ justifyContent: 'center' }}
          rounded={true}
          visible={isNoMail}
        >
          <DialogContent style={{width: popupWidth}}>
            <View>
              {/* -------------- cancel button ----------------- */}
              <View style = {{alignSelf:'flex-end', marginTop:5, marginRight:-12 }}>
              <TouchableOpacity
                onPress={() => this.onClose()}
              >
                <Text >
                  <MaterialIcons
                    name="cancel"
                    color={Colors.discontinued}
                    size={fontType("StatusIcon")}
                  />
                </Text>
              </TouchableOpacity>
              </View>               
              <View style={{ flexDirection: 'column', alignSelf: 'center', alignItems: 'center', padding: 10 }}>
                <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}>
                  <Text style={{ fontSize: fontType('Heading1'), fontFamily: 'Inter', color: Colors.iconColor, }}>The Subject/LAR/Witness does not have sufficient information.</Text>
                  <Text style={{ fontSize: fontType('Heading1'), fontFamily: 'Inter', color: Colors.iconColor, }}> Please provide necessary information through edit profile to continue.</Text>
                </View>
                <View style={{ flexDirection: 'row', padding:5 }}>
                  <TouchableOpacity
                    style={[constStyles.buttonStyle, { marginHorizontal: 15, flexDirection: 'row' }]}
                    onPress={() => this.clickOnOkay()}
                  >
                    <MaterialCommunityIcons name='account-edit' color={Colors.whiteBg} size={fontType('StatusIcon')} />
                    {/* <Text style={[constStyles.buttonTxt]}>Edit </Text> */}
                  </TouchableOpacity>
                </View>
              </View>
            </View>

          </DialogContent>
        </Dialog>
    );
  }
}


export default NoEmailPopUp;
