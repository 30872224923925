import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {StyleSheet, Text, TextInput, ScrollView, FlatList, Dimensions} from 'react-native';
import AnimatedHideView from 'react-native-animated-hide-view';
import { createForm } from "rc-form";
import { View } from "react-native";
import { decode as atob } from 'base-64'
import { Content} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import HtmlParser from '../htmlView/HtmlParser'
import HtmlParserEg from '../htmlView/htmlparserEg';
import { Colors } from '../../constants/ui/colorScheme';
import { NavigationEvents } from 'react-navigation';
import _ from 'lodash';
import { createFormFieldsWithValues, buildFormDataList } from './formFieldUtils';
import RadioFieldDecorator from './RadioFieldDecorator';
import SubjectInitialInputDecorator from './SubjectInitialInputDecorator';
import { storeDocumentFormDataList } from '../../actions/documents';
import showToast from '../../utils/toast';
import RadioInitialsFieldDecorator from './RadioInitialsFieldDecorator';

let { height } = Dimensions.get('window');
height = height - hp('30%');

const htmlData = 'PHA+PGJyIC8+SU5GT1JNRUQgQ09OU0VOVCBGT1JNPGJyIC8+IDxiciAvPlNwb25zb3IgLyBTdHVkeSBUaXRsZTo8YnIgLz4gTUFOQSBSQk0gLyBFU0NBTEFURSwgQSBQaGFzZSBJSUkgUmFuZG9taXplZCBTdHVkeSBDb21wYXJpbmcgWHRhbmRpIG9yIE51YmVxYSB3aXRoIFJhZGl1bS0yMjMgdnMgWHRhbmRpIG9yIE51YmVxYSB3aXRoIFBsYWNlYm8gYW5kIHRoZSBFZmZlY3QgdXBvbiBTeW1wdG9tYXRpYyBTa2VsZXRhbCBFdmVudC1GcmVlIFN1cnZpdmFsIGZvciBtQ1JQQyBQYXRpZW50czwvcD4KPHA+UHJvdG9jb2wgTnVtYmVyOjxiciAvPiBQQzE4LTEwMDU8L3A+CjxwPlByaW5jaXBhbCBJbnZlc3RpZ2F0b3I6PGJyIC8+KFN0dWR5IERvY3Rvcik8YnIgLz4gJmxhcXVvO1BpRnVsbE5hbWUmcmFxdW87PC9wPgo8cD5UZWxlcGhvbmU6PGJyIC8+ICZsYXF1bztJY2ZQaG9uZU51bWJlciZyYXF1bzs8L3A+CjxwPkFkZGl0aW9uYWwgQ29udGFjdChzKTo8YnIgLz4oU3R1ZHkgU3RhZmYpPGJyIC8+ICZsYXF1bztBZGRpdGlvbmFsU3RhZmZNZW1iZXJDb250YWN0cyZyYXF1bzs8L3A+CjxwPkFkZHJlc3M6ICZsYXF1bztQaUxvY2F0aW9ucyZyYXF1bzs8L3A+CjxwPktFWSBJTkZPUk1BVElPTiA8YnIgLz5Zb3UgYXJlIGludml0ZWQgdG8gdGFrZSBwYXJ0IGluIGEgcmVzZWFyY2ggc3R1ZHkuIFRoaXMgcmVzZWFyY2ggc3R1ZHkgaXMgc3R1ZHlpbmcgWHRhbmRpIChlbnphbHV0YW1pZGUpIG9yIE51YmVxYSAoZGFyb2x1dGFtaWRlKSBmb2xsb3dlZCBieSBYb2ZpZ28gKHJhZGl1bS0yMjMpIG9yIFBsYWNlYm8gKHNhbHR3YXRlcikgYXMgYSBwb3NzaWJsZSB0cmVhdG1lbnQgZm9yIG1ldGFzdGF0aWMgY2FzdHJhdGlvbi1yZXNpc3RhbnQgcHJvc3RhdGUgY2FuY2VyIChtQ1JQQykuIE1BTkEgUkJNIGlzIHNwb25zb3JpbmcgdGhpcyByZXNlYXJjaCBzdHVkeS4gVGhpcyBJbmZvcm1lZCBDb25zZW50IHByb3ZpZGVzIGEgc3VtbWFyeSBvZiB0aGlzIHJlc2VhcmNoLiBJdCBkZXNjcmliZXMgdGhlIGltcG9ydGFudCBpbmZvcm1hdGlvbiB0aGF0IHdlIGJlbGlldmUgbW9zdCBwZW9wbGUgbmVlZCBpbiBvcmRlciB0byBkZWNpZGUgd2hldGhlciB0byB0YWtlIHBhcnQgaW4gdGhpcyByZXNlYXJjaC48L3A+'
class ChapterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      flatListProps: {
      scrolledKey: null,
      contentHeight: null,
      prevScreenHeight: null,
      focusInvalidField: false
      }
    };
    this.arr = [];
  }

  componentDidMount(){
    const { setScrollRefToDocs } = this.props;
    this.buildFormFields();
    // setTimeout(() => {
    //   this.setState({
    //     flatListProps: {
    //       onEndReachedThreshold: 0.9,
    //       onEndReached: () => {
    //         // enableNextButton()
    //         // alert("Reached end");
    //       }
    //     }
    //   })
    // }, 0)
    // setScrollRefToDocs(this.component);

  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentDoc, setScrollRefToDocs } = this.props;
    const { contentHeight } = this.state;
    if(prevState.contentHeight !== contentHeight) {
      this.setState({prevScreenHeight: prevState.contentHeight});
    }
    
    setTimeout(() => {
      this.enableNextIfNoScroll();
    }, 1000)
    
    if(currentDoc.id !== prevProps.currentDoc.id) {
      this.buildFormFields();
      this.arr = [];
      if(this.component) {
        // this.component._root.scrollToPosition(0, 0);
        this.enableNextButton(false);
        setTimeout(() => this.enableNextButton(false), 0);
      }
    } 
  }

  enableNextIfNoScroll = ()=>{
    const { contentHeight, prevScreenHeight } = this.state;
    if(prevScreenHeight !== contentHeight) {
      if(contentHeight && (contentHeight <  height)) {
          this.enableNextButton(true);
      } 
      else if(contentHeight && (contentHeight >  height)) {
        this.enableNextButton(false);
      }
    }
  }

  onContentSizeChange = (contentWidth, contentHeight) => {
    // Save the content height in state
    this.setState({ contentHeight: contentHeight });
  };

  scrollToField = (key) => {
    const y = this.arr[key];
    if(this.component) {
      this.setState({
        focusInvalidField: false
      });
        this.component._root.scrollToPosition(0, y);

      }
  }

  buildFormFields = () => {
    const { location, currentDoc, setChapterPageValidation } = this.props;
    let { formDataList } = this.props
    formDataList = formDataList[currentDoc.id] || [];
    if(location !== 'StudyInfo') {
      this.setState({
        formFields: createFormFieldsWithValues(currentDoc.formFields, formDataList)
      });
      if(location !== 'CounterSignDocument') {
        setChapterPageValidation(this.onNextClick);
      }
    } else if(location === 'StudyInfo') {
      setChapterPageValidation(null);
    }
  }

  onNextClick = () => {
    const { form, storeDocumentFormDataList, currentDoc, t } = this.props;
    const { formFields } = this.state;
    let isValid = false;
    this.setState({
      focusInvalidField: true
    });
    form.validateFields((error, values) => {
      if (error) {
        isValid = false;
        showToast(t('DocFieldWarning'), 'danger', 6000);
      } else {
        const formDataList = buildFormDataList(formFields, values, currentDoc.id, null);
        if(formDataList) {
          storeDocumentFormDataList(formDataList);
        }
        isValid = true;
      }
    })
    return isValid
  }

  onValueChange = () => {
    const { form, storeDocumentFormDataList, currentDoc } = this.props;
    const { formFields } = this.state;
    const values = form.getFieldsValue();
    const formDataList = buildFormDataList(formFields, values, currentDoc.id, null);
    if(formDataList) {
      storeDocumentFormDataList(formDataList);
    }
  }

  // updateScrolledKey = () => {
    
  // }

  renderFormField = (formField, fieldKey) => {
    const { form, location, t, scrolledKey, currentUserType } = this.props;
    const { focusInvalidField } = this.state;
    switch (formField.fieldType){
      case 'paragraph':
        // return <HtmlParser currentDoc={htmlData}/>
        return <View style={{paddingLeft: 10, paddingRight: 10}}>
          <HtmlParserEg currentDoc={formField.fieldName} key={formField?.id}/>
        </View>
        // return <View style={{ backgroundColor: 'yellow' }}>
        //   <Text>Heyyyyyyyyy hleooeeee</Text>
        //   <Text>Heyyyyyyyyy hleooeeee</Text>
        // </View>
      case 'radio':
        return (
        // <View>
          <RadioFieldDecorator t={t} 
          onValueChange={this.onValueChange} 
          form={form} formField={formField} 
          location={location}
          scrollToField={this.scrollToField}
          fieldKey={fieldKey}
          focusInvalidField={focusInvalidField}
          key={formField?.id}
          currentUserType={currentUserType}
          // setInValidFieldsToFocus={this.setInValidFieldsToFocus}
          // removedInValidFieldsToFocus={this.removedInValidFieldsToFocus}
          // scrolledKey={scrolledKey}
          />
        // </View>
        );
      case 'radioini':
        return (
        // <View>
          <RadioInitialsFieldDecorator t={t} 
          onValueChange={this.onValueChange} 
          form={form} formField={formField} 
          location={location}
          scrollToField={this.scrollToField}
          fieldKey={fieldKey}
          focusInvalidField={focusInvalidField}
          key={formField?.id}
          currentUserType={currentUserType}
          // setInValidFieldsToFocus={this.setInValidFieldsToFocus}
          // removedInValidFieldsToFocus={this.removedInValidFieldsToFocus}
          // scrolledKey={scrolledKey}
          />
        // </View>
        );  
      case 'subjini': 
          return (
            <SubjectInitialInputDecorator t={t} 
            onValueChange={this.onValueChange} 
            form={form} formField={formField} 
            location={location}
            scrollToField={this.scrollToField}
            fieldKey={fieldKey}
            focusInvalidField={focusInvalidField}
            key={formField?.id}
            currentUserType={currentUserType}
            // setInValidFieldsToFocus={this.setInValidFieldsToFocus}
            // removedInValidFieldsToFocus={this.removedInValidFieldsToFocus}
            />
          )
    }
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  canCheckForOnEndReached = () => {

  }

  enableNextButton = (value) => {
    const { enableNextButton: allowNextButton, enableComponentNextButton } = this.props;
    if(enableComponentNextButton) {
      enableComponentNextButton(value);
    }
  }

  // setInValidFieldsToFocus = () => {

  // }

  render() {
    const { animateVisible, currentDoc, location, setScrollRefToDocs, scrollRef } = this.props;
    const { formFields, flatListProps } = this.state;
    if(location === 'StudyInfo') {
      return  <Content ref={c => {
                this.component = c;
                if(setScrollRefToDocs && scrollRef == null) {
                  setScrollRefToDocs(c);
                }
              }}         
          >
          {/* <AnimatedHideView style={{flex: 1, padding: wp('2%')}} visible={animateVisible} duration={150}> */}
          {/* <Content> */}
            {/* <List> */}
                {/* <ListItem noBorder style={{ backgroundColor: Colors.chapterPage }}> */}
                  <View style={{paddingLeft: 10, paddingRight: 10}}>
                  <HtmlParserEg currentDoc={currentDoc.content}/>
                  </View>
                  <View>
                        </View>
                {/* </ListItem> */}
              {/* </List> */}
          {/* </Content> */}
        {/* </AnimatedHideView> */}
      </Content>
    } 
    // if (!_.isEmpty(formFields)) {
      return (
      //   <FlatList
      //   data={formFields}
      //   renderItem={({ item }) => {
      //     return this.renderFormField(item)
      //     }}
      //   keyExtractor={formField => formField.id}
      //   // onEndReachedThreshold={0.9}
      //   // onEndReached={() => {
      //   //     // enableNextButton()
      //   //     alert("Reached end");
      //   // }}
      //   {...flatListProps}
      // />
        <Content ref={c => {
          this.component = c;
          if(setScrollRefToDocs && scrollRef == null) {
            setScrollRefToDocs(c);
          }
        }}
            onScroll={({nativeEvent}) => {
            if (this.isCloseToBottom(nativeEvent)) {
                    this.enableNextButton(true);
                }
            }}
            onContentSizeChange={this.onContentSizeChange}
            scrollEventThrottle={400}
        >
          {/* <AnimatedHideView style={{padding: wp('2%')}} visible={animateVisible} duration={150}> */}
            {/* <Content contentContainerStyle={{flex:1}}> */}
            {/* <List> */}
            {/* <HtmlParser currentDoc={btoa(formFields[0].fieldName)}/> */}
            {/* <HtmlParserEg currentDoc={btoa(formFields[0].fieldName)}/> */}
            {_.isEmpty(formFields) ? <View><Text style={{ color: 'transparent'}}>.</Text></View>
            : _.map(formFields, (formField,key) => 
              <View
              key={key}
              onLayout={event => {
                const layout = event.nativeEvent.layout;
                this.arr[key] = layout.y;
              }}
              >
                {this.renderFormField(formField, key)}
                </View>
                )}
            
              {/* {_.map(formFields, (formField,key) => 
              <View
              key={key}
              onLayout={event => {
                const layout = event.nativeEvent.layout;
                this.arr[key] = layout.y;
              }}>
                {this.renderFormField(formField, key)}
                </View>
                )} */}
            {/* </List> */}
            {/* </Content> */}
          {/* </AnimatedHideView> */}
        </Content>
      );
    // }
    // return <View/>;
  }
}

const mapStateToProps = state => ({
  formDataList: state.documents.formDataList,
  currentUserType: state.consentFlow.currentUserType,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
      storeDocumentFormDataList,

  },
  dispatch,
);

const ChapterPageForm = createForm()(ChapterPage);
export default connect(mapStateToProps, mapDispatchToProps)(ChapterPageForm);

ChapterPage.defaultProps = {
  formDataList: {},
};



var abbrStyles = StyleSheet.create({
  dialogWord: { fontSize:wp('3%'), color:Colors.coloredText, paddingVertical:wp('1.5%'), borderBottomWidth:1,
   borderBottomColor: Colors.coloredText
  
  },

});
