import React, { Component } from 'react';
import { View, Text, StyleSheet, Alert, Platform, Dimensions } from 'react-native';
import { RadioGroup, RadioButton } from 'react-native-flexi-radio-button';
import styles from './fieldStyles';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../../../constants/ui/colorScheme';
import { fontType, cancelIcon, getTextInContainer } from '../../../styles/font'
import CustomRadioButton from '../../../utils/customRadioButton';
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

export default class SingleSelectItem extends Component {
  state = {
  }
  getError = error => {
    if (error) {
      return error.map(info => {
        return (
          <Text style={styles.errorinfoText} key={info}>
            {info}
          </Text>
        );
      });
    }
    return null;
  };

  getCancelIconIndex(option, value) {
    const { attempts, field, showWrongIcon } = this.props;
    // const { showWrongIcon } = this.state;
    const fieldOptions = field.options;
    if ((attempts === 2 || field.answer === fieldOptions[value]) && !showWrongIcon) {
      return '';
    }
    else {
      if (attempts === 1 && option === fieldOptions[value] && showWrongIcon) {
        return value;
      }
      else
        if (attempts === 0 && option === fieldOptions[value] && showWrongIcon)
          return value;
    }
  }

  render() {
    const { error, field, value, onChange, handleWrongIcon, attempts, showWrongIcon } = this.props;
    const fieldOptions = field.options;
    // alert('hel');
    return (
      <View >
        {fieldOptions.map((option, index) => {
          return (<CustomRadioButton
            attempts={attempts}
            getCancelIconIndex={this.getCancelIconIndex(option, index)}
            index={index}
            option={option}
            onSelect={(index, value) => {
              onChange(index);
              handleWrongIcon(false);
            }}
            showWrongIcon={showWrongIcon}
            value={value}
          />);

        })}
        <View style={styles.errorinfoText}>{this.getError(error)}</View>
      </View>
    )
  }

}
