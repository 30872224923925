import React, { useMemo } from 'react';
import { View, Image, Platform, Text, TouchableOpacity, Dimensions } from 'react-native';
import tryalLogo from '../../../assets/base64/tryalLogo'
import { MaterialIcons } from '@expo/vector-icons'
import tryalLogoBox from '../../../assets/base64/tryalLogoBox';
import { get } from 'lodash';

const screenWidth = Dimensions.get('window').width;

const Header = (props) => {
    const { title, navigation, canShowBackIcon } = props;

    const headerLogoStyle = useMemo(() => {
        if(screenWidth < 768) {
            return {
                width: 70,
                height: 60,
                marginRight: 8,
            }
        } else {
            return {
                width: 184,
                height: 60,
                marginRight: 8,
                marginLeft:10
            }
        }
    }, [screenWidth])

    return (
        <View style = {{ flexDirection: 'row', marginLeft: 10}}>
            {
                canShowBackIcon && 
                <TouchableOpacity onPress={() => navigation()} style = {{ alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialIcons name="arrow-back" color="#212121" size={22} />
                </TouchableOpacity>
            }
            
            <View>
                    <Image
                    source={{
                        uri: `data:image/png;base64,${
                            screenWidth < 768 ? tryalLogoBox : tryalLogo
                        }`,
                    }}
                    resizeMode="contain"
                    style={headerLogoStyle}
                    />
          </View>
          <View style = {{ alignItems: 'center', justifyContent: 'center'}}>
            <Text style = {{
                fontWeight: '400',
                fontFamily: 'Inter',
                alignSelf: 'center',
                fontSize: 24,
                color: '212121'}}>
                    {title}
            </Text>
          </View>
        </View>
    )
}

export default Header;