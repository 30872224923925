import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { Colors } from '../constants/ui/colorScheme';
import { fontType } from '../styles/font'
import _ from 'lodash';
import { Feather } from '@expo/vector-icons';
import ChooseConsentDelivery from './subjectList/ChooseConsentDelivery';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export default class DocumentSelectionHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectConsentDocuments: [],
            showChooseConsentDelivery: false,
            isShowEmailPopUp: false,
            isNoMail: false
        };
    }

    onCheckUserAndSendData = async () => {
        const { changeConsentType } = this.props;
        changeConsentType("remote");
    }

    onContinue = () => {
        const {  changeConsentType } = this.props;
        changeConsentType("In Office");
    }

    closeChooseConsentDelivery = () => {
        this.setState({
            showChooseConsentDelivery: false
        })
    }

    openChooseConsentDelivery = () => {
        this.setState({
            showChooseConsentDelivery: true
        })
    }

    render() {
        const {showChooseConsentDelivery } = this.state;
        const { consentType, navigation } = this.props;
        return (
            <View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10, borderBottomWidth: 1, borderColor: 'lightgray' }}>
                    <Text style={{ fontSize: fontType("BasicFont"), color: '#616161', fontFamily: 'Inter' }}>Start Consent</Text>
                    <TouchableOpacity onPress={() => { navigation.replace('SubjectDetails') }}>
                        <Text style={{ color: 'grey', fontSize: fontType("subFont"), fontFamily: 'Inter' }}>RETURN</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10, borderBottomWidth: 1, borderColor: 'lightgray' }}>
                    <Text style={{ fontSize: fontType("BasicFont"), fontFamily: 'Inter' }}>How will the consent be signed ?</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    {/*  */}
                    <ChooseConsentDelivery
                        onSelectRemote={this.onCheckUserAndSendData}
                        onSelectInOffice={this.onContinue}
                        closeChooseConsentDelivery={this.closeChooseConsentDelivery}
                        showChooseConsentDelivery={showChooseConsentDelivery}
                    />
                    <View style={[styles.title, {backgroundColor: '#ffffff'}]}>
                        <View style={{ flexDirection: 'row', paddingLeft: 5 }}>
                            {consentType != "remote" ?
                                <Icon
                                    name="office-building"
                                    color={'#616161'}
                                    size={fontType('ChapterIcon')}
                                /> :
                                <Icon
                                    style={{ paddingTop: 2 }}
                                    name="email"
                                    color={'#616161'}
                                    size={fontType('ChapterIcon')}
                                />

                            }
                            <Text
                                style={{
                                    fontSize:fontType("BasicFont"),
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                }}
                            >
                                {consentType != "remote" ? "  In Office" : "  Email"}
                            </Text>
                        </View>
                        <Text style={{ fontSize: fontType("subFont"), color: "#9e9e9e", fontFamily: 'Inter' }}>
                            {consentType != "remote" ? " Documents will be signed in office " : "Documents will be emailed for Signatures."}

                        </Text>
                    </View>
                    <TouchableOpacity
                        style={
                            styles.options
                        }
                        onPress={() => this.openChooseConsentDelivery()}
                    >
                        <Feather
                            name="edit"
                            //   color={this.CheckSignIsSelectedOrNot(item.chapterPage.id, 1, 'color')}
                            size={fontType("ChapterIcon")}
                        />
                        <Text
                            style={[styles.optionsText]}
                        >
                            Edit
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

var styles = StyleSheet.create({
    content: {
        color: Colors.text,
        fontSize: fontType('Heading1'),
        padding: 5,
        textAlign: 'center',
        fontFamily: 'Inter'
    },
    title: {
        padding: 10,
        backgroundColor: '#e0d7d714',
        borderBottomColor: 'lightgray',
        borderBottomWidth: 0.2,
    },
    documentTxt: {
        fontSize: fontType('BasicFont'),
        color: Colors.text,
        fontFamily: 'Inter',
        padding: 5,
        marginLeft: 5
    },
    options: {
        alignItems: 'center',
        padding: 5,
        width: '10%',
        borderLeftWidth: 0.2,
        borderBottomWidth: 0.2,
        borderColor: 'lightgray',
        justifyContent: 'center'
    },
    skipableOption: {
        alignItems: 'center',
        padding: 5,
        minWidth: '20%',
        borderBottomWidth: 0.1,
        borderColor: 'lightgray',
        justifyContent: 'center'
    },
    optionsText: {
        marginTop: 4,
        fontSize: 9
    }

});

