import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {Colors} from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export const styles = StyleSheet.create(
    {
        container: {
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            width: wp('60%'),
            height: wp('80%'),
        },
        inputStyle: {
            textAlign: 'center',
            fontSize: wp('4%'),
            fontWeight: 'normal',
            
        },
        headerStyle: {
            // marginTop: 50,
            fontSize: fontType('Heading1'),
            fontWeight:'400',
            alignItems: 'center',
            textAlign: 'center',
            fontFamily:'Inter'
        },		
        infoText: {		
            // marginTop: 50,		
            fontSize: fontType('BasicFont'),		
            fontFamily:'Inter',		
            color: Colors.text,		
        },		
        errorText: {		
            fontSize: wp('2%'),		
            alignSelf: 'center',		
            fontFamily:'Inter',		
            color: Colors.error,		
        },
        buttonStyle: {
            backgroundColor: Colors.buttonwithbg,
            alignSelf: 'center',
            borderRadius: 50,
            width:wp('24%'),
            height:wp('8%'),
        },
        textStyle: {
            color: Colors.textColorwithbg,
            fontSize: wp('3.5%'),
            alignSelf: 'center',
            paddingLeft:wp('7%'),
            fontFamily:'Inter',
            paddingTop: wp('1%'),
        },
    }
)

