import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React, { Component } from 'react';
import { Container, Icon } from 'native-base';
import { View, ScrollView, Text, StyleSheet } from 'react-native';
import _ from 'lodash';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import api from '../utils/api';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import { Promise } from 'core-js';
import { AsyncStorage } from 'react-native';
import {fontType} from '../styles/font';
import showToast from '../utils/toast';
import { NavigationEvents } from 'react-navigation';



 class MetaDataLoading extends Component {

    render() {
        return (
            <View style={{flex:1, alignItems:'center',justifyContent:'center', backgroundColor: '#ffffff' }}>
                <Text style={{ fontSize: fontType('Heading1'), color: '#006699', padding: 10 }}>Loading...</Text>
            </View>
        )
    }
}

export default MetaDataLoading;


