import initialState from '../store/initialState';
import { HAS_NEW_UPDATE, HAS_UPDATED } from '../actions/update'


const hasUpdate = (state = initialState.hasUpdate, action) => {
  switch (action.type) {
    case HAS_NEW_UPDATE:
      return true;
    case HAS_UPDATED:
      return false;
    default:
      return state;
  }
};

export default hasUpdate;