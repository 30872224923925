import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StudyVideo from '../components/studyVideo/StudyVideo';
import { storeVisitedChapters, storeisAgreedForChapters, storeSubjectNavTour, updateSubjectNavTour } from '../actions/subject';
import { Icon } from 'native-base';
import {
    TouchableOpacity, View, Text
} from "react-native";
import { getConsentChapter } from '../utils/consentData';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { findVideoUrlById } from "../actions/multiMedia";

class StudyVideoListScreen extends Component {
    static contextType = ConsentDataContext;

    render() {
        const { navigation, isRegistered, subjectData, screenProps,
            storeVisitedChapters, isAgreedForChapters, storeisAgreedForChapters,
            storeSubjectNavTour, updateSubjectNavTour, selectedSubject, krDoneBy,
            findVideoUrlById, studyId
        } = this.props;
        const stdVideoData = getConsentChapter(this.context.languages, subjectData.language, 'STUDY_VIDEO');
        return (
            <StudyVideo
            stdVideoData = {stdVideoData}
            navigation={navigation}
            isRegistered={isRegistered}
            handleModalClose={this.handleModalClose}
            screenProps={screenProps}
            storeVisitedChapters={storeVisitedChapters}
            storeSubjectNavTour={storeSubjectNavTour}
            updateSubjectNavTour={updateSubjectNavTour}
            isAgreedForChapters = {isAgreedForChapters}
            storeisAgreedForChapters = {storeisAgreedForChapters}
            subjectData = {subjectData}
            selectedSubject = {selectedSubject}
            krDoneBy = { krDoneBy }
            findVideoUrlById = {findVideoUrlById}
            studyId={studyId}
            />
        );
    }
}
const mapStateToProps = state => ({
    isRegistered: state.subject.isRegistered,
    subjectData: state.subject,
    selectedSubject: state.selectedSubject,
    isAgreedForChapters: state.subject.isAgreedForChapters,
    krDoneBy: state.consentFlow.krDoneBy,
    studyId: state.studySite.studyId,

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeVisitedChapters,
        storeSubjectNavTour,
        updateSubjectNavTour,
        storeisAgreedForChapters,
        findVideoUrlById,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(StudyVideoListScreen);