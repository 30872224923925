import React, { useEffect, useRef } from 'react';
import { Text, StyleSheet, Dimensions, View } from 'react-native'
import { IconType, width } from '../constants';
import { Colors } from '../../../constants/ui/colorScheme';
import TryalIcon from '../icon';
const {height: SCREEN_HEIGHT} = Dimensions.get('window')

const SCREEN_WIDTH = width();
const BottomSheetStyles= StyleSheet.create({
    containerStyle:{
        flex: 1,
        width: SCREEN_WIDTH, 
        justifyContent: 'center',
        alignItems: 'center',
        height: SCREEN_HEIGHT*0.3,
        borderWidth: 0,
        marginTop: 2
    },
    
});
const BottomSheet = (props) => {
    const { open, children, onClose } = props;
    return (
        <View   
          style = {BottomSheetStyles.containerStyle}
        >
          
          {open && <View 
            style = {{
            alignSelf: 'center', 
            width: SCREEN_WIDTH*0.95,
            height: SCREEN_HEIGHT*0.4,
            borderWidth: 1,
            borderColor: Colors.borderGreyColor}}>
                <View style = {{ justifyContent: 'flex-end', alignSelf: 'flex-end'}}>
                <TryalIcon iconName='close' 
                  iconType={IconType.MATERIAL} 
                  color={Colors.borderColor} size = {22}
                  onClick={onClose}
                />
                </View>
                {children}
          </View>
            }
        </View>
    )
}

export default BottomSheet;
