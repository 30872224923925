import { LOGIN_SESSION_TIMEOUT, UPDATE_SESSION, UPDATE_STUDY_IN_SESSION, UPDATE_USER_TYPE } from "../actions/session";
import initialState from "../store/initialState";

export default (state = initialState.session, action) => {
  switch (action.type) {
    case LOGIN_SESSION_TIMEOUT:
      return {
        ...state,
        timeout: true,
      };
    case UPDATE_SESSION:
      return {
        ...state,
        primaryClient: action.primaryClient,
        site: action.site,
        primaryOrgCode: action.primaryOrgCode,
        userType: action.userType
      };
    case UPDATE_STUDY_IN_SESSION:
      return {
        ...state,
        study: action.study,
      };
    case UPDATE_USER_TYPE:
      return {
        ...state,
        userType: action.userType 
      }
    default:
      return state;
  }
};
