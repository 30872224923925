import React from 'react';
import {TextInput} from 'react-native';
import PropTypes from 'prop-types'
import { commonComponentStyles } from './styles';

const TryalTextInput = React.memo((props) => {
    const { value, onChange, inputStyle, placeholder, keyboardType, numberOfLines, readOnly } = props;
    return (
        <TextInput
        style={inputStyle}
        onChangeText={onChange}
        value={value}
        multiline
        placeholder={placeholder}
        keyboardType={keyboardType}
        numberOfLines={numberOfLines}
        editable = {!readOnly}
      />
    )
})

TryalTextInput.defaultProps = {
    value: '',
    onChange: () => null,
    placeholder: '',
    keyboardType: 'text',
    inputStyle: commonComponentStyles.inputText,
    numberOfLines: 5,
    readOnly: false,
}
TryalTextInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    keyboardType: PropTypes.string,
    inputStyle: PropTypes.instanceOf(Object),
    numberOfLines: PropTypes.number,
    readOnly: PropTypes.bool,
}

export default TryalTextInput;
