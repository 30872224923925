import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, Text, Image } from 'react-native';
import { constStyles } from '../../styles/constStyles';
import PropTypes from 'prop-types';

export default class AccessNotPermitted extends Component { 
    constructor(props) {
        super(props);
        this.state = {
        }
    }       
    render() {
        const { screenProps: { t }} = this.props;
        return (
            <Container>
                <View style={constStyles.containerStyle} >
                    {/* -------------- Title ------------------ */}
                    <View style={constStyles.contentCenterStyle} >
                    <Image
                         source={require("../../../assets/access-denied.png")}
                         style={{ height: 125, width: 125, paddingBottom: 10}}
                         resizeMode='contain'
                       />
                    </View>
                    <View style={constStyles.paddingStyle} >
                    <Text style={constStyles.errorHeadingText}>{t('AccessNotPermitted')}</Text>
                    </View>
                    {/* -------------- Message ------------------ */}
                    <View style={constStyles.centerStyle}>
                        <View style={constStyles.contentViewStyle}>
                            <Text style={constStyles.errorPageText}>{t('ApologyForNotPermit')}</Text>
                             <Text style={constStyles.errorPageText}>{t('ContactAdminstrator')}</Text>
                        </View>
                    </View>
                </View>
            </Container>
        );
    }
}
AccessNotPermitted.propTypes = {
    screenProps: PropTypes.shape({
        t: PropTypes.func,
    })
  };

