import React, {Component} from 'react';
import {View, StyleSheet, Text, Platform, TextInput} from 'react-native';
import {Item, Input} from 'native-base';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {Colors} from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';

export default class TextInputItem extends Component {
  state = {
    text: '',
  };

  getError = error => {
    if (error) {
      return error.map (info => {
        return (
          <Text style={styles.errorinfoText} key={info}>
            {info}
          </Text>
        );
      });
    }
    return null;
  };

  render () {
    const {error, value, setFieldValue, t, location,currentUserType} = this.props;
    // let loContent = content.split("\n").join('');
    return (
      <View>
        <TextInput
          style={{
            borderColor: Colors.text,
            borderWidth: 0.5,
            minWidth: 30,
            maxWidth: Platform.OS === 'web' ? 82 : 100,
            maxHeight: 20,
            fontFamily: 'Inter',
            backgroundColor: '#6FA8DC',
            paddingHorizontal: 5,
            marginTop: 14
          }}
          editable={(location === 'CounterSignDocument' || currentUserType == 4) ? false : true}
          value={value || ''}
          maxLength={10}
          //    value={this.state.text}
          // onChangeText = {(value) => this.setState({text: value})}
          onChangeText={setFieldValue}
        />
      </View>
    );
  }
}
const styles = StyleSheet.create ({
  errorinfoText: {
    color: Colors.error,
    // fontFamily: 'WorkSans-Light',
    fontSize: fontType ('subFont'),
    marginTop: 5,
  },
});
