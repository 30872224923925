import React, { Component } from 'react';
import SubjectDocumentSign from '../components/consentSignature/SubjectDocumentSign';
import { getConsentChapter, getFilteredDocuments, getFilteredDocumentSignatures } from '../utils/consentData';
import _ from 'lodash';
import { connect } from "react-redux";
import { buildSubjectData, buildSubjectSignatureData, getConsentVersionId } from '../utils/consentData';
import api from '../utils/api';
import showToast from "../utils/toast";
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { disableTap } from '../utils/signatureUtils';
import DocumentsList from '../components/consentSignature/DocumentsList';
import { bindActionCreators } from "redux";
import { isSubjectSignsDone } from "../actions/subject";
import { skipDocument, updateSubjectDocumentSignatures, canSkipTheDocument } from '../actions/subjectConsentDocument';
import { setSignatureTypes } from '../actions/consentFlow';
import { storeDeclarationFormData } from '../actions/documents';
import { getSignatureTypeOfSubject } from '../selectors/printNSign';
import constants from '../utils/constants';
import PropTypes from 'prop-types'


class SubDocsPage extends Component {
    static contextType = ConsentDataContext;
    state = {
        menuVisible: false,
        modalVisible: false,
        loading: false,
        enableSubmit:false,
    };

    submitConsentData = async() => {
        const { subject, navigation , studySite, subNavTours, formDataList, selectedSubject,  
            screenProps:{t}, signatureTypes, currentUserType, subjectRemoteConsent, setSignatureTypes, updateSubjectDocumentSignatures } = this.props;
        const {loading} = this.state;
        const data = buildSubjectData(subject, studySite);
        const fdKeys = Object.keys(formDataList);
        let formData = [];
        _.forEach(fdKeys, fdKey => {
            formData = [...formData, ...formDataList[fdKey]];
        });

        formData = _.map(formData, fd => {
            const subjectConsent = fd.subjectConsent || {id: null};
            let subjectConsentId = subjectConsent.id ? subjectConsent.id : subject.subjectConsentId
            if(_.isEmpty(subjectConsentId)){
                subjectConsentId =  selectedSubject.subjectConsentId
            }
            return {
                ...fd,
                subjectConsent: {
                    id: subjectConsentId,
                },
                subject:{
                    id: selectedSubject.id
                }
            }
        });
        let captureConsentedOn = true;
        if(currentUserType == 1 && _.includes(signatureTypes,2)){
            captureConsentedOn = false;
        } else if(currentUserType == 2){
            captureConsentedOn = true;
        } else if(currentUserType == 4){
            captureConsentedOn = false;
        }
        let subSignData  = buildSubjectSignatureData(subject, captureConsentedOn);
        subSignData = {
            ...subSignData,
            formData: formData,
            lastConsentedIrbVersion: this.context.languages[subject.language].irbApprovedVersion,
            status: (_.isEmpty(signatureTypes) || _.isEqual(signatureTypes[signatureTypes.length - 1], currentUserType)) ? 'NEEDS_COUNTER_SIGNATURE' : 'NEEDS_SIGNATURE'
        };
        const subjectConsentData = {
            status: (_.isEmpty(signatureTypes) || _.isEqual(signatureTypes[signatureTypes.length - 1], currentUserType)) ? 'NEEDS_COUNTER_SIGNATURE' : 'NEEDS_SIGNATURE',
            consentVersion : {
                id: getConsentVersionId(this.context.languages)
            },
            navTours: subNavTours,
            isRemote: subject.isRemoteConsent
        };

        const loSubjectRemoteConsent = {
            type: (subject?.isRemoteConsent) ? subjectRemoteConsent?.type : null,
            userType: (currentUserType == 2 && selectedSubject?.larAsWitness) ? 4 : currentUserType,
            signStatus : 1,
            id : subjectRemoteConsent?.subjectRemoteConsentId
        }
       
        try{
            if(loading === false ) {
                this.setState({loading: true});
                
                // await api.post('/consent/subject/submit', data);
                if(subject.id !== null ) {
                 const res = await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subject.id}/subjectConsents/documents`, {subSignData, subjectConsentData, subjectRemoteConsent: loSubjectRemoteConsent});
                 this.updateSubjectSignsDone();
                 updateSubjectDocumentSignatures(res.data.subjectDocumentSignatures);
                 _.remove(signatureTypes, s => s== currentUserType);
                setSignatureTypes(signatureTypes);
                }
                this.setState({loading: false, enableSubmit:false});
                showToast(t('ConsentSubmitSuccess'), 'success', 3000);
            }
        } catch(error) {
            this.setState({loading: false, enableSubmit:true});
            console.log(error);
            showToast(t('ConsentSubmitFail'), 'danger', 3000);
        }
    }

    updateSubjectSignsDone = () => {
        const {isSubjectSignsDone} = this.props;
        isSubjectSignsDone();
    }

    getDocuments = () => {
        const { navigation, subject, currentUserType, subjectConsentDocuments, selectedSubject } = this.props;
        const documents = getConsentChapter(this.context.languages, subject.language, 'ICF_DOCUMENTS')
        const documentPages = getFilteredDocuments(documents, currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        return documentPages;
    }

    getNextSignatureType = () => {
        const { currentUserType, signatureTypes } = this.props;
        if(!_.isEmpty(signatureTypes)){
            const type = signatureTypes[0];
            return type;
        }
        return null;
    }

    renderSection = () => {
        const { loading, enableSubmit } = this.state;
        const { navigation, subject, isRegistered, screenProps, currentSubDocPosition, 
            selectedSubject, currentUserType, subjectConsentDocuments, storeDeclarationFormData, formDataList,  skipDocument: loSkipDocument, canSkipTheDocument , printNSignType } = this.props;
        const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        const documentPages = this.getDocuments();
        if (subjectDocumentSignatures.length == currentSubDocPosition) {
            return (
                <DocumentsList
                    navigation={navigation}
                    isRegistered={isRegistered}
                    subject={subject}
                    subjectDocumentSignatures={subjectDocumentSignatures}
                    documentPages = {documentPages}
                    submitConsentData={this.submitConsentData}
                    loading={loading}
                    screenProps={screenProps}
                    signType = {currentUserType}
                    enableSubmit = {enableSubmit}
                />
            );
        } else {
            var subDocSignNavProps = "";
            var subjectDocumentSignatureId = "";
        // const subjectDocumentSignatures = subject.subjectDocumentSignatures;
            // if (currentSubDocPosition < subjectDocumentSignatures.length) {
                subjectDocumentSignatureId = (!_.isEmpty(subjectDocumentSignatures)) ? subjectDocumentSignatures[currentSubDocPosition].id : null;
                subDocSignNavProps = {
                    subjectDocumentSignatureId,
                }
            // }
                const disableTapToSign = disableTap(subjectDocumentSignatures, subjectDocumentSignatureId);
                // const signatureChapter = getConsentChapter(this.context.languages, subject.language, 'SIGNATURE');
                const documentsChapter = getConsentChapter(this.context.languages, subject.language, 'ICF_DOCUMENTS');
                documentsChapter.pages = documentPages;
                const subjectDocumentSignature = subjectDocumentSignatures[currentSubDocPosition];
                // signatureChapter.documentSignatures.filter(docSignature => docSignature.id === documentSignatureId)[0];
                
                return (
                    <SubjectDocumentSign
                        currentUserType={currentUserType}
                        isRegisterd={isRegistered}
                        subject={subject}
                        navigation={navigation}
                        disableTapToSign={disableTapToSign}
                        documentsChapter={documentsChapter}
                        subjectDocumentSignature={subjectDocumentSignature}
                        screenProps={screenProps}
                        signType={currentUserType}
                        selectedSubject={selectedSubject}
                        subDocSignNavProps={subDocSignNavProps}
                        skipDocument = { loSkipDocument }
                        canSkipTheDocument = { canSkipTheDocument }
                        storeDeclarationFormData={storeDeclarationFormData}
                        formDataList={formDataList}
                        printNSignType={printNSignType}

                    />
                );
            }
    }

    render() {
        return (
            this.renderSection()
        )
    }
}

SubDocsPage.propTypes = {
    subject: PropTypes.object.isRequired,
    studySite: PropTypes.object.isRequired,
    isRegistered: PropTypes.bool,
    subNavTours: PropTypes.array,
    currentSubDocPosition: PropTypes.number,
    formDataList: PropTypes.object,
    selectedSubject: PropTypes.object.isRequired,
    currentUserType: PropTypes.number.isRequired,
    subjectConsentDocuments: PropTypes.array.isRequired,
    signatureTypes: PropTypes.array.isRequired,
    subjectRemoteConsent: PropTypes.object,
    printNSignType: PropTypes.string,
    isSubjectSignsDone: PropTypes.func.isRequired,
    skipDocument: PropTypes.func.isRequired,
    setSignatureTypes: PropTypes.func.isRequired,
    updateSubjectDocumentSignatures: PropTypes.func.isRequired,
    canSkipTheDocument: PropTypes.func.isRequired,
    storeDeclarationFormData: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired,
    screenProps: PropTypes.object.isRequired
};

SubDocsPage.defaultProps = {
    isRegistered: false,
    subNavTours: [],
    currentSubDocPosition: 0,
    formDataList: {},
    subjectRemoteConsent: {},
    printNSignType: ''
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        isSubjectSignsDone: isSubjectSignsDone,
        skipDocument,
        setSignatureTypes,
        updateSubjectDocumentSignatures,
        canSkipTheDocument,
        storeDeclarationFormData

    },
    dispatch,
);

const mapStateToProps = state => ({
    subject: state.subject,
    studySite: state.studySite,
    isRegistered: state.subject.isRegistered,
    subNavTours: state.subject.navTour,
    currentSubDocPosition: state.currentSubDocPosition,
    formDataList: state.documents.formDataList,
    selectedSubject: state.selectedSubject,
    currentUserType: state.session.userType,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    signatureTypes: state.consentFlow.signatureTypes,
    subjectRemoteConsent : state.subjectRemoteConsentDto,
    printNSignType: getSignatureTypeOfSubject(state)
});


export default connect(mapStateToProps,mapDispatchToProps)(SubDocsPage);