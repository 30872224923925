import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import TryalButton from '../common/button';

const SiteStaffCommentsStyles= StyleSheet.create({
    containerStyle:{
        display: 'flex',
        backgroundColor: Colors.background,
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10
    },
    bodyStyle: {
        padding: 10
    },
    textStyle: {
        fontFamily: 'Inter',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    }
});


const SubjectCommentsResolvedScreen = (props) => {
    const { screenProps, navigation } = props;
    const onNext = () => {
        navigation.replace('SubDocsPage', {signatureType: 1});
    }
    return (
        <View style = {SiteStaffCommentsStyles.containerStyle}>
            <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('AllCommentsMarkedResolved')}</Text>
            <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('AnyQuestionsText')}</Text>
            <Text style = {SiteStaffCommentsStyles.textStyle}>{screenProps.t('ContinueParticipation')}</Text>
            
            <View>
                <TryalButton title={screenProps.t('Continue')} onClick = {onNext}/>
            </View>
          
        </View>
    )
}

export default SubjectCommentsResolvedScreen;
