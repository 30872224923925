import React, { Component } from 'react';
import {TouchableOpacity, Text} from 'react-native';
import DocumentSelection from '../components/DocumentSelection';
import _ from 'lodash';
import { connect } from "react-redux";
import { getConsentChapter } from '../utils/consentData';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { setDocumentsAndSignatures } from '../actions/subjectConsentDocument';
import api from '../utils/api';
import DocumentSelectionHeader from '../components/DocumentSelectionHeader';
import { View } from 'native-base';
import { storeRegisteredSubjectData } from '../actions/subjectRegistrationForm';
import { bindActionCreators } from "redux";
import showToast from '../utils/toast';
import {getSignatureTypeOfSubject} from '../selectors/printNSign'
import { submitConsentWhenAllDocumentsAreOptional } from '../actions/documents';
import { updateSelectedSubjectStatus } from '../actions/subject';


class DocumentSelectionScreen extends Component {
    static contextType = ConsentDataContext;
    state = {
        documentPages:[],
        loading:false,
        consentType: 'remote',
    };

    componentDidMount() {
        const {navigation , printNSignType, screenProps:{t}} = this.props;
        const consentType = navigation.getParam('consentType');
        this.changeConsentType(consentType)
        if(consentType === 'remote' && printNSignType === 2){
            showToast(t('RemotePrintNSignError'), "danger", 3000);
        }
    }

    changeConsentType=(type)=>{
        const { selectedSubject, storeRegisteredSubjectData } = this.props;
        this.setState({
            consentType:type
        })
        storeRegisteredSubjectData({
            id: selectedSubject.id,
            registered: true,
            isRemoteConsent: type === 'remote'
        })
    }

    render() {
        const { documentPages, loading,consentType } = this.state;
        const {
          navigation,
          selectedSubject,
          storeRegisteredSubjectData,
          setDocumentsAndSignatures,
          printNSignType,
          submitConsentWhenAllDocumentsAreOptional,
          screenProps: { t } ,
          updateSubjectStatus
        } = this.props;
        return (
            <View style={{ backgroundColor: '#ffffff', flex: 1}}>
            <DocumentSelectionHeader
            changeConsentType={this.changeConsentType}
            storeRegisteredSubjectData={storeRegisteredSubjectData}
            consentType={consentType}
            documentPages={documentPages}
            selectedSubject = {selectedSubject}
            navigation={navigation}
            />
            <DocumentSelection
                documentPages={documentPages}
                selectedSubject = {selectedSubject}
                navigation = {navigation}
                setDocumentsAndSignatures = {setDocumentsAndSignatures}
                consentType = {consentType}
                disableRemoteConsent = {consentType === 'remote' && printNSignType === 2}
                submitConsentWhenAllDocumentsAreOptional={submitConsentWhenAllDocumentsAreOptional}
                t={t}
                updateSubjectStatus={updateSubjectStatus}
            />
            </View>
        );
    }
}

const mapStateToProps = state => ({
    selectedSubject: state.selectedSubject,
    printNSignType: getSignatureTypeOfSubject(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setDocumentsAndSignatures,
        storeRegisteredSubjectData,
        submitConsentWhenAllDocumentsAreOptional,
        updateSubjectStatus: updateSelectedSubjectStatus,
    },
    dispatch,
);
export default connect(mapStateToProps,mapDispatchToProps)(DocumentSelectionScreen);