import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {StyleSheet} from 'react-native';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'space-between',
        backgroundColor: Colors.background,
    },
    text: {
        fontWeight: "bold",
        color: Colors.textColor,
    },
    textNote : {
        fontSize: fontType('BasicFont'), 
        paddingBottom: 10, 
        padding:15,
        fontFamily:'Inter'
    },
});
