import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api';
import {PrintNSignSignatureType} from '../components/common/constants'
import constants from "../utils/constants.js";
const { ContextProperties } = constants

export const STORE_PRINT_AND_SIGN_SIGNATURE_TYPE = createActionType('STORE_PRINT_AND_SIGN_SIGNATURE_TYPE')
export const STORE_SUBJECT_CONSENT_DOCUMENTS = createActionType('STORE_SUBJECT_CONSENT_DOCUMENTS')
export const UPDATE_PRINT_AND_SIGN_DATA = createActionType('UPDATE_PRINT_AND_SIGN_DATA')


export const getSignatureType = (studySiteId,consentVersionId, studyId) => async dispatch => {
  try {
      const response = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/consents/${ContextProperties.CONSENT_ID}/consentVersions/${ContextProperties.CONSENT_VERSION_ID}/signaturesTypes`);
      dispatch({
        type: STORE_PRINT_AND_SIGN_SIGNATURE_TYPE,
        signatureType: response?.data ? PrintNSignSignatureType[response.data].value : 1,
        studySiteId: studySiteId,
        consentVersionId: consentVersionId,
      })
  } catch (error) {
      console.log(error)
  }
}

export const generatePdf = (subjectId, subjectConsentId, docId) => async dispatch => {
  try {
      const response = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/subjectConsents/${subjectConsentId}/pdfs`
        , [docId]);
      const pdfPath = _.find(response.data, ele => ele.documentId === docId)?.pdfPath
      dispatch(updatePrintNSignData(docId, pdfPath, null))
      return pdfPath
  } catch (error) {
      console.log(error)
  }
}

export const updateSignature = (subjectId, consentVersionId, documentId, status) => async (dispatch, getState) => {
  try {
      const response = await api.put(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subjectId}/subjectConsents/${getState().selectedSubject?.subjectConsent?.id}/documents/${documentId}?status=${status}&consentVersionId=${consentVersionId}&skipDocument=false`);
      dispatch(updatePrintNSignData(documentId, null, response.data?.isSignatureCompleted))
      return response.data?.isSignatureCompleted
  } catch (error) {
      console.log(error)
  }
}

const updatePrintNSignData = (documentId, blankPdfUrl=null, signatureCompleted=null) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRINT_AND_SIGN_DATA,
    documentId: documentId,
    blankPdfUrl: blankPdfUrl,
    signatureCompleted: signatureCompleted,
  })
}