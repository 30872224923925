import Dashboard from '../components/dashboard/Dashboard';
import React, { Component } from "react";
import {Text, View, TouchableOpacity} from 'react-native';
import { connect } from "react-redux";
import { Icon } from 'native-base';
import { bindActionCreators } from "redux";
import { storeSelectedSubject } from '../actions/subject';
import LogoutDialogue from '../components/dashboard/LogoutDialogue';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../constants/ui/colorScheme';

class DashboardScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showDialog: false,
        }
      }

    // static navigationOptions = ({ navigation }) => {
    //     const {params} = navigation.state;
    //     return {
    //         headerRight: () => (
    //             <TouchableOpacity
    //         onPress={() => params.setVisible()}
    //         title="Info"
    //         color= {Colors.tobg}
    //         style={{ width: 40 }}
    //       >
    //         <Icon name='menu' style={{ fontSize: 35, color: 'white' }}
    //         />
    //       </TouchableOpacity>
    //         ),
    //     }
    // };

    componentDidMount = () => {
    //   this.props.navigation.setParams({
    //     setVisible: this.handleHeaderMenuDialoge
    //   });
    }

    handleHeaderMenuDialoge = () => {
        this.setState(prevState => ({
          ...prevState,
          showDialog: !prevState.showDialog
        }));
      }
    
    render() {
        const { navigation, storeSelectedSub, studySite, user , organizationName} = this.props;
        const {showDialog } = this.state;
        return (
            <View style={{ flex: 1 , backgroundColor:'#FFF'}}>
                <Dashboard 
                navigation={navigation} 
                storeSelectedSubject={storeSelectedSub} 
                studySite={studySite}
                isReconsent={false}
                user = {user}
                consentTab={true}
                organizationName={organizationName}
                 />
                <LogoutDialogue 
                    navigation={navigation} 
                    showDialog = {showDialog}
                    user = {user}
                    handleHeaderMenuDialoge = {this.handleHeaderMenuDialoge}/>
            </View>
        );
    }
}
const mapStateToProps = state => ({
    subject: state.subject,
    studySite: state.studySite,
    // user: state.clientUser.selectedUser,
    documentSignatureList: state.documents.documents,
    user: state.clientUser.selectedUser,
    organizationName : state.clientUser.organizationName
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSelectedSub: storeSelectedSubject,
    },
    dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
