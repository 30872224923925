import createActionType from "../utils/action";
export const LOGIN_SESSION_TIMEOUT = createActionType("LOGIN_SESSION_TIMEOUT");
export const UPDATE_SESSION = createActionType("UPDATE_SESSION");
export const UPDATE_STUDY_IN_SESSION = createActionType("UPDATE_STUDY_IN_SESSION");
export const UPDATE_USER_TYPE = createActionType("UPDATE_USER_TYPE")

export const sessionExpired = () => (dispatch) => {
  dispatch({
    type: LOGIN_SESSION_TIMEOUT,
  });
};

export const updateSession = ({primaryClient, site, primaryOrgCode, userType}) => (dispatch) => {
  dispatch({
    type: UPDATE_SESSION,
    primaryClient,
    site,
    primaryOrgCode,
    userType
  });
};

export const updateStudyInSession = ({study}) => (dispatch) => {
  dispatch({
    type: UPDATE_STUDY_IN_SESSION,
    study
  });
};

export const updateSessionUserType = (userType) => ({
  type: UPDATE_USER_TYPE,
  userType,
});