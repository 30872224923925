import createActionType from '../utils/action';
// import api from '../utils/api';
import _ from 'lodash';

export const STORE_SIGNATURE_DATA = createActionType('STORE_SIGNATURE_DATA')

export const storeSignatureData = (subjectDocumentSignature) => ({
    type:  STORE_SIGNATURE_DATA,
    subjectDocumentSignature,
});

