import ConsentSignature  from '../components/signatureScreen/SignatureScreen';
import React, { Component } from "react";
import {Icon} from 'native-base';
import {getConsentChapter} from '../utils/consentData';
import {getDocumentSignature, getFilteredDocumentSignatures} from '../utils/consentData'
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSignatureData } from '../actions/subjectSignatureData';
import {incDocSignPosition} from '../actions/update'
import { disableTap } from '../utils/signatureUtils';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import _ from 'lodash';
import { storeDeclarationFormData } from '../actions/documents';	
import { SIGNATURE_TYPE } from '../components/common/constants';

class ConsentSignatureScreen extends Component {
    static contextType = ConsentDataContext;

    constructor(props) {
        super(props);
        this.state = {
            sign: null,
            signType : 1
        };
    };
    static navigationOptions = ({ navigation }) => ({
        // title: "Signature",
        // headerRight: (
        //     <Icon name='menu' style={{fontSize:27, color:'white',padding:10
        //     }} />
        //   ),
    });

    componentDidMount () {
        const { navigation }  = this.props;
        const signType = navigation.getParam('signType');
        this.setState({
            signType : signType
        })
    }

    captureSignature = (sign) => { 
        const {storeSignatureData, navigation, subject, incDocSignPosition, selectedSubject, subjectConsentDocuments, currentUserType, currentSubDocPosition} = this.props;
        const signType = navigation.getParam('signType');
        const documentPageId = navigation.getParam('documentPageId');
        const fullName = subject.fullName;
        const documentSignatureId = navigation.getParam('documentSignatureId');
        this.setState({ sign });
        const fileName = `ConsentSignature${moment().format('DDMMMYYYYHHMMss')}.png`;
        const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');
        var documentSignature = {
            id: subjectDocumentSignatureId,
            signedBy: fullName,
            fileName: fileName,
            signatureDate: moment(),
            signature: sign,
            signatureType: signType,
            documentPageId: documentPageId,
            documentSignatureId,
        }
        storeSignatureData(documentSignature);
        const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        
        if( currentSubDocPosition < subjectDocumentSignatures.length ){
            incDocSignPosition();
        }
        navigation.navigate('SubDocsPage', {signatureType: signType });
        
    };

    onback = ()=> {
        const { navigation } = this.props; 
        const signType = navigation.getParam('signType');
        navigation.navigate('SubDocsPage', {signatureType: signType });
    }

    getDocumentSignatures = () => {
        const { navigation, subject,} = this.props;
        const signature = getConsentChapter(this.context.languages, subject.language, 'SIGNATURE');
        const signType = navigation.getParam('signType');
        let documentSignatures =  _.filter(signature.documentSignatures, { 'signatureType': signType });
        if(subject.isReconsent) {
            documentSignatures = _.filter(documentSignatures, { 'isReconsentRequired': true });
        } else {
            documentSignatures = _.filter(documentSignatures, {  'isRequired': true  });
        }
        return documentSignatures;
    }

    render() {
            const {  navigation,subject,state, screenProps, subjectConsentDocuments, currentUserType, formDataList, storeDeclarationFormData, selectedSubject } = this.props;
            const fullName = subject.fullName;
            const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments);
            const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');
            const subjectDeclaration =  navigation.getParam('subjectDeclaration');
            const disableTapToSign = disableTap(subjectDocumentSignatures, subjectDocumentSignatureId);
            const signature = getConsentChapter(this.context.languages, subject.language, "SIGNATURE");
            const subjectDocumentSignature = _.filter(subjectDocumentSignatures, sd => _.isEqual(sd.id, subjectDocumentSignatureId))[0];
            // getDocumentSignature(signature, documentSignatureId);
            let declarationFormFields =_.find(signature.documentSignatures, { 'documentId': subjectDocumentSignature?.documentId, 'signatureType': currentUserType });


            return (
                <ConsentSignature
                disableTapToSign={disableTapToSign}
                    navigation={navigation}
                    declarationFormFields={declarationFormFields?.formFields}
                    documentId = {declarationFormFields?.documentId}
                    documentSignatureId = {declarationFormFields?.id}
                    declaration={subjectDeclaration}
                    captureSignature={this.captureSignature}
                    signedBy={fullName}
                    isWitness={false}
                    screenProps={screenProps}
                    heading={screenProps.t('SubjectSignature')}
                    isRemoteConsent={subject.isRemoteConsent}
                    onback={this.onback}
                    storeDeclarationFormData = {storeDeclarationFormData}	
                    formDataList = {formDataList}
                    selectedSubject = {selectedSubject}
                    currentSignatureType={SIGNATURE_TYPE.SUBJECT}
                />
            );
    }
}
const mapStateToProps = state => ({
    subject: state.subject,
    currentSubDocPosition: state.currentSubDocPosition,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    currentUserType: state.consentFlow.currentUserType,
    selectedSubject: state.selectedSubject,
    formDataList: state.documents.formDataList	
    
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSignatureData,
        incDocSignPosition,
        storeDeclarationFormData
    },
    dispatch,
);
export default connect(mapStateToProps,mapDispatchToProps)(ConsentSignatureScreen);
