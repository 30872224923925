import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font'

export const styles = StyleSheet.create(
    {
        container: {
            flex:2,
            justifyContent:'space-around',
            alignSelf:'center',
            padding:wp('3%'),
        },
        headerStyle: {
            fontSize: fontType('Heading1'),
            paddingHorizontal:4,
            color:Colors.text,
            fontFamily:'Inter'
        },

        leftheaderStyle: {
            paddingTop:wp('0.5%'),
            fontSize: fontType('BasicFont'),
            paddingLeft:4,
            alignItems: 'center',
            justifyContent:'center',
            textAlign:'left',
            fontWeight: '400' ,
            fontFamily:'Inter',          
        },
        rightheaderStyle: {
            paddingTop:wp('0.5%'),
            fontSize: fontType('BasicFont'),
            alignItems: 'center',
            justifyContent:'center',
            textAlign:'right',
            alignSelf:'center',
            color:Colors.text,           
            fontFamily:'Inter',      
        },
        buttonStyle: {
            padding:wp('6%'),
            // height: hp('5%'),
            backgroundColor:Colors.buttonwithbg,
            borderRadius: 50,
            justifyContent:'center',
            alignSelf:'center'
        },
        textStyle: {
            // padding:wp('4%'),
            color: Colors.textColorwithbg,
            fontSize: fontType('ButtonTxt'),
            fontFamily:'Inter_Bold'
            // alignSelf: 'center',
        },
    }
)

