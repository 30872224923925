import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {Colors} from '../../../constants/ui/colorScheme';
import {fontType } from '../../../styles/font';


export const styles = StyleSheet.create(
    {
        container: {
            alignSelf: 'center',
            width: '60%',
            height: '100%',
            flex:1,
            padding:50,
        },
        inputStyle: {
            textAlign: 'center',
            fontSize: wp('4%'),
            fontWeight: 'normal',
            
        },
        heading: {
            fontSize: fontType('Heading1'),
            fontWeight:'400',
            alignItems: 'center',
            textAlign: 'center',
            fontFamily:'Inter'
        },		
        infoText: {		
            fontSize: wp('2%'),		
            fontFamily:'Inter',		
            color: Colors.text,		
        },		
        errorText: {		
            fontSize: wp('2%'),		
            alignSelf: 'center',		
            fontFamily:'Inter',		
            color: Colors.error,		
        },
        buttonStyle: {
            backgroundColor: Colors.buttonwithbg,
            alignSelf: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
            width:wp('20%'),
            height:wp('5%'),
            marginTop: 40
        },
        textStyle: {
            color: Colors.textColorwithbg,
            alignSelf: 'center',
            fontFamily:'Inter',
            paddingTop: wp('1%'),
        },
        passwordRules: {
            paddingBottom: 5,
            fontSize:fontType('subFont'),
            fontFamily:'Inter'
        }
    }
)

