import React from 'react';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  Dimensions,
  View,
  Text,
  ScrollView,
  Platform
} from 'react-native';
import _ from 'lodash';
import {Button, Container} from 'native-base';
import {createForm} from 'rc-form';
import TextInputItem from './TextInputItem';
import {styles} from './styles/subjectRegistrationFormStyles';
// import DatePicker from 'react-native-datepicker';
import ChapterTitle from '../chapterTitle/ChapterTitle';
import KeyboardShift from '../KeyboardShift';
import moment from 'moment';
import { Colors } from '../../constants/ui/colorScheme';
import {buildSubjectRegisteredData, getConsentData, buildSubjectConsentData} from '../../utils/consentData';
import api from '../../utils/api';
import showToast from "../../utils/toast";
import {getConsentVersionId} from "../../utils/consentData";
import { ConsentDataContext } from '../../providers/ConsentDataProvider';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {fontType} from '../../styles/font';
import MyLoader from '../../utils/webLoader';
// date picker for webapp
// import DatePk from '../../utils/webDatePicker';


const mode = 1;
const Todaydate = new Date (Date.now ());

class SubjectRegistrationForm extends React.Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props)
        this.state = {
            date: null,
            loading:false,
            isValidDate: false,
        };
    };

    componentDidUpdate(prevProps) {
        const { subject, studySite,data } = this.props;
        var ordinals = _.map (data.formFields, 'ordinal');
        if(ordinals.includes(2)){
            if(!this.state.isValidDate){
                if (subject.isRegistered !== prevProps.subject.isRegistered && subject.isRegistered) {
                    this.saveRegisteredData(subject, studySite);
                }
            }
        } else {
            if (subject.isRegistered !== prevProps.subject.isRegistered && subject.isRegistered) {
                this.saveRegisteredData(subject, studySite);
            }
        }
    }

    changeDate = (date) => {
        this.setState({ date });
    };
    
    changeDateValidation = (date) => {
        this.setState({ isValidDate:false });
    };

    getFieldValue = (values, fieldOrdinal) => {
        const { data } = this.props;
        const filtField = data.formFields.filter(ff => ff.ordinal === fieldOrdinal);
        if(filtField.length > 0) {
            return values[filtField[0].id];
        } 
        return null;
    }

    submit = async()  => {
        const { data, subjectData, storeRegistrationData, storeSubjectStatus,
                navigation, askForLAR, subject, studySite, storeSignatureType, clearSubjectNavTour, formDataList  } = this.props;
        const {loading} = this.state;
        const currChapterOrdinal = navigation.getParam('ordinal');
        const fdKeys = Object.keys(formDataList);
        let formFields = [];
        _.forEach(fdKeys, fdKey => {
            formFields = [...formFields,...formDataList[fdKey]];
        });
        // formFields with FormData
        this.props.form.validateFields((error, values) => {
            if (error) {
                return null;
            } else {
                var a = data.formFields.length;
                for (let i = 0; i < a; i++) {
                    if(i==2){
                        formFields.push({
                            "fieldOid": data.formFields[i].fieldOid,
                            "fieldValue": this.state.date,
                            "consentChapter": {
                                "id": data.id,
                            },
                            "formField" : {
                                "id": data.formFields[i].id,
                            },
                        });
                    } else {
                    formFields.push({
                            "fieldOid": data.formFields[i].fieldOid,
                            "fieldValue": values[data.formFields[i].id],
                            "consentChapter": {
                                "id": data.id,
                            },
                            "formField" : {
                                "id": data.formFields[i].id,
                            },
                    });
                }
                };

                const dateValue = this.state.date;
                var ordinals = _.map (data.formFields, 'ordinal');
                const subjectDetails = {
                    firstName: this.getFieldValue(values, 0),
                    lastName: this.getFieldValue(values, 1),
                    dob: dateValue ? new Date(moment(dateValue).format("YYYY-MM-DD")) : dateValue,
                    gender: this.getFieldValue(values, 3),
                    phoneNo: this.getFieldValue(values, 4),
                }
                if(ordinals.includes(2) && !this.state.isValidDate){
                        storeRegistrationData(formFields, subjectDetails);
                        storeSubjectStatus('NEEDS_SIGNATURE');
                } else {
                    storeRegistrationData(formFields, subjectDetails);
                    storeSubjectStatus('NEEDS_SIGNATURE');
                }
            }
        });
    };

  
  findFormFieldName = ordinal => {
    const {data} = this.props;
    return _.find (data.formFields, user => user.ordinal == ordinal).fieldName;
  };
  findFormFieldId = ordinal => {
    const {data} = this.props;
    return _.find (data.formFields, user => user.ordinal == ordinal).id;
  };

    saveRegisteredData = async (subject, studySite) => {
        const {storeRegisteredSubjectData, navigation, askForLAR, user, storeSignatureType,
             subjectNavTours, clearSubjectNavTour, screenProps:{t}, failedToRegisterSubject, deviceNo } = this.props;
        const registeredData = buildSubjectRegisteredData(subject, studySite, getConsentVersionId(this.context.languages) );
        const ConsentData = getConsentData(this.context.languages);
        const subjectConsentData = buildSubjectConsentData(ConsentData, user, subjectNavTours, subject )
        try {
            this.setState({loading:true});
            const res = await api.post('/consent/subjectRegisteredData/submit',{registeredData,subjectConsentData, deviceNo});
            if(res.data) {
                const subject = res.data;
                storeRegisteredSubjectData(subject);
                this.setState({loading:false});
                clearSubjectNavTour();
                if (askForLAR) {
                    navigation.navigate('AskForLarPage')
                } else {
                    storeSignatureType(1);
                    navigation.navigate('SubDocsPage', { signatureType: 1 })
                }
            }
        } catch (error) {
            this.setState({loading:false});
            showToast(t('SaveFail'), 'danger', 2000);
            failedToRegisterSubject();
            console.log(error);
        }
    }

    validatePhoneNumber = (rule, value, callback) => {
        const { screenProps: { t } } = this.props;
        const phoneNumber = parsePhoneNumberFromString (value);
        if (phoneNumber === undefined || !phoneNumber.isValid () === true) {
          callback (t('ValidPhn'));
        } else {
          callback ();
        }
      };

  render () {
    const {data, navigation, subjectData, screenProps: {t}} = this.props;
    const {getFieldDecorator, getFieldError} = this.props.form;
    const {loading} = this.state;
    var ordinals = _.map (data.formFields, 'ordinal');
    const {chapterTitle} = data;
    return (
   
      <Container>
        <View style={{flex: 5.5}}>
        <MyLoader  active={loading}/>
        <ChapterTitle chapterType="REGISTRATION_FORM" chapterTitle={t('RegistrationForm')} />
        <KeyboardShift>
          {() => (
              <View style={{ flex: 1, paddingTop: wp ('4%'),padding:15}}>
                <ScrollView>
                <View style={{flex: 1, padding: wp ('1.5%')}}>
                    {
                    ordinals.includes(0) &&
                    <View style={{paddingTop: hp ('5%')}}>
                        <Text style={styles.labelFont}>
                        {this.findFormFieldName (0)}
                        </Text>
                        <View>
                        {getFieldDecorator (this.findFormFieldId (0), {
                            validateFirst: true,
                            rules: [
                            {required: true, message: t ('FirstNameWarn')},
                            ],
                        }) (
                            <TextInputItem
                            t={t}
                            autoFocus={false}
                            mode={mode}
                            autoFocus
                            style={styles.placeholder}
                            error={getFieldError (this.findFormFieldId (0))}
                            maxlength={100}
                            />
                        )}
                        </View>
                    </View>
                    }
                    {
                    ordinals.includes(1) &&
                    <View style={{paddingTop: hp ('5%')}}>
                        <Text style={styles.labelFont}>
                        {this.findFormFieldName (1)}
                        </Text>
                        {getFieldDecorator (this.findFormFieldId (1), {
                        validateFirst: true,
                        rules: [
                            {required: true, message: t ('LastNameWarn')},
                        ],
                        }) (
                        <TextInputItem
                            t={t}
                            mode={mode + 1}
                            autoFocus
                            placeholder="please type your lastname"
                            style={{color: Colors.error}}
                            error={getFieldError (this.findFormFieldId (1))}
                            maxlength={100}
                        />
                        )}
                    </View>
                    }
                    {
                    ordinals.includes(2) &&
                    <View
                        style={{paddingTop: hp ('5%'), paddingBottom: hp ('1%')}}
                    >
                        <Text style={styles.labelFont}>
                        {this.findFormFieldName (2)}
                        </Text>
                        <View style={{paddingTop: hp ('2%')}}>
                            {/* date picker for web */}
                        {/* <DatePk changeDate = {this.changeDate} date = {this.state.date} changeDateValidation = {this.changeDateValidation} t={t} /> */}
                        
                        {/* Date picker for tablet */}
                            <DatePicker
                            style={{width: wp ('93%')}}
                            date={this.state.date}
                            mode="date"
                            placeholder={t('DateOfBirth')}
                            format="DD-MMM-YYYY"
                            minDate="01-01-1900"
                            maxDate={Todaydate}
                            confirmBtnText={t('Confirm')}
                            cancelBtnText={t('Cancel')}
                            customStyles={{
                                placeholderText: {
                                    fontSize: fontType('BasicFont'),
                                    fontFamily: 'Inter',
                                    marginLeft: 0,
                                    bottom: 9,
                                    left: 0,
                                },
                                dateText: {
                                    bottom: 0,
                                    fontSize: fontType('BasicFont'),
                                    fontFamily: 'Inter'
                                },
                                dateIcon: {
                                    position: 'absolute',
                                    right: 0,
                                    bottom: -hp('1%'),
                                    resizeMode: 'contain',
                                    height: hp('9%'),
                                    width: wp('7%')
                                },
                                dateInput: {
                                    height: hp('4%'),
                                    alignItems: 'flex-start',
                                    marginLeft: wp('1%'),
                                    borderTopColor: 'white',
                                    borderStartColor: 'white',
                                    borderEndColor: 'white',

                                    borderColor: '#cacaca'
                                },
                            }}
                            onDateChange={date => {
                                this.changeDateValidation();
                                this.changeDate (date);
                            }}
                            />
                        </View>
                        <View style={{zIndex:-2}}>
                        {this.state.isValidDate ?
                            <Text style={{marginTop: 10,
                                color: Colors.error,
                                fontSize: fontType('subFont')}}>
                             {t('DOBWarn')}
                             </Text> :
                             <Text> </Text>
                        }
                        </View>
                        </View>
                    }
                    
                    {
                    ordinals.includes(3) &&
                    <View style={{paddingTop: hp ('3%'), zIndex:-2}}>
                        <Text style={styles.labelFont}>{this.findFormFieldName (3)}</Text>
                        {getFieldDecorator (this.findFormFieldId (3), {
                        validateFirst: true,
                        rules: [
                            {required: true, message: t('GenderWarn')},
                        ],
                        }) (
                        <TextInputItem
                        t={t}
                            mode={mode + 2}
                            placeholder="please type your gender"
                            style={{color: Colors.error, fontFamily:'Inter'}}
                            error={getFieldError (this.findFormFieldId (3))}
                            maxlength={10}
                        />
                        )}
                    </View>
                    }
                    {
                    ordinals.includes(4) &&
                    <View style={{paddingTop: hp ('5%'), zIndex:-2}}>
                        <Text style={styles.labelFont}>{this.findFormFieldName (4)}</Text>
                        {getFieldDecorator (this.findFormFieldId (4), {
                        validateFirst: true,
                        rules: [
                            {
                            required: true,
                            message: t('PhoneNoWarn'),
                            },
                            {
                                validator: this.validatePhoneNumber,
                            },
                            //     {
                            //         pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/,
                            //         message: 'phonenumber entered is not valid',
                            //     },
                            //     {
                            //         validator: (rule, value, callback) => {
                            //             this.checkUserPhone(value, callback);
                            //         },
                            //         message: 'phone number is valid',
                            //     },
                        ],
                        }) (
                        <TextInputItem
                            t={t}
                            mode={mode + 3}
                            keyboardType="phone-pad"
                            autoFocus
                            placeholder="please type your phonenumber"
                            style={{color: 'green', fontFamily:'Inter'}}
                            error={getFieldError (this.findFormFieldId (4))}
                            maxlength={15}
                        />
                        )}
                    </View>
                    }
                </View>
                </ScrollView>
            </View>
          )}
        </KeyboardShift>
        </View>
        <View style={{ flex: 0.5, justifyContent: "flex-end"}}>
        <Button
                    full
                    style={{
                    // width: Dimensions.get ('window').width,
                    // height: wp ('8.5%'),
                    margin: 8,
                    backgroundColor: Colors.buttonwithbg,
                    }}
                    onPress={()=> {
                        if(this.state.date==null){
                            this.submit();
                          this.setState({isValidDate: true})  
                        } else {
                        this.submit()
                        }
                    }}
                >
                    <Text style={{color: Colors.textColorwithbg, fontSize:fontType('BasicFont'), fontFamily:'Inter_Bold'}}>
                    {t ('Register')}
                    </Text>
                </Button>
        </View>
      </Container>
    );
  }
}

export default createForm () (SubjectRegistrationForm);
