import React, { Component } from 'react';
import { View, Item, Input, Text } from 'native-base';
import RadioFormInput from './RadioFormInput';

export default class RadioFieldDecorator extends Component{

  constructor(props) {
    super(props);
    this.inputRef = {
      focus: () => {},
    }
  }

    setFieldValue = async (value) => {
        const { form, formField, onValueChange } = this.props;
        await form.setFieldsValue({
            [formField.fieldOid]:value
        });
        onValueChange();
    }

    // componentDidUpdate(prevProps) {
    //   const { form, formField, focusInvalidField, fieldKey, scrollToField } = this.props;
    //   const { form: prevForm } = prevProps;
    //   const error = form.getFieldError(`${formField.fieldOid}`);
    //   const prevError = prevForm.getFieldError(`${formField.fieldOid}`);
    //   if(error && focusInvalidField !== prevProps.focusInvalidField && focusInvalidField) {
    //     scrollToField(fieldKey)
    //   }
    //   // else if(error !== prevError && !error) {
    //   //   removedInValidFieldsToFocus(fieldKey);
    //   // }
    // }

    render() {
      const { form, formField, t, location, scrollToField, fieldKey, focusInvalidField, currentUserType} = this.props;
      if(form.getFieldError(`${formField.fieldOid}`) && focusInvalidField) {
        scrollToField(fieldKey)
      }
      return (
        <View
         ref={ele => this.inputRef = ele}
         style={{ paddingBottom: 5}}>
          {form.getFieldDecorator(`${formField.fieldOid}`, {
            initialValue: formField.formData.optionOid,
          rules: [
            { required: formField.isRequired,
            message: t('SelectOption')},
          ],
        })(
          <RadioFormInput
            formField = {formField}
            error={form.getFieldError(`${formField.fieldOid}`)}
            setFieldValue={this.setFieldValue}
            location={location}
            key={formField?.fieldOid}
            currentUserType={currentUserType}
          />
        )}
        {form.getFieldError(`${formField.fieldOid}`) ? <Text style={{
          fontSize: 12,
          color: '#f44336',
          alignSelf: 'flex-start',
          marginLeft: 4,
          borderRadius: 5,
          padding: form.getFieldError(`${formField.fieldOid}`) ? 5 : 0
        }}>{form.getFieldError(`${formField.fieldOid}`)}</Text> : <Text/>}
        </View>
      )
    }
}
