import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPasswordScreen from "../components/forgotPassword/ForgotPasswordScreen";

class ForgotPasswordPage extends Component {
    render() {
        const { navigation } = this.props;
        return (
            <ForgotPasswordScreen
                navigation={navigation}
            />
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch,
);

export default connect( mapStateToProps, mapDispatchToProps )(ForgotPasswordPage);
