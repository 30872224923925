import React from "react";
import {
  View,
  Text,
  Animated,
  TouchableOpacity,
  Platform,
  ScrollView,
  Dimensions,
  StyleSheet,
} from "react-native";
import Signature from "react-native-signature-canvas";
import { styles, canvasStyles } from "./styles/signatureScreenStyle";
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogButton,
} from "react-native-popup-dialog";
import _ from "lodash";
import { Colors } from "../../constants/ui/colorScheme";
import WebSignCanvas from "./WebSignCanvas.web";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import MyLoader from "../../utils/webLoader";
import DeclarationFormField from "../documents/DeclarationFormFields";
import { SIGNATURE_TYPE, SubjectStatus } from "../common/constants";
import PropTypes from "prop-types";

const { width: SCREEN_WIDTH } = Dimensions.get("window");

class SignatureScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateFormFields: null,
      fadeAnim: new Animated.Value(1),
      fadeAnim1: new Animated.Value(1),
      fadeAnim2: new Animated.Value(1),
      date: "",
      signature: null,
      visible: false,
      witnessValidation: false,
      signatureVisible: false,
      onloading: true,
      witnessname: "",
      captureWitnessName: "",
      value: "",
      text: "",
      contPopupVisible: true,
      validationError: null,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.openContainerPopup();
    navigation.addListener("focus", () => {
      this.openContainerPopup();
    });
    Animated.timing(this.state.fadeAnim, {
      toValue: 0,
      duration: 1200,
    }).start();

    Animated.timing(this.state.fadeAnim2, {
      toValue: 0,
      duration: 1000,
    }).start();

    setTimeout(() => {
      this.signatureload();
    }, 4000);
  }

  captureWitnessName = (e) => {
    this.setState({ witnessname: e });
  };

  signatureload = () => {
    this.setState({ signatureVisible: true });

    Animated.timing(this.state.fadeAnim1, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  loadCompleted = () => {
    this.setState({ onloading: false });
  };

  onSignatureSubmitted = (sign) => {
    const { captureSignature, isCounterSignature } = this.props;
    const { witnessname } = this.state;
    this.setState({ showSignature: false });
    let validation = this?.child ? this.child.updateValidation() : true;
    if (validation) {
      if (!isCounterSignature) {
        this.setState(
          {
            contPopupVisible: false,
          },
          () => {
            setTimeout(
              () => captureSignature(sign.split(",")[1], witnessname),
              500
            );
          }
        );
      } else {
        setTimeout(
          () =>
            captureSignature(
              sign.split(",")[1],
              witnessname,
              this.closeContainerPopup
            ),
          500
        );
      }
      if (this.webCanvas) {
        this.webCanvas.clear();
      }
    }
  };

  handleVisible = () => {
    this.setState(prevsta);
  };

  handleCanvasIsEmpty = () => {
    this.setState((state) => ({
      visible: !state.visible,
    }));
  };

  setScrollRef = (position) => {
    this.dialogScrollRef.scrollTo({ x: 0, y: position });
  };

  openContainerPopup = () => {
    this.setState({
      contPopupVisible: true,
    });
  };

  closeContainerPopup = () => {
    this.setState(
      {
        contPopupVisible: false,
      },
      () => {
      }
    );
  };

  showValidationError = (message) => {
    this.setState({
      validationError: message,
    });
  };

  render() {
    const {
      declarationFormFields,
      documentSignatureId,
      documentId,
      declaration,
      signedBy,
      loading,
      screenProps,
      heading,
      isRemoteConsent,
      onback,
      storeDeclarationFormData,
      formDataList,
      screenProps: { t },
      selectedSubject,
      currentSignatureType,
    } = this.props;
    const { contPopupVisible, validationError } = this.state;
    return (
      <View style={signatureScreenStyles.conatiner}>
        <MyLoader active={loading} />
        <View
          visible={contPopupVisible}
          rounded={false}
          height={"79%"}
          width={SCREEN_WIDTH >= 1030 ? 800 : SCREEN_WIDTH}
          overlayBackgroundColor="transparent"
        >
          <View
            style={signatureScreenStyles.content}
          >
            <View style={signatureScreenStyles.conatiner}>
              <View style={{ flex: 0.5 }}>
                <Text
                  style={signatureScreenStyles.headerTextStyle}
                >
                  {heading}
                </Text>
              </View>

              {!isRemoteConsent && declarationFormFields ? (
                <View style={{ flex: 3, padding: 5 }}>
                  <ScrollView
                    ref={(c) => {
                      this.dialogScrollRef = c;
                    }}
                  >
                    <DeclarationFormField
                      declarationFormFields={declarationFormFields}
                      documentSignatureId={documentSignatureId}
                      t={screenProps.t}
                      documentId={documentId}
                      storeDeclarationFormData={storeDeclarationFormData}
                      formDataList={formDataList}
                      onRef={(ref) => (this.child = ref)}
                      setScrollRef={this.setScrollRef}
                      showValidationError={this.showValidationError}
                    />
                  </ScrollView>
                </View>
              ) : (
                <View style={{ flex: 1, padding: 5 }}></View>
              )}

              {!_.isEqual(selectedSubject.status, SubjectStatus.NEEDS_COUNTER_SIGNATURE) && _.isEqual(currentSignatureType, SIGNATURE_TYPE.COUNTER) &&
                <View style={styles.msgContainer}>
                  <Text style={styles.msgText}>{t('CounterSignRequiredSignaturesPending')}</Text>
                </View>
              }
              <View style={{ flex: 0.5, padding: 10 }}>
                <Text
                  style={{
                    fontSize: fontType("BasicFont"),
                    marginTop: 10,
                    color: Colors.signDetails,
                    justifyContent: "flex-end",
                    alignSelf: "center",
                    fontFamily: "Inter",
                  }}
                >
                  {signedBy}
                </Text>
              </View>
              {isRemoteConsent ? (
                <View>
                  <Text
                    style={{
                      fontSize: fontType("BasicFont"),
                      marginTop: 5,
                      alignSelf: "center",
                      fontFamily: "Inter",
                    }}
                  >
                    {screenProps.t("Subdec")}
                  </Text>
                </View>
              ) : null}
              {/*----------------Canvas ------------------  */}
              {Platform.OS === "web" ? (
                <View style={{ flex: 4 }}
                  pointerEvents={!_.isEqual(selectedSubject.status, SubjectStatus.NEEDS_COUNTER_SIGNATURE) && _.isEqual(currentSignatureType, SIGNATURE_TYPE.COUNTER) ? "none" : "auto"}
                >
                  <WebSignCanvas
                    onSignDone={this.onSignatureSubmitted}
                    handleCanvasIsEmpty={this.handleCanvasIsEmpty}
                    screenProps={screenProps}
                    SCREEN_WIDTH={SCREEN_WIDTH}
                    webCanvas={(ref) => (this.webCanvas = ref)}
                  />
                </View>
              ) : (
                <View
                  style={{
                    flex: declaration ? 5 : 7,
                    width: "100%",
                    height: "80%",
                    alignSelf: "center",
                  }}
                  pointerEvents={!_.isEqual(selectedSubject.status, SubjectStatus.NEEDS_COUNTER_SIGNATURE) ? "none" : "auto"}
                >
                  <Signature
                    onOK={this.onSignatureSubmitted}
                    onEmpty={() => this.setState({ visible: true })}
                    descriptionText={
                      !_.isEmpty(screenProps)
                        ? screenProps.t("SignHere")
                        : "Sign Here"
                    }
                    clearText={
                      !_.isEmpty(screenProps) ? screenProps.t("Clear") : "CLEAR"
                    }
                    confirmText={
                      !_.isEmpty(screenProps)
                        ? screenProps.t("DoneSigning")
                        : "DONE"
                    }
                    webStyle={canvasStyles}
                  />
                </View>
              )}
              <View style={{ flex: 0.7, marginTop: 160 }}>
                <TouchableOpacity
                  style={[
                    constStyles.buttonStyle,
                    { backgroundColor: "#FFF", marginTop: "15px" },
                  ]}
                  onPress={() => {
                    this.setState({
                      contPopupVisible: false,
                    });
                    setTimeout(() => onback(), 100);
                  }}
                >
                  <Text
                    style={[
                      constStyles.buttonTxt,
                      { color: Colors.radioGroup },
                    ]}
                  >
                    {!_.isEmpty(screenProps)
                      ? screenProps.t("GoBackToDoc")
                      : "BACK TO DOCUMENT"}
                  </Text>
                </TouchableOpacity>
              </View>
              {/* -----------------Dialogs */}
              <Dialog
                visible={!_.isEmpty(validationError)}
                footer={
                  <DialogFooter>
                    <DialogButton
                      text={
                        !_.isEmpty(screenProps) ? screenProps.t("OK") : "OK"
                      }
                      textStyle={{
                        fontFamily: "Inter_Bold",
                        color: "#006699",
                      }}
                      onPress={() => this.setState({ validationError: null })}
                    />
                  </DialogFooter>
                }
              >
                <DialogContent
                  style={{
                    paddingTop: 20,
                    backgroundColor: Colors.backToDocBt,
                  }}
                >
                  <Text
                    style={{
                      fontSize: fontType("BasicFont"),
                      color: "white",
                      fontFamily: "Inter_Bold",
                    }}
                  >
                    {validationError}
                  </Text>
                </DialogContent>
              </Dialog>
              <Dialog
                visible={this.state.visible}
                footer={
                  <DialogFooter>
                    <DialogButton
                      text={
                        !_.isEmpty(screenProps) ? screenProps.t("OK") : "OK"
                      }
                      textStyle={{
                        fontFamily: "Inter_Bold",
                        color: "#006699",
                      }}
                      onPress={() => this.setState({ visible: false })}
                    />
                  </DialogFooter>
                }
              >
                <DialogContent
                  style={{ paddingTop: 5, backgroundColor: Colors.backToDocBt }}
                >
                  <Text
                    style={{
                      fontSize: fontType("BasicFont"),
                      color: "white",
                      fontFamily: "Inter_Bold",
                    }}
                  >
                    {screenProps
                      ? screenProps.t("SignWarning")
                      : "Please sign to continue"}
                  </Text>
                </DialogContent>
              </Dialog>
              <Dialog
                visible={this.state.witnessValidation}
                footer={
                  <DialogFooter>
                    <DialogButton
                      text={
                        !_.isEmpty(screenProps) ? screenProps.t("OK") : "OK"
                      }
                      textStyle={{
                        fontFamily: "Inter_Bold",
                        color: "#006699",
                      }}
                      onPress={() =>
                        this.setState({ witnessValidation: false })
                      }
                    />
                  </DialogFooter>
                }
              >
                <DialogContent
                  style={{ paddingTop: 5, backgroundColor: Colors.backToDocBt }}
                >
                  <Text
                    style={{
                      fontSize: fontType("BasicFont"),
                      color: "white",
                      fontFamily: "Inter_Bold",
                    }}
                  >
                    {"Please enter witness name"}
                  </Text>
                </DialogContent>
              </Dialog>
            </View>
          </View>
        </View>
      </View >
    );
  }
}

SignatureScreen.defaultProps = {
  declarationFormFields: [],
  declaration: false,
  loading: false,
  heading: "",
  isRemoteConsent: false,
  storeDeclarationFormData: () => { },
  formDataList: {},
  isCounterSignature: false
}
SignatureScreen.propTypes = {
  declarationFormFields: PropTypes.arrayOf(Object),
  documentSignatureId: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  declaration: PropTypes.bool,
  signedBy: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  screenProps: PropTypes.instanceOf(Object).isRequired,
  heading: PropTypes.string,
  isRemoteConsent: PropTypes.bool,
  onback: PropTypes.func.isRequired,
  storeDeclarationFormData: PropTypes.func,
  formDataList: PropTypes.instanceOf(Object),
  selectedSubject: PropTypes.instanceOf(Object).isRequired,
  currentSignatureType: PropTypes.string.isRequired,
  navigation: PropTypes.instanceOf(Object).isRequired,
  captureSignature: PropTypes.func.isRequired,
  isCounterSignature: PropTypes.bool,
}
const signatureScreenStyles = StyleSheet.create({
  conatiner: {
    flex: 1,
    backgroundColor: "#eaeaea"
  },
  content: {
    backgroundColor: "#eaeaea",
    flex: 1,
    paddingTop: 10
  },
  headerTextStyle: {
    alignSelf: "center",
    color: Colors.signHeading,
    fontSize: fontType("Heading1"),
    fontFamily: "Inter"
  }
})
export default SignatureScreen;
