import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { View } from 'react-native';
import NavigationScreens from "./NavigationScreens";
import HeaderMenuPopup from '../components/abortConsent/HeaderMenuPopup';
import {updateConsentStatus, clearSubjectNavTour } from '../actions/subject'
import {storeSubjectStatus} from '../actions/subject';
import LogoutDialogue from '../components/dashboard/LogoutDialogue';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import StudyListDialogue from '../components/dashboard/StudyListDialgue';
import { storeStudySiteList, storeStudySite } from '../actions/studySite';
import { getFilteredDocumentSignatures } from "../utils/consentData";
import AppSwitcher from "../components/dashboard/AppSwitcher";
import OrganizationListDialogue from '../components/dashboard/OrganizationListDialogue';
import { setOrganizationName } from '../actions/clientUser';

class AppNavigation extends Component {
  static contextType = ConsentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      showLogoutDialogue: false,
      showAppSwitcherDialogue : false,
      navigation:{},
      showStudyListPopUp: false,
      showOrgListPopUp: false,
    }
  }

  componentDidUpdate = (prevProps)  => {
    const { screenProps:{isSessionActive} } = this.props;
    if(prevProps.screenProps.isSessionActive !== isSessionActive && isSessionActive == false) {
        this.setState(prevState => ({
            ...prevState,
            showDialog: false,
            showLogoutDialogue: false,
            showAppSwitcherDialogue : false
        })); 
    }
  }

  handleHeaderMenuDialoge = () => {
    this.setState(prevState => ({
      ...prevState,
      showDialog: !prevState.showDialog
    }));
  }
  setNavigation = (navigation) => {
    this.setState({
      navigation,
    }
    )
  }
  logoutMenuDialoge = () => {
    this.setState(prevState => ({
      ...prevState,
      showLogoutDialogue: !prevState.showLogoutDialogue
    }));
  }

  appSwitcherMenuDialoge = () => {
    this.setState(prevState => ({
      ...prevState,
      showAppSwitcherDialogue : !prevState.showAppSwitcherDialogue
    }));
  }

  switchStudyPopUp = () => {
    this.setState(prevState => ({
      ...prevState,
      showStudyListPopUp: !prevState.showStudyListPopUp
    }));
  }

  switchOrgPopUp = () => {
    this.setState(prevState => ({
      ...prevState,
      showOrgListPopUp: !prevState.showOrgListPopUp
    }));
  }

  render() {
    const { isRegistered, subjectData,currentUserType,
       consentID, studySiteId, storeSubjectStatus, studySiteList, selectedUser, storeStudySiteList, studySite,
       storeStudySite, updateConsentStatus, consentCompleted, screenProps,user, clearSubjectNavTour, deviceNo, isSubSignsDone, isRemoteConsent,currentSubDocPosition, subjectConsentDocuments, selectedSubject, setOrganizationName, organizationId, otp } = this.props;
    const {navigation, showDialog, showLogoutDialogue, showAppSwitcherDialogue, showStudyListPopUp, showOrgListPopUp} = this.state;
    const languages = this.context.languages;
    const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
var isDocSignsCompleted = false;
    if (subjectDocumentSignatures.length == currentSubDocPosition) {
      isDocSignsCompleted = true;
    }
    return (
      <View style={{ flex: 1}}>
        <NavigationScreens
          screenProps={{ 
                ...screenProps,
                handleHeaderMenuDialoge: this.handleHeaderMenuDialoge,
                logoutMenuDialoge: this.logoutMenuDialoge,
                appSwitcherMenuDialoge: this.appSwitcherMenuDialoge,  
                setNavigation: this.setNavigation,
                languages: languages,
                switchStudyPopUp: this.switchStudyPopUp,
                studySite: studySite
            }}
        />
        <HeaderMenuPopup
          isDocSignsCompleted ={isDocSignsCompleted}
          currentUserType={currentUserType}
          studySiteId={studySiteId}
          consentCompleted={consentCompleted}
          updateConsentStatus={updateConsentStatus}
          navigation={navigation}
          isRegistered={isRegistered}
          subjectData={subjectData}
          consentID={consentID}
          showDialog={showDialog && screenProps.isSessionActive}
          handleHeaderMenuDialoge={this.handleHeaderMenuDialoge}
          screenProps={screenProps}
          storeSubjectStatus={storeSubjectStatus}
          clearSubjectNavTour={clearSubjectNavTour}
          languages = {languages}
          isSubSignsDone = {isSubSignsDone}
          deviceNo={deviceNo}
          currentSubDocPosition={currentSubDocPosition}
          subjectConsentDocuments={subjectConsentDocuments}
          isRemoteConsent = {isRemoteConsent}
          otp={otp}
           />
        <LogoutDialogue 
          navigation={navigation} 
          showDialog = {showLogoutDialogue && screenProps.isSessionActive}
          user = {user}
          handleHeaderMenuDialoge = {this.logoutMenuDialoge}
          switchOrgPopUp = {this.switchOrgPopUp}
          // appVersion = {appVersion}
          deviceNo={deviceNo}
        />
        {/* <AppSwitcher 
          navigation={navigation} 
          showDialog = {showAppSwitcherDialogue && screenProps.isSessionActive}
          user = {user}
          appSwitcherMenuDialoge = {this.appSwitcherMenuDialoge}
          handleHeaderMenuDialoge = {this.appSwitcherMenuDialoge}
          // appVersion = {appVersion}
          deviceNo={deviceNo}
          selectedUser = {selectedUser}
        /> */}
        <StudyListDialogue
          navigation={navigation} 
          screenProps={screenProps}
          showDialog = {showStudyListPopUp && screenProps.isSessionActive}
          handleHeaderMenuDialoge={this.switchStudyPopUp}
          studySiteList={studySiteList}
          selectedUser={selectedUser}
          storeStudySiteList={storeStudySiteList}
          storeStudySite={storeStudySite}
          organizationId={organizationId}
        />
        <OrganizationListDialogue
          navigation={navigation} 
          screenProps={screenProps}
          showDialog = {showOrgListPopUp && screenProps.isSessionActive}
          handleHeaderMenuDialoge={this.switchOrgPopUp}
          selectedUser={selectedUser}
          setOrganizationName = {setOrganizationName}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserType : state.consentFlow.currentUserType,
    isRegistered: state.subject.isRegistered,
    isSubSignsDone: state.subject.isSubSignsDone,
    subjectData: state.subject,
    consentID: state.subject.consentId,
    isRemoteConsent: state.subject.isRemoteConsent,
    consentCompleted: state.subject.consentCompleted,
    studySiteId: state.studySite.studySiteId,
    user: state.clientUser.selectedUser,
    // appVersion: state.studySite.stdSite.appVersion,
    deviceNo: state.consentTokenData.deviceNo,
    studySiteList: state.studySite.list,
    selectedUser: state.clientUser.selectedUser,
    studySite: state.studySite,
    currentSubDocPosition: state.currentSubDocPosition,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    selectedSubject: state.selectedSubject,
    organizationId: state.clientUser.organizationId,
    otp: state.subjectRemoteConsentDto?.otp,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateConsentStatus,
    storeSubjectStatus,
    clearSubjectNavTour,
    storeStudySiteList,
    storeStudySite,
    setOrganizationName,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);