import { combineReducers } from "redux";
// import navigationReducer from './navReducer';
import subject from "./subjectData";
import documents from "./documents";
import studySite from "./studySite";
import clientUser from "./clientUser";
import selectedSubject from "./subject";
import consentTokenData from "./consentIdGeneration";
import hasUpdate from "./update";
import loading from "./loading";
import hasAppUpdate from "./appUpdate";
import currentSubDocPosition from "./subDocInc";
import documentChapter from "./documentChapter";
import study from "./study";
import subjectRemoteConsentDto from "./subjectRemoteConsentDto";
import consentFlow from "./consentFlow";
import subjectConsentDocument from "./subjectConsentDocument";
import clientUserDetails from "./clientUserDetails";
import initialState from "../store/initialState";
import printNSign from "./printNSign";
import session from "./session";

const appReducer = combineReducers({
  // navigationReducer,
  subject,
  documents,
  clientUser,
  studySite,
  selectedSubject,
  consentTokenData,
  loading,
  hasUpdate,
  hasAppUpdate,
  currentSubDocPosition,
  documentChapter,
  study,
  subjectRemoteConsentDto,
  consentFlow,
  subjectConsentDocument,
  clientUserDetails,
  printNSign,
  session,
});

const rootReducer = (state, action) => {
  // when a RESET_STORE action is dispatched it will reset redux state
  if (action.type === "RESET_STORE") {
    state = { ...initialState };
  }

  return appReducer(state, action);
};

export default rootReducer;
