import createActionType from '../utils/action';
import _ from 'lodash';
import api from '../utils/api';

export const DOCUMENT_SIGN_SAVE_REQUEST = createActionType('DOCUMENT_SIGN_SAVE_REQUEST')
export const DOCUMENT_SIGN_SAVE_SUCCESS = createActionType('DOCUMENT_SIGN_SAVE_SUCCESS')
export const DOCUMENT_SIGN_SAVE_FAILURE = createActionType('DOCUMENT_SIGN_SAVE_FAILURE')
import constants from "../utils/constants.js";
const { ContextProperties } = constants

const saveDocSignRequest = () => ({
    type: DOCUMENT_SIGN_SAVE_REQUEST,
});

const saveDocSignFailure = () => ({
    type: DOCUMENT_SIGN_SAVE_FAILURE,
});

const saveDocSignSuccess = (res, documentId, dsType) => ({
    type: DOCUMENT_SIGN_SAVE_SUCCESS,
    subDocSign: res.data,
    documentId: documentId,
    dsType: dsType,
});


export const saveDocumentSignature = (SubjectDocumentSignature, documentId, navigation, subjectConsentData, subject) => async (dispatch,getState) => {
    const dsType = SubjectDocumentSignature.documentSignature.signatureType;
    dispatch(saveDocSignRequest());
    try {
        const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${subject.id}/subjectConsents/${getState().selectedSubject?.subjectConsentId}/documents/${documentId}/signatures`
            , {SubjectDocumentSignature, subjectConsentData, subject});
        dispatch(saveDocSignSuccess(res, documentId, dsType));
        setTimeout(() => navigation.replace('CounterSignDoc', {documentId: documentId}), 500);
        return true;
    } catch(error) {
        console.log(error)
        dispatch(saveDocSignFailure());
        return false;
    }
    
}
