import SubjectEdit from '../components/subjectList/SubjectEdit';
import React, { Component } from "react";
import { connect } from "react-redux";
import {updateConsentStatus, storeSelectedSubjectDetailsinSubject, storeSelectedSubject} from '../actions/subject'
import { bindActionCreators } from "redux";
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { getLanguageList } from '../utils/consentData';
import AddSubject from '../components/subjectList/AddSubject';
import { getHeaderTitle } from './NavigationScreens';
import { Platform } from 'react-native';

class SubjectEditScreen extends Component {
   static contextType = ConsentDataContext;
   constructor(props) {
      super(props);
      this.state = {
         subjectList: [],
      };
   }
   componentDidMount = () => {
      const {updateConsentStatus} = this.props;
      // updateConsentStatus(true);
  }

  static navigationOptions = (props) => {
   const {screenProps: { languages, studySite}, navigation } = props;
   return  {   
      headerTitle:() => (getHeaderTitle(languages,false,true, studySite)),
      // headerTitleStyle: { alignSelf: 'center' },
   }
};

   render() {
      const {screenProps, selectedSubject, navigation, storeSelectedSubject,
         studySiteId, deviceNo} = this.props;
         const languages = getLanguageList(this.context.languages);
         let fromDocSelection = false;
         fromDocSelection = navigation.getParam("fromDocSelection")
      return (
         <AddSubject
         data={selectedSubject}      
         languages={languages}  
         navigation = {navigation}
         screenProps={screenProps}
         studySiteId={studySiteId}
         deviceNo={deviceNo}
         storeSelectedSubjectDetailsinSubject={storeSelectedSubjectDetailsinSubject}
         storeSelectedSubject={storeSelectedSubject}
         fromDocSelection = {fromDocSelection}
         fromEdit={true}
         />
      );
   }
}

const mapStateToProps = state => ({
    subject: state.subject,
    selectedSubject: state.selectedSubject,
    studySiteId: state.studySite.studySiteId,
   deviceNo: state.consentTokenData.deviceNo,
});

const mapDispatchToProps = dispatch => bindActionCreators(
   {
     updateConsentStatus,
     storeSelectedSubjectDetailsinSubject,
     storeSelectedSubject
   },
   dispatch,
 );
 
 export default connect(mapStateToProps, mapDispatchToProps)(SubjectEditScreen);





