import SubjectList from '../components/subjectList/SubjectList';
import React, { Component } from "react";
import { connect } from "react-redux";
import {updateConsentStatus, storeSelectedSubjectDetailsinSubject} from '../actions/subject'
import { bindActionCreators } from "redux";
import {clearSubjectData, clearSelectedSubject} from '../actions/subject';
import { clearFormData } from '../actions/documents';
import { clearDocumentChapter } from '../actions/documentChapter';

class SubjectListScreen extends Component {
   constructor(props) {
      super(props);
      this.state = {
         subjectList: [],
      };
   }
   componentDidMount = () => {
      const {updateConsentStatus} = this.props;
      // updateConsentStatus(true);
  }
   render() {
      const { navigation, storeSelectedSubject, studySite, clearSubjectData,
              updateConsentStatus, storeSelectedSubjectDetailsinSubject, 
              isReconsent, clearFormData, clearDocumentChapter, clearSelectedSubject, consentTab } = this.props;
      return (
         <SubjectList
             clearSubjectData={clearSubjectData}
             updateConsentStatus={updateConsentStatus}
             navigation={navigation} 
             storeSelectedSubject={storeSelectedSubject}
             studySite = {studySite}    
             storeSelectedSubjectDetailsinSubject = {storeSelectedSubjectDetailsinSubject}
             isReconsent={isReconsent}
             clearFormData={clearFormData}
             clearDocumentChapter={clearDocumentChapter}
             clearSelectedSubject={clearSelectedSubject}
             consentTab= {consentTab}
         />
      );
   }
}
const mapDispatchToProps = dispatch => bindActionCreators(
   {
     updateConsentStatus,
     storeSelectedSubjectDetailsinSubject,
     clearSubjectData,
     clearFormData,
     clearDocumentChapter,
     clearSelectedSubject
   },
   dispatch,
 );
 
 export default connect(null, mapDispatchToProps)(SubjectListScreen);




