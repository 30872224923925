import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CommentBox from './commentBox';
import CommentViewBox from './commentViewBox';
import { Colors } from '../../constants/ui/colorScheme';
import { width } from '../common/constants';
import TryalTextInput from '../common/textInput';
import PropTypes from 'prop-types';

const SCREEN_WIDTH = width()
const CommentResponseBoxStyles= StyleSheet.create({
    containerStyle:{
        borderColor: Colors.borderGreyColor,
        minHeight: 120,
        borderWidth: 1,
        paddingTop: 5,
        width: SCREEN_WIDTH*0.85,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    commentContainerStyle:{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        marginBottom: '10px',
        paddingLeft: 10,
    },
    cancelBtnStyle: {
        marginRight: '10px',
    },
    textStyle: {
        textAlign: 'center',
        fontFamily: 'Inter',
        marginBottom: 20,
        fontSize: 14,
    },
});

const CommentResponseBox = (props) => {
    const { commentDetails, isSubjectView, updateResponse, screenProps } = props;
    const [editingIndex, setEditingIndex] = useState([]);
    const [commentReplies, setCommentReplies] = useState([]);
    const onResponseEdit = (index) => {
        const indexs = [...editingIndex, index];
        setEditingIndex(indexs);
    }

    const removeEditingIndex = (index) => {
        const indexes = [...editingIndex];
        _.remove(indexes, i => i === index);
        setEditingIndex(indexes);
    }

    const refreshComments = (index, comment) => {
        const updatedComments = [...commentDetails?.subjectCommentReplies];
        if(!updatedComments[index]?.id){
          _.remove(updatedComments, (c,i) => i == index);
          updatedComments.push({...comment})
        } else {
          updatedComments[index] = {...comment}
        }
        removeEditingIndex(index);
        updateResponse({...commentDetails, subjectCommentReplies: updatedComments})
      }
  
      const onCommentCancel = (index) => { 
        const updatedComments = [...commentReplies];
        if(!updatedComments[index].id){
        _.remove(updatedComments, (c,i) => i == index);
          updateResponse({...commentDetails, subjectCommentReplies: updatedComments});
        }
        removeEditingIndex(index)
      }
  
      const onCommentDelete = (index) => {
        const updatedComments = [...commentDetails?.subjectCommentReplies];
        _.remove(updatedComments, (c,i) => i == index);
        updateResponse({...commentDetails, subjectCommentReplies: updatedComments})
      }

      React.useEffect(() => {
        if(_.isEmpty(commentDetails.subjectCommentReplies)){
            setCommentReplies([{response: '', edit: true, id: null, subjectComment: commentDetails}])
            setEditingIndex([0])
        }else{
            setCommentReplies(commentDetails.subjectCommentReplies)
        }
     }, [commentDetails])

    return (
        <View style = {CommentResponseBoxStyles.containerStyle}>
            <View style = {CommentResponseBoxStyles.commentContainerStyle}>
            <Text style={[CommentResponseBoxStyles.textStyle, {width: SCREEN_WIDTH*0.8}]} >{commentDetails?.comment}</Text>
            </View>
            <View style = {CommentResponseBoxStyles.commentContainerStyle}>
                {
                    _.map(commentReplies, (r, index) => 
                        _.includes (editingIndex, index)?
                        <CommentBox 
                        isEditing={true} commentDetails = {{...r, resolved: commentDetails?.resolved}} 
                        isResponse = {true} 
                        isSubjectView = {isSubjectView}
                        onCommentSave = {(res) => refreshComments(index, res)}
                        onCommentCancel = {() => onCommentCancel(index)}
                        screenProps={screenProps}
                        ></CommentBox>
                        :
                        <CommentViewBox 
                            commentDetails = {{...r, edit: false, resolved: commentDetails?.resolved}} 
                            isResponse = {true} 
                            isSubjectView = {isSubjectView}
                            onEdit = {() => onResponseEdit(index)}
                            onCommentDelete = {() => onCommentDelete(index)}
                            screenProps={screenProps}
                        ></CommentViewBox>
                    )
                }
            </View>
        </View>
    )
}

CommentResponseBox.propTypes = {
    screenProps: PropTypes.object,
    commentDetails: PropTypes.instanceOf(Object),
    isSubjectView: PropTypes.any,
    updateResponse: PropTypes.instanceOf(Object),
}

export default CommentResponseBox;
