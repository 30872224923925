import initialState from '../store/initialState';
import { SET_CURRENT_USER_TYPE, SET_SIGNATURE_TYPES } from '../actions/consentFlow'
import { STORE_QUIZ_ATTEMPT_DATA } from '../actions/quizAttempts';

const consentFlowUpdate = (state = initialState.consentFlow, action) => {
  switch (action.type) {
    case SET_SIGNATURE_TYPES:
      return {
          ...state,
          signatureTypes: action.signatureTypes,
      }
      case SET_CURRENT_USER_TYPE:
          return {
              ...state,
              currentUserType: action.currentUserType,
          }
      case STORE_QUIZ_ATTEMPT_DATA:
        return {
            ...state,
            krDoneBy: action.doneBy,
        }
    default:
      return state;
  }
};

export default consentFlowUpdate;