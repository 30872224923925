import React, { Component } from "react";
import {
  getColor,
  getBgColor,
  styles as styls
} from "../../utils/textFormatUtils";
// import HTMLView from "react-native-htmlview";
import { StyleSheet, Text, View, Image,  } from "react-native";
// import {WebView} from 'react-native-webview';
import { Button } from "native-base";
import Dialog, {
  DialogContent,
  ScaleAnimation,
} from "react-native-popup-dialog";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import _ from 'lodash';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchableOpacity, ScrollView } from "react-native-gesture-handler";
import { Colors } from "../../constants/ui/colorScheme";
// import htmlencoded from './config';

class HtmlParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      word: "",
      abbr: "",
      dialogueVisible: false,
      Height: '100%'
    };
    // this.renderNode = this.renderNode.bind(this);
    // this.handleAbbrDialogue = this.handleAbbrDialogue.bind(this);
  }

  // renderNode(node, that) {
  //   if (node.name == 'img') {
  //     const a = node.attribs;
  //     return (<Image style={{ width: 1000, height: 1000 }} source={{ uri: a.src }} />);
  //   }
  //   else if (node.name == 'abbr') {
  //     const abbreviation = node.attribs.title;
  //     let word = node.children[0].data
  //     word = word.charAt(0).toUpperCase() + word.slice(1);
  //     return (
  //       <Text
  //       accessibilityRole='button'
  //           onPress={() => {
  //             that.handleAbbrDialogue(abbreviation, word)
  //           }}
  //         style={{
  //         // fontWeight: 'bold',
  //         textDecorationLine: 'dotted',
  //         color: Colors.warning,
  //         backgroundColor: Colors.htmlparserText,
  //         }}
  //       >{node.children[0].data} </Text>
  //     )
  //   }
  // }

  handleAbbrDialogue = (abbrivation, word) => {
    this.setState({
      word: word,
      abbr: abbrivation,
      dialogueVisible: true
    });
  };

  handleHeaderMenuDialoge = () => {
    this.setState({
      ...this.state,
      dialogueVisible: false
    });
  };

  onNavigationChange(event) {
    if (event.title) {
        let htmlHeight = Number(event.title) //convert to number
        htmlHeight = isNaN(htmlHeight) ? 0 : htmlHeight;
        this.setState({Height:htmlHeight});
    }
 }

  render() {
    const { currentDoc, isDeclaration, isDialogBox } = this.props;
    const { dialogueVisible, word, abbr, Height } = this.state;
    const decodedDoc = currentDoc ? currentDoc :'';
    // const decodedDoc = atob(htmlencoded);
    let height = hp('70%');
    let webViewStyle = {  paddingHorizontal: 15 }
    if(isDeclaration && !_.isEmpty(decodedDoc)) {
      height = hp('16%')
      if(isDeclaration == true && isDialogBox == true) {
        height = hp('8%')
      }
    } else if (isDeclaration && _.isEmpty(decodedDoc)) {
      height = 0
    }
    let bgColor = '#fff'
    if(isDeclaration) {
      webViewStyle = { minWidth: wp('95%'), minHeight: height, backgroundColor: '#eaeaea' }
      bgColor = '#eaeaea';
    }
    if(isDeclaration == true && isDialogBox == true) {
      webViewStyle = { minWidth: wp('30%'), minHeight: height, backgroundColor: '#fff' }
      bgColor = '#fff';
    }
const html = `<html><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1"></head><body style="font-size:18px; background-color:${bgColor}">${decodedDoc}</body></html>`
const myScript = `
let abbrArray = document.getElementsByTagName("abbr");
    for(let i=0; i< abbrArray.length; i++) {
      document.getElementsByTagName("abbr")[i].style.color = "#ff8e00";
      document.getElementsByTagName("abbr")[i].style.backgroundColor = "#fff8e1";
      document.getElementsByTagName("abbr")[i].style.textDecorationLine = "none";
      document.getElementsByTagName("abbr")[i].addEventListener("click", function(){
        let obj = {
          title: this.title,
          word: this.innerHTML,
          }
        window.postMessage(
          JSON.stringify(obj)
        );
      });
    }
    
    true; // note: this is required, or you'll sometimes get silent failures`  
const script = `
<script>
	window.location.hash = 1;
    var calculator = document.createElement("div");
    calculator.id = "height-calculator";
    while (document.body.firstChild) {
        calculator.appendChild(document.body.firstChild);
    }
	document.body.appendChild(calculator);
    document.title = calculator.clientHeight;
</script>
`;
const style = `
<style>
body, html, #height-calculator {
    margin: 0;
    padding: 0;
}
#height-calculator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
</style>
`;
// alert(Height);
    return (
      <View 
      style={isDeclaration ? { padding:1 } : { flex:1,}}
      >
       
        <Dialog
          // onTouchOutside={() => this.handleHeaderMenuDialoge()}
          // containerStyle={{ justifyContent: 'flex-start' }}
          // dialogStyle={{ marginTop: hp('2.5%'), marginLeft: wp('48%') }}
          dialogAnimation={new ScaleAnimation()}
          rounded={true}
          visible={dialogueVisible}
          width={0.5}
        >
          <DialogContent>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={abbrStyles.dialogWord}>{word}</Text>
              </View>
              <TouchableOpacity
                onPress={() => this.handleHeaderMenuDialoge()}
                style={{ flex: 1, justifyContent: "center" }}
              >
                <MaterialCommunityIcons name="close" size={hp("3%")} />
              </TouchableOpacity>
            </View>
            <View style={{ height: 2, backgroundColor: "#000" }}></View>
            <View>
              <Text
                style={{
                  fontSize: hp("2%"),
                  paddingVertical: wp("2%"),
                  fontFamily: "Inter"
                }}
              >
                {abbr}
              </Text>
            </View>
            {/* <Button
                        style={{ backgroundColor: '#fff', borderRadius: 5, width: wp('6%'), alignSelf: 'flex-end' }}
                        onPress={() => this.handleHeaderMenuDialoge()}
                      >
                        <Text style={{ alignSelf: 'center', color: '#006699', fontSize: wp('2%'), paddingLeft: 13 }}>
                          Ok
                        </Text>
                      </Button> */}
          </DialogContent>
        </Dialog>
      </View>
    );
  }
}
export default HtmlParser;

var abbrStyles = StyleSheet.create({
  dialogWord: {
    fontSize: hp("2%"),
    color: Colors.coloredText,
    paddingVertical: hp("1%"),
    top: hp("0.7%"),
    // borderBottomWidth:1,
    //  borderBottomColor:'#006699',
    fontFamily: "Inter"
  }
});

// import React, { Component } from 'react';
// import {WebView} from "react-native";

// const rawHtml="<html><head></head><body><p>Hello <a href='http://flexwork.io'>flexwork.io</a></p></body></html>";
// const script = `
// <script>
// 	window.location.hash = 1;
//     var calculator = document.createElement("div");
//     calculator.id = "height-calculator";
//     while (document.body.firstChild) {
//         calculator.appendChild(document.body.firstChild);
//     }
// 	document.body.appendChild(calculator);
//     document.title = calculator.scrollHeight;
// </script>
// `;
// const style = `
// <style>
// body, html, #height-calculator {
//     margin: 0;
//     padding: 0;
// }
// #height-calculator {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
// }
// </style>
// `;
// class AutoHeightWebView extends Component
// {
// 	constructor(props) {
//         super(props);

//         this.state = {
//            Height:0
//         };
//     }
//     onNavigationChange(event) {
//         if (event.title) {
//           alert('Event Title: '+ event.title)
//             const htmlHeight = Number(event.title)
//             alert('Height: '+ htmlHeight) //convert to number
//             this.setState({Height:htmlHeight});
//         }

//      }
//     render () {
//     	return (
//     		<WebView scrollEnabled={false}
//                 source={{html:rawHtml+style+script}} 
//                 style={{height:this.state.Height}}
//       			javaScriptEnabled ={true}
//       			onNavigationStateChange={this.onNavigationChange.bind(this)}>
// 			</WebView>

//     		)
//     }

// }
// export default AutoHeightWebView;
