import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Table, TableWrapper, Row, Rows } from 'react-native-table-component'
import { Colors } from '../../constants/ui/colorScheme'
import { fontType } from '../../styles/font';

const CustomTable = (props) => {
    const {data, coloumns} = props
    return(
		<View>
			<ScrollView style={{maxHeight:100}} stickyHeaderIndices={[0]}>
				<Row 
					style={[styles.textLable, { textAlign: 'center', backgroundColor: Colors.tableHeader, height: 40 }]}
	        		data={coloumns}
    	    	/>
				<Table borderStyle={{ borderColor:Colors.borderColor , borderWidth: 1 }} >	
        			<TableWrapper>
          				<Rows
           					data={data}
							textStyle={[styles.textLable, { padding: 5, textAlign: 'center' }]}
			        	/>
        			</TableWrapper>
				</Table>
			</ScrollView>
		</View>
    )

}

export default CustomTable


const styles = StyleSheet.create({
	textLable: {
	  fontSize: fontType('BasicFont'),
	  fontFamily: 'Inter',
	},
  });
