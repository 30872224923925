import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentsList from "../components/consentSignature/DocumentsList";
import CounterSignDocument from "../components/counterSign/CounterSignDocument";
import {
  getConsentChapter,
  getDocSignatureBySignType,
  getVersionMetadata,
} from "../utils/consentData";
import { buildCounterDocumentSignatures } from "../utils/signatureUtils";
import _ from "lodash";
import { ConsentDataContext } from "../providers/ConsentDataProvider";
import { bindActionCreators } from "redux";
import { storeDeclarationFormData } from "../actions/documents";
import { getConsentVersionMetaData } from "../actions/documents";

class CounterSignDocumentScreen extends Component {
  static contextType = ConsentDataContext;
  state = {
    showDialog: false,
    selectedDocumentSignatureId: "",
    selectedDocSignDeclaration: "",
    selectedDocument: "",
    documentLoading: false,
    selectedDocumentId: "",
    subjectDocumentSignatureId:"",
  };
  static navigationOptions = ({ navigation }) => ({
    // title: "Signature"
  });

  closePopup = () => {
    this.setState({
      showDialog: false,
    });
  };
  navigateToSign = async (type, documentSignatureId, subjectDocumentSignatureId) => {
    const { navigation, documents, selectedUser, selectedSubject,consentVersionsMetadata } = this.props;
    const { showDialog } = this.state;
    const documentId = navigation.getParam("documentId");
    if (type === 3 && selectedSubject?.isCommentsResolved) {
      const versionMetadata = getVersionMetadata(documentId,documents, consentVersionsMetadata)
      const ds = getDocSignatureBySignType(
        !_.isEmpty(versionMetadata) ? versionMetadata: this.context.languages,
        documents,
        documentId,
        documentSignatureId
      );
      // check for optional
      if (ds?.isEsignRequired) {
        let documentSignatures = documents[documentId];
        let mstrDocSignList = getConsentChapter(
          this.context.languages,
          documentSignatures[0].language,
          "SIGNATURE"
        ).documentSignatures;
        mstrDocSignList = mstrDocSignList.filter(
          (mds) => mds.documentId.toLowerCase() === documentId.toLowerCase()
        );
        let declarationFormFields = _.find(mstrDocSignList, {
          documentId: documentId,
          signatureType: type,
        });

        this.setState({
          showDialog: true,
          selectedDocSignDeclaration: ds.declaration,
          selectedDocumentSignatureId: documentSignatureId,
          declarationFormFields: declarationFormFields.formFields,
          docId: declarationFormFields.documentId,
          docSignId: declarationFormFields.id,
          subjectDocumentSignatureId:subjectDocumentSignatureId,
        });
      } else {
        // navigation.navigate("CounterSignatureScreen", {
        //     fullName: selectedUser.fullName,
        //     documentId,
        //     documentSignatureId
        // });
        navigation.navigate("Admin", {
          screen: "CounterSignatureScreen",
          params: {
            fullName: selectedUser.fullName,
            documentId,
            documentSignatureId,
          },
        });
      }
      // navigation.navigate('CounterSignatureScreen', { documentId, documentSignatureId})
    }
  };

  getDocuments = () => {
    const { navigation, selectedSubject } = this.props;
    const documents = getConsentChapter(
      this.context.languages,
      selectedSubject.locale,
      "ICF_DOCUMENTS"
    );
    let documentPages = documents.pages;
    return documentPages;
  };

  constructSelectedDocument = (documentId, languageData = null) => {
    const { documents } = this.props;
    let selectedDocument = {};
    let documentSignatures = documents[documentId];
    const documentMeta = {
      language: documentSignatures[0].language,
      id: documentSignatures[0].documentId,
    };
    const docs = getConsentChapter(
      languageData ? languageData : this.context.languages,
      documentMeta.language,
      "ICF_DOCUMENTS"
    ).pages;
    let mstrDocSignList = getConsentChapter(
      languageData ? languageData : this.context.languages,
      documentMeta.language,
      "SIGNATURE"
    ).documentSignatures;
    mstrDocSignList = mstrDocSignList.filter(
      (mds) => mds.documentId.toLowerCase() === documentId.toLowerCase()
    );
    documentSignatures = documentSignatures.map((ds) => {
      return {
        ...ds,
        documentSignatureId: _.find(
          mstrDocSignList,
          (mds) => mds.signatureType === ds.signatureType
        ).id,
      };
    });

    documentSignatures = buildCounterDocumentSignatures(documentSignatures);
    documentSignatures = _.sortBy(documentSignatures, ["signatureType"]);

    selectedDocument = {
      ...docs.filter(
        (doc) => doc.id.toLowerCase() === documentMeta.id.toLowerCase()
      )[0],
      documentSignatures,
    };
    this.setState({
      selectedDocument: selectedDocument,
      documentLoading: false,
    });
    return selectedDocument;
  };

  getReconsentedNeedsSignatureData = async (consentVersionId) => {
    const { getConsentVersionMetaData } = this.props;
    const languageData = await getConsentVersionMetaData(consentVersionId);
    return languageData;
  };

  /* 
  To return the metadata for a reconsented Subject whose status is Needs Signature.
  */
  constructPreviousHistoryDocuments = (documentId) => {
    const {  documents } = this.props;
    this.setState({ documentLoading: true });
    this.getReconsentedNeedsSignatureData(
      documents[documentId][0]?.consentVersionId
    ).then((languageData) => {
      this.setState({ documentLoading: false });
      this.constructSelectedDocument(documentId, languageData);
    });
  }

  /* 
  To return the metadata for a reconsented Subject whose status is Needs Signature.
  To open all version's historic document, we need the version's metadata for a Needs Signature subject
  Note: This method is not require for " Needs Counter Sign subject" & "consented subject"
   For a Needs Counter Sign subject, we are showing only version's documents always
   For a consented subject, we are not opening documents to show signature,
*/
  componentDidMount() {
   this.prepareSelectedDocument();
  }

  //Construct document when there is a change in the selectedDocumentId
  componentDidUpdate(prevProps, prevState){
    const { navigation } = this.props;
    const documentId = navigation.getParam("documentId");
    if(!_.isEmpty(prevState.selectedDocumentId) && documentId!= prevState.selectedDocumentId){
      this.prepareSelectedDocument();
    }
  }

  prepareSelectedDocument = () => {
    const { navigation, documents } = this.props;
    const documentId = navigation.getParam("documentId");
    this.setState({selectedDocumentId : documentId })
    if (_.has(documents, documentId)) {
      //Here docs represents the documents of current subjectConsentVersion
      let docs = getConsentChapter(this.context.languages, documents[documentId][0].language,"ICF_DOCUMENTS").pages;      
      if (!_.isEmpty(docs) && _.isEmpty(_.filter(docs, doc => _.isEqual(doc.id, documentId)))) {
        //Constructing documents of previous subject consent version based on language metadata
        this.constructPreviousHistoryDocuments(documentId);
      } else {
        //Constructing documents of current subject consent version
        this.constructSelectedDocument(documentId, null);
      }
    } else {
      let loselectedDocument = _.filter(this.getDocuments(), {
        id: documentId,
      })[0];
      this.setState({ selectedDocument: loselectedDocument });
    }
  }
  render() {
    const {
      showDialog,
      selectedDocumentSignatureId,
      selectedDocSignDeclaration,
      declarationFormFields,
      docId,
      docSignId,
      selectedDocument,
      documentLoading,
      subjectDocumentSignatureId,
    } = this.state;
    const {
      navigation,
      documents,
      selectedSubject,
      userName,
      selectedUser,
      screenProps: { t, isSessionActive },
      currentUserType,
      storeDeclarationFormData,
      formDataList,
      siteId,
      studyId,
    } = this.props;
    const documentId = navigation.getParam("documentId");
    const documentPages = this.getDocuments();
    let hasSigns = true;
    if (!_.has(documents, documentId)) {
      hasSigns = false;
    }

    return (
      <CounterSignDocument
        currentUserType={currentUserType}
        selectedDocumentSignatureId={selectedDocumentSignatureId}
        closePopup={this.closePopup}
        showDialog={showDialog && isSessionActive}
        selectedSubject={selectedSubject}
        selectedDocument={selectedDocument}
        navigation={navigation}
        documentID={documentId}
        navigateToSign={this.navigateToSign}
        userName={userName}
        hasSigns={hasSigns}
        documentPages={documentPages}
        constructSelectedDocument={this.constructSelectedDocument}
        documents={documents}
        selectedDocSignDeclaration={selectedDocSignDeclaration}
        t={t}
        selectedUser={selectedUser}
        declarationFormFields={declarationFormFields}
        docId={docId}
        docSignId={docSignId}
        storeDeclarationFormData={storeDeclarationFormData}
        formDataList={formDataList}
        documentLoading={documentLoading}
        subjectDocumentSignatureId={subjectDocumentSignatureId}
        siteId={siteId}
        studyId={studyId}
      />
    );
    // } else {
    //   return <View />;
    // }
  }
}

const mapStateToProps = (state) => ({
  documents: state.documents.documents,
  subject: state.subject,
  selectedSubject: state.selectedSubject,
  userName: state.clientUser.selectedUser.fullName,
  selectedUser: state.clientUser.selectedUser,
  currentUserType: state.consentFlow.currentUserType,
  formDataList: state.documents.formDataList,
  consentVersionsMetadata: state.documents.consentVersionsMetadata,
  siteId: state.session.site,
  studyId: state.session.study,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeDeclarationFormData,
      getConsentVersionMetaData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounterSignDocumentScreen);
