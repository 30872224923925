import _ from 'lodash';

export const disableTap = (subjectDocumentSignatures, documentSignatureId) => {
    const docsWithSign = subjectDocumentSignatures.filter(sds => sds.id === documentSignatureId);
    return !_.isEmpty(docsWithSign.signatureFilePath);
}

export const buildCounterDocumentSignatures = (documentSignatures) => {
    let dss = [...documentSignatures];
    _.remove(dss, (ds) => ds.isDeleted === true);
    const indexOfLar = _.findIndex(dss, ['signatureType', 2]);
    const indexOfSubSig = _.findIndex(dss, ['signatureType', 1]);
    if (indexOfLar !== -1 && indexOfSubSig !== -1) {
        const larSignatureFilePath = dss[indexOfLar].signatureFilePath;
        const subjSignatureFilePath = dss[indexOfSubSig].signatureFilePath;
        if (larSignatureFilePath !== null) {
            _.remove(dss, (ds) => ds.signatureType == 1);
            return dss;
        } else if (subjSignatureFilePath !== null) {
            _.remove(dss, (ds) => ds.signatureType == 2);
            return dss;
        } else {
            return dss;  
        }
    } else {
       return dss;
    }
}

export const hasLarSigned = (documentSignatures) => {
    const indexOfLar = _.findIndex(documentSignatures, ['signatureType', 2]);
    if(indexOfLar !== -1) {
        const larSignatureFilePath = documentSignatures[indexOfLar].signatureFilePath;
        if(larSignatureFilePath !== null) {
            return true;
        } return false;
    } return false
}
