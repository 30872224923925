import { UPDATE_VISITED_DOCUMENT_STATUS, INITIALIZE_DOCUMENTS, CLEAR_DOCUMENT_CHAPTER } from '../actions/documentChapter';
import initialState from '../store/initialState';
import _ from 'lodash';

export default (state = initialState.documentChapter, action) => {
    switch (action.type) {
        case UPDATE_VISITED_DOCUMENT_STATUS:            
            return {
                ...state,
                [action.documentId]: true,
            };
        case INITIALIZE_DOCUMENTS:
            let dc = {};
            if(_.isEmpty(state)) {
                _.forEach(action.documentIds, documentId => {
                    dc = {
                        ...dc ,
                        [documentId]: false,
                    };
                })
            } else {
                dc = {
                    ...state,
                };
            }
            return {
            ...dc,
            };
        case CLEAR_DOCUMENT_CHAPTER: 
        return {

        };  
        default:
            return state;
    }

};

