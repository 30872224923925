
import moment from 'moment-timezone';

export const formatDateTime = (actualDate) => {
  if (actualDate ) {
    var timeZone = moment.tz.guess();
    return moment.tz(actualDate, timeZone).format("DD-MMM-YYYY hh:mm A z").toUpperCase();
  }
  return null;
};

export const formatDate = (actualDate, orginTimezone) => {
  if (actualDate ) {
    var timeZone = moment.tz.guess();
    if(orginTimezone != null && orginTimezone != '' && orginTimezone != timeZone) {      
      return moment.tz(actualDate, orginTimezone).tz(timeZone).format("DD-MMM-YYYY").toUpperCase();
    } else {
      return moment(actualDate).format("DD-MMM-YYYY").toUpperCase();
    }
  }
  return null;
};

