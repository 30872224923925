import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {StyleSheet} from 'react-native';
import { Colors } from '../../../constants/ui/colorScheme';
export const styles = StyleSheet.create({
    container: {
        justifyContent: 'space-between',
        backgroundColor: Colors.background,
    },
    text: {
        fontWeight: "bold",
        color: Colors.textColor,
    },
    textNote : {
        fontSize: wp('4%'), 
        paddingBottom: hp('3%'), 
        padding: wp('2%'),
        alignSelf:'center',
        alignContent:'center',
        alignItems:'center',
        color: Colors.coloredText,
    },
});

