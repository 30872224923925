import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KnowledgeReview from '../components/knowledgeReview/KnowledgeReview';
import {getConsentChapter} from '../utils/consentData';
import {storeQuizAttemptData} from '../actions/quizAttempts';
import {ConsentDataContext} from '../providers/ConsentDataProvider';

class KnowledgeReviewScreen extends Component {
    static contextType = ConsentDataContext;


    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
       const { navigation, quizAttempts, storeQuizAttemptData, 
        isRegistered, subjectData, screenProps,isKnowledgeReviewDone  } = this.props;
       const knowledgeReviewChapter = getConsentChapter(this.context.languages, subjectData.language, 'KNOWLEDGE_REVIEW')
        return (
            <KnowledgeReview 
            isKnowledgeReviewDone={subjectData.isKnowledgeReviewDone}
            // onNextChapter={this.onNextChapter}
            subjectData={subjectData}
            isRegistered={isRegistered}
            navigation={navigation}
            quizAttempts={quizAttempts}
            storeQuizAttemptData={storeQuizAttemptData}
            knowledgeReview={knowledgeReviewChapter}
            screenProps={screenProps}
            />
        );
    }
}
const mapStateToProps = state => ({
    quizAttempts: state.subject,
    isRegistered: state.subject.isRegistered,
    subjectData: state.subject,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeQuizAttemptData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeReviewScreen);