import { ActivityIndicator, View } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DocumentRightIcons, DocumentStatus, SubjectStatus } from '../../common/constants';
import DocumentLegendText from './DocumentLegendText';
import Documents from './Documents';
import VersionWiseDocuments from './VersionWiseDocuments';
import { Colors } from '../../../constants/ui/colorScheme';
import privilege from '../../constants/privilege';

const SubjectDocumentDetails = (props) => {
  const { selectedSubject, documents, loading } = useSelector(state => state);
  const { commentsResolved, showPrintIcon, showPrintAndSignDocuments, checkIfSignaturesOfADocisDone, onDocumentSelected } = props;


  if(loading){
    return (
      <View>
         <ActivityIndicator
              size="large"
              color={Colors.ActivityIndicator}
              animating={true}
              hidesWhenStopped={true}
        />
      </View>
    )
  }

  //Hiding document if it has no signatures are done when SubjectStatus is Consented or Discontinued
  const getDocumentsOfAltestOneSignDone = () => {
    const filteredDocs = {};
    if (selectedSubject.status == SubjectStatus.CONSENTED || selectedSubject.status == SubjectStatus.DISCONTINUED) {
      _.forEach(documents.documents, (doc, key) => {
        const isSignExist =
          _.filter(doc, (sign) => sign.signatureFilePath)?.length > 0;
        if (isSignExist) {
          filteredDocs[key] = doc;
        }
      });
      return filteredDocs;
    }
    return documents.documents;
  }

  //Document's right side icons based on completion of document's signatures
  const getRightSideChildren = (doc, key) => {
    let list = [];
    if (commentsResolved && showPrintIcon && !doc.isSignatureCompleted && selectedSubject.status != SubjectStatus.CONSENTED) {
      list.push({
        type: DocumentRightIcons.PRINT_AND_SIGN,
        onClick: () => showPrintAndSignDocuments(
            true,
            DocumentRightIcons.PRINT_AND_SIGN,
            key
          ),
        icon: {},
        privilege: privilege.VIEW_SUBJECT,
      });
    }
    if (commentsResolved && doc.blankPdfUrl && selectedSubject.status != SubjectStatus.CONSENTED) {
      list.push({
        type: DocumentRightIcons.VERIFY_PRINT_AND_SIGN,
        onClick: () => showPrintAndSignDocuments(
            true,
            doc.isSignatureCompleted
              ? DocumentRightIcons.UNVERIFY_PRINT_AND_SIGN
              : DocumentRightIcons.VERIFY_PRINT_AND_SIGN,
            key
          ),
        icon: {},
        privilege: privilege.VIEW_SUBJECT,
      });
    }
    const loIcon = checkIfSignaturesOfADocisDone(key);
    if (selectedSubject.status === SubjectStatus.CONSENTED || selectedSubject.status === SubjectStatus.DISCONTINUED ) {
      if (doc.isSignatureCompleted || _.isEqual(loIcon.name, "checkcircle")) {
        //For Print and Sign consented document checking doc.isSignatureCompleted
        //For Other category, checking signature are completed: checkcircle
        list.push({
          type: DocumentRightIcons.CONSENTED_OR_DISCONTINUED,
          onClick: () => onDocumentSelected(key),
          icon: {},
          documentStatus: DocumentStatus.FULLY_SIGNED,
          privilege: privilege.VIEW_ENCRYPTED_DATA,
        });
      } else {
        //when required signatures are not yet done for the document
        list.push({
          type: DocumentRightIcons.ARE_SIGNATURES_DONE,
          onClick: () => null,
          icon: { ...loIcon },
          documentStatus: DocumentStatus.NOT_FULLY_SIGNED,
          privilege: privilege.VIEW_SUBJECT,
        });
      }
    } else if (!doc.isSignatureCompleted) {
      list.push({
        type: DocumentRightIcons.ARE_SIGNATURES_DONE,
        onClick: () => null,
        icon: { ...loIcon },
        privilege: privilege.VIEW_SUBJECT,
      });
    }
    return list;
  };

  let documentList = [];
  const filtered = getDocumentsOfAltestOneSignDone();
  const keys = Object.keys(filtered || {});
  keys.map((key) => {
    let obj = {
      documentName: "",
      documentId: "",
      documentSignatureId: "",
      ordinal: "",
      consentVersionNumber: "",
      consentVersionOrdinal: "",
      documentVersion: "",
      showVerifyIcon: false,
      isSignatureCompleted: false,
    };
    let req = filtered[key][0];
    // console.log(Array.isArray(req));
    obj.documentName = req.documentName;
    obj.documentId = key;
    obj.documentSignatureId = req.documentSignatureId;
    obj.ordinal = req.ordinal;
    obj.consentVersionNumber = req.consentVersionNumber;
    obj.consentVersionOrdinal = -req.consentVersionOrdinal;
    obj.documentVersion = req.documentVersion;
    obj.showVerifyIcon = req.blankPdfUrl ? true : false;
    obj.isSignatureCompleted = req.isSignatureCompleted;
    obj.actionList = getRightSideChildren(req, key);
    documentList.push(obj);
  });
  documentList = _.sortBy(documentList, ["consentVersionOrdinal", "ordinal"]);

  return (
    <View>
      {/* -----------------------Document's Legend Text -------------------------- */}
      {!_.isEmpty(documentList) && (<DocumentLegendText />)}
      {/* 
        Showing all version documents when subject's status is either consented or discontinued
        Else showing current version documents (Eg: when subject's status is needs_signature or needs_counter_signature)
      */}
      {selectedSubject.status == SubjectStatus.CONSENTED || selectedSubject.status == SubjectStatus.DISCONTINUED ? 
        (<VersionWiseDocuments documentList={documentList} onDocumentSelected={onDocumentSelected}/>)
        : (<Documents documentList={documentList} onDocumentSelected={onDocumentSelected}/>)
      }
    </View>
  )
}
export default SubjectDocumentDetails