import createActionType from "../utils/action";
// import api from '../utils/api';

export const STORE_SUBJECT_REMOTE_CONSENT_DTO = createActionType(
  "STORE_SUBJECT_REMOTE_CONSENT_DTO"
);

export const storeSubjectRemoteConsentDto = (subjectRemoteConsentDto, otp) => ({
  type: STORE_SUBJECT_REMOTE_CONSENT_DTO,
  subjectRemoteConsentDto,
  otp,
});
