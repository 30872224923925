export default {
  WhatToExpect: "Qué esperar",
  WhatToDescription:
    "Esta aplicación puede ayudarle a entender mejor el estudio, para que pueda decidir si desea participar.",
  WhatToFooter:
    "Recibirá una copia del formulario de consentimiento informado firmado y fechado al final del proceso de consentimiento informado.",
  NextChapter: "SIGUIENTE CAPÍTULO",
  Next: "SIGUIENTE",
  PreviousChapter: "CAPÍTULO ANTERIOR",
  Previous: "ANTERIOR",
  Continue: "CONTINUAR",
  Help: "Ayuda",
  Close: "CERRAR",
  Hint: "SUGERENCIA",
  HintText:
    "Vea la parte del consentimiento informado a la que se refiere esta pregunta y responda de nuevo.",
  Question: "Pregunta",
  Submit: "ENVIAR",
  Congratulations: "¡Felicitaciones!",
  Cong1:
    "Ha completado la revisión de conocimientos. Si tiene alguna pregunta antes de decidir si quiere participar, pregunte al investigador o al personal del estudio antes de continuar al proceso de firma.",
  Cong2:
    "Si desea participar en el estudio, pulse el botón Continuar abajo para iniciar el proceso de firma.",
  Cong3:
    'Si ha decidido no participar en el estudio, pulse el botón "Detener mi participación" a continuación.',
  StopMyPart: "DETENER MI PARTICIPACIÓN",
  WhoInfC: "¿Quién firma este consentimiento informado?",
  SubjInfc: "Firmaré el consentimiento informado.",
  LarInf:
    "Mi representante legalmente autorizado firmará el consentimiento informado.",
  SignedBy: "Firmado por",
  SignedOn: "Firmado el",
  TapToSign: "TOQUE PARA FIRMAR",
  SignWarning: "Firme para continuar",
  OK: "OK",
  Clear: "BORRAR",
  Cancel: "CANCELAR",
  ThankYou:
    "Gracias por firmar este consentimiento electrónico. Cuando se obtengan todas las firmas, recibirá una copia de su Formulario de consentimiento informado firmado. Si tiene alguna pregunta, comuníquese con su sitio.",
  ReturnTab:
    "Devuelva el dispositivo al coordinador o al investigador del estudio.",
  DocsToSign: "Documentos para firmar",
  DocsList: "Lista de documentos",
  EndPart: "¿Terminar su participación en el estudio?",
  SignHere: "Firme aquí",
  SelectWarn: "Seleccione al menos una opción. ",
  FirstNameWarn: "El nombre no puede estar vacío.",
  LastNameWarn: "El apellido no puede estar vacío.",
  DOBWarn: "La fecha de nacimiento no puede estar vacía.",
  DoneSigning: "LISTO",
  Register: "INSCRIBIRSE",
  SelectDate: "Seleccionar fecha",
  DidNotSignConsent: "No firmó el consentimiento",
  ConsentSigned: "Consentimiento firmado",
  SubjectSignature: "Firma del sujeto",
  GoBackToDoc: "REGRESAR AL DOCUMENTO",
  GenderWarn: "El sexo no puede estar vacío.",
  PhoneNoWarn: "El número de teléfono no puede estar vacío.",
  RegPlacHolder: "Toque aquí para escribir",
  StopPartFailed: "Algo salió mal. Inténtelo de nuevo.",
  LARsign: "Firma del representante legalmente autorizado (RLA)",
  LARName: "Nombre completo del representante legalmente autorizado",
  LARRelation:
    "Descripción/relación del tutor legal o representante legalmente autorizado",
  TypeLARName: "Toque aquí para escribir el nombre",
  TypeLARRelation: "Toque aquí para escribir la relación",
  FillWarn: "Rellene este campo.",
  EnterHere: "Ingrese aquí",
  RegistrationForm: "Formulario de inscripción",
  SignatureChapter: "Firma",
  AreYouSure: "¿Está seguro?",
  Confirm: "Confirmar",
  PlaceHolderText: "Toque aquí para escribir",
  SaveFail: "No se ha guardado.",
  ValidPhn: "Introduzca un número de teléfono válido.",
  NoData: "No hay contenido.",
  SelectOption: "Seleccione al menos una opción.",
  DocFieldWarning: "Por favor, rellene todos los campos obligatorios.",
  PleaseScroll:
    "Desplácese hacia abajo y lea el documento completamente antes de continuar.",
  IRBApprovedDate: "Fecha de aprobación de la IRB",
  Version: "Versión",
  SessionExpired: "La sesión expiró. Inicie sesión nuevamente.",
  ConsentSubmitSuccess: "El consentimiento se envió correctamente.",
  ConsentSubmitFail: "El envío del consentimiento falló. Inténtelo de nuevo",
  NetworkErr: " Error de red.",
  Username: "Nombre de usuario",
  Password: "Contraseña",
  EnterPassword: "Introducir la contraseña",
  EnterUsername: "Introduzca su nombre de usuario",
  InvalidUsrName: "Usuario o contraseña invalido.",
  UserLocked: "Usuario bloqueado.",
  SomethingWentWrong: "Algo salió mal.",
  SubjAuthorization: "Autorización del sujeto",
  LarAuthorization: "Autorización LAR",
  WitnsAuthorization: "Autorización de testigos",
  SubjIniWarn: "Por favor, introduzca sus iniciales aquí.",
  Subdec: "Por favor, escriba su firma en el espacio de abajo.",
  Initials: "Iniciales",
  RadioInitialsVal:
    "Seleccione una opción e ingrese sus iniciales junto a la opción que ha seleccionado.",
  Skip: "saltarse.",
  SkipFail: "No se pudo omitir.",
  DateOfBirth: "Ingrese fecha de nacimiento",
  WitnessSign: "Firma de testigo",
  SubjectList: "Lista de materias",
  CancelFlow:
    "Gracias, devuelva el dispositivo al representante de la clínica.",
  OfficeThankYou:
    "Gracias, devuelva el dispositivo al representante de la clínica para finalizar el consentimiento.",
  ReturnToSiteStaff:
    "Para volver a la lista de temas, confirme que es el médico y haga clic en el botón Volver a la lista de temas",
  CommentReview: "Comentario Revisión",
  CommentsPending:
    "Tienes comentarios pendientes registrados en nuestro sistema. El personal del sitio ha sido notificado y se comunicará con ellos para analizar sus preguntas o puede contactarlos en ",
  ConsentIncomplete:
    "Su consentimiento no se puede completar hasta que se resuelvan todos los comentarios. Recibirá un correo electrónico cuando pueda continuar con el proceso de consentimiento.",
  AllCommentsAddressed: "Todos los comentarios han sido tratados.",
  ContinueWithSubject:
    "Entregue el dispositivo al sujeto para continuar con el proceso de consentimiento informado.",
  Comments: "Comentarios",
  CommentsEmailSent:
    "Se ha enviado un correo electrónico al participante, comuníquese con él para completar el Consentimiento informado.",
  AllCommentsMarkedResolved:
    "Todos sus comentarios han sido marcados como resueltos.",
  AnyQuestionsText:
    "Si tiene alguna pregunta antes de decidir si desea participar, consulte al investigador o al personal del estudio antes de continuar con el proceso de firma.",
  ContinueParticipation:
    "Si desea participar en el estudio, toque el botón Continuar a continuación para comenzar el proceso de firma.",
  WrongAnswer: "La respuesta que seleccionó es incorrecta, intente nuevamente",
  PrintNSign:
    "Ha seleccionado imprimir y firmar. Este documento se imprimirá con el espacio para las firmas manuscritas. Asegúrese de que el participante haya revisado el contenido del consentimiento electrónico y haya tenido la oportunidad de discutir cualquier pregunta.",
  VerifyPrintNSign:
    'Ha seleccionado verificar que el consentimiento ha sido firmado fuera del sistema electrónico. Presente la copia impresa firmada y asegúrese de que el participante reciba una copia.Una vez que haya seleccionado "Enviar consentimiento", esto no se puede deshacer.',
  PrintNSignFlow: "Este estudio requiere firma manual.",
  UnverifyPrintNSign:
    'Ha seleccionado anular la verificación de este documento. Cuando haga clic en Aceptar, el documento no se verificará y podrá imprimirlo. Podrá volver a verificar el documento. Todos los documentos deben ser verificados para enviar el consentimiento. Una vez que haya seleccionado el botón "enviar consentimiento", este campo no se podrá cambiar, el documento permanecerá verificado.',
  RemotePrintNSignError:
    "Este sitio requiere firmas manuales en el Consentimiento informado, no puede enviar el consentimiento por correo electrónico.",
  KnowledgeReviewCompletion: "Ha completado la Revisión de conocimientos.",
  InOfficeCommentsPending:
    "Tiene comentarios pendientes registrados en nuestro sistema. Devuelva el dispositivo al personal del sitio para analizar y resolver los problemas pendientes.",
  CounterSignRequiredSignaturesPending:
    "Todos los firmantes requeridos aún no han firmado este documento. Regrese a esta pantalla una vez que se hayan recopilado todas las firmas requeridas.",
  Logout: "CERRAR SESIÓN",
  StopConsentPopup:
    "¿Está seguro de que desea detener el consentimiento y cerrar la sesión del médico en el sistema? Será necesario iniciar sesión para que pueda continuar con el consentimiento.",
  Yes: "Sí",
  No: "No",
  ChangingDataLabel: "Estás cambiando los siguientes datos:",
  ReasonForChangeLabel: "Por favor, ingresa el motivo del cambio",
  ReasonForChangeWarning: "Se requiere el motivo del cambio.",
  SaveChanges: "GUARDAR CAMBIOS",
  ReasonForChangeNote:
    "Nota: El motivo del cambio introducido se aplicará a todos los cambios. Si desea introducir diferentes motivos para el cambio, haga clic en la X para cerrar esta ventana y realizar los cambios en transacciones separadas. No incluya datos protegidos en el campo Motivo del cambio.",
  ReasonForChangeDetails: "Detalles del motivo del cambio",
  ReasonForChange: "Motivo del cambio",
  Verify: "VERIFICAR",
  UserId: "ID de usuario",
  EnterUserId: "Introduzca el ID de usuario",
  ClinicianAuthorization: "Autorización del médico",
  SubmitConsent: "Enviar consentimiento",
  AccessNotPermitted: "Acceso no permitido",
  ApologyForNotPermit:
    "Lo sentimos, pero su función actual no permite el acceso a esta aplicación.",
  ContactAdminstrator:
    "За помощью, пожалуйста, обращайтесь к руководителю проекта вашего исследования». ...",
  UnAuthorizedMessage: "No está autorizado para ver esta página.",
  ProccedFail: 'No se pudo proceder.',
  Comment: "Comentario",
  Chapter: "Capítulo",
  Response: "Respuesta",
  Save: "Ahorrar",
  AddResponse: "Agregar respuesta",
  AddComment: "Agregar comentario",
  ResolveComment: "Resolver comentario",
  UnresolveComment: "Comentario sin resolver",
  SomeThingWentWrong: "ALGO SALIÓ MAL",
  PleaseLoginAgain: "Por favor, inicie sesión de nuevo",
  ClickHereToLogin: "HAGA CLIC AQUÍ PARA INICIAR SESIÓN",
};
