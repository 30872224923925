import React, { Component } from 'react';
import { View, Item, Input } from 'native-base';
import SingleSelectItem from './SingleSelect';

export default class SingleSelectDecorator extends Component{
    render() {
      const { form, field, attempts, t, handleWrongIcon, showWrongIcon } = this.props;
      return (
        <View >
          {form.getFieldDecorator(`${field.id}.value`, {
          rules: [
            { required: true, message: t('SelectWarn') },
          ],
        })(
          <SingleSelectItem
            autoFocus
            form ={form}
            field = {field}
            attempts={attempts}
            error={form.getFieldError(`${field.id}.value`)}
            handleWrongIcon={handleWrongIcon}
            showWrongIcon={showWrongIcon}
          />
        )}
        {form.getFieldDecorator(`${field.id}.attempts`)}
        </View>
      )
    }
}
