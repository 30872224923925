import React, { Component } from 'react';
import KnowledgeReview from '../components/knowledgeReview/KnowledgeReview';
import PickSignature from '../components/larSignature/PickSignatureType';
import { getConsentChapter } from '../utils/consentData';
import { storeLARData } from '../actions/larForm';
import { storeSignatureType } from '../actions/subject';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {ConsentDataContext} from '../providers/ConsentDataProvider';


class LARSignatureScreen extends Component {
    static contextType = ConsentDataContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static navigationOptions = ({ navigation }) => ({
        // title: "....."
    });

    render() {
        const { subjectData, screenProps , storeSignatureType
             
        } = this.props;
        const signature = getConsentChapter(this.context.languages,subjectData.language, 'SIGNATURE');
        // const isAskForLAR  = signature.askForLAR;
        const { navigation, storeLARData } = this.props;
        return (
            <PickSignature
                storeSignatureType={storeSignatureType}
                screenProps={screenProps}
                navigation={navigation}
                documentSignatures={signature}
                storeLARData = {storeLARData}
            />
        );
    }
}

const mapStateToProps = state => ({
    subjectData: state.subject,
        signatureType: state.subject.storeSignatureType,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeLARData,
        storeSignatureType
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(LARSignatureScreen);
