import ConsentSignature from '../components/signatureScreen/SignatureScreen';
import React, { Component } from "react";
import { Icon } from 'native-base';
import { getConsentChapter } from '../utils/consentData';
import { getDocumentSignature, getFilteredDocumentSignatures } from '../utils/consentData'
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSignatureData } from '../actions/subjectSignatureData';
import { disableTap } from '../utils/signatureUtils';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import {incDocSignPosition} from '../actions/update'
import _ from 'lodash';
import { storeDeclarationFormData } from '../actions/documents';
import { SIGNATURE_TYPE } from '../components/common/constants';


class ConsentSignatureScreen extends Component {
    static contextType = ConsentDataContext;

    constructor(props) {
        super(props);
        this.state = {
            sign: null,
            signedBy: ''
        };
    };
    // static navigationOptions = ({ navigation }) => ({
    //     // title: "Signature",
    //     headerRight: (
    //         <Icon name='menu' style={{
    //             fontSize: 27, color: 'white', padding: 10
    //         }} />
    //     ),
    // });

    componentDidMount () {
        const { currentUserType, selectedSubject } = this.props
        if(currentUserType === 2) {
            this.setState({
                signedBy: selectedSubject.larFirstName+ ' ' +selectedSubject.larLastName,
            })
        } else if (currentUserType === 4) {
            this.setState({
                signedBy: selectedSubject.witnessFirstName+ ' ' +selectedSubject.witnessLastName
            })
        } else if (currentUserType === 1) {
            this.setState({
                signedBy: selectedSubject.firstName+ ' ' +selectedSubject.lastName
            })
        }
    }



    captureSignature = (sign) => {
        const { storeSignatureData, larDetails, navigation, subjectConsentDocuments, currentSubDocPosition, incDocSignPosition, selectedSubject, currentUserType } = this.props;
        const { signedBy } = this.state;
        const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');
        const documentSignatureId = navigation.getParam('documentSignatureId');
        const signType = navigation.getParam('signType');
        const documentPageId = navigation.getParam('documentPageId');
        const fileName = `ConsentSignature${moment().format('DDMMMYYYYHHMM')}.png`;
        var documentSignature = {
            id: subjectDocumentSignatureId,
            signedBy: signedBy,
            fileName: fileName,
            signatureDate: moment(),
            signature: sign,
            signatureType: currentUserType,
            documentPageId: documentPageId,
            documentSignatureId,
        }
        storeSignatureData(documentSignature);
        const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        
        if( currentSubDocPosition < subjectDocumentSignatures.length ){
            incDocSignPosition();
        }
        navigation.navigate('SubDocsPage', {signatureType: signType });
    };

    getSignatureHeader = (signatureType) => {
        const { screenProps } = this.props;
        switch (signatureType) {
            case 2:
                return screenProps.t('LARsign');
            case 4:
                return screenProps.t('WitnessSign');
            default:
                break;
        }
    }

    onback = ()=> {
        const { navigation } = this.props; 
        const signType = navigation.getParam('signType');
        navigation.navigate('SubDocsPage', {signatureType: signType });
    }

    render() {
        const {  navigation, larDetails, subject, screenProps, currentUserType, subjectConsentDocuments, selectedSubject, storeDeclarationFormData, formDataList } = this.props;
        // const subjectDocumentSignatures = subject.subjectDocumentSignatures;
        const { signedBy } = this.state;
        const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');
        const larDeclaration = navigation.getParam('larDeclaration');     
        const signature = getConsentChapter(this.context.languages, subject.language, "SIGNATURE");
        const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        const subjectDocumentSignature = _.filter(subjectDocumentSignatures, sd => _.isEqual(sd.id, subjectDocumentSignatureId))[0];
        const disableTapToSign = disableTap(subjectDocumentSignatures, subjectDocumentSignatureId);
        // getDocumentSignature(signature, documentSignatureId);
        let declarationFormFields =_.find(signature.documentSignatures, { 'documentId': subjectDocumentSignature?.documentId, 'signatureType': currentUserType });

        return (
            <ConsentSignature
            screenProps={screenProps}
            disableTapToSign={disableTapToSign}
                navigation={navigation}
                declarationFormFields={declarationFormFields?.formFields}
                documentId = {declarationFormFields?.documentId}
                documentSignatureId = {declarationFormFields?.id}
                declaration={larDeclaration}
                captureSignature={this.captureSignature}
                signedBy={signedBy}
                isWitness={false}
                isRemoteConsent={subject.isRemoteConsent}
                heading={this.getSignatureHeader(subjectDocumentSignature?.documentSignature?.signatureType)}
                onback={this.onback}
                storeDeclarationFormData={storeDeclarationFormData}
                formDataList={formDataList}
                selectedSubject={selectedSubject}
                currentSignatureType={SIGNATURE_TYPE.LAR}
            />
        );
    }
}
const mapStateToProps = state => ({
    subject: state.subject,
    larDetails: state.subject.larDetails,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    currentSubDocPosition: state.currentSubDocPosition,
    currentUserType: state.consentFlow.currentUserType,
    selectedSubject: state.selectedSubject,
    formDataList: state.documents.formDataList

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSignatureData,
        incDocSignPosition,
        storeDeclarationFormData

    },
    dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(ConsentSignatureScreen);
