import CaptureDeviceSNO from '../components/deviceNumber/CaptureDeviceSNO';
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveDeviceSNo } from "../actions/consentToken";
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import api from '../utils/api';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import Loading from '../components/LoadingScreen';
import showToast from '../utils/toast';
import DownloadVideosAndStoreData from '../components/DownloadVideosAndStoreData';


class CaptureDeviceSNoPage extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            isStoringData: true,
         }
     }

     componentDidMount = () => {
        //   this.storeLanguage();
     }

     //<p> This method is used to store  metadata locally
    //  storeLanguage = async() => {
    //     const { navigation } = this.props;
    //     const studySiteId = navigation.getParam('studySiteId');
    //     try {
    //         const res= await api.post(`/getMetaData/${studySiteId}`);
    //         if(res.data) {
    //             const data = JSON.stringify(res.data)
    //             this.context.saveLanguages(data);
    //             this.setState({isStoringData:false});
    //         }
    //     } catch (error) {
    //         showToast('Failed to Install data.', 'danger', 2000);
    //         navigation.navigate('StudySiteAccessScreen');
    //         console.log(error);
    //     }
    //  }


    render() {
        const { navigation, loading, saveDeviceSNo } = this.props;
        const studySiteId = navigation.getParam('studySiteId');
        // const {isStoringData} = this.state;
        // if(isStoringData){
        //     return (
        //       <View style={{ flex: 1 }}>
        //         <DownloadVideosAndStoreData  
        //             studySiteId = {studySiteId}
        //             navigation = {navigation}    
        //         />
        //       </View>
        //     );
        // }
        return (
           <CaptureDeviceSNO
            navigation={navigation}
            loading = {loading}
            saveDeviceSNo={saveDeviceSNo}
            />
        );
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        saveDeviceSNo,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CaptureDeviceSNoPage);