import React, { Component } from 'react';
import { Text, Dimensions, ScrollView, View } from 'react-native';
import { createForm } from "rc-form";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import HtmlParserEg from '../htmlView/htmlparserEg';
import _ from 'lodash';
import { createFormFieldsWithValues, buildFormDataList } from './formFieldUtils';
import RadioFieldDecorator from './RadioFieldDecorator';
import showToast from '../../utils/toast';

let { height } = Dimensions.get('window');
height = height - hp('30%');

class DeclarationFormFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: [],
            flatListProps: {
                scrolledKey: null,
                contentHeight: null,
                prevScreenHeight: null,
                focusInvalidField: false
            }
        };
        this.arr = [];
    }

    componentDidMount() {
        this.props.onRef(this)
        this.buildFormFields();
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    updateValidation = () => {
        const {fromPopup, form, storeDeclarationFormData, documentId, documentSignatureId, t, showValidationError } = this.props;
        const { formFields } = this.state;
        let isValid = false;
        this.setState({
            focusInvalidField: true
        });
        form.validateFields((error, values) => {
            if (error) {
                let show = true;
                if(fromPopup) {
                    show = false
                }
                isValid = false;
                if(show) showValidationError(t('DocFieldWarning'));
            } else {
                isValid = true
            }
        })
        return isValid
    }

    scrollToField = (key) => {
        const { setScrollRef } = this.props;
        const y = this.arr[key];
        // if (this.component) {
            this.setState({
                focusInvalidField: false
            });
            setScrollRef(y);
        // }
    }

    buildFormFields = () => {
        const { declarationFormFields, documentId, documentSignatureId } = this.props;
        let { formDataList } = this.props
        formDataList = formDataList[documentSignatureId] || [];
        this.setState({
            formFields: createFormFieldsWithValues(declarationFormFields, formDataList)
        });
    }

    onValueChange = () => {
        const { form, documentSignatureId, documentId, storeDeclarationFormData } = this.props;
        const { formFields } = this.state;
        const values = form.getFieldsValue();
        const formDataList = buildFormDataList(formFields, values, documentId, documentSignatureId);
        if (formDataList) {
            storeDeclarationFormData(formDataList);
        }
    }

    renderFormField = (formField, fieldKey) => {
        const { form, location, t } = this.props;
        const { focusInvalidField } = this.state;
        switch (formField.fieldType) {
            case 'paragraph':
                if(formField.fieldName != "" && formField.fieldName != null) {
                    return  <View style={{paddingLeft: 10, paddingRight: 10}}>
                                <HtmlParserEg currentDoc={formField.fieldName}key={formField?.id}/>
                            </View>
                } else {
                    return null;
              
                }
               
            case 'radio':
                return (
                    <RadioFieldDecorator t={t}
                        onValueChange={this.onValueChange}
                        form={form} formField={formField}
                        location={location}
                        scrollToField={this.scrollToField}
                        fieldKey={fieldKey}
                        focusInvalidField={focusInvalidField}
                        key={formField?.id}
                    />
                );
        }
    }

    render() {
        const { formFields } = this.state;
       const ffields =  _.sortBy(formFields, ['ordinal']);

        return (
            <ScrollView>
                {_.isEmpty(ffields) ? <View><Text style={{ color: 'transparent' }}>.</Text></View>
                    : _.map(ffields, (formField, key) =>
                        <View
                            key={key}
                            onLayout={event => {
                                const layout = event.nativeEvent.layout;
                                this.arr[key] = layout.y;
                            }}
                        >
                            {this.renderFormField(formField, key)}
                        </View>
                    )}
            </ScrollView>
        );
    }
}

const DeclarationFormField = createForm()(DeclarationFormFields);
export default DeclarationFormField;

DeclarationFormField.defaultProps = {
    formDataList: {},
};

