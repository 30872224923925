import React from 'react';
import { IconType } from './constants';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons'
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';

const Icon = ({iconName, iconType, color, size, onClick, disabled}) => {
    switch (iconType) {
        case IconType.MATERIAL:
            return (
                <TouchableOpacity onPress={onClick} disabled={disabled}>
                    <MaterialIcons name={iconName} color={color} size={size} />
                </TouchableOpacity>
            )
        case IconType.MATERIAL_COMMUNITY:
            return (
                <TouchableOpacity onPress={onClick} disabled={disabled}>
                    <MaterialCommunityIcons name={iconName} color={color} size={size} />
                </TouchableOpacity>
            )
        default:
            return <></>;
    }
}
const TryalIcon = (props) => {
    return (
        <Icon
            {...props} 
        />
    )

}

TryalIcon.defaultProps = {
    color: Colors.buttonwithbg,
    size: 22,
    onClick: () => null,
    disabled: false,
}

TryalIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
    iconType: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    onClick: PropTypes.func
}
export default TryalIcon;
