import React, {Component} from 'react';
import { View, StyleSheet, Text } from 'react-native';
import {Item, Input} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font'
import { constStyles } from '../../../styles/constStyles';

export default class TextInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
    render() {
    const {error, value, onChange, t} = this.props;
        return (
            <View >
            <View style={{ flex: 1, marginRight: 10 }}  >
              <Item regular
              style={{ height:50, borderRadius:5, borderColor:Colors.borderColor, marginTop:10 }}
              >
               <Input 
                autoFocus
                placeholder={t('EnterHere')}
                placeholderTextColor={Colors.borderColor}
                // style={{borderRadius:5, borderColor:Colors.borderColor, borderWidth:0.5, fontFamily:'Inter'}}
                value = {value || ''}
                maxLength={500}
                style={[constStyles.inputStyles, {fontSize: '18px'}]}
               // value={this.state.text}
                onChangeText = {onChange}
               />
             </Item>
             <View style={styles.errorinfoText}>{this.getError(error)}</View>
         </View>
             </View>
          )
    }

}
const styles = StyleSheet.create({
  errorinfoText: {
    color: Colors.error,
    // fontFamily: 'WorkSans-Light',
    fontSize: fontType('subFont'), 
    marginTop: 5
  },
})