import React, { Component } from 'react';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import Dialog, { DialogContent, DialogFooter, DialogButton } from 'react-native-popup-dialog';
import { Colors } from '../../constants/ui/colorScheme';
import api from '../../utils/api';
import {fontType} from '../../styles/font';
import MyLoader from '../../utils/webLoader';
import {constStyles} from '../../styles/constStyles';
import showToast from '../../utils/toast';
import constants from '../../utils/constants';


class ConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      loading: false,
    };
  }

  handleDialogeVisibility = () => {
    this.setState(prevState => ({
      ...prevState,
      showDialog: !prevState.showDialog
    }));
  }

  submitSubjectData = async () => {
    const { subjectData, navigation, storeSubjectStatus, handleHeaderMenuDialoge, canShowHeaderDialogue,
      updateConsentStatus, studySiteId, handleConfirmationPopup, deviceNo, t } = this.props;
    this.setState({ loading: true })
    const subject = {
      status: 'DISCONTINUED',
      consentId: subjectData.consentId,
      studySite: {
        id: studySiteId
      },
      languageId: subjectData.languageId,
    }
    try {
      const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectData?.id}/abort`, {subject,deviceNo})
      this.setState({ loading: false })
      canShowHeaderDialogue ?  setTimeout(() => {
        handleHeaderMenuDialoge();
      }, 200) : null
      handleConfirmationPopup();
      updateConsentStatus(true);
      storeSubjectStatus('DISCONTINUED');
      navigation.navigate('CompletedScreen')
    }
    catch (error) {
      this.setState({ loading: false })
      if (error.response) {
        showToast(t('StopPartFailed'), 'danger', 4000);
      } else {
        showToast(t('NetworkErr'), 'danger', 3000);
      }
      console.log(error);
    }
  }

  render() {
    const { handleConfirmationPopup, confirmPopupVisible, t } = this.props;
    const { loading } = this.state
    return (
      
      <View>
        <MyLoader active={loading}/>
        <Dialog
          containerStyle={{ justifyContent: 'center' }}
          // dialogStyle={{ alignSelf:'center' }}
          rounded={true}
          visible={confirmPopupVisible}
        >
          <DialogContent>
            <View style={{flexDirection:'column',alignSelf:'center',alignItems:'center', padding:15 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'center', padding:20 }}>
                <Text style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter', color: Colors.iconColor, }}>{t('AreYouSure')}</Text>
              </View>
              <View style={{ flexDirection: 'row', }}>
                <TouchableOpacity onPress={() => handleConfirmationPopup()}
                  style={[constStyles.buttonStyle, {margin:10}]}
                  >
                  <Text 
                      style={constStyles.buttonTxt}
                  >
                    {t('Cancel')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={[constStyles.buttonStyle, {backgroundColor: Colors.errorButton,margin:10}]}
                  onPress={() => this.submitSubjectData()}
                >
                  <Text 
                  style={constStyles.buttonTxt}
                  >
                  {t('OK')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog>

      </View>
    );
  }
}
export default ConfirmationPopup;