import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, ScrollView, Text, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import Signt from 'react-native-vector-icons/FontAwesome5';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import _ from 'lodash';
import {styles} from './styles/consentOverViewStyles';
import { getLanguageData, getMinOrdinalChapter, checkChapterTypeAndNavigate  } from '../../utils/consentData';
import { Colors } from '../../constants/ui/colorScheme';
import {ConsentDataContext} from '../../providers/ConsentDataProvider';
import {fontType} from '../../styles/font'
import {constStyles} from '../../styles/constStyles'
import ChapterItem from '../chapter/chapterItem';
import { IconType } from '../common/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

 class ConsentOverview extends Component {
    static contextType = ConsentDataContext;

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            modalVisible: false,
        }
    }
    searchUpdated(term) {
        this.setState({ searchTerm: term })
    }

    renderIcons = (term) => {
        if (term === 'STUDY_VIDEO') {
            return <Icon
                name='videocamera'
                color= {Colors.consentIconColor}
                size={fontType('StatusIcon')}
            />
        }
        else if (term === 'ICF_DOCUMENTS') {
            return <Icon
                name='filetext1'
                color={Colors.consentIconColor}
                size={fontType('StatusIcon')}
            />
        }
        else if (term === 'KNOWLEDGE_REVIEW') {
            return <Icon
                name='questioncircleo'
                color={Colors.consentTextColor}
                size={fontType('StatusIcon')}
            />
        }
        else if (term === 'STUDY_INFORMATION') {
            return <Icon
                name='infocirlceo'
                color={Colors.consentTextColor}
                size={fontType('StatusIcon')}
            />
        }
        else if (term === 'REGISTRATION_FORM'){
            // return <Icon
            //     name='solution1'
            //     color={Colors.consentIconColor}
            //     size={fontType('StatusIcon')}
            // />
            return null;
        }
        else {
            return <Signt
                name='signature'
                color={Colors.consentIconColor}
                size={fontType('StatusIcon')}
            />
        }

    }
    

    filteredChapters = (chapters) => {
        const { currentUserType, selectedSubject: { isKnowledgeReviewDone }, navigation } = this.props;
        if(currentUserType == 1){
            return chapters;
        } else if (currentUserType == 2){
            if(!isKnowledgeReviewDone){
                return chapters;
            } else {
                return _.filter(chapters, cp => !_.isEqual(cp.chapterType, 'KNOWLEDGE_REVIEW'));
            }
        }
        return [];
    }

    render() {
        const {isRegistered,storeVisitedChapters, navigation, subjectData, screenProps: { t }} = this.props;
        const chapters = _.sortBy(getLanguageData(this.context.languages,subjectData.language), ['ordinal']);
        const languageData = this.filteredChapters(chapters);
        const minOrdinalChapter = getMinOrdinalChapter(languageData);
        // const filteredSearch = english.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
        return (
            <Container style={styles.container}>
                <View style={{ marginTop:3 ,paddingLeft:10}}>
                    <Text style={{ fontSize: fontType('Heading1'), fontFamily:'Inter', color: Colors.text , marginTop:12, marginBottom: 12}}><b>{t('WhatToExpect')}</b></Text>
                    <Text note style={{ fontSize: fontType('BasicFont'), fontFamily:'Inter',  paddingBottom:2, paddingRight:3,color:Colors.text }} >{t('WhatToDescription')}</Text>
                </View>
                <View style={{ paddingLeft: 10, flex:3, marginTop:10   }}>
                    <ScrollView>
                        {languageData.map(term => {
                            if(term.chapterType !== 'REGISTRATION_FORM'){
                            return (
                                <React.Fragment>
                                    {term.chapterType === 'SIGNATURE' && 
                                    <ChapterItem 
                                        item={{ chapterTitle: t('CommentReview'), description: ''}} 
                                        iconName='comment-check' 
                                        iconType={IconType.MATERIAL_COMMUNITY} 
                                        iconColor={Colors.consentIconColor}
                                        t = {t}
                                    />}
                                    <View style={{ flexDirection: 'row', padding: wp('1%'), }} >
                                        <View style={{ flex: 0.45, flexDirection: 'row',  paddingLeft:4, alignSelf: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                            {this.renderIcons(term.chapterType)}
                                        </View>
                                        <View style={{ flex: 3.55, flexDirection: 'column', paddingLeft: 22 }}>
                                            <Text style={{ fontSize: fontType('BasicFont'), fontWeight: 'normal', color: Colors.consentTextColor, fontFamily:'Inter' }}>
                                        {term.chapterType === 'SIGNATURE' ? t('SignatureChapter') : term.chapterTitle }  
                                            </Text>
                                            <Text note style={{ fontSize: fontType('subFont'), fontFamily:'Inter', color:Colors.consentTextColor }}>
                                                {term.description}
                                            </Text>
                                        </View>
                                    </View>
                                </React.Fragment>
                            )
                            }
                        })}
                    </ScrollView>
                    <Text style={{fontSize: fontType('BasicFont') ,paddingRight:4, fontFamily:'Inter', color:Colors.signDetails, paddingLeft: wp('0.5%'),padding:wp('0.2%') }}>{t('WhatToFooter')}</Text>
                </View>
                <View style={{flexDirection: 'column', justifyContent: 'center', backgroundColor: 'white' }}>
                    <View style={{ backgroundColor: 'white',  alignItems:'center', alignContent: 'center', alignSelf: 'center', padding:15 }}>
                        <TouchableOpacity onPress={() => { 
                            storeVisitedChapters(minOrdinalChapter?.ordinal); 
                            checkChapterTypeAndNavigate(minOrdinalChapter, navigation) 
                        }}
                            style={[constStyles.buttonStyle,]}
                        >
                          <Text style={[constStyles.buttonTxt, ]}>{t('Continue')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {/* Modal Window */}
            </Container>
        );
    }
}

ConsentOverview.propTypes = {
    isRegistered: PropTypes.bool,
    storeVisitedChapters: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired,
    subjectData: PropTypes.object.isRequired,
    screenProps: PropTypes.object.isRequired,
    currentUserType: PropTypes.number.isRequired,
    selectedSubject: PropTypes.shape({
        isKnowledgeReviewDone: PropTypes.bool,
    }),
};

ConsentOverview.defaultProps = {
    isRegistered: false,
    selectedSubject: {
        isKnowledgeReviewDone: false,
    },
};
const mapStateToProps = (state) => ({
    currentUserType: state.session.userType,
  });

  
export default connect(mapStateToProps, null)(ConsentOverview);