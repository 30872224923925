import React, {Component, useState} from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import _ from 'lodash';
import {Colors} from '../../constants/ui/colorScheme';
import styles from './styles/subjectDetailsStyles';
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogButton,
  DialogTitle,
} from 'react-native-popup-dialog';
import api from '../../utils/api';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';
import showToast from '../../utils/toast';
import {ListItem, CheckBox, Body} from 'native-base';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

class ChooseConsentDelivery extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isForSign: false,
      loading: false,
    };
  }

  render () {
    const { onSelectRemote, onSelectInOffice, closeChooseConsentDelivery, showChooseConsentDelivery } = this.props;
    // const { isForSign, loading } = this.state;
    return (
      <Dialog
        containerStyle={{justifyContent: 'center'}}
        // height={1}
        // width={Platform.OS !== 'web' && 1}
        rounded={false}
        visible={showChooseConsentDelivery}
        dialogTitle={
          <View style={{ backgroundColor: '#F7F7F7', flexDirection: 'row', justifyContent: 'space-between',  paddingVertical: 6, paddingHorizontal: 8 }}>
            <Text style={{ fontFamily: 'Inter', color: '#616161'}}>Choose Consent Delivery</Text>
            <TouchableOpacity onPress={closeChooseConsentDelivery}>
              <Icon
                name="close"
                color={'#616161'}
                size={fontType ('ChapterIcon')}
              />
            </TouchableOpacity>
          </View>
        }
      >
        <DialogContent style={Platform.OS!='web' ? {backgroundColor: '#fff',height:SCREEN_HEIGHT/4}: {backgroundColor: '#fff', flex: 1} }>
          {/* <View style={{ backgroundColor: 'yellow' }}>
            <Text>Choose Consent Delivery</Text>
          </View> */}
          <View style={{flexDirection: 'column', padding: 15}}>
            <View style={{padding: 15}}>
              <Text
                style={{
                  fontSize: fontType ('Heading'),
                  color: '#212121',
                  fontFamily: 'Inter',
                  alignSelf: 'center',
                  marginBottom: 10,
                  //   backgroundColor: 'cyan'
                }}
              >
                How will the consent be signed?
              </Text>
              <View style={{flexDirection:'column',height:'85%',borderWidth:2,borderColor:'grey',borderRadius:15}}>
            <TouchableOpacity style={{width:'100%',padding:10,flexDirection:'column'}}
            onPress={() =>{ closeChooseConsentDelivery();
                    setTimeout(() => {
                      onSelectRemote();   
                    }, 500);                    
                  }}>
              <View style={{flexDirection:'row'}}>
              <Icon
                      name="email"
                      color={'#616161'}
                      size={fontType ('ChapterIcon')}
                      style={{marginTop:2}}
                    />
                    <Text
                      style={{
                        fontSize: fontType ('Heading1'),
                        color: '#212121',
                        fontFamily: 'Inter',
                        //   backgroundColor: 'cyan'
                      }}
                    >
                      {' '}Email
                    </Text>
                    </View>
                    <View>
                    <Text
                    style={{
                      fontSize: fontType ('BasicFont'),
                      fontFamily: 'Inter',
                      color: '#757575',
                    }}
                  >
                    {' '}Subject will be emailed documents to sign
                  </Text>
                      </View>
              </TouchableOpacity>
              <View style={{width:'100%',padding:10,borderColor: '#616161',borderTopWidth:1}} >
              <TouchableOpacity onPress={() =>{ closeChooseConsentDelivery(); 
                    setTimeout(() => {
                      onSelectInOffice(); 
                    }, 500);
                  }} >
               <View style={{flexDirection:'row'}}>
              <Icon
                      name="office-building"
                      color={'#616161'}
                      size={fontType ('ChapterIcon')}
                    />
                    <Text
                      style={{
                        fontSize: fontType ('Heading1'),
                        color: '#212121',
                        fontFamily: 'Inter',
                        //   backgroundColor: 'cyan'
                      }}
                    >
                      {' '}In Office
                    </Text>
                    </View>
                    <View>
                    <Text
                    style={{
                      fontSize: fontType ('BasicFont'),
                      fontFamily: 'Inter',
                      color: '#757575',
                      //   marginTop: 5,
                    }}
                  >
                    {' '}Subject will sign in person
                  </Text>
                      </View>
                      </TouchableOpacity>
              </View>
              </View>
              {/* ----buttonss ------------ */}
              {/* <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop:50 }}>
                                <TouchableOpacity
                                    // onPress={() =>{ handleEmailConsentPopup(); this.setToggleCheckBox(false) }}
                                    style={[constStyles.buttonStyle, { backgroundColor: Colors.errorButton, marginHorizontal: 15 }]}
                                >
                                    <Text style={[constStyles.buttonTxt]}>CANCEL </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[constStyles.buttonStyle, { backgroundColor: '#6c98d4', marginHorizontal: 15 }]}
                                    onPress={this.onSendClick}
                                >
                                    <Text style={[constStyles.buttonTxt]}>SEND
                                        <MyLoader active={loading} />
                                    </Text>
                                </TouchableOpacity>
                            </View> */}
            </View>
          </View>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ChooseConsentDelivery;
