import createActionType from '../utils/action';
import _ from 'lodash';

export const UPDATE_VISITED_DOCUMENT_STATUS = createActionType('UPDATE_VISITED_DOCUMENT_STATUS');
export const INITIALIZE_DOCUMENTS = createActionType('INITIALIZE_DOCUMENTS');
export const CLEAR_DOCUMENT_CHAPTER = createActionType('CLEAR_DOCUMENT_CHAPTER');

export const updateVisitedDocumentStatus = (documentId) => ({
    type: UPDATE_VISITED_DOCUMENT_STATUS,
    documentId,
});

export const initializeDocuments = (documentIds) => ({
    type: INITIALIZE_DOCUMENTS,
    documentIds,
});

export const clearDocumentChapter = () => ({
    type: CLEAR_DOCUMENT_CHAPTER,
});