import React from 'react';
import { View, Text, Image } from 'react-native';
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import { Container, ListItem, List } from 'native-base';
import * as TouchableOpacity from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/AntDesign';
import Signt from 'react-native-vector-icons/FontAwesome5';
import {fontType} from '../../styles/font'

var j = 0;
var storedNavigations = [];
var completed = 0;
var k = 0;
export default ({ chapters, navigation, handleHeaderMenuDialoge, noOfchapters, signatureType, isRegistered, navigationMenus, visitedChapters,isDocSignsCompleted }) => {


    var redirectIcons = [];
    var ListofChapters = chapters;
    var arr = ["", "infocirlceo", "videocamera", "filetext1", "question", "solution1"];
   
//    if(isRegistered){
//         ListofChapters.map((chapter) => {
//             if (chapter.chapterType!="SIGNATURE" && chapter.chapterType!="KNOWLEDGE_REVIEW" && chapter.chapterType!="REGISTRATION_FORM" && navigation.state.routeName!="ConsentOverviewScreen" && navigation.state.routeName!="CompletedScreen") {
//                     redirectIcons.push({ chapterTitle: chapter.chapterTitle, ordinal: chapter.ordinal });
                
//             } else if(chapter.chapterType=="SIGNATURE" && navigation.state.routeName!="AskForLarPage" && chapter.chapterType!="REGISTRATION_FORM" && navigation.state.routeName!="ConsentOverviewScreen" && navigation.state.routeName!="CompletedScreen" && (signatureType==1 || signatureType==2) ){
//                 redirectIcons.push({ chapterTitle: chapter.chapterTitle, ordinal: chapter.ordinal }); 
//             } else if(chapter.chapterType=="SIGNATURE" && navigation.state.routeName === "SubDocsPage" && signatureType=="" ){
//                 redirectIcons.push({ chapterTitle: chapter.chapterTitle, ordinal: chapter.ordinal }); 
//             }
            
//         });
//         return showChapterPages(redirectIcons, navigation, handleHeaderMenuDialoge, noOfchapters, signatureType, arr);
    // } else {
    if(signatureType!=4 && !isDocSignsCompleted ){   
     ListofChapters.map((chapter) => {
            if (chapter.chapterType!="SIGNATURE" && chapter.ordinal <= visitedChapters && chapter.chapterType!="KNOWLEDGE_REVIEW" && chapter.chapterType!="REGISTRATION_FORM" && navigation.state.routeName!="ConsentOverviewScreen" && navigation.state.routeName!="CompletedScreen") {
                    redirectIcons.push({ chapterTitle: chapter.chapterTitle, ordinal: chapter.ordinal });
                
            } else if(chapter.ordinal <= visitedChapters && chapter.chapterType=="SIGNATURE" && chapter.chapterType!="KNOWLEDGE_REVIEW" && chapter.chapterType!="REGISTRATION_FORM" && navigation.state.routeName!="ConsentOverviewScreen" && navigation.state.routeName!="CompletedScreen" && navigation.state.routeName!="AskForLarPage" && signatureType!=""){
                redirectIcons.push({ chapterTitle: chapter.chapterTitle, ordinal: chapter.ordinal }); 
            }
            
        });
        return showChapterPages(redirectIcons, navigation, handleHeaderMenuDialoge, noOfchapters, signatureType, arr);
    } else {
        return (<View></View>);
    }
    // }
        function showChapterPages(redirectIcons, navigation, handleHeaderMenuDialoge, noOfchapters, signatureType, arr) {
            var chapterTitles = redirectIcons.map((chapter, index) => {
                if(chapter.chapterTitle != "Signature") {
                return (
                    <ListItem touchableHighlightStyle={{ borderRadius: 20 }} noBorder={index >= redirectIcons.length - 1 ? true : false} onPress={() => { handleNavigation(navigation, chapter.ordinal, handleHeaderMenuDialoge, noOfchapters, signatureType) }} >
                         <Icon
                                name={arr[chapter.ordinal]}
                                color={Colors.radioGroup}
                                size={fontType('ChapterIcon')}
                            />
                        <Text style={{ color: Colors.radioGroup, fontSize: 14, paddingTop: 5, paddingLeft: 20, fontFamily: 'Inter' }}>{chapter.chapterTitle}</Text>
                    </ListItem>
                )
                }
            })
            return chapterTitles;

        }


        function handleNavigation(navigation, ordinal, handleHeaderMenuDialoge, noOfchapters, signatureType) {
            var redirectPages = ["", "StudyInfoScreen", "StudyVideoListScreen", "DocumentsScreen", "KnowledgeReviewScreen", "SubjectRegistrationForm", "SubDocsPage"];
         
            handleHeaderMenuDialoge();
            var chapter = redirectPages[ordinal];
            if (chapter != "CongratulationsScreen" && chapter != "SubDocsPage") {
                navigation.navigate(chapter, { ordinal: redirectPages.indexOf(chapter) });
            } else if (chapter == "SubDocsPage") {
                if (signatureType == 2) {
                    navigation.navigate(chapter, { signatureType: 2 });
                } else {
                    navigation.navigate(chapter, { signatureType: 1 });
                }
            } else {
                navigation.navigate(chapter, { currChapterOrdinal: 4 });

            }
        }
    }
