import StudyListScreen from '../components/studyList/StudyListScreen';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeStudySiteList, storeStudySite } from '../actions/studySite';
import { setSubjectToEmpty} from '../actions/subject';

class StudyListPage extends Component {
    
    componentDidMount = () => {
        const { setSubjectToEmpty} = this.props;        
        const subject = {
            formData:[],
            subjectDocumentSignatures:[],
            larDetails:{},
            quizAttempts:[],
            consentId:'',
            isRegistered: false,
            consentCompleted: false
        };
        setSubjectToEmpty(subject);
    }

    render() {
        const { navigation, screenProps, studySiteList, selectedUser, storeStudySiteList,
            storeStudySite, organizationId } = this.props;
        return (
            <StudyListScreen 
                navigation={navigation}
                screenProps={screenProps}
                studySiteList={studySiteList}
                selectedUser={selectedUser}
                storeStudySiteList={storeStudySiteList}
                storeStudySite={storeStudySite}
                organizationId={organizationId}
            />
        );
    }
}

const mapStateToProps = state => ({
    studySiteList: state.studySite.list,
    selectedUser: state.clientUser.selectedUser,
    organizationId: state.clientUser.organizationId,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeStudySiteList,
        storeStudySite,
        setSubjectToEmpty
    },
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(StudyListPage);