import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React, { Component } from 'react';
import { Container, Icon } from 'native-base';
import { View, ScrollView, Text, StyleSheet } from 'react-native';
import _ from 'lodash';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import api from '../utils/api';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import { Promise } from 'core-js';
import { AsyncStorage } from 'react-native';
import {fontType} from '../styles/font';
import showToast from '../utils/toast';
import constants from '../utils/constants'



 class DownloadVideosAndStoreLanguages extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            isStoringData: true,
        }
    }


    componentDidMount = () => {
        this.saveVideos();
    }

     saveVideos = async() => {
        const { navigation, studySiteId} = this.props;
         try {
             this.setState({isStoringData: true});
             const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/metadata`);
             const promises = [];
             if (res.data) {
                 const data = res.data;
                 const keys = Object.keys(data);
                 let newObj = res.data;
                 let assets = [];
                // keys.map( key=> {
                //     const chapters = data[key].chapters;
                //         if(chapters.length >= 1 ) {
                //             chapters.map( (chapter, chapterIndex) => {
                //                 if(chapter.chapterType === 'STUDY_VIDEO') {
                //                     chapter.pages.map( (page, pageIndex) => {
                //                         page.multiMediaContent.map((mmc, mmcIndex) => {
                //                             promises.push(this.storeVideo(mmc.url).then(path=>{
                //                                 // const loAsset = JSON.parse(path);
                //                                 // assets.push(loAsset);
                //                                 newObj[key]
                //                                 .chapters[chapterIndex]
                //                                 .pages[pageIndex]
                //                                 .multiMediaContent[mmcIndex]
                //                                 .path =path;
                //                             }).catch(error=>{
                //                                 this.setState({isStoringData: false});
                //                                 // showToast('Poor Network Connection.', 'danger', 4000);
                //                                 navigation.navigate('LoginPage');
                //                                 return new PromiseRejectionEvent().promise;
                //                             }));
            
                //                         });
                //                     });
                //                 }
                //             });
                //         }
                // });
                
                Promise.all(promises).then(res=>{
                    this.storeAssetsAndLanguages(data, assets);                 
                });
             }
         } catch (error) {
             console.log(error);
             const result = error.response ? error.response.status : 'NetworkError';
             if (result === 400) {
                showToast('Something went wrong.', 'danger', 4000);
             }  else {
                 showToast('Network Error.', 'danger', 4000);
             }
             setTimeout(() => {
                navigation.navigate('LoginPage');  
             }, 5000);
           
            }
     }

     storeAssetsAndLanguages = async (languages, assets) => {
        const languagesdata =  JSON.stringify(languages);
        this.storeLanguage(languagesdata);
        if(assets.length >= 1) {
            const loAssets = JSON.stringify(assets);
            await AsyncStorage.setItem('assets', loAssets);
        }
     }

    storeLanguage = (languagesdata) => {
        const { navigation, studySiteId, deviceNo } = this.props;
        try {
            let promises = [];
            promises.push(this.context.saveLanguages(languagesdata));
            Promise.all(promises).then(res=>{
                navigation.replace('StudySiteAccessInfoScreen');                
            });               
        } catch (error) {
            console.log(error);
            navigation.navigate('LoginPage');
            
        }
     }

     makeid = (length) =>  {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


     storeVideo = (uri) => {
         const filename = this.makeid(5);
         let fileUri = FileSystem.documentDirectory + `eicVideo${filename}.mp4`;
         return FileSystem.downloadAsync(uri, fileUri)
            //  .then(({ uri }) => {
            //      return this.saveFile(uri).then(path => {
            //          return path;
            //      }).catch(error => {
            //          return new PromiseRejectionEvent().promise;
            //      })
            //  })
            .then(({ uri }) => {
                return uri
            })
             .catch(error => {
                // showToast('Poor Network Connection', 'danger', 4000);
                 console.error(error);
                 return new PromiseRejectionEvent().promise;
             })
     }
    
    
    //   saveFile = async (fileUri) => {
    //       try{
    //         const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    //         console.log("status is " + status);
    //         if (status === "granted") {
    //           const asset = await MediaLibrary.createAssetAsync(fileUri)
    //           console.log("asset is " + JSON.stringify(asset));
    //         //   await MediaLibrary.createAlbumAsync("Download", asset, true)
    //           const assetObj = JSON.stringify(asset)
    //           console.log("asset obj is "+ assetObj);
    //           return assetObj;
    //         }
    //       } catch(error) {
    //           console.log("failed to save video "+error);
    //           showToast(t('Failed to save multimedia'), 'danger', 4000);
    //       }
       
    //   }

    render() {
        return (
            <View style={{flex:1, alignItems:'center',justifyContent:'center', backgroundColor: '#ffffff' }}>
                {/* <NavigationEvents
                    onDidFocus={() => {
                        this.saveVideos();
                    }}
                /> */}
                <Text style={{ fontSize: fontType('Heading1'), color: '#006699', padding: 10 }}>Loading...</Text>
            </View>
        )
    }
}

export default DownloadVideosAndStoreLanguages;


