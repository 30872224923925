import moment from 'moment-timezone';

export const getOverallTimeSpentBySubject = (entryTime, exitTime) => {
    var timeZone = moment.tz.guess();                               
	var exitTimeZone =  moment.tz(exitTime, timeZone);
	const diff = exitTimeZone.diff(entryTime);
	const diffDuration = moment.duration(diff);
	const leftMin = diffDuration.minutes();
    const totalSeconds = diffDuration.asSeconds(); 
    return totalSeconds;
}

export const getSubjectPageExitTime = () => {
	const exitTime =  moment().format();
	return exitTime;
}

export const buildSubjectNavTour = (entryTime, exitTime, chapterId, pageId, overallTimeSpent ) => {
	const subNavTour = {
		entryTime: entryTime,
		exitTime: exitTime,
		consentChapter:{
			id: chapterId
		},
		chapterPage:{
			id: pageId
		},
		totalTimeSpent: overallTimeSpent
	}
	return subNavTour;
}