import React from "react";
import TextAvatar from "react-native-text-avatar";
import { fontType } from "../../styles/font";
import { FontAwesome, AntDesign, Feather } from "@expo/vector-icons";
import styles from "./styles/tryalHeaderStyles";
import {
  View,
  Text,
  Platform,
  Dimensions,
  TouchableOpacity,
} from "react-native";

export default function HeaderProfile(props) {
  const {
    userFullName,
    appVersion,
    organizationName,
    title,
    deviceNo,
    showDeviceNo,
    onLogoutPress,
    handleHeaderMenuDialoge,
  } = props;

  const getUserName = () => {
    const userNameArr = userFullName.split(" ");
    return userNameArr[0].charAt(0) + "" + userNameArr[1].charAt(0);
  };

  return (
    <View style={{ width: "205px", height: "200px" }}>
      <View>
        <View style={styles.userDataWrapper}>
          <Text style={{marginTop: '-10px'}}>
            <TextAvatar
              backgroundColor={"#3a35414d"}
              textColor={"white"}
              size={40}
              type={"circle"}
            >
              {getUserName()}
            </TextAvatar>
            <View
              style={{
                position: "absolute",
                borderRadius: "50%",
                width: "12px",
                height: "12px",
                border: "2px solid #fff",
                backgroundColor: "#56ca00",
                marginTop: '26px',
                marginLeft: '-10px',
              }}
            ></View>
          </Text>
          <View
            style={[styles.userData, styles.flexGrow1, styles.userDataInfo]}
          >
            <Text
              style={[
                styles.H2FontSize,
                { marginBottom: "2%", fontFamily: "Inter", fontWeight: '500' },
              ]}
            >
              {userFullName}
            </Text>
            <View style={styles.subTitle}>
              <Text style={styles.userData}>Title: {title}</Text>
              <Text style={styles.userData}>
                App Version: {appVersion}{" "}
              </Text>
              {showDeviceNo ? (
                <Text style={styles.userData}>
                  Device S.NO: {deviceNo}
                </Text>
              ) : (
                <View></View>
              )}
            </View>
            <Text
              style={[
                styles.H2FontSize,
                styles.colorGray800,
                {
                  color: "rgba(58,53,65,.68)",
                  marginTop: "2%",
                  fontFamily: "Inter",
                  marginBottom: '5%'
                },
              ]}
            >
              {organizationName}
            </Text>
          </View>
        </View>
        <View style={[styles.profileMenu, styles.listUnstyled, styles.p0]}>
          <View style={[styles.li]}>
            <TouchableOpacity style={{ fontFamily: "Inter" }}>
              <Text style={styles.profileMenuItems}>
                <FontAwesome
                  name="user-o"
                  style={{ fontSize: fontType("subFontSize"), marginRight: 12, color: 'rgba(58,53,65,.87)' }}
                />
                Profile
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.li]}>
            <TouchableOpacity
              onPress={() => handleHeaderMenuDialoge()}
              style={{ fontFamily: "Inter" }}
            >
              <Text style={styles.profileMenuItems}>
                <AntDesign
                  name="closecircleo"
                  style={{ fontSize: fontType("subFontSize"), marginRight: 12, color: 'rgba(58,53,65,.87)' }}
                  color="black"
                />
                Close
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.li]}>
            <TouchableOpacity
              onPress={() => onLogoutPress()}
              style={{ fontFamily: "Inter" }}
            >
              <Text style={styles.profileMenuItems}>
                <AntDesign
                  name="logout"
                  style={{ fontSize: fontType("subFontSize"), marginRight: 12, color: 'rgba(58,53,65,.87)' }}
                />
                Logout
              </Text>
            </TouchableOpacity>
          </View>     
        </View>
      </View>
    </View>
  );
}
