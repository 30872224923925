import React, { useEffect, useState } from 'react';
import TryalTextInput from '../common/textInput';
import { View, StyleSheet, Dimensions, Text, ActivityIndicator } from 'react-native';
import TryalButton from '../common/button';
import { ButtonTypes, width } from '../common/constants';
import { Colors } from '../../constants/ui/colorScheme';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addNewComment, addResponse, deleteComment } from '../../actions/comments';
import {fontType} from '../../styles/font'
import { Formik } from "formik";
import * as yup from "yup";
import _ from 'lodash'
import MyLoader from '../../utils/webLoader';

const SCREEN_WIDTH = width();
export const CommentBoxStyles= StyleSheet.create({
    buttonContainerStyle:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '10px',
        width: SCREEN_WIDTH*0.85,
        alignSelf: 'center'
    },
    cancelBtnStyle: {
        marginRight: '10px',
    },
    textInpuStyle: {
        borderColor: Colors.borderGreyColor,
        borderWidth: 1,
        width: SCREEN_WIDTH*0.85,
        minHeight: 40,
        borderRadius: 5,
        // justifyContent: 'center',
        alignSelf: 'flex-start',
        paddingLeft: 10,
        position: 'relative'
    },
});

const CommentBox = (props) => {
    const { isEditing, commentDetails, onCommentSave, onCommentCancel, isResponse=false,
         isSubjectView, onCommentDelete, chapterType , screenProps: { t }} = props;
    const [loading, setLoading] = useState(false);
    const { isRemoteConsent } = useSelector(state => state.subject);
    const { selectedSubject } = useSelector(state => state);
    const { studySiteState } = useSelector(state => state.studySite);
    const { studySite } = useSelector(state => state);
    const dispatch = useDispatch();

   

    const onSave = async (values) => {
            try {
                setLoading(true)
                const res = !isResponse ? await dispatch(addNewComment(selectedSubject?.id, {...commentDetails, subjectConsentId: selectedSubject?.subjectConsent.id, chapterType: chapterType, comment: values.comment}, isRemoteConsent)) :
                await dispatch(addResponse(selectedSubject?.id, {...commentDetails, response: values.comment, subjectConsentId: selectedSubject?.subjectConsent.id}));
                setLoading(false)
                onCommentSave(res);
            } catch (error) {
                setLoading(false)
                console.log(error);
            }
    }

    const validationSchema = yup.object().shape({
        comment: yup.string().required(t('FillWarn')).test(
        'empty-check',
        t('FillWarn'),  function(value) { 
            return !_.isEmpty(_.trim(value));
         }),
    });
    
    return (
        <View>
            
            <MyLoader active={loading} />
            <Formik
            enableReinitialize
            initialValues={{ comment: isResponse ?commentDetails?.response: commentDetails?.comment,}}
            validationSchema={validationSchema}
            onSubmit={(values, formikActions) => {
                onSave(values)
                formikActions.resetForm()
              }}
            >
             {formikProps => (
            <React.Fragment>
            <TryalTextInput 
                inputStyle={[CommentBoxStyles.textInpuStyle, {minHeight: isEditing? 80 : 40, width: isResponse ? SCREEN_WIDTH*0.8 : SCREEN_WIDTH*0.85}]} 
                placeholder = {isResponse? t('AddResponse') : t('AddComment')}
                onChange = {formikProps.handleChange('comment')}
                value = {formikProps.values.comment}
                readOnly = {!isEditing}
            />
             {formikProps.errors.comment &&  (<Text style={{ color: 'red', fontSize: fontType('subFont') }}>{formikProps.errors.comment}</Text>)}
            { isEditing &&
            <View style = {[CommentBoxStyles.buttonContainerStyle, {width: isResponse ? SCREEN_WIDTH*0.8 : SCREEN_WIDTH*0.85}]}>
                <View style = {CommentBoxStyles.cancelBtnStyle}>
                    <TryalButton title={t('Cancel')} 
                    type={ButtonTypes.OUTLINED} 
                    buttonColor={Colors.dangerColor} 
                    onClick = {onCommentCancel}
                    />
                </View>
                <View>
                    <TryalButton title={t('Save')} onClick = {formikProps.handleSubmit}/>
                </View>
            </View>
            }
            </React.Fragment>
             )}
            </Formik>
        </View>
    )
}

CommentBox.defaultProps = {
    isEditing: true,
    commentDetails: {
        comment: ''
    }
}
CommentBox.propTypes = {
    isEditing: PropTypes.bool,
    commentDetails: PropTypes.instanceOf(Object),
    screenProps: PropTypes.object,
}
export default CommentBox;