import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType, questionFont} from '../../../styles/font';

export const styles = StyleSheet.create({
    buttonNext: {
        backgroundColor: Colors.button,
        margin: wp('1%'),
        borderRadius: wp('5%'),
        borderColor: 'white',
        borderWidth: 1,
        height: wp('5%'),
        // width: wp('20%')
        paddingHorizontal: wp('4%'),
        fontFamily:'Inter_Bold',

    },
    submitButton: {
        backgroundColor: Colors.button,
        margin: wp('1%'),
        borderRadius: wp('4%'),
        height: wp('7%'),
        // minWidth: wp('18%'),
        paddingHorizontal: wp('15%'),
        fontFamily:'Inter_Bold'

    },
    buttonTxt: {
        color: 'white',
        // marginLeft: wp('2%'),
        textAlign: 'center',
        fontSize: wp('3%'),
        fontFamily:'Inter_Bold',
        
    },
    fieldNameStyle: {
        color: Colors.textdisabled,
        fontSize: 24
    },
    footer:{
            backgroundColor: '#0086ca',
            justifyContent: 'flex-end',
            alignItems:'center',
            height: wp('10%')
    },
    question: {
        color: Colors.text,
        fontSize: questionFont(),
        marginLeft: 20,
        // paddingRight: wp('3%'),
        fontFamily:'Inter',
        fontSize: 20,
    },
    currentQuestionNumber:{
        flexDirection: "row",
        padding: 10,
        alignItems:'center',
        flex: 0.2,
    },
    field:{
        position: 'relative',
        flex: 1, flexWrap: 'wrap',
        paddingTop:3,
        paddingLeft: 10,
        paddingRight:8,
    },

});