import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React from 'react';
import { Item, Label, Input } from 'native-base';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    Dimensions,
    TextInput,
    Text,
    View,
    Alert,
} from 'react-native';
import SubjectRegistrationForm from './SubjectRegistrationForm';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';

const styles = StyleSheet.create({
    placeholder: {
        color: Colors.error,
        fontSize: fontType('BasicFont'),
    },
    container: {
        flex: 1,
        backgroundColor: Colors.background,
        alignItems: 'flex-start',
        padding: 50,
        justifyContent: 'flex-start',
        fontSize: fontType('BasicFont'),

    },
    inputView: {
        paddingLeft: 10,
        fontSize: fontType('BasicFont'),
        height: 10,
        borderRadius: 30,

    },
    input: {
        fontSize: fontType('BasicFont'),
    },
    errorinfo: {
        marginTop: 2,
       
    },
    errorinfoText: {
        color: Colors.error,
        fontSize: fontType('subFont'),
    },
    labelFont: {
        fontSize: fontType('Heading1'),
    },

});
class TextInputItem extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.string,
        error: PropTypes.array,
    };
    getError = error => {
        if (error) {
            return error.map(info => {
                return (
                    <Text style={styles.errorinfoText} key={info}>
                        {info}
                    </Text>
                );
            });
        }
        return null;
    };
    render() {
        const { label, onChange, value, error, mode, keyboardType,t,maxlength } = this.props;
        return (
            <View>
                <Item>
                        <Input
                            placeholder={t('PlaceHolderText')}
                            placeholderTextColor={Colors.textdisabled}
                            style={styles.input}
                            value={value || ''}
                            label={`${label}：`}
                            duration={150}
                            onChangeText={onChange}
                            highlightColor="blue"
                            keyboardType={keyboardType || 'default'}
                            maxLength={maxlength}
                        />
                </Item>
                <View style={styles.errorinfo}>{this.getError(error)}</View>
            </View>
        );
    }
}
export default TextInputItem;