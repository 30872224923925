import { 
STORE_PRINT_AND_SIGN_SIGNATURE_TYPE,
} from "../actions/printNSign";
import initialState from "../store/initialState";

export default (state = initialState.printNSign, action) => {
  switch (action.type) {
    case STORE_PRINT_AND_SIGN_SIGNATURE_TYPE:
        return {
          ...state,
          data: {
            ...state.data,
            [action.studySiteId] : {
              ...state.data[action.studySiteId],
              [action.consentVersionId]: {
                signatureType: action.signatureType
              }
            }
          },
        };
    default:
      return state;
  }
};
