import ConsentSignature from '../components/signatureScreen/SignatureScreen';
import React, { Component } from "react";
import { Icon } from 'native-base';
import _ from 'lodash';
import { getConsentChapter, getConsentVersionId } from '../utils/consentData';
import { getDocumentSignature, getFilteredDocumentSignatures } from '../utils/consentData';

import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSignatureData } from '../actions/subjectSignatureData';
import { saveDocumentSignature } from '../actions/documentSignature';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import {incDocSignPosition} from '../actions/update'
import { storeDeclarationFormData } from '../actions/documents';
import { SIGNATURE_TYPE } from '../components/common/constants';


class WitnessSignatureScreen extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            sign: null,
            witnessName: '',
        };
    };

    componentDidMount () {
        const { currentUserType, selectedSubject } = this.props
        if(currentUserType === 2) {
            this.setState({
                signedBy: selectedSubject.larFirstName+ ' ' +selectedSubject.larLastName,
            })
        } else if (currentUserType === 4) {
            this.setState({
                signedBy: selectedSubject.witnessFirstName+ ' ' +selectedSubject.witnessLastName
            })
        } else if (currentUserType === 1) {
            this.setState({
                signedBy: selectedSubject.firstName+ ' ' +selectedSubject.lastName
            })
        }
    }

    captureSignature = (sign,witnessname) => {
        const { storeSignatureData, larDetails,navigation, incDocSignPosition, submitDocumentSignature, currentUserType,currentSubDocPosition, subjectConsentDocuments, selectedSubject, loading, formDataList} = this.props;
        if(loading === false) {
const { signedBy } = this.state;
const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');      
const documentSignatureId = navigation.getParam('documentSignatureId');
        const signType = navigation.getParam('signType');
        const documentPageId = navigation.getParam('documentPageId');
        const fileName = `ConsentSignature${moment().format('DDMMMYYYYHHMM')}.png`;
        var documentSignature = {
            id: subjectDocumentSignatureId,
            signedBy: signedBy,
            fileName: fileName,
            signatureDate: moment(),
            signature: sign,
            signatureType: currentUserType,
            documentPageId: documentPageId,
            documentSignatureId,
        }
        storeSignatureData(documentSignature);
        const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        
        if( currentSubDocPosition < subjectDocumentSignatures.length ){
            incDocSignPosition();
        }
        navigation.navigate('SubDocsPage', {signatureType: signType });
        
    }}

    //added

    // captureSignature = (sign) => {
    //     const { storeSignatureData, larDetails, navigation, subjectConsentDocuments, currentSubDocPosition, incDocSignPosition, selectedSubject, currentUserType } = this.props;
    //     const { signedBy } = this.state;
    //     const subjectDocumentSignatureId = navigation.getParam('subjectDocumentSignatureId');
    //     const documentSignatureId = navigation.getParam('documentSignatureId');
    //     const signType = navigation.getParam('signType');
    //     const documentPageId = navigation.getParam('documentPageId');
    //     const fileName = `ConsentSignature${moment().format('DDMMMYYYYHHMM')}.png`;
    //     var documentSignature = {
    //         id: subjectDocumentSignatureId,
    //         signedBy: signedBy,
    //         fileName: fileName,
    //         signatureDate: moment(),
    //         signature: sign,
    //         signatureType: currentUserType,
    //         documentPageId: documentPageId,
    //         documentSignatureId,
    //     }
    //     storeSignatureData(documentSignature);
    //     const subjectDocumentSignatures = getFilteredDocumentSignatures(currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        
    //     if( currentSubDocPosition < subjectDocumentSignatures.length ){
    //         incDocSignPosition();
    //     }
    //     navigation.navigate('SubDocsPage', {signatureType: signType });
    // };

    onback = () => {
        const { navigation } = this.props; 
        const documentId = navigation.getParam('documentPageId');
        // navigation.replace('CounterSignDoc', {documentId: documentId});
        navigation.goBack();
    }

    render() {
        const { navigation,documentSignatureList, loading, subject, screenProps, storeDeclarationFormData, formDataList ,selectedSubject } = this.props;
        const documentSignatureId = navigation.getParam('documentSignatureId');
        const witnessDeclaration = navigation.getParam('witnessDeclaration');
        const documentId = navigation.getParam('documentId');
        let documentSignatures = documentSignatureList[documentId];
        // let mstrDocSignList = getConsentChapter(this.context.languages,subject.language, 'SIGNATURE').documentSignatures;
        // mstrDocSignList = mstrDocSignList.filter(mds => mds.documentId.toLowerCase() === documentId.toLowerCase());
        // documentSignatures = documentSignatures.map(ds => {
        //     return {
        //         ...ds,
        //         documentSignatureId: _.find(mstrDocSignList, mds =>   mds.signatureType === ds.signatureType).id,
        //     }
        // });
        // const ds = documentSignatures.filter(ds => ds.documentSignatureId === documentSignatureId)[0];
        const signature = getConsentChapter(this.context.languages, subject.language, "SIGNATURE");
        let declarationFormFields =_.find(signature.documentSignatures, { 'id': documentSignatureId, 'signatureType': 4});

        return (
            <ConsentSignature
                navigation={navigation}
                // declaration={ds.declaration}
                declarationFormFields={declarationFormFields.formFields}
                documentId={declarationFormFields.documentId}
                documentSignatureId={declarationFormFields.id}
                captureSignature={this.captureSignature}
                isWitness={true}
                loading = {loading}
                heading = {screenProps.t('WitnessSign')}
                isRemoteConsent={subject.isRemoteConsent}
                onback={this.onback}
                screenProps={screenProps}
                storeDeclarationFormData={storeDeclarationFormData}
                formDataList={formDataList}
                signedBy={selectedSubject.witnessFullName}
                selectedSubject={selectedSubject}
                currentSignatureType={SIGNATURE_TYPE.WITNESS}
            />
        );
    }
}

const mapStateToProps = state => ({
    subject: state.subject,
    selectedSubject: state.selectedSubject,
    documentSignatureList: state.documents.documents,
    loading: state.loading,
    larDetails: state.subject.larDetails,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    currentSubDocPosition: state.currentSubDocPosition,
    currentUserType: state.consentFlow.currentUserType,
    formDataList: state.documents.formDataList

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSignatureData,
        incDocSignPosition,
        submitDocumentSignature: saveDocumentSignature,
        storeDeclarationFormData
    },
    dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(WitnessSignatureScreen);
