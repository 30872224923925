import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React, { Component } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { Colors } from '../constants/ui/colorScheme';
import {fontType} from '../styles/font'
import {constStyles} from '../styles/constStyles'


export default class HandoverToSubjectScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    static navigationOptions = ({ navigation }) => ({
        headerRight: () => (
           <Text></Text>
          )
    });
    render() {
        const viewStyles = Platform.OS === 'web' ? { backgroundColor: '#fff' } : {backgroundColor:'#fff', justifyContent:'center', };
        const { navigation, screenProps: { t } } = this.props;
        return (
            <View style={{flex: 1, justifyContent:'center'}}>
                <View style={{flexDirection:'column' }} >
                    <Text style={styles.content}>
                    A new subject is created for consent process.
                    </Text>
                    <Text style={styles.content}>
                    Please give the device to the subject to continue with consent.
                    </Text>
                    <View style={{marginTop:'5%'}}>
                        <TouchableOpacity style={constStyles.buttonStyle} onPress={() => this.props.navigation.navigate('Consent', {screen: 'ConsentOverviewScreen'})}>
                                <Text style={constStyles.buttonTxt}>
                                CONTINUE
                                </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

var styles = StyleSheet.create({
    content: {
         color: Colors.text,
         fontSize: fontType('Heading1'),
         padding: 5,
         textAlign: 'center',
         fontFamily:'Inter' 
    },
    // buttonStyle: {
    //     padding:wp('6%'),
    //     margin:wp('5%'),
    //     height: hp('5%'),
    //     backgroundColor: Colors.buttonwithbg,
    //     borderRadius: 50,
    //     justifyContent:'center',
    //     alignSelf:'center'
    // },
    // textStyle: {
    //     color: Colors.textColorwithbg,
    //     paddingHorizontal: wp('6%'),
    //     fontSize: wp('3%'),
    //     alignSelf: 'center',
    //     fontFamily:'Inter_Bold'
    // },
 });
 
