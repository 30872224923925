import React, { useState } from "react";
import { View, Text } from "react-native";
import { TOOLTIP_PLACEMENT, width } from "../constants";

/*
    This method is used to get the styles for the tooltip based on the placement
*/
const getTooltipStyles = (placement) => {
  switch (placement) {
    case TOOLTIP_PLACEMENT.TOP:
      return {
        bottom: 30,
        alignSelf: 'center'
      }
    case TOOLTIP_PLACEMENT.BOTTOM:
      return {
        marginTop: 30,
        alignSelf: 'center'
      }
    case TOOLTIP_PLACEMENT.TOP_LEFT:
      return {
        bottom: 30,
        left: -40
      }
    case TOOLTIP_PLACEMENT.BOTTOM_LEFT:
      return {
        bottom: -50,
        left: -40
      }
    case TOOLTIP_PLACEMENT.TOP_RIGHT:
      return {
        bottom: 30,
        right: -40
      }
    case TOOLTIP_PLACEMENT.BOTTOM_RIGHT:
      return {
        marginTop: 10,
        right: -40
      }
    default:
      return{};
  }
}
const   ToolTip = (props) => {
  const [isElementActive, setIsElementActive] = useState(false)
  const { tip, placement = TOOLTIP_PLACEMENT.BOTTOM, tooltipWidth } = props;
  const getTooltipStyle = () => {
    const style = {
      ...getTooltipStyles(placement), 
      width: tooltipWidth,
      visibility: 'hidden',
      backgroundColor: '#47476b',
      color: '#fff',
      textAlign: 'left',
      borderRadious: 6,
      padding: 5,
      position: 'absolute',
      zIndex: 100
      // opacity: 0
    } 
    if(isElementActive){
      style.visibility = 'visible'
    }
    return style
  }
  return (
    <View
    accessible = {true}
    onMouseEnter={() => setIsElementActive(true)}
    onMouseLeave={() => setIsElementActive(false)}
    testID="tooltip-view"
    >
      {
      tip && _.includes(placement, TOOLTIP_PLACEMENT.TOP) ? 
      <Text style={getTooltipStyle()} testID="tootltip-text">{tip}</Text>
      : <></>
      }
              {props.children}
            { tip && _.includes(placement, TOOLTIP_PLACEMENT.BOTTOM) ? 
      <Text style={getTooltipStyle()} testID="tootltip-text">{tip}</Text>
      : <></>
      }
    </View>
  );
};

export default ToolTip;
