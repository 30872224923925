import React, { Component } from "react";
import { StyleSheet, Text } from "react-native";
import { View, Item, Input } from "native-base";
import { styles } from "./fieldStyles";
import NumericInputItem from './NumericInput';

export default class NumericInputDecorator extends Component {
  constructor(props) {
    super(props);
    this.state = { number: '' };
  }
 
  render() {
    const { form: { getFieldDecorator, getFieldError}, field:{id}, attempts, t } = this.props;
    return (
      <View >
      {getFieldDecorator(`${id}.value`, {
      rules: [
        { required: true, message: t('FillWarn') },
      ],
    })(
      <NumericInputItem
      autoFocus
        error={getFieldError(`${id}.value`)}
        attempts={attempts}
        t={t}
      />
    )}
      {getFieldDecorator(`${id}.quizAttempts`)}
 
    </View>
    );
  }
}
