import React, {Component} from 'react';
import {View, Text, StyleSheet, TextInput} from 'react-native';
import styles from './fieldStyles';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {Item, Input} from 'native-base';
import { constStyles } from '../../../styles/constStyles';

export default class TextAreaItem extends Component{
    
    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
    render() {
        const {error, value, onChange, t} = this.props;
        return (
            <View style={{flex: 1, marginRight: 10,width:'100%'}} >
              <Item regular
              style={{borderColor:'#fff',marginTop:5 }}
              >
               <Input 
                 autoFocus
                 underlineColorAndroid='rgba(0,0,0,0)'
                 editable={true}
                 maxLength={500}
                 placeholder={t('EnterHere')}
                 placeholderTextColor={Colors.borderColor}
                 multiline={true}
                 numberOfLines={5}
                 value={value || ''}
                 onChangeText={onChange}
                 style={[styles.inputTextFont, styles.textArea, styles.textBoxBorderColor, constStyles.inputStyles, { height:100, fontSize: '18px'}]}
               />
               </Item>
            
                <View style={styles.errorinfoText}>{this.getError(error)}</View>
            </View>
          )
    }

}
