import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubjectDocumentSign from '../components/consentSignature/SubjectDocumentSign';
import { getConsentChapter } from '../utils/consentData';
import { disableTap } from '../utils/signatureUtils';
import {ConsentDataContext} from '../providers/ConsentDataProvider';


class SubjectDocumentSignScreen extends Component {
    static contextType = ConsentDataContext;

    state = {};
    static navigationOptions = ({ navigation }) => ({
        // title: "Document Details"
    });

    render() {
        const { navigation, subject, currentUserType, isRegisterd, screenProps, selectedSubject } = this.props;
        const subjectDocumentSignatures = subject.subjectDocumentSignatures;
        const documentSignatureId = navigation.getParam('documentSignatureId');
        const disableTapToSignIfNoSigns = navigation.getParam('disableTapToSignIfNoSigns');
        let disableTapToSign = true;
        if(disableTapToSignIfNoSigns) {
            disableTapToSign =  true
        } else {
            disableTapToSign = disableTap(subjectDocumentSignatures, documentSignatureId);
        }
        const subDocSignNavProps = {
            documentSignatureId,
            disableTapToSignIfNoSigns

        }
        // {disableTapToSignIfNoSigns ? disableTapToSign = disableTapToSignIfNoSigns : disableTapToSign}
        const signatureChapter = getConsentChapter(this.context.languages, subject.language, 'SIGNATURE');
        const documentsChapter = getConsentChapter(this.context.languages, subject.language, 'ICF_DOCUMENTS');
        const documentSignature = signatureChapter.documentSignatures.filter(docSignature => docSignature.id === documentSignatureId)[0];
        return (
            <SubjectDocumentSign
                currentUserType={currentUserType}
                isRegisterd={isRegisterd}
                subject={subject}
                navigation={navigation}
                disableTapToSign={disableTapToSign}
                documentsChapter={documentsChapter}
                documentSignature={documentSignature}
                screenProps={screenProps}
                selectedSubject = {selectedSubject}
                subDocSignNavProps = {subDocSignNavProps}
                />
        );
    }
}
const mapStateToProps = state => ({
    subject: state.subject,
    selectedSubject: state.selectedSubject,
    isRegisterd: state.subject.isRegisterd,
    currentUserType: state.consentFlow.currentUserType, 

});
export default connect(mapStateToProps, null)(SubjectDocumentSignScreen);