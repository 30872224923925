import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import TryalIcon from '../common/icon';
import { fontType } from '../../styles/font';
import { Colors } from '../../constants/ui/colorScheme';

const ChapterItemStyles= StyleSheet.create({
    containerStyle:{
        flexDirection: 'row', 
        padding: wp('1%')
    },
    iconStyle: {
        flex: 0.45, 
        flexDirection: 'row',  
        paddingLeft:4, 
        alignSelf: 'center', 
        justifyContent: 'center', 
        alignContent: 'center'
    },
    titleDescContainer: {
        flex: 3.55, 
        flexDirection: 'column', 
        paddingLeft: 22 
    },
    titleStyle: {
        fontSize: fontType('BasicFont'), 
        fontWeight: 'normal', 
        color: Colors.consentTextColor, 
        fontFamily:'Inter' 
    },
    descStyle: {
        fontSize: fontType('subFont'), 
        fontFamily:'Inter', 
        color:Colors.consentTextColor
    }
});

const ChapterItem = (props) => {
    const { item, iconName, iconType, iconColor, t } = props;
    return (
        <View style={ChapterItemStyles.containerStyle} >
            <View style={ChapterItemStyles.iconStyle}>
                <TryalIcon iconName = {iconName} iconType={iconType} color={iconColor} size={fontType('StatusIcon')}/>
            </View>
            <View style={ChapterItemStyles.titleDescContainer}>
                <Text style={ChapterItemStyles.titleStyle}>
                {item.chapterType === 'SIGNATURE' ? t('SignatureChapter') : item.chapterTitle }  
                </Text>
                <Text note style={ChapterItemStyles.descStyle}>
                    {item.description}
                </Text>
            </View>
        </View>
    )
}

export default ChapterItem;
