import { createSelector } from 'reselect'
export const getStudySiteId = (state) => state.studySite.studySiteId
export const getPrintNSignData = (state) => state.printNSign.data
export const getConsentVersionId = (state) => state.selectedSubject.consentVersionId


export const getSignatureTypeOfSubject = createSelector(
    [getStudySiteId, getPrintNSignData, getConsentVersionId],
    (studySiteId, data, consentVersionId) => {
      return data?.[studySiteId]?.[consentVersionId]?.["signatureType"]
    }
  )