import SessionExpiryScreen from "../components/sessionExpiry/sessionExpired";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentUserType } from "../actions/consentFlow";
import { bindActionCreators } from "redux";
import { clearDocSignPosition } from "../actions/update";

class SessionExpiredPage extends Component {
  render() {
    const { navigation, clientName, isRemoteConsent, otp } = this.props;
    const t = navigation.getParam("t");

    return (
      <SessionExpiryScreen
        clientName={clientName}
        navigation={navigation}
        isRemoteConsent={isRemoteConsent}
        otp={otp}
        t={t}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  signatureTypes: state.consentFlow.signatureTypes,
  currentUserType: state.consentFlow.currentUserType,
  clientName: state.clientUser?.selectedUser?.client?.organizationCode,
  isRemoteConsent: state.subject.isRemoteConsent,
  otp: state.subjectRemoteConsentDto?.otp,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentUserType,
      clearDocSignPosition,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpiredPage);
