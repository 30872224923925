import {StyleSheet, Dimensions, Platform} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {Colors} from '../../../constants/ui/colorScheme';
import {fontType } from '../../../styles/font';
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')


export const styles = StyleSheet.create(
    {
        card: {flex:5, padding:20, maxWidth:SCREEN_WIDTH-20, 
            minWidth: Platform.OS == 'web' ? SCREEN_WIDTH/3 : SCREEN_WIDTH/2,
        },
        inputStyle: {
            textAlign: 'center',
            fontSize: wp('4%'),
            fontWeight: 'normal',
            
        },
        headerStyle: {
            // marginTop: 50,
            fontSize: fontType('Heading1'),
            fontWeight:'200',
            alignItems: 'center',
            textAlign: 'center',
            fontFamily:'Inter',
        },	
        infoText: {		
            // marginTop: 50,		
            fontSize: wp('2%'),		
            fontFamily:'Inter',		
            color: Colors.text,		
        },		
        errorText: {		
            fontSize: wp('2%'),		
            alignSelf: 'center',		
            fontFamily:'Inter',		
            color: Colors.error,		
        },
        buttonStyle: {
            backgroundColor: Colors.buttonwithbg,
            alignSelf: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
            width:wp('20%'),
            height:wp('5%'),
            marginTop: 40
        },
        textStyle: {
            color: Colors.textColorwithbg,
            // fontSize: wp('2%'),
            alignSelf: 'center',
            // paddingLeft:wp('7%'),
            fontFamily:'Inter',
            paddingTop: wp('1%'),
        },
    }
)

