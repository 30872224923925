import ConsentOverview from "../components/consentOverview/ConsentOverview";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { View } from "react-native";
import { updateConsentStatus, storeVisitedChapters } from "../actions/subject";
import PropTypes from "prop-types";

class ConsentOverviewScreen extends Component {
  componentDidMount = () => {
    const { updateConsentStatus } = this.props;
    updateConsentStatus(false);
  };


  render() {
    const {
      navigation,
      isRegistered,
      subjectData,
      screenProps,
      storeVisitedChapters,
      currentUserType,
      selectedSubject,
    } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <ConsentOverview
          navigation={navigation}
          isRegistered={isRegistered}
          subjectData={subjectData}
          screenProps={screenProps}
          storeVisitedChapters={storeVisitedChapters}
          currentUserType={currentUserType}
          selectedSubject={selectedSubject}
        />
      </View>
    );
  }
}
ConsentOverviewScreen.defaultProps = {
  isRegistered: false,
  subjectData: {},
  screenProps: {},
  currentUserType: "",
  selectedSubject: null,
};

ConsentOverviewScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  isRegistered: PropTypes.bool,
  subjectData: PropTypes.object,
  screenProps: PropTypes.object,
  storeVisitedChapters: PropTypes.func.isRequired,
  currentUserType: PropTypes.string,
  selectedSubject: PropTypes.object,
  updateConsentStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isRegistered: state.subject.isRegistered,
  subjectData: state.subject,
  currentUserType: state.consentFlow.currentUserType,
  selectedSubject: state.selectedSubject,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateConsentStatus,
      storeVisitedChapters,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentOverviewScreen);
