import React, {Component} from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {fontType} from '../../styles/font';

class NoDataPage extends Component {
    render () {
        const {message} = this.props;
        return (
            <View style={styles.noDataTxtContainer}>
                <View style={styles.childContainer}>
                    <Text>
                        <MaterialCommunityIcons name='file-question' size={fontType('ChapterIcon')} color="#c9c9c9" />
                        <Text style={styles.noDataTxt}>{message}</Text>
                    </Text>
                </View>
            </View>
        )
    }
}
export default NoDataPage;

export const styles = StyleSheet.create ( {
    noDataTxtContainer:{
        flex:1,
        justifyContent:'center',
        // width:Dimensions.get('window').width,
        // height:Dimensions.get('window').height
      },
      noDataTxt: {
        textAlign: "center",
        fontSize: fontType('Heading1'),
        color: '#c9c9c9',
        padding: 10,
        fontFamily:'Inter'
      },
      childContainer: {
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
      }
})