import React, { Component } from "react";
import {
    Container,
    ListItem,
    Text,
    Item,
    Input,
    Body,
    Icon
} from "native-base";
import {
    View,
    ScrollView,
    FlatList,
    ActivityIndicator,
} from "react-native";
import { styles } from '../studyList/styles/studyListStyle';
import { createFilter } from "react-native-search-filter";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from '../../styles/font';


const KEYS_TO_FILTERS = [
    "name",
];



export default class OrgListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            loading: false,
        };
    }

    searchUpdated(term) {
        this.setState({ searchTerm: term })
    }

    onSelectClient = (org) => {
         const { navigation, handleHeaderMenuDialoge, setOrganizationName } = this.props;
         setOrganizationName(org.name, org.id);
        if (handleHeaderMenuDialoge) {
            handleHeaderMenuDialoge();
        }
        setTimeout(() => {
            navigation.navigate('Admin', {
                screen: 'StudyListPage',
                // params: {
                //     studySiteId: null,
                // },
            });
        }, 100);
    }

    render() {
        const { selectedUser } = this.props;
        const { loading } = this.state;
        const organizationList = selectedUser.clientOrganizations;
        const loOrganizationList = organizationList ? organizationList.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS)) : [];
        return (
            <Container style={{ backgroundColor: '#ffff', height: '100%' }}>                

                {/* -------------- screen title --------------------- */}
                <View style={styles.title}>
                    <Text style={{ fontSize: fontType('Heading1'), fontFamily: "Inter" }}>
                        Select Site
                </Text>
                </View>

                {/* ------------- search bar ------------------------ */}
                <View style={styles.searchbarContainer}>
                    <Item
                        searchBar
                        rounded
                        style={{ backgroundColor: Colors.searchBg }}
                    >
                        <Icon name="ios-search" style={{ fontSize: fontType('BasicFont'), padding: 2 }} />
                        <Input
                            placeholder="Search"
                            style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter' }}
                            autoCorrect={false}
                            onChangeText={term => {
                                this.searchUpdated(term);
                            }}
                        />
                        <Icon name="ios-people" style={{ fontSize: fontType('BasicFont') }} />
                    </Item>
                </View>

                {/* ------------- Loading ------------------------ */}
                {
                    loading
                        ? <ActivityIndicator
                            size="large"
                            color={Colors.ActivityIndicator}
                            animating={true}
                            hidesWhenStopped={true}
                        />
                        : loOrganizationList.length > 0
                            /* ------------- Render list ------------------------ */
                            ? <ScrollView alwaysBounceVertical={true}>
                                <FlatList
                                    data={loOrganizationList}
                                    keyExtractor={item => item.key}
                                    renderItem={({ item }) =>
                                        <ListItem
                                            thumbnail
                                            onPress={() => {
                                                this.onSelectClient(item);
                                            }}
                                        >
                                            <Body>
                                                <View style={{ justifyContent: "flex-start" }}>
                                                    <Text
                                                        style={{
                                                            fontSize: fontType('BasicFont'),
                                                            fontFamily: "Inter"
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                </View>
                                            </Body>
                                        </ListItem>}
                                />
                            </ScrollView>
                            :
                            /* ------------- Render No data ------------------------ */
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: wp("15%"),
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: fontType('Heading1'),
                                        fontFamily: "Inter",
                                        color: Colors.textdisabled
                                    }}
                                >
                                    No data.
                        </Text>
                            </View>
                }
            </Container>
        );
    }
}
