import React from 'react';
import { StyleSheet, Text, View, Image, ScrollView, TouchableOpacity } from 'react-native';
import { connect } from "react-redux";
import { Container, Footer} from 'native-base';
import { bindActionCreators } from "redux";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import HtmlParserEg from '../components/htmlView/htmlparserEg';
import htmldata , { smallHtml } from '../components/htmlView/config';

class HtmlTestScreen extends React.Component {
  state = {
    showHtml: false
  };

  componentDidMount() {
    this.forceUpdate();
}

  render() {
    const encodedHtml = htmldata;
    const { showHtml } = this.state;

    return (
      <View style={{ flex: 1 }}>
      <Container style={{flex:1}}>
      <Text>ChapterTitle</Text>
      <View>
        <Text>2ndView</Text>
      </View>
      <TouchableOpacity onPress={() => this.setState({showHtml: !showHtml})}><Text>Click here to open</Text></TouchableOpacity>
      <View style={{flex:5.5}}>
      {showHtml &&
      <ScrollView>
      <HtmlParserEg currentDoc={encodedHtml}/>
      {/* <Text style={{fontSize: 30}}>Hello!!!!!!!!!</Text>
      <Text style={{fontSize: 30}}>Hello!!!!!!!!!</Text>
      {/* <HtmlParserEg currentDoc={encodedHtml}/> */}
      <Text style={{fontSize: 30}}>Hello!!!!!!!!!</Text>
      <Text style={{fontSize: 30}}>Hello!!!!!!!!!</Text>
      </ScrollView> }
      </View>
      {/* <Footer style={{
        backgroundColor: '#0086ca', 
        justifyContent: 'flex-end',
        alignItems:'center',
        height: wp('10%')
      }}>
        <Text>This is footer</Text>
      </Footer> */}
      </Container>
      </View>
    );
  }
}



export default HtmlTestScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});