import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import { Icon } from "native-base";
import _ from "lodash";
import { MaterialIcons, AntDesign} from "@expo/vector-icons";
import { Colors } from "../../../constants/ui/colorScheme";
import { fontType } from "../../../styles/font";
const styles= StyleSheet.create({
  title: {
    padding: 10,
    backgroundColor: Colors.background,
  },
  legendText: {
    fontSize: fontType("subFont"),
    fontFamily: "Inter",
    paddingLeft: 15,
    color: Colors.reglabels,
  },
  printReviewIconContainerStyle: {
    display: "flex",
    height: 20,
    width: 15,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.buttonwithbg,
  },
});

const DocumentLegendText = (props) => {
  return (
    <View
      style={[
        styles.title,
        {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        },
      ]}
    >
      <View>
        <Text
          style={{
            fontSize: fontType("Heading1"),
            fontFamily: "Inter",
          }}
        >
          Documents
        </Text>
      </View>
      <View
        style={[
          styles.title,
          {
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            flexWrap: "wrap",
          },
        ]}
      >
        <Text style={styles.legendText}>
          <Icon
            name="arrow-forward"
            style={{
              color: Colors.buttonwithbg,
              fontSize: fontType("subFont"),
            }}
          />
          {"  "}Signatures required
        </Text>
        <Text style={styles.legendText}>
          <AntDesign
            name="checkcircleo"
            size={fontType("subFont")}
            color={Colors.checkCircle}
          />
          {"  "}All required signatures captured
        </Text>
        <Text style={styles.legendText}>
          <AntDesign
            name="checkcircle"
            size={fontType("subFont")}
            color={Colors.checkCircle}
          />
          {"  "}All signatures captured
        </Text>
        <View
          style={[
            styles.printReviewIconContainerStyle,
            {
              backgroundColor: Colors.buttonwithbg,
              height: 12,
              width: 10,
              marginLeft: 10,
            },
          ]}
        >
          <MaterialIcons
            name="check"
            color={Colors.background}
            size={8}
          />
        </View>
        <Text style={[styles.legendText, { paddingLeft: 8 }]}>
          Print & Sign Verified{" "}
        </Text>
      </View>
    </View>
  )
}
export default DocumentLegendText;