import React, { Component } from 'react';
import { Text, View, Platform, Dimensions, ActivityIndicator, ScrollView } from 'react-native';
import styles from './styles/dashboardStyles';
import SubjectListScreen from '../../containers/subjectListPage';
import { MaterialIcons, MaterialCommunityIcons, AntDesign } from '@expo/vector-icons';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import api from '../../utils/api';
import { NavigationEvents } from 'react-navigation';
import { Colors } from '../../constants/ui/colorScheme';
import { getConsentVersionId } from '../../utils/consentData';
import { ConsentDataContext } from '../../providers/ConsentDataProvider';
import {fontType} from '../../styles/font'
import constants from "../../utils/constants";
const { ContextProperties } = constants

class Dashboard extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dashboardData: {
                totalCount: 0,
                DISCONTINUED: 0,
                NEEDS_SIGNATURE: 0,
                NEEDS_COUNTER_SIGNATURE: 0,
                CONSENTED: 0,
            }
        }
    }

    componentDidMount() {
        const { navigation } = this.props;
        this._unsubscribe = navigation.addListener('focus', () => {
            this.getDashboardData();
        });
    }
  
    componentWillUnmount() {
        this._unsubscribe();
    }    

    async getDashboardData() {
        const { studySite, isReconsent } = this.props;
        const consentVersionId = getConsentVersionId(this.context.languages);
        try {
            this.setState({ loading: true });
            if(isReconsent) {
                const res = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/dashboardDetails?reconsent=true`);
                this.setState({ loading: false });
                const dashboardData = {
                    totalCount: 0,
                    DISCONTINUED: 0,
                    NEEDS_SIGNATURE: 0,
                    NEEDS_COUNTER_SIGNATURE: 0,
                    CONSENTED: 0
                }
                res.data.forEach(element => {
                    dashboardData[element.status] = element.percentage;
                    dashboardData.totalCount = element.totalCount;
                });
                this.setState({
                    dashboardData,
                })
            } else {
                const res = await api.get(`${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/dashboardDetails`);
                this.setState({ loading: false });
                const dashboardData = {
                    totalCount: 0,
                    DISCONTINUED: 0,
                    NEEDS_SIGNATURE: 0,
                    NEEDS_COUNTER_SIGNATURE: 0,
                    CONSENTED: 0
                }
                res.data.forEach(element => {
                    dashboardData[element.status] = element.percentage;
                    dashboardData.totalCount = element.totalCount;
                });
                this.setState({
                    dashboardData,
                })
            }

        }
        catch (error) {
            this.setState({ loading: false });
            console.log(error);
        }
    }

    render() {
        const { navigation, storeSelectedSubject, studySite, isReconsent, user, consentTab ,organizationName} = this.props;
        const { dashboardData, loading } = this.state;
        return (
            <ScrollView style={styles.container}>
                {/* <NavigationEvents
                onWillFocus={() => {
                    this.getDashboardData();
                }}
                /> */}
                {/* Dashboard */}
                {loading ? (
                    <View  style={{ height: '3%', margin: wp('0.5%'), backgroundColor:Colors.background }}>
                    <ActivityIndicator size="large" color={Colors.ActivityIndicator} animating={true} hidesWhenStopped={true} style={{marginTop:hp('8%')}} />
                    </View>
                ) :
                <View >
                    {/* Headding View */}
                    <View style={styles.heddingView}>
                        <Text style={{fontSize: fontType('Heading1'), color: Colors.text, fontFamily:'Inter',textAlign:'left' }}>
                            {organizationName}
                        </Text>
                    </View>
                    {/* Content View */}
                    <View style={{flexDirection:'row', flexWrap:'wrap', backgroundColor:'#fff'}}>
                        <View style={{width:'20%', padding:3}}>
                            <View style={{ justifyContent: 'center', alignSelf: 'center' }}>
                                <View style={{ flexDirection: 'column', alignItems: 'center', marginBottom:2 }}>
                                    <Text style={styles.numberOfSubjects} >
                                        {dashboardData.totalCount}
                                    </Text>
                                    <View style={{ alignSelf: 'center' }}>
                                        <Text style={styles.nameOfHospitalText}>Subjects</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ width:'80%', flexDirection:'row', flexWrap:'wrap', padding:3, alignContent:'center'}}>
                        {!isReconsent && 
                                <View style={{height:'50%', width:'50%', padding:3}}>
                                        <View style={styles.component}>
                                            <View style={{ flexDirection: 'row', }}>
                                                <Text>
                                                    <MaterialCommunityIcons name='close-circle-outline' size={fontType('StatusIcon')} color={Colors.discontinued} />
                                                </Text>
                                                <View style={{ margin: wp('0.5%'), }}>
                                                    <Text style={{ color: Colors.discontinued, fontSize: fontType('Heading1'), fontFamily: 'Inter' }}>{dashboardData.DISCONTINUED}%</Text>
                                                    <Text style={{ color: Colors.discontinued, fontSize: fontType('BasicFont'), fontFamily: 'Inter', flexWrap:'wrap' }} >Discontinued</Text>
                                                </View>
                                            </View>
                                        </View>
                                </View>}
                                <View style={{height:'50%', width:'50%', padding:3}}>
                                    <View style={styles.component}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text>
                                                <MaterialCommunityIcons name='dots-horizontal-circle' size={fontType('StatusIcon')} color={Colors.needSign} />
                                            </Text>
                                            <View style={{ margin: wp('0.5%'), }}>
                                                <Text style={{ color: Colors.needSign, fontSize: fontType('Heading1'), fontFamily: 'Inter' }}>{dashboardData.NEEDS_SIGNATURE}%</Text>
                                                <Text style={{ color: Colors.needSign, fontSize: fontType('BasicFont'), fontFamily: 'Inter', flexWrap:'wrap' }} >Needs Signature</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{height:'50%', width:'50%', padding:3}}>
                                    <View style={styles.component}>
                                        <View style={{ flexDirection: 'row'}}>
                                            <Text>
                                                <MaterialCommunityIcons name='circle-slice-4' size={fontType('StatusIcon')} color={Colors.needsCounterSign} />
                                            </Text>
                                            <View style={{ margin: wp('0.5%'), }}>
                                                <Text style={{ color: Colors.needsCounterSign, fontSize: fontType('Heading1'), fontFamily: 'Inter' }}>{dashboardData.NEEDS_COUNTER_SIGNATURE}%</Text>
                                                <Text style={{ color: Colors.needsCounterSign, fontSize: fontType('BasicFont'), fontFamily: 'Inter'}} >Needs Counter Signature</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                {!isReconsent &&
                                <View style={{height:'50%', width:'50%', padding:3}}>
                                    <View style={styles.component}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text>
                                                <MaterialCommunityIcons name='checkbox-marked-circle' size={fontType('StatusIcon')} color={Colors.consentedColor} />
                                            </Text>
                                            <View style={{ margin: wp('0.5%'), }}>
                                                <Text style={{ color: Colors.consentedColor, fontSize: fontType('Heading1'), fontFamily: 'Inter' }}>{dashboardData.CONSENTED}%</Text>
                                                <Text style={{ color: Colors.consentedColor, fontSize: fontType('BasicFont'), fontFamily: 'Inter', flexWrap:'wrap' }} >Consented</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View> }
                        </View>
                    </View>
                </View>
                }
                {/* Subject List */}
                <View style={{ flex:1,backgroundColor: Colors.subjectListBackground}}>
                    <SubjectListScreen
                        isReconsent={isReconsent}
                        navigation={navigation}
                        storeSelectedSubject={storeSelectedSubject}
                        studySite={studySite}
                        consentTab={consentTab}
                    />
                </View>

            </ScrollView>


        )
    }
}
export default Dashboard;