import React, { Component } from "react";
import { View } from "react-native";
import {
  retrieveSubDocsData,
  retrieveAllVersionsSubDocsData,
} from "../actions/documents";
import { updateSelectedSubjectStatus } from "../actions/subject";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "../utils/api";
import SubjectDocumentsList from "../components/subjectList/subjectDocumentsList";
import showToast from "../utils/toast";
import { checkErrorStatusAndShowToast } from "../utils/errorMessageUtils";
import _ from "lodash";
import { printNSignIconVisible } from "../utils/consentData";
import { ConsentDataContext } from "../providers/ConsentDataProvider";
import { getConsentChapter } from "../utils/consentData";
import MyLoader from "../utils/webLoader";
import { NavigationEvents } from "@react-navigation/compat";
import { generatePdf, updateSignature } from "../actions/printNSign";
import { getCommentsCount, retrieveComments } from "../actions/comments";
import { getSignatureTypeOfSubject } from "../selectors/printNSign";
import { PrintNSignSignatureType } from "../components/common/constants";
import constants from "../utils/constants.js";
const { ContextProperties } = constants

class SubjectDocumentListScreen extends Component {
  static contextType = ConsentDataContext;
  constructor(props) {
    super(props);
    this.state = {
      afterSubmitLoading: false,
      subjectIdModal: false,
      onSubmitLoading: false,
    };
  }
  static navigationOptions = ({ navigation }) => ({
    title: "Signature",
  });

  // componentWillMount() {
  //     this.getSubjectDetails()
  // }

  componentDidMount() {
    this._isMounted = true;
    const { navigation } = this.props;
    this._unsubscribe = navigation.addListener("blur", () => {
      if (this._isMounted) {
        this.handleSubjectIdModal();
      }
    });
    if (this._isMounted) {
      this.getSubjectDetails();
    }
  }

  onFocus = () => {
    const { navigation } = this.props;
    this._unsubscribe = navigation.addListener("blur", () => {
      this.handleSubjectIdModal();
    });
    this.getSubjectDetails();
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._unsubscribe();
  }

  getSubjectDetails() {
    const {
      subjectConsentId,
      languageId,
      retrieveSubDocsData,
      selectedSubject,
      fetchAllVersionsSubDocsData,
    } = this.props;
    if (
      selectedSubject.status === "CONSENTED" ||
      selectedSubject.status === "DISCONTINUED"
    ) {
      fetchAllVersionsSubDocsData(selectedSubject);
    } else {
      retrieveSubDocsData(
        selectedSubject.locale,
        selectedSubject.id,
        languageId,
        subjectConsentId,
        selectedSubject.consentVersionId
      );
    }
  }

  submitConsent = async () => {
    const {
      selectedSubject,
      navigation,
      updateSubjectStatus,
      fetchAllVersionsSubDocsData,
      printNSignType,
    } = this.props;
    const { afterSubmitLoading } = this.state;
    const subject = {
      id: selectedSubject.id,
      usubjectId: selectedSubject.usubjectId,
      status: "CONSENTED",
      locale: selectedSubject.locale,
      email: selectedSubject.email,
    };
    const subjectConsentData = {
      status: "CONSENTED",
      consentVersion: {
        id: selectedSubject.consentVersionId,
      },
      signatureType:
        printNSignType == 2
          ? PrintNSignSignatureType.REQUIRED.key
          : PrintNSignSignatureType.NOT_ALLOWED.key,
    };
    try {
      if (afterSubmitLoading === false) {
        this.setState({ afterSubmitLoading: true });
        await api.post( `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/subjectConsents/${selectedSubject?.subjectConsent.id}/documents/submit?withoutDocuments=${false}`,
        { subject, subjectConsentData });
        this.setState({ afterSubmitLoading: false });
        updateSubjectStatus("CONSENTED");
        fetchAllVersionsSubDocsData(subject);
        showToast("Saved successfully.", "success", 2000);
        if (!selectedSubject.usubjectId) {
          setTimeout(() => {
            this.setState({ subjectIdModal: true });
          }, 0);
        }
      }
    } catch (error) {
      this.setState({ afterSubmitLoading: false });
      // showToast('Failed to save.', 'danger', 2000);
      checkErrorStatusAndShowToast(error, "Failed to save.");
      console.log(error);
    }
  };

  handleSubjectIdModal = () => {
    this.setState({ subjectIdModal: false });
  };

  getDocuments = () => {
    const { navigation, selectedSubject } = this.props;
    const documents = getConsentChapter(
      this.context.languages,
      selectedSubject.locale,
      "ICF_DOCUMENTS"
    );
    let documentPages = documents.pages;
    return documentPages;
  };

  generatePdfToSign = async (documentId) => {
    const { selectedSubject, generatePdf } = this.props;
    const pdfPath = await generatePdf(
      selectedSubject?.id,
      selectedSubject?.subjectConsent?.id,
      documentId
    );
    return pdfPath;
  };

  updateSignatureStatus = async (documentId, status) => {
    const { updateSignature, selectedSubject } = this.props;
    await updateSignature(
      selectedSubject.id,
      selectedSubject.consentVersionId,
      documentId,
      status
    );
  };

  render() {
    const {
      navigation,
      documents,
      loading,
      selectedSubject,
      isSessionActive,
      t,
      printNSignType,
      subject,
      commentsResolved,
    } = this.props;
    const { afterSubmitLoading, subjectIdModal, onSubmitLoading } = this.state;
    const documentPages = this.getDocuments();
    const showPrintIcon =
      printNSignType == 2 &&
      printNSignIconVisible(
        this.context.languages,
        "English",
        subject.isKnowledgeReviewDone,
        selectedSubject.subjectConsent
      );
    return (
      <View style={{ flex: 1 }}>
        <MyLoader active={afterSubmitLoading} />
        <NavigationEvents
          onWillFocus={() => {
            this.onFocus();
          }}
        />
        <SubjectDocumentsList
          navigation={navigation}
          documents={documents}
          documentPages={documentPages}
          submitConsent={this.submitConsent}
          loading={loading}
          selectedSubject={selectedSubject}
          subjectIdModal={subjectIdModal}
          handleSubjectIdModal={this.handleSubjectIdModal}
          isSessionActive={isSessionActive}
          t={t}
          showPrintIcon={showPrintIcon}
          generatePdfToSign={this.generatePdfToSign}
          updateSignatureStatus={this.updateSignatureStatus}
          printNSignType={printNSignType}
          commentsResolved={commentsResolved}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  subject: state.subject,
  documents: state.documents,
  selectedSubject: state.selectedSubject,
  loading: state.loading,
  printNSignType: getSignatureTypeOfSubject(state),
  studySite: state.studySite,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveSubDocsData,
      updateSubjectStatus: updateSelectedSubjectStatus,
      fetchAllVersionsSubDocsData: retrieveAllVersionsSubDocsData,
      generatePdf,
      updateSignature,
      getCommentsCount,
      retrieveComments,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectDocumentListScreen);
