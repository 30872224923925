import { useContext } from 'react';
import _ from 'lodash';
// import * as languages from '../db/index';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {decode as atob} from 'base-64'


export const getConsentVersionId = (languages) => {    
    return languages['English']['consentVersionId'];
}

export const getConsentData = (languages) => {
    const lang = languages['English'];
    const data = {
        consentVersionId : lang.consentVersionId,
        consentVersionName : lang.versionName,
        consentName: lang.consentName,
    }
    return data;
}

export const getConsentDetails = (languages, language) => {
    if(languages != null) {
        if(languages[language]) {
            const studyNum = languages[language]['studyNumber'];
             const versioName = languages[language]['versionName'];
            return `${studyNum}  -  ${versioName}`;
        } else return '';
        
    }else return '';

}

export const getStudyNumber = (languages, language) => {
    if(languages != null) {
        if(languages[language]) {
            const studyNum = languages[language]['studyNumber'];
            return studyNum;
        } else return '';
        
    }else return '';

}

export const getBuildVersion = (languages, language) => {
    if(languages != null) {
        if(languages[language]) {
             const buildVersion = languages[language]['versionName'];
            return  buildVersion;
        } else return '';
        
    }else return '';

}

export const getConsentVersionName = (languages, language) => {
    if (languages != null) {
        if (languages[language]) {
            return languages[language].versionName;
        } else return '';
    } else return '';
}

export const getAskForLAR = (languages, language) => {
    if (languages != null) {
        if (languages[language]) {
            return languages[language].askForLAR;
        } else return false;

    } else return false;
}

export const getLanguageById = (languageId) => {
    var language ;
    Object.keys(languages).map( key => {
        if(languages[key].languageId == languageId){
            language = key;
        }
    });
    return language;
}

export const getLanguageIdByLocale = (languages,locale) => {
   const language = _.find(languages, { 'language': locale });
    return language.id;
}

export const getConsentName = (languages, language) => {
    if (languages != null) {
        if (languages[language]) {
            return languages[language].consentName;
        } else return '';

    } else return '';
}

export const getMaxOrdinalChapter = (chapters) => {
    const maxOrdinalChapter =  _.maxBy(chapters, (chapter) => chapter.ordinal);
    return maxOrdinalChapter;
}

export const getMinOrdinalChapter = (chapters) => {
    const minOrdinalChapter = _.minBy(chapters, (chapter) => chapter.ordinal);
    return minOrdinalChapter;
}

export const getPreviousChapters = (languages, language, currOrdinal) => {
    const chapters = [...languages[language].chapters];
    const prevChapters = _.filter(chapters, (chapter) => chapter.ordinal < currOrdinal)
    return prevChapters;
}

export const getRemainingChapters = (languages, language, currOrdinal) => {
    const chapters = [...languages[language].chapters];
     const remainingChapters = _.filter(chapters, (chapter) => chapter.ordinal > currOrdinal)
    return remainingChapters;
}

export const onSelectingPrevChapter = (languages,language, currOrdinal, navigation) => {
    const prevChapters = getPreviousChapters(languages, language, currOrdinal);
    const maxOrdinalChptr = getMaxOrdinalChapter(prevChapters);
    checkChapterTypeAndNavigate(maxOrdinalChptr, navigation);
}

export const onSelectingNextChapter = (languages,language, currOrdinal, navigation,isRegistered,signatureType,isKnowledgeReviewDone,storeVisitedChapters,visitedChapters, isKrDone, krDoneBy) => {
    const nextChapters =  getRemainingChapters(languages, language, currOrdinal);
    const minOrdinalChapter =  getMinOrdinalChapter(nextChapters);
    const askForLAR = getAskForLAR(languages, language);
    if(visitedChapters < currOrdinal+1) {
        storeVisitedChapters(currOrdinal+1);
    }
    // if(minOrdinalChapter==undefined){
    //     navigation.navigate('SubjectRegistrationForm',{ordinal: 7})
    // } else {
        checkChapterTypeAndNavigate(minOrdinalChapter, navigation,isRegistered,signatureType,isKnowledgeReviewDone,askForLAR, isKrDone, krDoneBy);
    // }

}

export const prevButtonVisible = (languages, language, currOrdinal ) => {
    const prevChapters = getPreviousChapters(languages, language, currOrdinal);
    if(prevChapters.length > 0) {
        return true;
    }
    return false;
}

export const checkChapterTypeAndNavigate = (chapter, navigation,isRegistered,
    signatureType,isKnowledgeReviewDone,askForLAR, isKrDone, krDoneBy = null) => {
    const isCompletedKr1stFlow = isKnowledgeReviewDone;
    // if(chapter.chapterType==undefined){
    //     navigation.navigate('SubjectRegistrationForm',{ordinal: 7})
    // }
    switch(chapter.chapterType) {
        case 'STUDY_INFORMATION': {
            navigation.navigate('StudyInfoScreen', {ordinal: chapter.ordinal});
            break;
        }
        case 'STUDY_VIDEO': {
            navigation.navigate('StudyVideoListScreen', {ordinal: chapter.ordinal});
            break;
        }
        case 'KNOWLEDGE_REVIEW': {
            if(isKrDone || isCompletedKr1stFlow){
                // if(isCompletedKr1stFlow && signatureType && _.isEqual(signatureType, krDoneBy)){
                //     navigation.replace('CongratulationsScreen', {currChapterOrdinal : chapter.ordinal});
                // } else {
                //     navigation.replace('SubDocsPage', {signatureType: 1});
                // }
                navigation.navigate('CommentsReviewScreen', {signatureType: 1});
            }else {
                navigation.navigate('KnowledgeReviewScreen', {ordinal: chapter.ordinal});
            }
            break;
        }
        case 'ICF_DOCUMENTS': {
            navigation.navigate('DocumentsScreen', {ordinal: chapter.ordinal});
            break;
        }
        case 'COMMENTS_REVIEW': {
            navigation.navigate('CommentsReviewScreen', {ordinal: chapter.ordinal});
            break;
        }
        case 'REGISTRATION_FORM': {
            if(isRegistered){
               
                    navigation.navigate('SubDocsPage', {signatureType: signatureType});
            }
            // else {
            //     navigation.navigate('SubjectRegistrationForm', {ordinal: chapter.ordinal});
            // }
            break;
        }
        case 'SIGNATURE': { 
            // if(askForLAR) {
            //     navigation.navigate('AskForLarPage', {ordinal: chapter.ordinal});    
            // } else {
                navigation.navigate('SubDocsPage', {signatureType: 1});
            // }
            break;
        }
        default:{
            return null;
        }
    }
}

export const getLanguageList = (languages) => {
    return _.sortBy(Object.keys(languages).map(key => ({
        id: languages[key].languageId,
        language: key,
        languageName: key.charAt(0).toUpperCase() + key.slice(1),
        irbApprovedVersion: languages[key].irbApprovedVersion
    })), ['language']);
}

export const getLanguageData = (languages,language) => {
    return languages[language]?.chapters || [];
}

export const getConsentChapter = (languages, language, chapterType) => {
    if (languages != null) {
        if (languages[language]) {
            const consentLanguage = languages[language].chapters;
            let chapter = consentLanguage.filter(chapter => chapter.chapterType === chapterType)[0];
            chapter = {
                ...chapter,
                quizItems: _.map(chapter.quizItems, qitm => ({
                    ...qitm,
                    options: qitm.options !== '' && qitm.options !== null ? JSON.parse(atob(qitm.options)) : atob(qitm.options),
                })),
                formFields: _.filter(chapter.formFields, ff => ff.isRequired),
            }
            return chapter;
        } else return '';

    } else return '';

}

export const getDocumentSignature = (signatureChapter, documnetSignatureID) => {
    return signatureChapter.documentSignatures.filter(ds => ds.id === documnetSignatureID)[0];
}


export const getDocSignatureBySignType = (languages, documentSignatureList, documentId, documentSignatureId) => {
    let documentSignatures = documentSignatureList[documentId];
    let mstrDocSignList = getConsentChapter(languages, documentSignatures[0].language, 'SIGNATURE').documentSignatures;
    mstrDocSignList = mstrDocSignList.filter(mds => mds.documentId.toLowerCase() === documentId.toLowerCase());
    documentSignatures = documentSignatures.map(ds => {
        return {
            ...ds,
            documentSignatureId: _.find(mstrDocSignList, mds => mds.signatureType === ds.signatureType)?.id,
            isEsignRequired: _.find(mstrDocSignList, mds => mds.signatureType === ds.signatureType)?.isEsignRequired,
        };
    });
    const ds = documentSignatures.filter(ds => ds.documentSignatureId === documentSignatureId)[0];
    return ds;
}

export const getVersionMetadata = (documentId, documents, metadataList) => {
    let consentVersionId = documents[documentId][0]?.consentVersionId
    return metadataList[consentVersionId];
}


export const buildSubmitSubjectData = (subject) => ({
    subject: buildSubjectData(subject),
})

const buildSubjectSignaturesData = (subject) => subject.subjectDocumentSignatures[0].signature;

//------------------This for offline purpose------------
export const buildSubjectData = (subject, studySite) => {
    let subjectData = {};
    subjectData = {
        consentId: subject.consentId || null,
        studySite: {
            id: studySite.studySiteId
        },
        usubjectId: subject.usubjectId,
        firstName:  subject.firstName,
        lastName: subject.lastName,
        dob: subject.dob,
        gender: subject.gender,
        phoneNo: subject.phoneNo,
        status: 'NEEDS_COUNTER_SIGNATURE',
        enrollStatus: null,
        languageId: subject.languageId,
        formData: subject.formData,
        quizAttempts: subject.quizAttempts,
        subjectDocumentSignatures: subject.subjectDocumentSignatures.map(sds => {
            return { 
                documentSignature: {
                    id: sds.id
                },
                signedBy: sds.signedBy,
                signatureFileName: sds.fileName,
                signatureDate: sds.signatureDate,
                imageUri: sds.signature,
                larRelation: sds.larRelation,
            }
        }),
        consentedOn: moment().format("YYYY-MM-DD"),
        originatingTimezone: momentTz.tz.guess(true),
    }
    return subjectData;
}

//----------To save registered data ---------------------------
export const buildSubjectRegisteredData = (subject, studySite, getConsentVersionId) => {
    let subjectData = {};
    subjectData = {
        consentId: subject.consentId || null,
        studySite: {
            id: studySite.studySiteId
        },
        id: null,
        registered: true,
        usubjectId: subject.usubjectId,
        firstName:  subject.firstName,
        lastName: subject.lastName,
        dob: subject.dob,
        gender: subject.gender,
        phoneNo: subject.phoneNo,
        status: 'NEEDS_SIGNATURE',
        enrollStatus: null,
        currentConsentVersionId: getConsentVersionId,
        languageId: subject.languageId,
        locale: subject.language,
        formData: subject.formData,
        quizAttempts: subject.quizAttempts,
    }
    return subjectData;
}

export const buildSubjectConsentData = (ConsentData, user, subjectNavTours, subject) => {
    let subjectConsentData = {};
    subjectConsentData = {
        id: null,
        status: 'NEEDS_SIGNATURE',
        consentVersion : {
            id:  ConsentData.consentVersionId,
        },
        isKrDone: subject.isKnowledgeReviewDone,
        consentVersionLanguage : {
            id: subject.languageId,
        },
        consentVersionName : ConsentData.consentVersionName,
        consentName: ConsentData.consentName,
        supervisedBy: user.fullName,
        navTours: subjectNavTours,
        quizAttempts: subject.quizAttempts,
    }
    return subjectConsentData;
}

// ---------------- Save signatures to the registered subject ------------------
export const buildSubjectSignatureData = (subject, captureConsentedOn) => {
    let subjectData = {};
    subjectData = {
        id: subject.id,
        usubjectId: subject.usubjectId,
        enrollStatus: null,
        consentedOn: captureConsentedOn ? moment().format("YYYY-MM-DD") : null,
        consentedOnString: captureConsentedOn ? moment().format("YYYY-MM-DD") : null,
        originatingTimezone: momentTz.tz.guess(true),
        subjectDocumentSignatures: subject.subjectDocumentSignatures.map(sds => {
            return { 
                documentSignature: {
                    id: sds.documentSignatureId,
                    chapterPage: {
                        id: sds.documentPageId,
                    }
                },
                id: sds.id,
                signedBy: sds.signedBy,
                signatureFileName: sds.fileName,
                signatureDate: sds.signatureDate,
                imageUri: sds.signature,
                larRelation: sds.larRelation != null ? sds.larRelation : sds.signatureType ==2 ? subject.larRelation : null,
            }
        }),
    }
    return subjectData;
}

export const getFilteredDocuments = (chapter, currentUserType, subjectConsentDocuments, larAsWitness = false) => {
    const pages = chapter.pages;
    const requiredPages = [];
    _.map(subjectConsentDocuments, sd => {
        const page = _.filter(pages, p => _.isEqual(_.toLower(p.id), _.toLower(sd.chapterPage.id)))[0];
        const signatures = _.filter(sd.subjectDocumentSignatures , sds => _.isEqual(sds.documentSignature.signatureType, currentUserType));
        if(signatures.length){
            // const doc = _.filter(pages, p => _.isEqual(_.toLower(p.id), _.toLower(page.id)))
            requiredPages.push(page);
        }
        
    });
    const data = _.sortBy(requiredPages, p => p.ordinal);
    if(currentUserType == 2 && larAsWitness){
        let loPages = []
        _.map(subjectConsentDocuments, sd => {
            const page = _.filter(pages, p => _.isEqual(_.toLower(p.id), _.toLower(sd.chapterPage.id)))[0];
            const signatures = _.filter(sd.subjectDocumentSignatures , sds => _.isEqual(sds.documentSignature.signatureType, 4));
            if(signatures.length){
                // const doc = _.filter(pages, p => _.isEqual(_.toLower(p.id), _.toLower(page.id)))
                loPages.push(page);
            }
            
        });
        loPages = _.sortBy(loPages, p => p.ordinal);
        _.map(loPages, p => data.push(p));
    }
    return data;
}

export const getFilteredDocumentSignatures = (currentUserType, subjectConsentDocuments, larAsWitness = false) => {
    const requiredSigns = [];
    subjectConsentDocuments = _.sortBy(subjectConsentDocuments, sd => sd.ordinal);
    _.map(subjectConsentDocuments, sd => {
        const signatures = _.filter(sd.subjectDocumentSignatures , sds => _.isEqual(sds.documentSignature.signatureType, currentUserType));
        if(signatures.length){
            _.map(signatures, s =>  {
                s.documentId = sd.chapterPage.id,
                requiredSigns.push(s);
            })
        }
        
    });
    if(currentUserType == 2 && larAsWitness){
        _.map(subjectConsentDocuments, sd => {
            const signatures = _.filter(sd.subjectDocumentSignatures , sds => _.isEqual(sds.documentSignature.signatureType, 4));
            if(signatures.length){
                _.map(signatures, s =>  {
                    s.documentId = sd.chapterPage.id,
                    requiredSigns.push(s);
                })
            }
            
        });
    }
    return requiredSigns;
}

export const printNSignIconVisible = (languages, language, isKnowledgeReviewDone, subjectConsent ) => {
    const chapters = [...languages[language].chapters];
    const knRvCh = _.filter(chapters, ch => ch.chapterType == 'KNOWLEDGE_REVIEW')
    if(!_.isEmpty(knRvCh)){
       return isKnowledgeReviewDone || subjectConsent.isKrDone
    }else{
        const docCh = _.filter(chapters, ch => ch.chapterType == 'ICF_DOCUMENTS')  
        if(!_.isEmpty(docCh)){
            return subjectConsent.isDocReviewed
        }
    }
    return false;
}

export const isKnowledgeReviewChapterPresent = (languages, language, isKnowledgeReviewDone, subjectConsent ) => {
    const chapters = [...languages[language].chapters];
    const knRvCh = _.filter(chapters, ch => ch.chapterType == 'KNOWLEDGE_REVIEW')
    if(!_.isEmpty(knRvCh)){
       return isKnowledgeReviewDone || subjectConsent.isKrDone
    }else{
       return false
    }
}