import { Container } from 'native-base';
import React, { Component } from 'react';
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native';
import styles from './styles/documentSignStyles';
import { Colors } from '../../constants/ui/colorScheme';
import moment from 'moment-timezone';
import ChapterPage from '../documents/ChapterPage';
import { chapterButtonStyles } from '../../styles/constStyles';
import DialogBox from '../counterSign/DialogBox';
import { ConsentDataContext } from '../../providers/ConsentDataProvider';
import { getConsentChapter, getDocumentSignature } from '../../utils/consentData';
import api from '../../utils/api';
import showToast from '../../utils/toast';
import constants from '../../utils/constants';
import PropTypes from 'prop-types';


class SubjectDocumentSign extends Component {
    static contextType = ConsentDataContext;
    state = {
        validateFormFields: null,
        scrollRef: null,
        showDialog: false,
        subjectDeclaration: '',
    }

    formatDate = (actualDate) => {
        if (actualDate) {
            var timeZone = moment.tz.guess();
            return moment.tz(actualDate, timeZone).format("DD-MMM-YYYY").toUpperCase();
        }
        return null;
    };

    setChapterPageValidation = (validateFormFields) => {
        this.setState({
            validateFormFields,
        })
    }

    checkIfFormFieldsFilled = () => {
        const { validateFormFields } = this.state;
        if (validateFormFields === null) {
            return true;
        } else {
            const validation = validateFormFields();
            return validation;
        }
    }

    setScrollRefToDocs = (ref) => {
        const { scrollRef } = this.state;
        if (scrollRef == null && ref != null) {
            this.setState({
                scrollRef: ref,
            });
        }
    }

    closeSubESignPopup = (authorized) => {
        const { navigation, signType, subjectDocumentSignature } = this.props;
        if (authorized) {
            this.setState(prevState => ({
                ...prevState,
                showDialog: false
            }), () => {
                subjectDocumentSignature.documentSignature.signatureType === 1 ? navigation.navigate('SubjectSignatureScreen', { subjectDocumentSignatureId: subjectDocumentSignature.id, signType: signType, documentPageId: subjectDocumentSignature.documentId, documentSignatureId: subjectDocumentSignature.documentSignature.id }) : navigation.navigate('LARSignatureScreen', { subjectDocumentSignatureId: subjectDocumentSignature.id, signType: signType, documentPageId: subjectDocumentSignature.documentId, documentSignatureId: subjectDocumentSignature.documentSignature.id });
            })
        } else {
            this.setState({ showDialog: false })
        }
    }

    onTapToSignClicked = (scrollRef, subjectDocumentSignature, navigation, signType) => {
        const { subject, printNSignType } = this.props;
        const signature = getConsentChapter(this.context.languages, subject.language, "SIGNATURE");
        const ds = getDocumentSignature(signature, subjectDocumentSignature.documentSignature.id);
        this.setState({ subjectDeclaration: subjectDocumentSignature.documentSignature.declaration });
        if (this.checkIfFormFieldsFilled()) {
            scrollRef._root?.scrollToPosition(0, 0);
            if (subject.isRemoteConsent) {
                this.setState({
                    showDialog: true,
                    declarationFormFields: ds.formFields,
                    docId: ds.documentId,
                    docSignId: ds.id
                });
            } else {

                if (printNSignType == 2 && (subjectDocumentSignature.documentSignature.signatureType == 1 || subjectDocumentSignature.documentSignature.signatureType == 2)) {
                    navigation.navigate('PrintNSignFlow');
                } else {
                    switch (subjectDocumentSignature.documentSignature.signatureType) {
                        case 1:
                            navigation.navigate('SubjectSignatureScreen', { subjectDeclaration: ds.declaration, subjectDocumentSignatureId: subjectDocumentSignature.id, signType: signType, documentPageId: subjectDocumentSignature.documentId, documentSignatureId: subjectDocumentSignature.documentSignature.id });
                            break;
                        case 2:
                            navigation.navigate('LARSignatureScreen', { larDeclaration: ds.declaration, subjectDocumentSignatureId: subjectDocumentSignature.id, signType: signType, documentPageId: subjectDocumentSignature.documentId, documentSignatureId: subjectDocumentSignature.documentSignature.id });
                            break;
                        case 4:
                            navigation.navigate('WitnessSignatureScreen', { witnessDeclaration: ds.declaration, subjectDocumentSignatureId: subjectDocumentSignature.id, signType: signType, documentPageId: subjectDocumentSignature.documentId, documentSignatureId: subjectDocumentSignature.documentSignature.id });
                            break;
                    }
                }

            }
        }
    }

    skipSelectedDocument = async (document) => {
        const { screenProps: { t }, selectedSubject, skipDocument } = this.props;
        try {
            const res = await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${selectedSubject?.id}/subjectConsents/${selectedSubject.subjectConsent.id}}/documents/${document.id}?skipDocument=true`);
            await skipDocument(res.data.id);
        } catch (error) {
            console.log(error);
            showToast(t('SkipFail'), 'danger', 3000);
        }
    }

    render() {
        const { scrollRef, subjectDeclaration, showDialog, declarationFormFields, docId, docSignId } = this.state;
        const { subjectDocumentSignature, documentsChapter, currentUserType, navigation, disableTapToSign, subject, screenProps: { t }, signType, selectedSubject, subDocSignNavProps,
            storeDeclarationFormData, formDataList, canSkipTheDocument } = this.props;
        const document = documentsChapter.pages.filter(doc => doc.id === subjectDocumentSignature.documentId)[0];
        return (
            <Container style={signatureStyles.container}>

                {
                    !showDialog && <>
                        <View style={signatureStyles.titleConatiner}>
                            <Text style={styles.pageTitle}>{document.pageTitle}</Text>
                        </View>
                        <View style={signatureStyles.documentContainer}>
                            <ChapterPage t={t} animateVisible={true} currentDoc={document} location="SubjectDocumentSign"
                                setChapterPageValidation={this.setChapterPageValidation}
                                setScrollRefToDocs={this.setScrollRefToDocs}
                            />
                        </View>
                        <View style={signatureStyles.footerConatiner}>
                            <View style={signatureStyles.versionInfoStyle}>
                                {document.irbApprovedOn ? <Text style={signatureStyles.versionInfoTextStyle}>{t('IRBApprovedDate')}: {document.irbApprovedOn}</Text> : <Text />}
                                {document.documentVersion ? <Text style={signatureStyles.versionInfoTextStyle}>  {t('Version')}: {document.documentVersion} </Text> : <Text />}
                            </View>
                            {!disableTapToSign &&
                                <View style={signatureStyles.footerButtonsContainer}>
                                    <View>
                                        {
                                            (canSkipTheDocument(signType, document.id)) && document.isOptionalDocument &&
                                            <TouchableOpacity transparent iconLeft onPress={() => {
                                                this.skipSelectedDocument(document);
                                            }}
                                                style={chapterButtonStyles.button}>
                                                <Text style={chapterButtonStyles.buttonTxt}>{t('Skip')}</Text>
                                            </TouchableOpacity>
                                        }

                                    </View>
                                    <View>
                                        <TouchableOpacity transparent
                                            disabled={disableTapToSign}
                                            onPress={() => {
                                                this.onTapToSignClicked(scrollRef, subjectDocumentSignature, navigation, signType);
                                            }}
                                            style={chapterButtonStyles.button}
                                        >
                                            <Text style={chapterButtonStyles.buttonTxt}>{t('TapToSign')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            }
                        </View>
                    </>
                }
                {showDialog &&
                    <DialogBox
                        currentUserType={currentUserType}
                        navigation={navigation}
                        showDialog={showDialog}
                        closePopup={this.closeSubESignPopup}
                        subjectEsignPopup={true}
                        subject={subject}
                        selectedDocSignDeclaration={subjectDeclaration}
                        t={t}
                        subjAuth={true}
                        selectedSubject={selectedSubject}
                        subDocSignNavProps={subDocSignNavProps}
                        signatureType={signType}
                        declarationFormFields={declarationFormFields}
                        docId={docId}
                        docSignId={docSignId}
                        storeDeclarationFormData={storeDeclarationFormData}
                        formDataList={formDataList}
                    />
                }
            </Container>
        )
    }

}

const signatureStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
    titleConatiner: {
        backgroundColor: Colors.background,
        zIndex: 2
    },
    documentContainer: {
        flex: 1,
        paddingBottom: 5,
        marginBottom: 10
    },
    footerConatiner: {
        flexDirection: 'column',
        backgroundColor: 'rgb(145, 85, 253)'
    },
    versionInfoStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    versionInfoTextStyle: {
        color: 'white'
    },
    footerButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    }
})

SubjectDocumentSign.defaultProps = {
    subjectDocumentSignature: {},
    disableTapToSign: true,
    canSkipTheDocument: false,
    subDocSignNavProps: {},
    formDataList: []
}
SubjectDocumentSign.propTypes = {
    subjectDocumentSignature: PropTypes.instanceOf(Object),
    documentsChapter: PropTypes.instanceOf(Object).isRequired,
    currentUserType: PropTypes.number.isRequired,
    disableTapToSign: PropTypes.bool,
    navigation: PropTypes.instanceOf(Object).isRequired,
    subject: PropTypes.instanceOf(Object).isRequired,
    screenProps: PropTypes.instanceOf(Object).isRequired,
    signType: PropTypes.number.isRequired,
    selectedSubject: PropTypes.instanceOf(Object).isRequired,
    subDocSignNavProps: PropTypes.instanceOf(Object),
    storeDeclarationFormData: PropTypes.func.isRequired,
    formDataList: PropTypes.arrayOf(Object),
    canSkipTheDocument: PropTypes.bool,
    printNSignType: PropTypes.number.isRequired,
    skipDocument: PropTypes.func.isRequired
}
export default SubjectDocumentSign