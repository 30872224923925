import React, { Component } from "react";
import { ActivityIndicator, Text, View, Platform } from "react-native";
import Dialog, { DialogContent } from "react-native-popup-dialog";
import Spinner from "react-native-loading-spinner-overlay";
import { Colors } from "../constants/ui/colorScheme";
import { store } from "../store/configureStore";
import { useSelector } from "react-redux";

export default function APPLoader({ active, children }) {
  const isSessionTimeout = useSelector((state) => state?.session?.timeout);
  if (Platform.OS !== "web") {
    return (
      <Spinner
        size="large"
        visible={active && !isSessionTimeout}
        color={Colors.spinner}
        overlayColor="rgba(0, 0, 0, 0)"
      />
    );
  } else if (Platform.OS === "web") {
    return (
      <Dialog
        overlayOpacity={0.2}
        visible={active && !isSessionTimeout}
        rounded={false}
        dialogStyle={{ backgroundColor: "transparent" }}
      >
        <ActivityIndicator
          animating={active && !isSessionTimeout}
          size="large"
          color={Colors.spinner}
        />
      </Dialog>
    );
  }
}
