import React, { Component } from "react";
import {
  View,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Text,
  FlatList,
  Image,
  ActivityIndicator,
} from "react-native";
import { Container, Content, Card, Footer, Icon } from "native-base";
import AnimatedHideView from "react-native-animated-hide-view";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { Video } from "expo-av";
import { ScreenOrientation } from "expo";
import ChapterTitle from "../chapterTitle/ChapterTitle";
import {
  onSelectingNextChapter,
  onSelectingPrevChapter,
  prevButtonVisible,
} from "../../utils/consentData";
import { Colors } from "../../constants/ui/colorScheme";
import { NavigationEvents } from "react-navigation";
import moment from "moment";
import {
  getSubjectPageExitTime,
  getOverallTimeSpentBySubject,
  buildSubjectNavTour,
} from "../../utils/subjectNavTourUtils";
import CheckBox from "react-native-check-box";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import { AsyncStorage } from "react-native";
import NoDataPage from "../emptyData/NoDataPage";
import { fontType } from "../../styles/font";
import { chapterButtonStyles } from "../../styles/constStyles";
import { th } from "date-fns/locale";
import BottomSheet from "../common/bottomSheet/bottomSheet";
import CommentsPage from "../comments/commentsContainer";
import { ChapterType } from "../common/constants";
import { getConsentVersionId } from "../../utils/consentData";
const childRef = React.createRef();
export default class StudyVideo extends Component {
  static contextType = ConsentDataContext;

  constructor(props) {
    super(props);
    this._video = [];
    this.state = {
      resizeMode: "contain",
      currentTime: 0.0,
      isBuffering: false,
      currentOrdinal: 1,
      animatedVisible: true,
      chapterTitle: "Study Video",
      shouldPlay: false,
      subjectPageEntryTime: "",
      mountedCount: 0,
      media: null,
      scrollRef: null,
      videoRes: [],
      allVideosLoaded: false,
      loadedVideos: [],
      videoPlaybackStatus: [],
      openComments: false,
      enableNext: true,
      presignedUrls: {}
    };
  }

  componentDidMount() {
    const { stdVideoData, navigation } = this.props;
    this.getVideoListChangePlayBackStatus();
    this._unsubscribe = navigation.addListener("focus", () => {
      if (stdVideoData.pages.length > 0) {
        this.GotoInitialPage();
        this.getAssets();
        this.retrieveUrlsById();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentOrdinal } = this.state;
    if (prevState.currentOrdinal !== currentOrdinal) {
      this.getVideoListChangePlayBackStatus();
      this.retrieveUrlsById();
    }
  }

  retrieveUrlsById = async () => {
    const {stdVideoData, findVideoUrlById, studyId, selectedSubject } = this.props;
    const { currentOrdinal, presignedUrls } = this.state;
    const videoPages = stdVideoData.pages;
    const consentVersionId = getConsentVersionId(this.context.languages);
    let currentPage = videoPages.filter(
      (chapter) => chapter.ordinal === currentOrdinal
    )[0];
    if(!_.isEmpty(currentPage) &&!_.isEmpty(currentPage.multiMediaContent)) {
        _.forEach(currentPage.multiMediaContent, async (mv) => {
          const url = await findVideoUrlById(studyId, selectedSubject.consentVersionId, selectedSubject.subjectConsentId, currentPage.id, mv.url);
          this.setState((prevState) => ({
            ...prevState,
            presignedUrls:{
              ...prevState.presignedUrls,
              [mv.videoName]:url.url
            },
          }));
        });
      }
  };

  componentWillUnmount() {
    this._unsubscribe();
  }

  getVideoListChangePlayBackStatus = () => {
    const videoList = this.getVideoList();
    let videoData = [];
    for (let i = 0; i < videoList.length; i++) {
      const obj = {
        videoIndex: i,
        playbackStatus: videoList[i].requiredPlayback ? false : true,
      };
      videoData.push(obj);
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        videoRes: [],
        videoPlaybackStatus: videoData,
      }),
      () => this.enableNextChapter()
    );
  };

  getAssets = async () => {
    try {
      const assets = await AsyncStorage.getItem("assets");
      const loAssets = JSON.parse(assets);
    } catch (error) {
      console.log(error);
    }
  };

  GotoInitialPage = () => {
    this.setState({ currentOrdinal: 1 }, () => this.callMount());
  };

  onProgress = (data) => {
    this.setState({ currentTime: data.currentTime });
  };
  onBuffer = () => {
    this.setState({ isBuffering: true });
  };

  changeCurrentOrdinal = (btnName) => {
    const {
      navigation,
      subjectData,
      isRegistered,
      screenProps: { t },
      stdVideoData,
      storeVisitedChapters,
      selectedSubject,
      krDoneBy,
    } = this.props;
    const { currentOrdinal, subjectPageEntryTime } = this.state;
    const currentChapterOrdinal = navigation.getParam("ordinal");
    this.setState({ animatedVisible: false });

    if (btnName === t("Next")) {
      if (stdVideoData.pages.length > 0) {
        setTimeout(() => {
          this.setState(
            {
              subjectPageEntryTime: moment().format(),
              currentOrdinal: currentOrdinal + 1,
              animatedVisible: true,
            },
            () => this.setExitTimeAndStoreNavTour()
          );
        }, 200);
      } else {
        onSelectingNextChapter(
          this.context.languages,
          subjectData.language,
          currentChapterOrdinal,
          navigation,
          subjectData.isRegistered,
          subjectData.signatureType,
          subjectData.isKnowledgeReviewDone,
          storeVisitedChapters,
          subjectData.visitedChapters,
          selectedSubject.subjectConsent.isKrDone,
          krDoneBy
        );
      }
    } else if (btnName == t("Previous")) {
      setTimeout(() => {
        this.setState(
          {
            subjectPageEntryTime: moment().format(),
            currentOrdinal: currentOrdinal - 1,
            animatedVisible: true,
          },
          () => this.setExitTimeAndStoreNavTour()
        );
      }, 200);
    }
  };

  setExitTimeAndStoreNavTour = () => {
    const { updateSubjectNavTour, stdVideoData } = this.props;
    updateSubjectNavTour();
    if (stdVideoData.pages.length > 0) {
      this.insertSubNavTour();
    }
  };

  callMount = () => {
    const { updateSubjectNavTour } = this.props;
    updateSubjectNavTour();
    this.insertSubNavTour();
  };

  insertSubNavTour = () => {
    const { stdVideoData, storeSubjectNavTour } = this.props;
    const currentPage = this.getCurrentPage();
    const obj = buildSubjectNavTour(
      moment().format(),
      null,
      stdVideoData.id,
      currentPage.id,
      0
    );
    storeSubjectNavTour(obj);
  };

  getButtonNameBasedOnCurrentOrdinal = () => {
    const {
      stdVideoData,
      screenProps: { t },
    } = this.props;
    const { currentOrdinal } = this.state;
    if (
      stdVideoData.pages.length === currentOrdinal ||
      stdVideoData.pages.length < 0
    ) {
      return t("NextChapter");
    } else return t("Next");
  };

  getPreviousButtonNameBasedOnCurrentOrdinal = () => {
    const {
      screenProps: { t },
    } = this.props;
    const { currentOrdinal } = this.state;
    if (currentOrdinal === 1) {
      return t("PreviousChapter");
    } else return t("Previous");
  };

  hasPreviousPage = () => {
    const { currentOrdinal } = this.state;
    return currentOrdinal > 1 ? true : false;
  };

  getVideoList = () => {
    const { currentOrdinal } = this.state;
    const { stdVideoData } = this.props;
    const videoPages = stdVideoData.pages;
    let currentPage = videoPages.filter(
      (chapter) => chapter.ordinal === currentOrdinal
    )[0];
    const videoList = currentPage.multiMediaContent;
    return videoList;
  };

  asyncVideo = () => {
    const videoList = this.getVideoList();
    videoList.forEach(async (video) => {
      this[`_video${videoList.indexOf(video)}`].stopAsync();
    });
  };

  hasPreviousChapter = () => {
    const { navigation } = this.props;
    const currChapterOrdinal = navigation.getParam("ordinal");
    const {
      screenProps: { locale },
    } = this.props;
    return prevButtonVisible(
      this.context.languages,
      locale,
      currChapterOrdinal
    );
  };

  onReadyForDisplay = () => {
    const videoList = this.getVideoList();
    videoList.forEach(async (video) => {
      this[`_video${videoList.indexOf(video)}`].setStatusAsync({
        useNativeControls: true,
      });
    });
  };

  onPlaybackStatusUpdate = (playbackStatus, currVideoIndex) => {
    const { videoPlaybackStatus } = this.state;
    const videoList = this.getVideoList();
    if (
      videoList[currVideoIndex]?.requiredPlayback &&
      playbackStatus?.positionMillis >
        (playbackStatus?.durationMillis / 100) *
          videoList[currVideoIndex]?.videoCompletePercentage
    ) {
      videoPlaybackStatus[currVideoIndex].playbackStatus = true;
      this.setState(
        {
          videoPlaybackStatus: videoPlaybackStatus,
        },
        () => this.enableNextChapter()
      );
    }
    if (playbackStatus.isPlaying) {
      videoList.forEach(async (video) => {
        if (videoList.indexOf(video) != currVideoIndex) {
          this[`_video${videoList.indexOf(video)}`].pauseAsync();
        }
      });
    }
  };

  setSubjectPageEntryTime = () => {
    this.setState({
      subjectPageEntryTime: moment().format(),
    });
  };

  enableNextChapter = () => {
    const { videoPlaybackStatus } = this.state;
    const result = videoPlaybackStatus.filter((vpb) => !vpb.playbackStatus);
    if (result.length === 0) {
      this.setState({
        enableNext: true,
      });
    } else {
      this.setState({
        enableNext: false,
      });
    }
  };

  getCurrentPage = () => {
    const { currentOrdinal } = this.state;
    const { stdVideoData } = this.props;
    const videoPages = stdVideoData.pages;
    let currentPage = videoPages.filter(
      (page) => page.ordinal === currentOrdinal
    )[0];
    return currentPage;
  };

  setScrollRefToDocs = (ref) => {
    const { scrollRef } = this.state;
    if (scrollRef == null) {
      this.setState({
        scrollRef: ref,
      });
    }
  };

  // This method is to get adjusted height based on the height of the video
  getVideoHeight = (height) => {
    if (height > 720) {
      return height / 4;
    } else if (height > 600) {
      return height / 3;
    } else if (height > 400) {
      return height / 2;
    } else if (height < 400) {
      if (height > 300) {
        return 270;
      } else {
        return height;
      }
    } else {
      return height;
    }
  };

  onCommentClick = () => {
    if (this.state.openComments && childRef.current) {
      childRef.current.onAdd();
    }
    this.setState((prevState) => ({
      ...prevState,
      openComments: true,
    }));
  };

  onCommentCancelClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      openComments: false,
    }));
  };

  // This method is to update the loaded videos
  updateLoadedVideos = (videoName) => {
    const { loadedVideos } = this.state;
    if(_.findIndex(loadedVideos, videoName) < 0) {
    loadedVideos.push(videoName);
    this.setState({
      loadedVideos: [...loadedVideos],
    });
  }
}

  /*
    This method is to get the style of the video based on the video loading status
    If the video is loaded, then the aspect ratio will be calculated based on the video height and width
    If the video is not loaded, then no aspect ratio will be taken
  */
  getVideoStyle = (videoName, index) => {
    const { loadedVideos, allVideosLoaded,videoRes } = this.state;
    const { currentPage } = this.props;
    const videoIndex = _.findIndex(loadedVideos, videoName);
    const videoHeight = this.getVideoHeight(
      videoRes[
        currentPage?.multiMediaContent?.length -
          index -
          1
      ]?.height || 0
    )
    const videoWidth = videoRes[
      currentPage?.multiMediaContent?.length -
        index -
        1
    ]?.width || 0
    if( videoIndex > -1 && allVideosLoaded) {
      return {
        aspectRatio: videoHeight / videoWidth,
      height: videoHeight,
      backgroundColor: "#000",
      }
    } else if(videoIndex > -1){
      return {
      height:videoHeight,
      backgroundColor: "#000",
      }
    }
    return {
      backgroundColor: "#000",
      height: this.getVideoHeight(Dimensions.get("window").height),
    }
  }
  render() {
    const {
      currentOrdinal,
      animatedVisible,
      shouldPlay,
      scrollRef,
      allVideosLoaded,
      openComments,
      enableNext,
      presignedUrls
    } = this.state;
    const {
      navigation,
      subjectData,
      screenProps: { t },
      stdVideoData,
      storeVisitedChapters,
      selectedSubject,
      krDoneBy,
      screenProps,
    } = this.props;
    const videoPages = stdVideoData.pages;
    const { chapterTitle } = stdVideoData;
    let currentPage = videoPages.filter(
      (chapter) => chapter.ordinal === currentOrdinal
    )[0];
    const currentChapterOrdinal = navigation.getParam("ordinal");
    if (stdVideoData.pages.length > 0) {
      return (
        <Container>
          <ChapterTitle
            chapterType="STUDY_VIDEO"
            chapterTitle={chapterTitle}
            onCommentClick={this.onCommentClick}
          />
          <Content
            ref={(c) => {
              this.component = c;
              if (this.setScrollRefToDocs && scrollRef == null) {
                this.setScrollRefToDocs(c);
              }
            }}
            style={{ marginHorizontal: wp("2.5%") }}
          >
            <AnimatedHideView duration={200} visible={animatedVisible}>
              <View style={{ padding: wp("1%") }}>
                <View
                  style={{
                    padding: wp("1%"),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      color: Colors.text,
                      fontSize: fontType("Heading1"),
                      fontFamily: "Inter",
                    }}
                  >
                    {currentPage.pageTitle}
                  </Text>
                </View>
                <View>
                  {currentPage.multiMediaContent.map((item, index) => {
                    return (
                      <Card
                        bordered
                        className="test"
                        style={{
                          padding: wp("1.5%"),
                          flex: 1,
                          boxShadow: allVideosLoaded
                            ? "rgb(0 0 0 / 10%) 0px 2px 1.5px;"
                            : "none",
                          border: allVideosLoaded
                            ? "1px solid rgb(204, 204, 204)"
                            : "none",
                        }}
                      >
                        {/* Video Content */}
                        {_.isEmpty(presignedUrls[item.videoName]) && (
                          <ActivityIndicator
                            size="large"
                            color={Colors.ActivityIndicator}
                          />
                        )}
                        {!_.isEmpty(presignedUrls[item.videoName]) && 
                        <Video
                          style={this.getVideoStyle(item.videoName, index)}
                          ref={(component) =>
                            (this[`_video${index}`] = component)
                          }
                          source={{ uri: presignedUrls[item.videoName] }}
                          shouldPlay={shouldPlay}
                          downloadFirst={true}
                          // onLoad = {() => this.onLoad(item.path)}
                          // isBuffering={true}
                          rate={1.0}
                          onPlaybackStatusUpdate={(playbackStatus) =>
                            this.onPlaybackStatusUpdate(playbackStatus, index)
                          }
                          onReadyForDisplay={(playbackStatus) =>
                            this.onReadyForDisplay()
                          }
                          useNativeControls={true}
                          volume={1.0}
                          resizeMode={Video.RESIZE_MODE_CONTAIN}
                          // usePoster={true}
                          // posterSource={{ uri: 'https://www.istockphoto.com/in/vector/loading-icon-gm695717992-129010303'}}
                          isMuted={false}
                          onLoad={(x) => {
                            var temp = this.state.videoRes;
                            var res = {
                              width: x.target.videoWidth,
                              height: x.target.videoHeight,
                            };
                            temp.push(res);
                            this.setState({
                              videoRes: temp,
                            });
                            this.updateLoadedVideos(item.videoName);
                            if (
                              index ==
                              currentPage.multiMediaContent.length - 1
                            ) {
                              this.setState({
                                allVideosLoaded: true,
                              });
                            }
                          }}
                        />
                  }
                      </Card>
                    );
                  })}
                </View>
              </View>
            </AnimatedHideView>
          </Content>
          <View>
            <BottomSheet
              open={openComments}
              onClose={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  openComments: false,
                }))
              }
            >
              <CommentsPage
                ref={childRef}
                addNewComment={true}
                onCommentCancelClick={this.onCommentCancelClick}
                chapterType={ChapterType.STUDY_VIDEO.key}
                screenProps={screenProps}
              />
            </BottomSheet>
          </View>
          <Footer style={chapterButtonStyles.footer}>
            {(this.hasPreviousChapter() || this.hasPreviousPage()) && (
              <TouchableOpacity
                transparent
                onPress={() => {
                  scrollRef._root.scrollToPosition(0, 0, false);
                  this.asyncVideo();
                  this.getPreviousButtonNameBasedOnCurrentOrdinal() ===
                  t("Previous")
                    ? this.changeCurrentOrdinal(t("Previous"))
                    : onSelectingPrevChapter(
                        this.context.languages,
                        subjectData.language,
                        currentChapterOrdinal,
                        navigation
                      );
                }}
                style={chapterButtonStyles.button}
              >
                <Text style={chapterButtonStyles.buttonTxt}>
                  {this.getPreviousButtonNameBasedOnCurrentOrdinal()}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              transparent
              disabled={!enableNext}
              onPress={() => {
                this.setState({
                  videoRes: [],
                });
                scrollRef._root.scrollToPosition(0, 0, false);
                this.asyncVideo();
                this.getButtonNameBasedOnCurrentOrdinal() === t("Next")
                  ? this.changeCurrentOrdinal(t("Next"))
                  : onSelectingNextChapter(
                      this.context.languages,
                      subjectData.language,
                      currentChapterOrdinal,
                      navigation,
                      subjectData.isRegistered,
                      subjectData.signatureType,
                      subjectData.isKnowledgeReviewDone,
                      storeVisitedChapters,
                      subjectData.visitedChapters,
                      selectedSubject.subjectConsent.isKrDone,
                      krDoneBy
                    );
              }}
              style={chapterButtonStyles.button}
            >
              <Text
                style={
                  enableNext
                    ? chapterButtonStyles.buttonTxt
                    : chapterButtonStyles.buttonTxtDisabled
                }
              >
                {this.getButtonNameBasedOnCurrentOrdinal()}
              </Text>
            </TouchableOpacity>
          </Footer>
        </Container>
      );
    } else {
      return (
        <Container>
          <ChapterTitle
            chapterType="STUDY_VIDEO"
            chapterTitle={chapterTitle}
            onCommentClick={this.onCommentClick}
          />
          <NoDataPage message={t("NoData")} />
          <View>
            <BottomSheet
              open={openComments}
              onClose={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  openComments: false,
                }))
              }
            >
              <CommentsPage
                ref={childRef}
                addNewComment={true}
                onCommentCancelClick={this.onCommentCancelClick}
                chapterType={ChapterType.STUDY_VIDEO.key}
                screenProps={screenProps}
              />
            </BottomSheet>
          </View>
          <Footer style={chapterButtonStyles.footer}>
            {(this.hasPreviousChapter() || this.hasPreviousPage()) && (
              <TouchableOpacity
                transparent
                onPress={() => {
                  this.getPreviousButtonNameBasedOnCurrentOrdinal() ===
                  t("Previous")
                    ? this.changeCurrentOrdinal(t("Previous"))
                    : onSelectingPrevChapter(
                        this.context.languages,
                        subjectData.language,
                        currentChapterOrdinal,
                        navigation
                      );
                }}
                style={chapterButtonStyles.button}
              >
                <Text style={chapterButtonStyles.buttonTxt}>
                  {this.getPreviousButtonNameBasedOnCurrentOrdinal()}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              transparent
              onPress={() => {
                enableNext &&
                  onSelectingNextChapter(
                    this.context.languages,
                    subjectData.language,
                    currentChapterOrdinal,
                    navigation,
                    subjectData.isRegistered,
                    subjectData.signatureType,
                    subjectData.isKnowledgeReviewDone,
                    storeVisitedChapters,
                    subjectData.visitedChapters,
                    selectedSubject.subjectConsent.isKrDone,
                    krDoneBy
                  );
              }}
              style={chapterButtonStyles.button}
            >
              <Text
                style={
                  enableNext
                    ? chapterButtonStyles.buttonTxt
                    : chapterButtonStyles.buttonTxtDisabled
                }
              >
                {t("NextChapter")}
              </Text>
            </TouchableOpacity>
          </Footer>
        </Container>
      );
    }
  }
}
var styles = StyleSheet.create({
  buttonNext: {
    backgroundColor: Colors.button,
    margin: wp("1%"),
    borderRadius: wp("5%"),
    borderColor: "white",
    borderWidth: 1,
    minWidth: wp("16%"),
    height: wp("5.5%"),
  },
  buttonTxt: {
    color: "white",
    marginLeft: wp("1%"),
    textAlign: "center",
    fontSize: wp("2.5%"),
    paddingHorizontal: wp("4%"),
    fontFamily: "Inter_Bold",
  },
  buttonPrevTxt: {
    color: "white",
    textAlign: "center",
    fontSize: wp("2.5%%"),
    paddingHorizontal: wp("4%"),
    fontFamily: "Inter_Bold",
  },
  footer: {
    backgroundColor: "#0086ca",
    justifyContent: "flex-end",
    alignItems: "center",
    height: wp("10%"),
  },
  noDataTxtContainer: {
    flex: 1,
    justifyContent: "center",
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  noDataTxt: {
    textAlign: "center",
    fontSize: wp("4%"),
    color: "#006699",
    padding: 10,
  },
});
