import React, { Component } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, ScrollView, Platform, TouchableOpacity } from 'react-native';
import { Form, Body, Right, Container, Item, Card, } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import api from '../../utils/api';
import { Colors } from '../../constants/ui/colorScheme';
import TextInputItem from './TextInputForSubjectEdit';
import { createForm, } from "rc-form";
import SubjectDetails from './subjectDetails';
import PropTypes from 'prop-types';
import moment from 'moment';
import { renderStatusName } from './statusIconsAndNames';
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import DatePicker from 'react-native-datepicker';
import showToast from "../../utils/toast";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';
import {formatDate} from '../../utils/dateFormatUtils'

const Todaydate = new Date(Date.now());


class SubjectEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            loading: false,
        };
    }

    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        error: PropTypes.array,
    };


    componentDidMount() {
        this.setInitialDateValue();
    }

    setInitialDateValue = () => {
        const { selectedSubject, form } = this.props;
        const { setFieldsValue, getFieldsValue } = form;
        if (selectedSubject.dob == null) {
            this.setState({ date: null });
        } else {
            this.setState({ date: new Date(moment(selectedSubject.dob).format("YYYY-MM-DD")) });
            setFieldsValue({
                ...getFieldsValue(),
                'dob': new Date(moment(selectedSubject.dob).format("YYYY-MM-DD")),
            });
        }

    }

    changeDate = (date) => {
        const { form } = this.props;
        const { setFieldsValue, getFieldsValue } = form;
        this.setState({ date });
        setFieldsValue({
            ...getFieldsValue(),
            'dob': date,
        });
    };

    onSubmit = () => {
        const { form, selectedSubject, navigation, storeSelectedSubject } = this.props;
        form.validateFields(async (error, values) => {
            if (!error) {
                const dateval = moment(values.dob).format("YYYY-MM-DD");
                const subject = {
                    id: selectedSubject.id,
                    // dob: new Date(moment(values.dob).format("YYYY-MM-DD")),
                    dob: selectedSubject.dob,
                    firstName: selectedSubject.firstName,
                    gender: selectedSubject.gender,
                    lastName: selectedSubject.lastName,
                    phoneNo: selectedSubject.phoneNumber,
                    usubjectId: values.subjectId,
                }
                try {
                    this.setState({ loading: true });
                    const res = await api.post('subject/profileUpdate', subject);
                    if (res.status == 200) {
                        this.setState({ loading: false });
                        showToast("Saved successfully.", 'success', 2000);
                        storeSelectedSubject(res.data);
                        navigation.navigate('SubjectDetails');
                    }
                } catch (e) {
                    this.setState({ loading: false });
                    showToast("Failed to save.", 'danger', 2000);
                }
            } else {

            }
        });
    }

    renderIcons = (status) => {
        if (status === "DISCONTINUED") {
            return (
                <MaterialCommunityIcons
                    name="close-circle-outline"
                    size={fontType('StatusIcon')}
                    color={Colors.discontinued}
                />
            );
        } else if (status === "NEEDS_SIGNATURE") {
            return (
                <MaterialCommunityIcons
                    name="dots-horizontal-circle"
                    size={fontType('StatusIcon')}
                    color={Colors.needSign}
                />
            );
        } else if (status === "NEEDS_COUNTER_SIGNATURE") {
            return (
                <MaterialCommunityIcons
                    name="circle-slice-4"
                    size={fontType('StatusIcon')}
                    color={Colors.needsCounterSign}
                />
            );
        } else if (status === "CONSENTED")
            return (
                <MaterialCommunityIcons
                    name="checkbox-marked-circle"
                    size={fontType('StatusIcon')}
                    color={Colors.consentedColor}
                />
            );
        return null;
    };

    validatePhoneNumber = (rule, value, callback) => {
        const { screenProps: { t } } = this.props;
        const phoneNumber = parsePhoneNumberFromString(value);
        if (phoneNumber == undefined && value == '') {
            callback();
        } else if (phoneNumber == undefined || !phoneNumber.isValid() === true) {
            callback("Please enter a valid Phone Number.");
        }
        else {
            callback()
        }
    };

    render() {
        const { form: { getFieldDecorator, getFieldError, textInputProps, getFieldProps }, selectedSubject } = this.props;
        const { screenProps } = this.props;
        const { date, loading } = this.state;
        //    const dt = `${selectedSubject.dob ==  null ? '' : new Date(moment(selectedSubject.dob).format("YYYY-MM-DD"))}`
        const { t } = screenProps;
        return (
            <ScrollView >
                <Container style={{backgroundColor:'#fff'}}>
                    <View style={{ padding: 10, backgroundColor: Colors.background }}>
                        <Text style={{ fontSize: fontType('Heading1'), fontFamily: "Inter" }}>
                            Edit Profile
                        </Text>
                    </View>
                    <View style={{ margin: 10, padding: 3}}>
                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>First Name</Text>
                            </View>
                            <View>
                                {/* {getFieldDecorator('firstName', {
                                        initialValue: `${selectedSubject.firstName}`,
                                        rules: [
                                            { required: true, message: 'Please fill this field.' },
                                        ],
                                    })(
                                        <TextInputItem
                                            autoFocus
                                            error={getFieldError('firstName')}
                                            placeholderValue="Enter First Name"
                                            maxLength={100}
                                        />
                                    )} */}
                                <Text style= {{fontSize: fontType('BasicFont'), paddingTop:4, color: Colors.text, fontFamily: 'Inter',}}>{selectedSubject.firstName}</Text>
                            </View>
                        </View>
                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>Last Name</Text>
                            </View>
                                {/* {getFieldDecorator('lastName', {
                                    initialValue: `${selectedSubject.lastName}`,
                                    rules: [
                                        { required: true, message: 'Please fill this field.' },
                                    ],
                                })(
                                    <TextInputItem
                                        autoFocus
                                        error={getFieldError('lastName')}
                                        placeholderValue="Enter Last Name"
                                        maxLength={100}
                                    />
                                )} */}
                                <Text style= {{fontSize: fontType('BasicFont'), paddingTop:4, color: Colors.text, fontFamily: 'Inter',}}>{selectedSubject.lastName}</Text>
                        </View>
                        {selectedSubject.lastConsentedIrbVersion ? 
                            <View style={styles.fields}>
                                <View style={styles.lables}>
                                    <Text style={styles.textLable}>Last Consented IRB Version</Text>
                                </View>
                                <Text style= {{fontSize: fontType('BasicFont'), paddingTop:4, color: Colors.text, fontFamily: 'Inter',}}>{selectedSubject.lastConsentedIrbVersion}</Text>
                            </View> : null }
                        {selectedSubject.consentedOn ? 
                            <View style={styles.fields}>
                                <View style={styles.lables}>
                                    <Text style={styles.textLable}>Last Consented On</Text>
                                </View>
                                <Text style= {{fontSize: fontType('BasicFont'), paddingTop:4, color: Colors.text, fontFamily: 'Inter',}}>{formatDate(selectedSubject.consentedOn, selectedSubject.originatingTimezone)}</Text>
                            </View> : null }
                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>Subject ID</Text>
                            </View>
                            <View style={{ paddingTop:4}}>
                                {getFieldDecorator('subjectId', {
                                    initialValue: `${selectedSubject.usubjectId || ''}`,
                                    rules: [
                                        { required: false, message: 'Please fill this field.' },
                                    ],
                                })(
                                    <TextInputItem
                                        autoFocus
                                        error={getFieldError('subjectId')}
                                        placeholderValue="Enter Subject Id"
                                        maxLength={18}
                                    />
                                )}
                            </View>
                        </View>
                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>Subject Status</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                <Text
                                    style={{
                                        fontSize: fontType('subFont'),
                                        fontFamily: 'Inter',
                                        alignSelf: 'center', color: Colors.noOfSubjects,
                                        // padding: 6, paddingBottom: 20
                                    }}>
                                    {this.renderIcons(selectedSubject.status)}
                                </Text>
                                <Text style={{ fontSize: fontType('subFont') }}>
                                    &nbsp;{renderStatusName(selectedSubject.status)}
                                </Text>
                            </View> 
                        </View>
                        {/* <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>Gender</Text>
                            </View>
                            <View>
                                {getFieldDecorator('gender', {
                                    initialValue: `${selectedSubject.gender || ''}`,
                                    rules: [
                                        { required: false, message: 'Please fill this field.' },
                                    ],
                                })(
                                    <TextInputItem
                                        autoFocus
                                        error={getFieldError('gender')}
                                        placeholderValue="Enter Gender"
                                        maxLength={10}
                                    />
                                )}
                            </View>
                        </View>
                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>DOB</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                {getFieldDecorator('dob', {
                                    initialValue: `${selectedSubject.dob || ''}`,
                                    validateFirst: true,
                                    rules: [{ required: false, message: t('DOBWarn') }],
                                })(
                                    <DatePicker
                                        style={Platform.OS !== 'web' && { width: wp('30%')}}
                                        date={date}
                                        mode="date"
                                        placeholder='Select date'
                                        format="DD-MMM-YYYY"
                                        minDate="01-01-1900"
                                        animationType='slide'
                                        modalTransparent="true"
                                        maxDate={Todaydate}
                                        confirmBtnText={t('Confirm')}
                                        cancelBtnText={t('Cancel')}
                                        error={getFieldError('dob')}
                                        onDateChange={date => {
                                            this.changeDate(date);
                                        }}
                                        customStyles={{
                                            dateText: {
                                                fontSize: fontType('BasicFont'),
                                                fontFamily: 'Inter'
                                            },
                                            placeholderText: {
                                                fontSize: fontType('BasicFont'),
                                                fontFamily: 'Inter'
                                            },
                                            dateIcon: {
                                                resizeMode: 'contain',
                                                // width: wp ('9%'),
                                                height: 40
                                            },
                                        }}
                                    />
                                )}
                            </View>
                        </View>

                        <View style={styles.fields}>
                            <View style={styles.lables}>
                                <Text style={styles.textLable}>Phone Number</Text>
                            </View>
                            <View>
                                {getFieldDecorator('phoneNumber', {
                                    initialValue: `${selectedSubject.phoneNo || ''}`,
                                    rules: [
                                        { required: false, message: 'Please fill this field.' },
                                        {
                                            validator: this.validatePhoneNumber,
                                        },
                                    ],
                                })(
                                    <TextInputItem
                                        autoFocus
                                        error={getFieldError('phoneNumber')}
                                        placeholderValue="Enter Phone Number"
                                        keyboardType='phone-pad'
                                    />
                                )}
                            </View> 
                        </View>
                        */}
                       

                        <TouchableOpacity
                            onPress={() => this.onSubmit()}
                            style={[constStyles.buttonStyle,{ marginTop:35}]}
                        >
                            <Text style={[constStyles.buttonTxt,{marginHorizontal:50}]} >
                                SAVE
                            </Text>
                            <MyLoader  active={loading}/>
                           
                        </TouchableOpacity>
                    </View>
                </Container>
            </ScrollView>
        )
    }
}

export default createForm()(SubjectEdit);

const styles = StyleSheet.create({
    textLable: {
        fontSize: fontType('BasicFont'),
        color: Colors.coloredText,
        fontFamily: 'Inter',
        padding: 6,
        textAlign: 'left',
    },
    sbmtBtn: {
        alignSelf: 'baseline',
        minWidth: wp('26%'),
        backgroundColor: Colors.coloredText,
        borderWidth: 1,
        // borderColor:Colors.coloredText, 
        marginTop: wp('6%'),
        marginLeft: wp('30%'),
        borderRadius: 6,
        justifyContent: 'center',
    },
    fields: {
        flexDirection: "row",
        marginVertical:4,
        padding:2,
        alignItems:'center'

    },
    btnTxt: {
        // marginLeft:wp('8%'),
        color: Colors.textColorwithbg,
        fontSize: wp('2.6%'),
        fontFamily: 'Inter_Bold',
    },
    lables: {
        width:'30%',
        justifyContent:'center'
    }

});
