import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import {Item, Input} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';


export default class TextInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
  render() {
    const { error, value, onChange, placeholderValue, maxLength } = this.props;
    return (
      <View >
          <TextInput
            placeholder={placeholderValue}
            style={styles.txtInput}
            value={value || ''}
            onChangeText={onChange}
            fontSize={wp('3.5%')}
            height={wp('5%')}
            maxLength={maxLength || null}
          />
          <View style={styles.errorinfoText}>{this.getError(error)}</View>
      </View>
    )
  }

}
const styles = StyleSheet.create({
  errorinfoText: {
    color: Colors.error,
    fontFamily:'Inter',
    fontSize: wp('2.5%'), 
    margin:wp('1%'),
    alignSelf:'center'
  },

  txtInput: {
    borderBottomWidth:2,
    borderColor: '#bdbdbd',
    textAlign:'center',
    fontFamily:'Inter'
  },
})
