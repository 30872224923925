import React from "react";
import { View, Text } from "react-native";
import "./toolTip.css";

const ToolTip = (props) => {
  const { children, tip } = props;

  return (
    <View className="tooltip">
      {children}
      <Text className="tooltiptext">{tip}</Text>
    </View>
  );
};

export default ToolTip;
