import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { View, Text, ActivityIndicator, Platform,  TouchableOpacity, Dimensions} from "react-native";
// import styles from './styles/webSignStyles.cssm'
import { Colors } from '../../constants/ui/colorScheme';
import {fontType } from '../../styles/font'
import _ from 'lodash';

export default class SignatureCanvas extends Component {
  state = { trimmedDataURL: null }

  sigPad = {}

  componentDidMount() {
    this.props.webCanvas(this)
  }

  componentWillUnmount() {
    this.props.webCanvas(undefined)
  }


  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png') })
  }

  getSignature = () => {
    const { onSignDone, handleCanvasIsEmpty } = this.props;
    if(this.sigPad.isEmpty()){
      handleCanvasIsEmpty();
    } else {
      const canvasRef = this.sigPad.getCanvas();
      const signData = canvasRef.toDataURL();
      this.sigPad.clear()
      onSignDone(signData);
    }
  }

  render() {
    const {screenProps, SCREEN_WIDTH} = this.props;
    return(
      <View style={{alignSelf: 'center', margin:5}}>
        <View style={{  backgroundColor: '#fff', height: SCREEN_WIDTH > 1030 ? hp('30%') : hp('35%'), width: SCREEN_WIDTH > 1030 ? wp('40') : wp('90%') }}>
          <SignaturePad canvasProps={{ height: SCREEN_WIDTH > 1030 ? hp('30%') : hp('35%'), width: SCREEN_WIDTH > 1030 ? wp('40') : wp('90%'), backgroundColor: '#fff' }}
            ref={(ref) => { this.sigPad = ref }}
          />
        </View>
        <View style={{paddingHorizontal:5, flexDirection: 'row',  backgroundColor: '#fff', width: SCREEN_WIDTH > 1030 ? wp('40') : wp('90%'), marginTop:5, justifyContent:'space-between'  }}>
          <TouchableOpacity style={{ fontSize: 18, color: Colors.textColor }}
            onPress={ this.clear }
          >
            <Text style={{ fontSize: 18, color: Colors.radioGroup }}>
              {!_.isEmpty(screenProps) ? screenProps.t('Clear') : 'CLEAR'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ fontSize: 18, color: Colors.textColor }}
            onPress={this.getSignature}
          >
            <Text style={{ fontSize: 18, color: Colors.fieldSubmit }}>
            {!_.isEmpty(screenProps) ? screenProps.t('DoneSigning') : 'DONE'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

