import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {StyleSheet} from 'react-native';
import { Colors } from '../../../constants/ui/colorScheme';
export const styles = StyleSheet.create({
    loginAgain: {
        fontFamily: "Inter",
        fontSize: hp(4.5),
        fontWeight: "400",
        lineHeight: hp(5.9),
        textAlign: "center",
        color: Colors.loginAgain,
        marginTop: hp(1.3),
    },
    someThingWentWrong: {
        color: Colors.somethingWentWrong,
        fontFamily: "Inter",
        fontSize: hp(2),
        fontWeight: "600",
        lineHeight: hp(3),
        textAlign: "center"
    },
    serverErrorImg: { 
        width: wp(43.16),
        height: hp(33.5),
        gap: '0px',
        paddingBottom: hp(1.3)
    },
    buttonstyle: {
        marginTop: hp(1.3),
        marginBottom: hp(2.6),
        width: wp(30.67)
    }
});

