import React, { Component } from "react";
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card } from "native-base";
import _ from "lodash";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import SubjectDocumentListScreen from "../../containers/subjectDocumentListPage";
import { Colors } from "../../constants/ui/colorScheme";
import styles from "./styles/subjectDetailsStyles";
import Dialog, {
  DialogContent,
} from "react-native-popup-dialog";
import api from "../../utils/api";
import showToast from "../../utils/toast";
import { getConsentVersionId } from "../../utils/consentData";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import { fontType } from "../../styles/font";
import { constStyles } from "../../styles/constStyles";
import MyLoader from "../../utils/webLoader";
import { formatDate } from "../../utils/dateFormatUtils";
import EmailConsentPopup from "./EmailConsentPopup";
import ChooseConsentDelivery from "./ChooseConsentDelivery";
import { SendDocumentPopup } from "./SendDocumentPopup";
import TryalButton from "../common/button";
import { ButtonTypes } from "../common/constants";
import { updateCommentsStatus } from "../../actions/subject";
import constants from "../../utils/constants.js";
import Mobile from "../common/mobile.js";
import Web from "../common/web.js";
const { ContextProperties } = constants

const screenWidth = Dimensions.get("window").width;
class SubjectDetails extends Component {
  static contextType = ConsentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      confirmPopupVisible: false,
      isShowEmailPopUp: false,
      loading: false,
      isNoMail: false,
      showEmailSentSuccessfully: false,
      showChooseConsentDelivery: false,
      showSendDocumentPopup: false,
      isReviewComments: false,
    };
    this.commentsCount = 0;
    this.commentsResolved = true;
  }

  componentDidMount() {
    const {navigation} = this.props
    this._unsubscribe = navigation.addListener('focus', () => {
      this.retrieveCommentsCount();
     this.retrieveSignatureType();
    });
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  componentDidUpdate(prevProps, prevState){
    const {selectedSubject} = this.props
    if(!_.isEmpty(prevProps.selectedSubject) && !_.isEmpty(prevProps.selectedSubject.id) && !_.isEqual(prevProps.selectedSubject.id, selectedSubject?.id)){
     this.retrieveCommentsCount();
     this.retrieveSignatureType();
    }
  }

  retrieveCommentsCount = async () => {
    const {
      retrieveComments,
      selectedSubject,
      updateCommentsStatus,
    } = this.props;
    try {
      const data = await retrieveComments(selectedSubject?.id);
      this.commentsCount = !_.isEmpty(data) ? data.length : 0;
      this.commentsResolved =
        _.filter(data, (d) => !d.resolved)?.length == 0 ? true : false;
      updateCommentsStatus(this.commentsResolved);
    } catch (error) {
      console.log(error);
    }
  };

  retrieveSignatureType = async () => {
    const { getSignatureType, siteId, studyId, printNSignType } = this.props;
    if (_.isNull(printNSignType) || _.isUndefined(printNSignType)) {
      const consentVersionId = getConsentVersionId(this.context.languages);
      await getSignatureType(siteId, consentVersionId, studyId);
    }
  };

  onCheckUserAndNavigate = () => {
    const {
      storeRegisteredSubjectData,
      selectedSubject,
      navigation,
    } = this.props;
    const subject = {
      id: selectedSubject.id,
      registered: true,
    };
    storeRegisteredSubjectData(subject);

    if (_.isEmpty(selectedSubject.userName)) {
      navigation.navigate("SubjectAuthStack", {
        screen: "SubjectSignUpScreen",
      });
    } else {
      navigation.navigate("SubjectAuthStack", {
        screen: "SubjectSignInScreen",
      });
    }
  };

  onCheckUserAndSendData = async () => {
    const { selectedSubject, navigation, storeRegisteredSubjectData } =
      this.props;
    const { isReviewComments } = this.state;
    const subject = {
      id: selectedSubject.id,
      registered: true,
      isRemoteConsent: true,
    };
    storeRegisteredSubjectData(subject);
    if (isReviewComments) {
      navigation.navigate("SiteStaffCommentsReviewScreen", {
        consentType: "remote",
        unresolvedComments: this.commentsCount,
      });
    } else {
      navigation.navigate("DocumentSelection", { consentType: "remote" });
    }
  };

  onContinue = () => {
    const {
      storeRegisteredSubjectData,
      selectedSubject,
      navigation,
    } = this.props;
    const { isReviewComments } = this.state;
    const subject = {
      id: selectedSubject.id,
      registered: true,
      isRemoteConsent: false,
    };
    storeRegisteredSubjectData(subject);
    if (isReviewComments) {
      navigation.navigate("SiteStaffCommentsReviewScreen", {
        consentType: "inOffice",
        unresolvedComments: this.commentsCount,
      });
    } else {
      navigation.navigate("DocumentSelection", { consentType: "inOffice" });
    }
  };

  handleEmailConsentPopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      isShowEmailPopUp: !prevState.isShowEmailPopUp,
    }));
  };

  handleEmailResentSuccessfullyPopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      showEmailSentSuccessfully: !prevState.showEmailSentSuccessfully,
    }));
  };

  handleConfirmationPopup = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmPopupVisible: !prevState.confirmPopupVisible,
    }));
  };

  onDisContinue = async () => {
    const { selectedSubject, navigation } = this.props;

    const cvId = selectedSubject.consentVersionId;

    const subject = {
      id: selectedSubject.id,
      status: "DISCONTINUED",
    };

    const subjectConsentData = {
      status: "DISCONTINUED",
      consentVersion: {
        id: cvId,
      },
    };

    try {
      this.setState({ loading: true });
      const res = await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/discontinue`
        , {
        subject,
        subjectConsentData,
      });
      if (res.status == 201) {
        this.setState({ loading: false });
        this.handleConfirmationPopup();
        navigation.navigate("HomeTabs");
      }
    } catch (error) {
      this.setState({ loading: false });
      this.handleConfirmationPopup();
      showToast("Failed to save.", "danger", 2000);
      console.log(error);
    }
  };

  resendEmailWithDocuments = async () => {
    const { selectedSubject } = this.props;
    if (
      selectedSubject.email ||
      selectedSubject.larEmail ||
      selectedSubject.witnessEmail
    ) {
      this.setState({ loading: true });
      try {
        await api.post(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/emails`);
        this.setState({ loading: false });
        this.handleEmailResentSuccessfullyPopup();
      } catch (error) {
        this.setState({ loading: false });
        const result = error.response ? error.response.status : "NetworkError";
        if (result !== "NetworkError") {
          showToast(
            "Email failed to send. Please check to ensure the subject has a valid email address and try again.",
            "danger",
            5000
          );
        } else {
          showToast("Network Error.", "danger", 4000);
        }
        console.log(error);
      }
    } else {
      this.setState({ isNoMail: true });
    }
  };

  renderIcons = (status) => {
    if (status === "DISCONTINUED") {
      return (
        <MaterialCommunityIcons
          name="close-circle-outline"
          size={fontType("StatusIcon")}
          color={Colors.discontinued}
        />
      );
    } else if (status === "NEEDS_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="dots-horizontal-circle"
          size={fontType("StatusIcon")}
          color={Colors.needSign}
        />
      );
    } else if (status === "NEEDS_COUNTER_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="circle-slice-4"
          size={fontType("StatusIcon")}
          color={Colors.needsCounterSign}
        />
      );
    } else if (status === "CONSENTED")
      return (
        <MaterialCommunityIcons
          name="checkbox-marked-circle"
          size={fontType("StatusIcon")}
          color={Colors.consentedColor}
        />
      );
    return null;
  };

  renderStatusName = (status) => {
    if (status === "DISCONTINUED") {
      return "Discontinued";
    } else if (status === "NEEDS_SIGNATURE") {
      return "Needs Signature";
    } else if (status === "NEEDS_COUNTER_SIGNATURE") {
      return "Needs Counter Signature";
    } else if (status === "CONSENTED") return "Consented";
    return null;
  };

  openChooseConsentDelivery = (isReviewComments) => {
    this.setState({
      showChooseConsentDelivery: true,
      isReviewComments,
    });
  };

  closeChooseConsentDelivery = () => {
    this.setState({
      showChooseConsentDelivery: false,
    });
  };

  onConfirmSendDocument = () => {
    this.setState({ showSendDocumentPopup: false });
    this.resendEmailWithDocuments();
  };

  handleSendDocumentPopup = (val) => {
    this.setState({ showSendDocumentPopup: val });
  };

  getDiscontinueButton = () => {
    return (
      <TryalButton
          title='Discontinue'
          buttonStyle={[
            constStyles.buttonStyle,
            {
              borderWidth: 1,
              borderColor: Colors.errorButton,
              marginRight: 6,
              backgroundColor: "#ffffff",
            },
          ]}
          buttonTxtStyle={[
            constStyles.buttonTxt,
            {
              color: Colors.errorButton,
              justifyContent: "center",
              padding: 6,
              fontFamily: "Inter",
            },
          ]}
          onClick={() => this.handleConfirmationPopup()}
          type={ButtonTypes.OUTLINED}
          buttonColor={Colors.errorButton}
        />
    );
  };
  getConsentButton = () => {
    const { selectedSubject } = this.props;
    return (
      <TryalButton
                    title={
                      selectedSubject.isReconsent
                        ? "Start Re-Consent"
                        : "Start Consent"
                    }
                    buttonStyle={[
                      constStyles.buttonStyle,
                      { marginHorizontal: 10 },
                    ]}
                    buttonTxtStyle={[constStyles.buttonTxt]}
                    onClick={() => this.openChooseConsentDelivery(false)}
                    type={ButtonTypes.OUTLINED}
                    buttonColor={Colors.buttonwithbg}
                  />
    );
  };
  getReviewCommentsButton = () => {
    return (
      <TryalButton
        title="Review Comments"
        buttonStyle={[
          constStyles.buttonStyle,
          { marginHorizontal: 10 },
        ]}
        buttonTxtStyle={[constStyles.buttonTxt]}
        onClick={() => this.openChooseConsentDelivery(true)}
        type={ButtonTypes.OUTLINED}
        buttonColor={Colors.buttonwithbg}
      />
    );
  };
  render() {
    const viewStyles =
      Platform.OS === "web"
        ? { backgroundColor: Colors.background, flex: 1 }
        : { flex: 1, backgroundColor: Colors.background };
    const { navigation, selectedSubject, isSessionActive, t } = this.props;
    const {
      confirmPopupVisible,
      loading,
      isShowEmailPopUp,
      isNoMail,
      showEmailSentSuccessfully,
      showChooseConsentDelivery,
      showSendDocumentPopup,
    } = this.state;
    return (
      <View style={viewStyles}>
        <View style={{ padding: 10, backgroundColor: Colors.background }}>
          <Text style={{ fontSize: fontType("Heading1"), fontFamily: "Inter" }}>
            Subject Details
          </Text>
        </View>
        <Card style={styles.card}>
          <View style={styles.detailsPart}>
            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: screenWidth < 768 ? "column" : 'row' }}>
                <View>
                  <Text
                    style={{
                      fontSize: fontType("Heading1"),
                      marginBottom: wp("1%"),
                      flexWrap: "wrap",
                      color: Colors.text,
                      marginRight: 30,
                      fontFamily: "Inter",
                    }}
                  >
                    {selectedSubject.fullName}
                    
                  </Text>
                </View>
                <View style = {{ flexDirection: 'row', justifyContent: 'flex-start'}}>
                {selectedSubject.larFirstName ? (
                        <View
                          style={{
                            fontSize: fontType("subFont"),
                            marginLeft: 10,
                            backgroundColor: "#92c57a",
                            color: "rgb(56, 76, 47)",
                            textAlign: "center",
                            borderRadius: "5%",
                            fontFamily: "Inter",
                            justifyContent: "center",
                            height: 25,
                          }}
                        >
                          <Text>{` LAR `} </Text>
                      </View>
                    ): (<Text />) }
                    {selectedSubject.witnessFirstName ? (
                      <View
                        style={{
                          fontSize: fontType("subFont"),
                          marginLeft: 10,
                          backgroundColor: "#fcda5c",
                          color: "rgb(93, 79, 30)",
                          borderRadius: "5%",
                          fontFamily: "Inter",
                          justifyContent: "center",
                          height: 25,
                        }}
                      >
                        <Text>{` Witness `}</Text>
                      </View>
                    ): (<Text />)}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  {selectedSubject.status === "CONSENTED" && (
                    <TouchableOpacity
                      onPress={() => this.handleSendDocumentPopup(true)}
                      style={{ paddingRight: wp("1%") }}
                    >
                      <MaterialCommunityIcons
                        name="email"
                        color={Colors.iconColor}
                        size={fontType("StatusIcon")}
                      />
                    </TouchableOpacity>
                  )}

                  <TouchableOpacity
                    onPress={() => navigation.navigate("SubjectEditScreen")}
                    style={{ paddingRight: wp("1%") }}
                  >
                    <MaterialCommunityIcons
                      name="account-edit"
                      color={Colors.iconColor}
                      size={fontType("StatusIcon")}
                    />
                  </TouchableOpacity>
                </View>
                <MyLoader active={loading} />
              </View>

              <Text style={styles.detailsTxt}>
                Consent ID&nbsp;:&nbsp;{selectedSubject.consentId}
              </Text>
              <Text style={styles.detailsTxt}>
                Subject ID&nbsp;:&nbsp;{selectedSubject.usubjectId}
              </Text>
              {selectedSubject.lastConsentedIrbVersion ? (
                <Text style={styles.detailsTxt}>
                  Last Consented IRB Version&nbsp;:&nbsp;
                  {selectedSubject.lastConsentedIrbVersion}
                </Text>
              ) : null}
              {selectedSubject.consentedOn ? (
                <Text style={styles.detailsTxt}>
                  Last Consented On&nbsp;:&nbsp;
                  {formatDate(
                    selectedSubject.consentedOn,
                    selectedSubject.originatingTimezone
                  )}
                </Text>
              ) : null}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Text
                  style={{
                    fontSize: fontType("subFont"),
                    color: Colors.statusText,

                    fontSize: fontType("BasicFont"),
                    fontFamily: "Inter",
                  }}
                >
                  {" "}
                  {this.renderIcons(selectedSubject.status)}
                </Text>
                <Text style={{ fontSize: fontType("subFont") }}>
                  {this.renderStatusName(selectedSubject.status)}
                </Text>
              </View>
            </View>
            {selectedSubject.status === "NEEDS_SIGNATURE" ? (
              <>
              <Mobile>
                  <View style = {{ flexDirection: 'row', justifyContent: 'space-between'}}>
                      {this.getDiscontinueButton()}
                      {this.commentsCount > 0 ?
                  this.getReviewCommentsButton()
                : this.getConsentButton()}
                  </View>
                  {
                    this.commentsCount > 0 && 
                    <View style = {{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10}}>
                      {this.getConsentButton()}
                  </View>
                  }
              </Mobile>
              <Web>
                <View style = {{ flexDirection: 'row', justifyContent: 'space-between'}}>
                      {this.getDiscontinueButton()}
                      {this.commentsCount > 0 &&
                  this.getReviewCommentsButton() }
                  {this.getConsentButton()}
                  </View>
              </Web>
              </>
            ) : null}
          </View>
        </Card>
        <SubjectDocumentListScreen
          navigation={navigation}
          subjectConsentId={selectedSubject.subjectConsentId}
          languageId={selectedSubject.locale}
          status={selectedSubject.status}
          isSessionActive={isSessionActive}
          t={t}
          commentsResolved={this.commentsResolved}
        />
        {/* -------------------------Discontinue confirm popup ---------------------------------- */}
        <Dialog
          containerStyle={{ justifyContent: "center" }}
          rounded={true}
          visible={confirmPopupVisible}
        >
          <DialogContent>
            <View
              style={{
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                padding: 15,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: fontType("BasicFont"),
                    fontFamily: "Inter",
                    color: Colors.iconColor,
                  }}
                >
                  Are you sure?
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => this.handleConfirmationPopup()}
                  style={[constStyles.buttonStyle, { marginHorizontal: 15 }]}
                >
                  <Text
                    style={[constStyles.buttonTxt]}
                  >
                    NO
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[constStyles.buttonStyle, { marginHorizontal: 15 }]}
                  onPress={this.onDisContinue}
                >
                  <Text
                    style={[constStyles.buttonTxt]}
                  >
                    YES
                    <MyLoader active={loading} />
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog>
        {/*--------------- For Email Consent Pop up --------------------  */}
        <EmailConsentPopup
          isShowEmailPopUp={isShowEmailPopUp}
          handleEmailConsentPopup={this.handleEmailConsentPopup}
          navigation={navigation}
          selectedSubject={selectedSubject}
        />
        <ChooseConsentDelivery
          onSelectRemote={this.onCheckUserAndSendData}
          onSelectInOffice={this.onContinue}
          closeChooseConsentDelivery={this.closeChooseConsentDelivery}
          showChooseConsentDelivery={showChooseConsentDelivery}
        />
        {/* ----- no mail configure show popup -------------------- */}
        <Dialog
          containerStyle={{ justifyContent: "center" }}
          rounded={true}
          visible={isNoMail}
        >
          <DialogContent>
            <View
              style={{
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                padding: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <Text
                  style={{
                    fontSize: fontType("Heading1"),
                    fontFamily: "Inter",
                    color: Colors.iconColor,
                  }}
                >
                  {" "}
                  The Subject/LAR/Witness does not have an email address.
                </Text>
                <Text
                  style={{
                    fontSize: fontType("Heading1"),
                    fontFamily: "Inter",
                    color: Colors.iconColor,
                  }}
                >
                  Please add a valid email address to the subject profile.
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={[
                    constStyles.buttonStyle,
                    {
                      backgroundColor: Colors.errorButton,
                      marginHorizontal: 15,
                    },
                  ]}
                  onPress={() => this.setState({ isNoMail: false })}
                >
                  <Text style={[constStyles.buttonTxt]}> OK </Text>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog>
        {/*Email sent successfully popup*/}
        <Dialog
          containerStyle={{ justifyContent: "center" }}
          rounded={true}
          visible={showEmailSentSuccessfully}
        >
          <DialogContent>
            <View
              style={{
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                padding: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <Text
                  style={{
                    fontSize: fontType("Heading1"),
                    fontFamily: "Inter",
                    color: Colors.iconColor,
                  }}
                >
                  Documents have been successfully emailed to the subject.
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={[
                    constStyles.buttonStyle,
                    {
                      backgroundColor: Colors.fieldSubmit,
                      marginHorizontal: 15,
                    },
                  ]}
                  onPress={this.handleEmailResentSuccessfullyPopup}
                >
                  <Text style={[constStyles.buttonTxt]}> OK </Text>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog>

        {/* ------------------Email Confirmation Popup-------------- */}
        <SendDocumentPopup
          visible={showSendDocumentPopup}
          onConfirm={() => this.onConfirmSendDocument()}
          onCancel={() => this.handleSendDocumentPopup()}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCommentsStatus,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(SubjectDetails);
