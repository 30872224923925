import initialState from '../store/initialState';

export default (state = initialState.study, action) => {
    switch (action.type) {
        default:
            return state;
    }

};

