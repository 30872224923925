import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';

export default class UserInactivated extends Component {        
    render() {
        return (
            <Container>
                <View style={{ flex: 1, justifyContent: 'center' }} >
                    {/* -------------- Lock Icon ------------------ */}
                    <View style={{ alignContent: 'center', alignSelf: 'center', }} >
                        <FontAwesome name="lock" style={{ padding: '10%', fontSize: 70, color: 'red' }} />
                    </View>
                    {/* -------------- Message ------------------ */}
                    <View style={{ alignContent: 'center', alignSelf: 'center'}}>
                        <View style={{ flex: 1, justifyContent: 'center' }}>
                            <Text style={{ fontSize: fontType('Heading1'), color: Colors.coloredText, padding: 5, textAlign: 'center', fontFamily: 'Inter' }}>
                                The user is inactivated. </Text>
                        </View>
                    </View>
                </View>
            </Container>
        );
    }
}

