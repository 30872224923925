import LanguageSelect from '../components/languageSelect/LanguageSelect';
import React, { Component } from 'react';
import {Icon} from 'native-base';
import {Text} from 'react-native';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeConsentTokenData, incrementSubSerialDevNo } from '../actions/consentToken';
import { setSelectedLanguage } from '../actions/language';
import {getLanguageList} from '../utils/consentData';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
class LanguageSelectScreen extends Component {
    static contextType = ConsentDataContext;
    state={};
    static navigationOptions = ({ navigation, screenProps }) => ({
        headerRight: () => (
           <Text></Text>
        ),
    });

    render() {
        const { navigation, consentTokenData, storeConsentTokenData, incrementSubSerialDevNo, selectLanguage, screenProps } = this.props;
        const languages = getLanguageList(this.context.languages);
        return (
            <LanguageSelect
                languages={languages}
                navigation={navigation}
                consentTokenData = {consentTokenData}
                storeConsentTokenData = {storeConsentTokenData}
                incrementSubSerialDevNo = {incrementSubSerialDevNo}
                selectLanguage={selectLanguage}
                screenProps={screenProps}
            />
        );
    }
}

const mapStateToProps = state => ({
    consentTokenData: state.consentTokenData,

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeConsentTokenData,
        incrementSubSerialDevNo,
        selectLanguage: setSelectedLanguage,
    },
    dispatch,
);
export default connect(mapStateToProps,mapDispatchToProps)(LanguageSelectScreen);