import _ from 'lodash'
import { Content, Input, List, ListItem } from 'native-base'
import React, { useEffect, useState } from 'react'
import { Text, View,ScrollView } from 'react-native'

const TryalDropdown = (props) => {
    const [searchText, setSearchText ] = useState(null);
    const [isInputFocused, setInputFocused ] = useState(false)
    const { items, textInputProps, onItemSelect, containerStyle, itemsContainerStyle, itemStyle,
        itemTextStyle, noOptionsText } = props;


    const getData = () => {
        if(searchText) {
            return _.filter(items, i => _.includes(_.toLower(i.name), _.toLower(searchText)))
        }
        return items;
    }

    const isDataExists = () => {
        const data = getData();
        return !_.isEmpty(data)
    }
    return (
          <View style={{ backgroundColor: '#ffffff', position: 'relative'}}>
            <Input
              value = {searchText || textInputProps.value}
              placeholder = {textInputProps.placeholder}
              onChangeText={(value) => {
                setSearchText(value);
                onItemSelect({})
              }}
              onFocus = {() => setInputFocused(true)}
              onBlur = {() => setInputFocused(false)}
              style={
               [textInputProps.style, {
            }]
              }
              
            />
            {
                isInputFocused && 
              <List style={[itemsContainerStyle, {position: 'absolute'}]}>
                <ScrollView>
                {
                    isDataExists() ? 
                _.map(getData(), 
                    (data) =>
                        <ListItem
                          selected={textInputProps?.value === data.name ? true : false}
                          style={[itemStyle]}
                          onPress={() => {
                            onItemSelect(data)
                            setSearchText(null)
                          }}
                        >
                          <Text style = {[itemTextStyle]} >{data.name}</Text>
                        </ListItem>
                      )
                  
                  :
                    <Text style = {[itemStyle, {textAlign: 'center'}]}>{noOptionsText}</Text>
                }
                </ScrollView>
              </List>
}
          </View>
      )
}


export default TryalDropdown
