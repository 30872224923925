import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import _ from "lodash";
import { Colors } from "../../constants/ui/colorScheme";
import Dialog, { DialogContent } from "react-native-popup-dialog";
import { constStyles } from "../../styles/constStyles";
import { Body } from "native-base";
import { fontType } from "../../styles/font";

export const SendDocumentPopup = (props) => {
  const { visible, onConfirm, onCancel } = props;
  return (
    <React.Fragment>
      <View>
        <Dialog
          containerStyle={{ justifyContent: "center" }}
          rounded={true}
          visible={visible}
        >
          <DialogContent>
            <View
              style={{
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                padding: 15,
              }}
            >
              <View>
                <Body>
                  <Text
                    style={{
                      fontSize: fontType("BasicFont"),
                      fontFamily: "Inter",
                    }}
                  >
                    This action will send confirmation documents to the
                    participant, would you like to proceed?
                  </Text>
                </Body>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: 50,
                }}
              >
                <TouchableOpacity
                  onPress={() => onCancel(false)}
                  style={[
                    constStyles.buttonStyle,
                    {
                      backgroundColor: Colors.errorButton,
                      marginHorizontal: 15,
                    },
                  ]}
                >
                  <Text style={[constStyles.buttonTxt]}>CANCEL </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    constStyles.buttonStyle,
                    {
                      backgroundColor: Colors.buttonwithbg,
                      marginHorizontal: 15,
                    },
                  ]}
                  onPress={() => onConfirm()}
                >
                  <Text style={[constStyles.buttonTxt]}>CONFIRM</Text>
                </TouchableOpacity>
              </View>
            </View>
          </DialogContent>
        </Dialog>
      </View>
    </React.Fragment>
  );
};
