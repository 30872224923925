import React from 'react'
import {View, StyleSheet, Text, Dimensions, Platform, PixelRatio } from 'react-native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

export const fontType = (fontType) => {
    switch(fontType) {
        case 'Heading':
            return heading()
        case 'Heading1': 
            return fontSizeHeader()
        case 'BasicFont':
            return basicFontSize()
        case 'subFont':
            return subFontSize()
        case 'ButtonTxt':
            return buttonFont()
        case 'StatusIcon':
            return statusIconSize()
        case 'ChapterIcon':
            return chapterIconSize()
    }
}

export const subFontType = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 11;
    } else if (SCREEN_WIDTH >= 750) {
        return 19
    } if (SCREEN_WIDTH > 350) {
        return 9
    } else {
        return 7;
    }
}

export const heading = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 18;
    } else if (SCREEN_WIDTH >= 750) {
        return 26
    } if (SCREEN_WIDTH > 350) {
        return 25
    } else {
        return 20;
    }
}

export const fontSizeHeader = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 16;
    } else if (SCREEN_WIDTH >= 750) {
        return 24
    } if (SCREEN_WIDTH > 350) {
        return 20
    } else {
        return 15;
    }
}

export const basicFontSize = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 15;
    } else if (SCREEN_WIDTH >= 750) {
        return 22
    } if (SCREEN_WIDTH >= 350) {
        return 12
    } else {
        return 10;
    }
}

export const subFontSize = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 12;
    } else if (SCREEN_WIDTH >= 750) {
        return 14
    } if (SCREEN_WIDTH > 350) {
        return 12
    } else {
        return 11;
    }
}

export const buttonFont = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 13;
    } else if (SCREEN_WIDTH >= 750) {
        return 16
    }else if (SCREEN_WIDTH > 450) {
        return 12
    }else if (SCREEN_WIDTH > 350) {
        return 9
    } else {
        return 8;
    }
}

export const statusIconSize = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 26;
    } else if (SCREEN_WIDTH >= 750) {
        return 36
    } if (SCREEN_WIDTH > 350) {
        return 20
    } else {
        return 20;
    }
}

export const chapterIconSize = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 18;
    } else if (SCREEN_WIDTH >= 750) {
        return 25
    } if (SCREEN_WIDTH > 350) {
        return 20
    } else {
        return 15;
    }
}

// Knowledge review styles
export const questionFont = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 23;
    } else if (SCREEN_WIDTH >= 750) {
        return 22
    } if (SCREEN_WIDTH > 350) {
        return 15
    } else {
        return 10;
    }
}

export const hintTextFont = () => {
    if (SCREEN_WIDTH >= 1024) {
        return 21;
    } else if (SCREEN_WIDTH >= 750) {
        return 20
    } if (SCREEN_WIDTH > 350) {
        return 12
    } else {
        return 9;
    }
}


export const cancelIcon = () => {
    if(SCREEN_WIDTH < 1030) {
        if (SCREEN_WIDTH >= 1024) {
            return hp('-1.8%');
        } else if (SCREEN_WIDTH >= 750) {
            return hp('-2.6%');
        } else if (SCREEN_WIDTH > 400){
            return hp('-3.2%');    
        } 
        else if(SCREEN_WIDTH > 370 ){
            return hp('-3.4%');
        } 
        else if (SCREEN_WIDTH > 350) {
            return hp('-3.7%');
        } else if (SCREEN_WIDTH > 300){
            return hp('-4.2%');
        }else if(SCREEN_WIDTH > 270){
            return hp('-3.3%');
        }
    } else {
        return hp('-2.8%');
    }
}

export const getTextInContainer = () => {
    if(SCREEN_WIDTH < 1030) {
        if (SCREEN_WIDTH >= 1024) {
            return SCREEN_WIDTH/1.2;
        } else if (SCREEN_WIDTH >= 750) {
            return '65%';
        } else if(SCREEN_WIDTH >= 540){
            return '40%'
        } else if (SCREEN_WIDTH > 400){  
            return '32%';    
        } 
        else if(SCREEN_WIDTH > 370 ){
            return '27%';
        } 
        else if (SCREEN_WIDTH > 350) {
            return '25%';
        } else if (SCREEN_WIDTH > 300){
            return '20%';
        } else if(SCREEN_WIDTH > 270){
            return '20%'
        } else {
            return '15%'
        }
    } else {
        return SCREEN_WIDTH/2.8;
    }
}