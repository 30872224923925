import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export default StyleSheet.create(
    {
        card: {
            padding: 10,
            paddingLeft: 12,
            marginRight:6,
            marginLeft: 6
          },
          detailsPart: {
            flexDirection: "column"
          },
          btnContinue: {
            backgroundColor: Colors.buttonwithbg,
            borderColor:Colors.buttonwithbg,
            borderWidth:1,
            margin: wp("1%"),
            borderRadius: 5,
            justifyContent: "center",
            padding: wp("1%"),
            alignSelf: "stretch",
            textAlignVertical: "center"
          },
          btnDisContinue: {
            backgroundColor: Colors.aiwithbg,
            borderColor:Colors.errorButton,
            borderWidth:1,
            margin: wp("1%"),
            borderRadius: 5,
            justifyContent: "center",
            padding: wp("1%"),
            alignSelf: "stretch",
            textAlignVertical: "center"
          },
          buttonTxt: {
            color: Colors.textColorwithbg,
            textAlign: "center",
            fontSize: fontType('ButtonTxt'),
            fontFamily: "Inter_Bold",
            // padding:5
          },
          detailsTxt: {
            fontSize: fontType('subFont'),
            marginBottom: wp("0.5%"),
            fontFamily: "Inter"
          },
          checkboxContainer: {
            flexDirection: "row",
            marginBottom: 20,
          },
          checkbox: {
            alignSelf: "center",
          }
    }
)