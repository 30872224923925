import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from "react-native-responsive-screen";

export const renderIcons = (status) => {
    if (status === "DISCONTINUED") {
      return (
        <MaterialCommunityIcons
          name="close-circle-outline"
          size={wp("6.5%")}
          color={Colors.discontinued}
        />
      );
    } else if (status === "NEEDS_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="dots-horizontal-circle"
          size={wp("6.5%")}
          color={Colors.needSign}
        />
      );
    } else if (status === "NEEDS_COUNTER_SIGNATURE") {
      return (
        <MaterialCommunityIcons
          name="circle-slice-4"
          size={wp("6.5%")}
          color={Colors.needsCounterSign}
        />
      );
    } else if (status === "CONSENTED")
      return (
        <MaterialCommunityIcons
          name="checkbox-marked-circle"
          size={wp("6.5%")}
          color={Colors.consentedColor}
        />
      );
    return null;
  };

  export const renderStatusName = (status) => {
    if (status === "DISCONTINUED") {
      return "Discontinued";
    } else if (status === "NEEDS_SIGNATURE") {
      return "Needs Signature";
    } else if (status === "NEEDS_COUNTER_SIGNATURE") {
      return "Needs Counter Signature";
    } else if (status === "CONSENTED") return "Consented";
    return null;
  };
