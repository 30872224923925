import { StyleSheet, View, Text } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SubjectStatus } from '../../common/constants';
import {FooterTab,Footer, Button} from "native-base";
import { Colors } from '../../../constants/ui/colorScheme';
import { fontType } from '../../../styles/font';
var styles = StyleSheet.create({
    buttonSbmt: {
      backgroundColor: Colors.button,
    },
    buttonTxt: {
      color: Colors.textColorwithbg,
      textAlign: "center",
      fontWeight: "200",
      fontSize: fontType("ButtonTxt"),
      fontFamily: "Inter_Bold",
    },
    Footer: {
      backgroundColor: Colors.footer,
      alignItems: "center",
      margin: 10,
    }
  });

const SubmitButton = (props) => {
    const {checkIfAllSignaturesAreDone, submitConsent} = props
    const { selectedSubject , loading} = useSelector(state => state);

  return (
    <View >
          {selectedSubject.status != SubjectStatus.CONSENTED && selectedSubject.status != SubjectStatus.DISCONTINUED && !loading && !checkIfAllSignaturesAreDone() && (
                  <Footer style={styles.Footer}>
                      <FooterTab
                          style={{
                              backgroundColor: checkIfAllSignaturesAreDone()
                                  ? Colors.buttondisabled
                                  : Colors.buttonwithbg,
                          }}
                      >
                          <Button
                              full
                              style={[
                                  styles.buttonSbmt,
                                  { backgroundColor: "transparent" },
                              ]}
                              disabled={checkIfAllSignaturesAreDone()}
                              onPress={() => {
                                  submitConsent();
                              }}
                          >
                              <View style={{ flexDirection: "row" }}>
                                  <Text style={styles.buttonTxt}>SUBMIT CONSENT</Text>
                              </View>
                          </Button>
                      </FooterTab>
                  </Footer>
              )}
    </View>
  )
}
export default SubmitButton