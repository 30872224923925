export default {
  WhatToExpect: "Чего ожидать",
  WhatToDescription:
    "Это приложение может помочь вам лучше понять исследования, так что вы можете решить, если вы хотите принять участие.",
  WhatToFooter:
    "Вы получите копию подписанной и датированной формы информированного согласия в конце информированного согласия.",
  NextChapter: "СЛЕДУЮЩАЯ ГЛАВА",
  Next: "Далее",
  PreviousChapter: "ПРЕДЫДУЩЕЙ ГЛАВЕ",
  Previous: "Предыдущие",
  Continue: "Далее",
  Help: "Помогите",
  Close: "Рядом",
  Hint: "Подсказка",
  HintText:
    "Смотреть часть информированное согласие, что этот вопрос ссылки и отвечу еще раз.",
  Question: "Вопрос",
  Submit: "Представить",
  Congratulations: "Поздравляем!",
  Cong1:
    "Вы завершили проверку знаний. Если у вас есть какие-либо вопросы, прежде чем решить, если вы хотите принять участие, пожалуйста, обращайтесь в кабинете следователя или сотрудников, прежде чем приступить к процессу подписания.",
  Cong2:
    "Если вы хотите участвовать в исследовании, пожалуйста, нажмите кнопку Продолжить ниже, чтобы начать процесс подписания.",
  Cong3:
    "Если вы решили не участвовать в исследовании, пожалуйста, нажмите кнопку Stop моим участием ниже.",
  StopMyPart: "ПРЕКРАЩАТЬ МОЕ УЧАСТИЕ",
  WhoInfC: "Кто подписывает это согласие ?",
  SubjInfc: "Я буду подписывать информированное согласие.",
  LarInf: "Мой законный представитель подписывает информированное согласие.",
  SignedBy: "Подписан",
  SignedOn: "Подписан",
  TapToSign: "НАЖМИТЕ НА ЗНАК",
  SignWarning: "ПОЖАЛУЙСТА, ВОЙДИТЕ, ЧТОБЫ ПРОДОЛЖИТЬ.",
  OK: "ОК",
  Clear: "Понятно",
  Cancel: "Отменить",
  ThankYou:
    "Благодарим вас за подписание настоящего электронного согласия. Когда все подписи будут получены, вы получите копию подписанной формы информированного согласия. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с вашим сайтом.",
  ReturnTab:
    "Пожалуйста, верните устройство обратно в координатор исследования или следователя.",
  DocsToSign: "Документы на подпись",
  DocsList: "Список документов",
  EndPart: "Окончание вашего участия в исследовании ?",
  SignHere: "Подпишите Здесь",
  SelectWarn: "Пожалуйста, выберите по крайней мере один параметр. ",
  FirstNameWarn: "Имя не может быть пустым.",
  LastNameWarn: "Фамилия не может быть пустым.",
  DOBWarn: "Дата рождения не может быть пустым.",
  DoneSigning: "Сделано",
  Register: "Зарегистрироваться",
  SelectDate: "Выберите дату",
  DidNotSignConsent: "Не знак согласия",
  ConsentSigned: "Согласие Подписано",
  SubjectSignature: "Предмет Подпись",
  GoBackToDoc: "ВЕРНУТЬСЯ К ДОКУМЕНТУ",
  GenderWarn: "Пол не может быть пустым.",
  PhoneNoWarn: "Номер телефона не может быть пустым.",
  RegPlacHolder: "Нажмите здесь, чтобы тип",
  StopPartFailed: "Что-то пошло не так. Пожалуйста, попробуйте снова позже.",
  LARsign: "Ларь подпись",
  LARName: "Имя законного представителя, полностью",
  LARRelation: "Описание / Отношения опекуна или законного представителя",
  TypeLARName: "Нажмите здесь, чтобы ввести имя",
  TypeLARRelation: "Нажмите здесь, чтобы ввести отношения",
  FillWarn: "Пожалуйста, заполните это поле.",
  EnterHere: "Вход здесь",
  RegistrationForm: "Форма регистрации",
  SignatureChapter: "Подпись",
  AreYouSure: "Ты уверен?",
  Confirm: "Подтверждение",
  PlaceHolderText: "Нажмите здесь, чтобы ввести",
  SaveFail: "Не удалось сохранить.",
  ValidPhn: "Пожалуйста, введите действующий телефонный номер.",
  NoData: "Без содержания.",
  SelectOption: "Пожалуйста, выберите хотя бы один вариант.",
  DocFieldWarning: "Пожалуйста, заполните все обязательные поля.",
  PleaseScroll:
    "Пожалуйста, прокрутите вниз и полностью прочитайте документ, прежде чем продолжить.",
  IRBApprovedDate: "Дата утверждения IRB",
  Version: "Версия",
  SessionExpired: "Сессия истекла! Пожалуйста, войдите снова.",
  ConsentSubmitSuccess: "Согласие успешно отправлено.",
  ConsentSubmitFail:
    "Не удалось отправить согласие. Пожалуйста, попробуйте снова.",
  NetworkErr: "Сетевая ошибка.",
  Username: "Имя пользователя",
  Password: "пароль",
  EnterPassword: "Введите пароль",
  EnterUsername: "Введите имя пользователя",
  InvalidUsrName: "Неправильное имя пользователя или пароль.",
  UserLocked: "Пользователь заблокирован.",
  SomethingWentWrong: "Что-то пошло не так.",
  SubjAuthorization: "Авторизация субъекта",
  LarAuthorization: "LAR авторизация",
  WitnsAuthorization: "Авторизация свидетеля",
  SubjIniWarn: "Пожалуйста, введите Ваши инициалы здесь.",
  Subdec: "Пожалуйста, напишите свою подпись в поле ниже.",
  Initials: "Инициалы",
  RadioInitialsVal:
    "Выберите вариант и введите букву рядом с выбранным вариантом.",
  Skip: "скип.",
  SkipFail: "Не удалось пропустить.",
  DateOfBirth: "Введите дату рождения",
  WitnessSign: "Подпись свидетеля",
  SubjectList: "Список тем",
  CancelFlow: "Спасибо, верните устройство представителю клиники.",
  OfficeThankYou:
    "Спасибо, пожалуйста, верните устройство представителю клиники, чтобы получить окончательное согласие.",
  ReturnToSiteStaff:
    "Чтобы вернуться к списку субъектов, подтвердите, что вы являетесь врачом, и нажмите кнопку «Вернуться к списку субъектов».",
  CommentReview: "Комментарий Обзор",
  CommentsPending:
    "У вас есть отличные комментарии, отслеживаемые в нашей системе. Персонал сайта был уведомлен и свяжется с вами, чтобы обсудить ваши вопросы, или вы можете связаться с ними по адресу ",
  ConsentIncomplete:
    "Ваше согласие не может быть завершено до тех пор, пока все комментарии не будут разрешены. Вы получите электронное письмо, когда сможете продолжить процесс получения согласия.",
  AllCommentsAddressed: "Все замечания учтены.",
  ContinueWithSubject:
    "Пожалуйста, дайте устройство субъекту, чтобы продолжить процесс получения информированного согласия.",
  Comments: "Комментарии",
  CommentsEmailSent:
    "Электронное письмо было отправлено участнику, пожалуйста, свяжитесь с ним, чтобы заполнить Информированное согласие.",
  AllCommentsMarkedResolved: "Все ваши комментарии отмечены как решенные.",
  AnyQuestionsText:
    "Если у вас есть какие-либо вопросы, прежде чем принять решение об участии, задайте их исследователю или сотрудникам, прежде чем приступить к процессу подписания.",
  ContinueParticipation:
    "Если вы хотите принять участие в исследовании, нажмите кнопку «Продолжить» ниже, чтобы начать процесс подписи.",
  WrongAnswer: "Вы выбрали неверный ответ, попробуйте еще раз",
  PrintNSign:
    "Вы выбрали печать и подпись. Этот документ будет распечатан с местом для рукописных подписей. Убедитесь, что участник ознакомился с содержанием eConsent и имел возможность обсудить любые вопросы.",
  VerifyPrintNSign:
    "Вы выбрали проверку того, что согласие было подписано вне электронной системы. Пожалуйста, отправьте подписанную печатную копию и убедитесь, что участнику предоставлена копия. После того, как вы выбрали «Отправить согласие», это нельзя будет отменить.",
  PrintNSignFlow: "Это Исследование требует ручной подписи.",
  UnverifyPrintNSign:
    "Вы решили отменить проверку этого документа. Когда вы нажмете OK, документ не будет проверен, и вы сможете его распечатать. Вы сможете повторно проверить документ. Все документы должны быть проверены для предоставления согласия. После того, как вы выбрали кнопку «Отправить согласие», это поле нельзя будет изменить, документ останется проверенным.",
  RemotePrintNSignError:
    "Этот сайт требует ручных подписей на информированном согласии, вы не можете отправить согласие по электронной почте.",
  KnowledgeReviewCompletion: "Вы завершили проверку знаний.",
  InOfficeCommentsPending:
    "У вас есть отличные комментарии, отслеживаемые в нашей системе. Пожалуйста, верните устройство сотрудникам сайта для обсуждения и решения нерешенных вопросов.",
  CounterSignRequiredSignaturesPending:
    "Все необходимые подписи еще не подписали этот документ. Пожалуйста, вернитесь к этому экрану после того, как все необходимые подписи будут собраны.",
  Logout: "ВЫХОД",
  StopConsentPopup:
    "Вы уверены, что хотите прекратить согласие и выйти из системы врача? Для продолжения согласия потребуется войти в систему.",
  Yes: "Да",
  No: "Нет",
  ChangingDataLabel: "Вы изменяете следующие данные:",
  ReasonForChangeLabel: "Пожалуйста, введите причину изменения",
  ReasonForChangeWarning: "Требуется указать причину изменения.",
  SaveChanges: "СОХРАНИТЬ ИЗМЕНЕНИЯ",
  ReasonForChangeNote:
    "Примечание: Указанная причина изменения будет применяться ко всем изменениям. Если вы хотите ввести разные причины изменения, нажмите X, чтобы закрыть это окно и внести изменения в отдельные проводки. Не указывайте защищенные данные в поле причины изменения.",
  ReasonForChangeDetails: "Сведения о причине изменения",
  ReasonForChange: "Причина изменения",
  Verify: "ПРОВЕРЯТЬ",
  UserId: "Идентификатор пользователя",
  EnterUserId: "Введите UserId",
  ClinicianAuthorization: "Авторизация врача",
  SubmitConsent: "Отправить согласие",
  AccessNotPermitted: "Доступ запрещен",
  ApologyForNotPermit:
    "Приносим свои извинения, но ваша текущая роль не разрешает доступ к этому приложению.",
  ContactAdminstrator:
    "За помощью, пожалуйста, обращайтесь к руководителю проекта вашего исследования».",
  UnAuthorizedMessage: "У вас нет прав для просмотра этой страницы.",
  ProccedFail: 'Продолжить не удалось.',
  Comment: "Комментарий",
  Chapter: "Глава",
  Response: "Ответ",
  Save: "Сохранять",
  AddResponse: "Добавить ответ",
  AddComment: "Добавить комментарий",
  ResolveComment: "Разрешить комментарий",
  UnresolveComment: "Неразрешить комментарий",
  SomeThingWentWrong: "ЧТО-ТО ПОШЛО НЕ ТАК",
  PleaseLoginAgain: "Пожалуйста, войдите в систему еще раз",
  ClickHereToLogin: "НАЖМИТЕ ЗДЕСЬ, ЧТОБЫ ВОЙТИ В СИСТЕМУ",
};
