import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import showToast from '../utils/toast';
import api from '../utils/api';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import MetaDataLoading from "../components/MetaDataLoading";
import { setSelectedLanguage } from "../actions/language";
import constants from "../utils/constants";
import PropTypes from 'prop-types'

const { ContextProperties } = constants;

class MetaDataLoadingPage extends Component {
    static contextType = ConsentDataContext
    static navigationOptions = ({ navigation }) => ({
        // headerLeft:null,
        // headerTitle:null
      });

      state = {
        metaDataLoading: false,
      }

      componentDidMount() {
          this.checkCVAndGetMD();
      }

      checkCVAndGetMD = async() => {
        const { navigation, selectLanguage, subjectConsentVersionId, selectedSubject, screenProps: { setLocale }, currentUserType } = this.props;
           try{
              this.setState({ metaDataLoading: true });
                const res = await api.get(`/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/consentVersions/${subjectConsentVersionId}/metaData`);
                 const languagesdata =  JSON.stringify(res.data);   
                 let promises = [];
                 promises.push(this.context.updateLanguages(languagesdata));
                 Promise.all(promises).then(res=>{
                    this.setState({ metaDataLoading: false });
                    setLocale(selectedSubject.locale);
                    const language= {
                        languageId: selectedSubject.languageId,
                        language:selectedSubject.locale,
                    }
                    selectLanguage(language);
                    if(currentUserType == 4){
                        navigation.replace('SubDocsPage');
                    } else {
                        navigation.replace('ConsentOverviewScreen');
                    }
                 });                                  
           } catch(err) {
              console.log(err);
              this.setState({ metaDataLoading: false });
              if (err.response) {
                 showToast("Something went wrong.", 'danger', 4000);
              } else {
                 showToast('Network Error.', 'danger', 2000);
              }
           }
     }

    render() {
        return (
            <MetaDataLoading/>
        );
    }
}

MetaDataLoadingPage.propTypes = {
    navigation: PropTypes.object.isRequired,
    selectLanguage: PropTypes.func.isRequired,
    subjectConsentVersionId: PropTypes.string,
    selectedSubject: PropTypes.object.isRequired,
    screenProps: PropTypes.object.isRequired,
    currentUserType: PropTypes.number.isRequired,
};

MetaDataLoadingPage.defaultProps = {
    subjectConsentVersionId: '',
};

const mapStateToProps = state => ({
    subjectConsentVersionId: state.selectedSubject.currentConsentVersionId,
    selectedSubject: state.selectedSubject,
    currentUserType: state.consentFlow.currentUserType,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        selectLanguage: setSelectedLanguage,
    },
    dispatch,
);

export default connect( mapStateToProps, mapDispatchToProps )(MetaDataLoadingPage);
