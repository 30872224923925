import Completed from '../components/congratulations/Completed';
import React, { Component } from "react";
import { Icon } from 'native-base';
import { connect } from "react-redux";
import { TouchableOpacity } from 'react-native'
import { setCurrentUserType } from '../actions/consentFlow';
import { bindActionCreators } from "redux";
import { clearDocSignPosition } from "../actions/update";
import { clearSubjectDocumentSignatures } from "../actions/subject";
class CompletedScreen extends Component {

    render() {
        const { navigation, screenProps, subjectStatus, isRemoteConsent,
            signatureTypes, currentUserType, setCurrentUserType: loSetCurrentUserType,
            clearDocSignPosition: loClearDocSignPosition, clearSubjectDocumentSignatures, otp } = this.props;
        return (
            <Completed navigation={navigation}
                isRemoteConsent={isRemoteConsent}
                handleModalClose={this.handleModalClose}
                screenProps={screenProps}
                subjectStatus={subjectStatus}
                signatureTypes={signatureTypes}
                currentUserType={currentUserType}
                setCurrentUserType={loSetCurrentUserType}
                clearDocSignPosition={loClearDocSignPosition}
                clearSubjectDocumentSignatures={clearSubjectDocumentSignatures}
                otp={otp}
            />
        );
    }
}


const mapStateToProps = state => ({
    subjectStatus: state.subject.status,
    isRemoteConsent: state.subject.isRemoteConsent,
    signatureTypes: state.consentFlow.signatureTypes,
    currentUserType: state.consentFlow.currentUserType,
    otp: state.subjectRemoteConsentDto?.otp,

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setCurrentUserType,
        clearDocSignPosition,
        clearSubjectDocumentSignatures
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompletedScreen);

