import { FlatList, View } from 'react-native'
import React from 'react'
import SubjectDocument from './Document'
import { List } from 'native-base'

const Documents = (props) =>  {
  const {documentList, onDocumentSelected} = props
  return (
    <View>
      {/*-------------------Showing document list -----------------  */}
      <FlatList
          data={documentList}
          progressViewOffset={30}
          renderItem={({ item, index }) => ( <List>
              {/* -----------Showing Each document ----------------- */}
              <SubjectDocument document={item} index={index} onDocumentSelected={onDocumentSelected}/>
            </List>
          )}
        />
    </View>
  )
}
export default Documents