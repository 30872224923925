import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import {Item, Input, Label} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';


export default class TextInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
  render() {
    const { error, value, onChange, placeholderValue } = this.props;
    return (
      <View>
           <TextInput
            placeholder={placeholderValue}
            style={styles.txtInput}
            value={value || ''}
            onChangeText={onChange}
            fontSize={fontType('BasicFont')}
            maxLength = {18}
          />
          <View style={styles.errorinfoText}>{this.getError(error)}</View>
      </View>
    )
  }

}
const styles = StyleSheet.create({
  errorinfoText: {
    color: Colors.error,
    // fontFamily: 'WorkSans-Light',
    fontSize: fontType('subFont'), 
    marginTop:3,
  },

  txtInput: {
    borderBottomWidth:wp('0.1%'),
    // textAlign:'center',
    marginTop:wp('3%'),
    fontFamily:'Inter',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 3,
    paddingRight: 3, 
  },
})
