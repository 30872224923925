import React, { Component } from 'react';
import ResetPin from '../components/userValidationPin/resetPin';
import { connect } from "react-redux";
import { hasNewUpdate, hasNewAppUpdate } from '../actions/update';
import {retrieveLoginSuccess,retrieveLoginFailure} from '../actions/clientUser';
import { bindActionCreators } from "redux";

class ResetPinPage extends Component {
    state = {};

    render() {
        const { retrieveLoginSuccess,retrieveLoginFailure,navigation,hasNewAppUpdate,hasNewUpdate,studySiteId,appVersion,hasUpdate,hasAppUpdate} = this.props;
        const userName = navigation.getParam("userName");
        const OtpId = navigation.getParam("OtpId");
        return (
            <ResetPin 
            retrieveLoginFailure={retrieveLoginFailure} 
            retrieveLoginSuccess={retrieveLoginSuccess} 
            hasNewAppUpdate={hasNewAppUpdate} 
            hasNewUpdate={hasNewUpdate} 
            appVersion={appVersion} 
            studySiteId={studySiteId} 
            userName={userName} 
            hasUpdate={hasUpdate} 
            hasAppUpdate={hasAppUpdate} 
            navigation={navigation}
            OtpId={OtpId}
            />
        );
    }
}
const mapStateToProps = state => ({
    studySiteId: state.studySite.stdSite.id,
    appVersion: state.studySite.stdSite.appVersion,
    hasUpdate:state.hasUpdate,
    hasAppUpdate: state.hasAppUpdate

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        hasNewUpdate,
        retrieveLoginSuccess,
        retrieveLoginFailure,
        hasNewAppUpdate

    },
    dispatch,
);


export default connect(mapStateToProps,mapDispatchToProps)(ResetPinPage);
