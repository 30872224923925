import React, { Component } from 'react';
import ChangePasswordScreen from '../components/changePassword/ChangePasswordScreen';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ChangePasswordPage extends Component {
    render() {
        const { selectedUser, navigation } = this.props;
        return (
            <ChangePasswordScreen 
            clientUser={selectedUser}
            navigation={navigation}/>            
        );
    }
}
const mapStateToProps = state => ({
    selectedUser: state.clientUser.selectedUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {

    },
    dispatch,
);


export default connect(mapStateToProps,mapDispatchToProps)(ChangePasswordPage);
