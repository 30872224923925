import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import PropTypes from 'prop-types';
import { commonComponentStyles } from './styles';
import { ButtonTypes } from './constants';
import { Colors } from '../../constants/ui/colorScheme';

const TryalButton = React.memo((props) => {
    const { title, onClick, buttonStyle, buttonTxtStyle, type, buttonColor,disabled } = props;
    console.log('TryalButton')
    return (
        <TouchableOpacity style = {ButtonTypes.FILLED === type 
        ?  [buttonStyle, {backgroundColor: disabled? '#d3d3d3' :buttonColor}]
        :[buttonStyle, {backgroundColor: disabled? '#d3d3d3': '#ffffff',borderWidth: 1, borderColor: buttonColor}]}
        onPress={onClick}
        disabled={disabled}
        >
            <Text style = {ButtonTypes.FILLED === type 
            ? [buttonTxtStyle]
            : [buttonTxtStyle,{ color:buttonColor}]
        }>{title}</Text>
        </TouchableOpacity>
    )
}
)

TryalButton.defaultProps = {
    onClick: () => null, 
    buttonStyle: commonComponentStyles.buttonStyle,
    buttonTxtStyle: commonComponentStyles.buttonTxtStyle,
    type: ButtonTypes.FILLED,
    buttonColor: Colors.buttonwithbg,
    disabled: false,
}
TryalButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    buttonStyle: PropTypes.instanceOf(Object),
    buttonTxtStyle: PropTypes.instanceOf(Object),
    type: PropTypes.string,
    buttonColor: PropTypes.string,
    disabled: PropTypes.bool,
}
export default TryalButton;
