import React, { Component } from 'react';
// import { AsyncStorage } from 'react-native';
import sessionStorage from 'redux-persist/lib/storage/session';

export const ConsentDataContext = React.createContext();


export default class ConsentDataProvider extends Component {
    state = {
        languages: {},
        studyNumber:'',
        latestBuildVersion:'',
        latestBuildVersionId:'',
        saveLanguages: async (languagesString) => {
            try {
                const resp = await sessionStorage.setItem('languages', languagesString);                
                const languages = JSON.parse(languagesString);
                const latestBuildVersionId = languages['English']['consentVersionId'];
                await sessionStorage.setItem('latestBuildVersionId', latestBuildVersionId);
                this.setState({
                     languages,
                     studyNumber: languages['English']['studyNumber'],
                     latestBuildVersion:languages['English']['versionName'],
                     latestBuildVersionId: latestBuildVersionId,
                })
                return resp;
            }
            catch (error) {
                console.log(error)
                this.setState({ error })
            }

        },
        removeLanguages: async () => {
            try {
                const resp = await sessionStorage.removeItem('languages');
                return resp
            }
            catch (error) {
                console.log(error)
                this.setState({ error })
            }
        },
        getLanguages: async () => {
            try {
                const resp = await sessionStorage.getItem('languages');
                return JSON.parse(resp);
            }
            catch (error) {
                console.log(error)
                this.setState({ error })
            }
        },

        updateLanguages: async (languagesString) => {
            try {
                const resp = await sessionStorage.setItem('languages', languagesString);
                this.setState({ languages: JSON.parse(languagesString) })
                return resp;
            }
            catch (error) {
                console.log(error)
                this.setState({ error })
            }

        },
        

    }

    

    componentDidMount() {
        sessionStorage.getItem('languages')
            .then((languages) => {
                const lan = JSON.parse(languages);
                this.setState({ languages: lan })
            })
            .catch(error => {
                console.log(error)
                this.setState({ error })
            })
        sessionStorage.getItem('latestBuildVersionId')
        .then((latestBuildVersionId) => {            
            this.setState({ latestBuildVersionId: latestBuildVersionId })
        })
        .catch(error => {
            console.log(error)
            this.setState({ error })
        })
    }

    render() {
        return (
            <ConsentDataContext.Provider value={this.state}>
                {this.props.children}
            </ConsentDataContext.Provider>
        );
    }
}