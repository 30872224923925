import {
    LOGIN_RETRIEVE_REQUEST,
    LOGIN_RETRIEVE_SUCCESS,
    LOGIN_RETRIEVE_FAILURE,
    STORE_STUDY_SITE_ACCESS_CODE,
    STORE_SITE_ID,
    STORE_STUDY_SITE_ACCESS_CODE_SUCCESS,
    STORE_STUDY_SITE_ACCESS_CODE_FAILURE,
    STORE_STUDY_SITE_ACCESS_CODE_REQUEST,
    CLEAR_STUDY_SITE_ACCESS_CODE
} from '../actions/clientUser';
import { STORE_STUDY_SITE_LIST_SUCCESS, STORE_STUDY_SITE_SUCCESS } from '../actions/studySite';
import { STORE_SUBJECT_REMOTE_CONSENT_DTO } from '../actions/subjectRemoteConsentDto';
import initialState from '../store/initialState';

export default (state = initialState.studySite, action) => {
    switch (action.type) {
        case LOGIN_RETRIEVE_REQUEST:
            return {
                ...state,
            };
        case LOGIN_RETRIEVE_SUCCESS:
            return {
                ...state,
                ...action.studySite,
            };
        case LOGIN_RETRIEVE_FAILURE:
            return {
                ...state,
            }
        case STORE_STUDY_SITE_ACCESS_CODE_REQUEST:{
            return {
                ...state,
            };
        }
        case STORE_STUDY_SITE_ACCESS_CODE_SUCCESS:
            return {
                ...state,
                accessCode: action.accessCode,
            }
        case CLEAR_STUDY_SITE_ACCESS_CODE:
        return {
            ...state,
            accessCode: action.accessCode,
        }
        case STORE_STUDY_SITE_ACCESS_CODE_FAILURE:{
            return {
                ...state,
            };
        }
        case STORE_SITE_ID:{
            return {
                ...state,
                stdSite: action.stdSite,
            }
        }
        case STORE_STUDY_SITE_LIST_SUCCESS:
        return {
            ...state,
            list: action.studySiteList,
            loading: false,
        };
        case STORE_STUDY_SITE_SUCCESS:
        return {
            ...state,
            studySiteId: action.studySite.id,
            siteNumber: action.studySite.siteNumber,
            studyId: action.studySite.study.id,
            studyNumber: action.studySite.study.studyNumber,
            sponsorName: action.studySite.study.sponsorName,
        };
        case STORE_SUBJECT_REMOTE_CONSENT_DTO:
        let { subjectRemoteConsentDto } = action;
        return {
            ...state,
            studySiteId: subjectRemoteConsentDto.studySiteId,
            siteNumber: subjectRemoteConsentDto.siteNumber,
            studyId: subjectRemoteConsentDto.studyId,
            studyNumber: subjectRemoteConsentDto.studyNumber,
            sponsorName: subjectRemoteConsentDto.sponsorName,
        };

        default:
            return state;
    }
}

