import React, { Component } from "react";
import ValidateOtp from "../components/userValidationPin/validateOtp";
import { connect } from "react-redux";

class ValidateOtpPage extends Component {
  state = {};

  render() {
    const { navigation } = this.props;
    const otpId = navigation.getParam("OtpId");
    const otpType = navigation.getParam("otpType");
    const userName = navigation.getParam("userName");

    return (
      <ValidateOtp
        otpType={otpType}
        userName={userName}
        otpId={otpId}
        navigation={navigation}
      />
    );
  }
}


export default ValidateOtpPage;
