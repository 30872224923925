// import React, { Component } from 'react';
// import HTML from 'react-native-render-html';
// import _ from 'lodash'
// import { StyleSheet, Text, View,  Dimensions, Platform, Linking } from 'react-native';
// import Dialog, { DialogContent,  ScaleAnimation,} from 'react-native-popup-dialog';
// import { MaterialCommunityIcons } from '@expo/vector-icons';
// import { TouchableOpacity } from 'react-native-gesture-handler';
// import { Colors } from '../../constants/ui/colorScheme';
// import { IGNORED_TAGS } from 'react-native-render-html/src/HTMLUtils';
// import {fontType} from '../../styles/font';
// import ReactHtmlParser from 'react-html-parser';


// const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')
// const tags = _.without(IGNORED_TAGS,
//   'table', 'caption', 'col', 'colgroup', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr'
// )

// const tableDefaultStyle = {
//   flex: 1,
//   justifyContent: 'flex-start',
// }

// const tableColumnStyle = {
//   ...tableDefaultStyle,
//   flexDirection: 'column',
//   alignItems: 'stretch',
//   borderWidth:0.2,
//   borderColor:'rgba(128, 128, 128, 0.18)',
// }

// const tableRowStyle = {
//   flex: 1,
//   flexDirection: 'row',
//   padding:2,
 
// }

// const tdStyle = {
//   ...tableDefaultStyle,
//   borderWidth:0.3,
//   borderColor:'rgba(128, 128, 128, 0.18)',
//   padding: 5,
//   paddingLeft:Platform.OS == 'web' ?10: 5
// }

// class HtmlParser extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       word: '',
//       abbr: '',
//       dialogueVisible: false
//     }
//     this.handleAbbrDialogue = this.handleAbbrDialogue.bind(this);
//   }

//   handleAbbrDialogue = (abbrivation, word) => {
//     this.setState({
//       dialogueVisible: true,
//       word: word,
//       abbr: abbrivation,
//     });
//   };

//   handleHeaderMenuDialoge = () => {
//     this.setState({
//       ...this.state,
//       dialogueVisible: false
//     })
//   }

//   alterNode = (node) => {
//     let { parent, data } = node;
// }

//   render() {
 
//     const { currentDoc, isDeclaration } = this.props;
//     const { dialogueVisible, word, abbr, Height } = this.state;
//     const decodedDoc = currentDoc ? currentDoc :'';
//     const renderers = {
//       table: (x, c) => <View style={tableColumnStyle}>{c}</View>,
//       tr: (x, c) => <View style={tableRowStyle}>{c}</View>,
//       h1: (x, c) => <Text>{c}</Text>,
//       h2: (x, c) => <Text>{c}</Text>,
//       h3: (x, c) => <Text>{c}</Text>,
//       td: (x, c) => (
//               <View style={tdStyle}>
//                   {c}
//               </View>
//       ),
//       abbr: (x, c) => <Text
//                           accessibilityRole='button'
//                           onPress={() => {
//                             this.handleAbbrDialogue(x.title, c);
//                           }}
//                           style={{
//                             textDecorationLine: 'dotted',
//                             color: Colors.warning,
//                             backgroundColor: Colors.htmlparserText,
//                           }}
//                       >{c}</Text>
//     }
//     return (
//       <View style={{ paddingHorizontal: 10}}>
//           {/* <HTML html={decodedDoc}
//             alterData={this.alterNode}
//             ignoredTags={tags}
//             renderers={renderers}
//             baseFontStyle={Platform.OS === 'web'? { fontFamily: "Inter", }: {fontFamily: "Inter",fontSize:17}}
//             ignoredStyles={["font-family", "letter-spacing"]}
//             tagsStyles={{
//               em: { fontFamily: 'System' },
//               strong: {fontFamily: 'System'}
//             }}
//             onLinkPress={(evt, href) => {
//                 if(Platform.OS == 'web'){
//                   window.open(href, '_blank');
//                 } else {
//                   Linking.openURL(href)
//                 }
//               }
//             }
//             /> */}
//         <View>{ReactHtmlParser(decodedDoc)}</View>
          
//         <Dialog
//           // dialogAnimation={new ScaleAnimation()}
//           rounded={true}
//           visible={dialogueVisible}
//           width={SCREEN_WIDTH >= 1030 ? 0.3 : 0.5}
//         >
//           <DialogContent >
//             <View style={{ flexDirection: 'row', alignItems: 'center', }}>
//               <View style={{ flex: 1 }}>
//                 <Text style={abbrStyles.dialogWord}>{word}</Text>
//               </View>
//               <TouchableOpacity
//                 onPress={() => this.handleHeaderMenuDialoge()}
//                 style={{ flex: 1, justifyContent: 'center' }}>
//                 <MaterialCommunityIcons name='close' size={fontType('ChapterIcon')} />
//               </TouchableOpacity>
//             </View>
//             <View style={{ height: 2, backgroundColor: '#000' }}></View>
//             <View >
//               <Text style={{ fontSize: fontType('BasicFont'), paddingVertical:8, fontFamily: 'Inter' }}>{abbr}</Text>
//             </View>
//           </DialogContent>
//         </Dialog>
//       </View>
//     );
//   }
// }
// export default HtmlParser;

// var abbrStyles = StyleSheet.create({
//   dialogWord: {
//     fontSize: fontType('BasicFont'), 
//     color: Colors.coloredText, 
//     paddingVertical: 5, 
//     top: 3,
//     fontFamily: 'Inter'

//   },

// });

import React, { Component, useState } from 'react';
import ReactHtmlParser, { convertNodeToElement, processNodes } from "react-html-parser";
import { StyleSheet, Text, View, Dimensions, Platform, Linking } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Dialog, { DialogContent, ScaleAnimation, } from 'react-native-popup-dialog';
import { TouchableOpacity } from 'react-native-gesture-handler';
import _ from 'lodash';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

function HtmlParseExample(props) {

  const options = { decodeEntities: true, transform, preprocessNodes: nodes => nodes };
  const [abbrDetails, setAbbrDetails] = useState({ dialogueVisible: false, word: '', abbr: '' });

  function transform(node, index) {
    if (node.type === "tag" && node.name === "abbr") {
      return <span style={{
          textDecorationLine: 'dotted',
          color: Colors.warning,
          backgroundColor: Colors.htmlparserText,
          cursor: "pointer"
        }}
        accessibilityRole='button'
        onClick={() => {
            setAbbrDetails({
              ...abbrDetails,
              dialogueVisible: true,
              word: node.children[0].data,
              abbr: node.attribs.title
            })
          }
        }
      >{node.children[0].data}</span>;
    }
    if (node.type === "tag" && node.name === "table") {
      return <div style={{
        width: "100%",
        overflowY: "hidden",
        overflowX: "visible"
      }}>
        {convertNodeToElement(node, index, transform)}
      </div>
    }
    /*
      Deriving width in numbers from image style's width attribute for comparision. Because width attribute is string(say 10px)
      The default width of the application is 800 as per App.js file and also the default width of the tablet starts from 800
      Comparing Image width with screenwidth: 
        when imageWidth > screenwidth, we are adjusting the image width to 100% and setting height as auto for autoadjustment with height
        when imageWidth < screenwidth, we are keeping the original image style attributes
      Comparing Image width with 800:
        Reason: In desktop type, the screen width is larger than the application width. Application is taking only 800px width at the centre of the browser
        when imageWidth > 800, we are adjusting the image width to 100% and setting height as auto
        when imageWidth < screenwidth, we are keeping the original image style attributes
    */
    if (node.type === "tag" && node.name === "img") {
      var styleObj = node?.attribs?.style ? getNodeStyle(node.attribs.style) : null;
      if(!_.isEmpty(styleObj) && !_.isEmpty(styleObj.width) && _.includes(styleObj.width, "px")){
        let widthInNumber = styleObj.width.replace("px", "")
        if(widthInNumber >= 800 || widthInNumber > SCREEN_WIDTH){
          styleObj.width = '100%';
          styleObj.height = 'auto';
        }
      }      
      return <img alt={node.attribs.alt} src={node.attribs.src} style={styleObj} /> ;
     }
  }

  const getNodeStyle = (styleText) => {
    let properties = styleText.split(" ").join("").split(";");
    var obj = {};
    properties.forEach(function (property) {
      var tup = property.split(":");
      obj[tup[0]] = tup[1];
    });
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
    return obj
  }

  return (
    <View>
      <View style={{ fontFamily: 'Inter'}}>{ReactHtmlParser(props.currentDoc, options)}</View>
      <Dialog
        rounded={true}
        visible={abbrDetails.dialogueVisible}
        width={SCREEN_WIDTH >= 1030 ? 0.3 : 0.5}
      >
        <DialogContent >
          <View style={{ flexDirection: 'row', alignItems: 'center', }}>
            <View style={{ flex: 1 }}>
              <Text style={abbrStyles.dialogWord}>{abbrDetails.word}</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                setAbbrDetails({
                  ...abbrDetails,
                  dialogueVisible: false
                })
              }
              }
              style={{ flex: 1, justifyContent: 'center' }}>
              <MaterialCommunityIcons name='close' size={fontType('ChapterIcon')} />
            </TouchableOpacity>
          </View>
          <View style={{ height: 2, backgroundColor: '#000' }}></View>
          <View >
            <Text style={{ fontSize: fontType('BasicFont'), paddingVertical: 8, fontFamily: 'Inter' }}>{abbrDetails.abbr}</Text>
          </View>
        </DialogContent>
      </Dialog>
    </View>
  )
}

export default HtmlParseExample;

var abbrStyles = StyleSheet.create({
  dialogWord: {
    fontSize: fontType('BasicFont'),
    color: Colors.coloredText,
    paddingVertical: 5,
    top: 3,
    fontFamily: 'Inter'
  },
});