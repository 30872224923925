import React, { Component } from "react";
import { Header, Button, Card, Input, Item, CardItem } from 'native-base';
import { StyleSheet, Text, View, Platform, TouchableOpacity } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { createForm, } from "rc-form";
import api from '../../utils/api';
import Dialog, { DialogContent, DialogFooter, DialogButton, DialogTitle, SlideAnimation, ScaleAnimation } from 'react-native-popup-dialog';
import TextInputItem from './TextInputItem';
import { Colors } from "../../constants/ui/colorScheme";
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';
import showToast from "../../utils/toast";
import constants from "../../utils/constants.js";
const { ContextProperties } = constants

class CaptureSubjectNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:false,
            loading:false,
            usubjectIdAlreadyExists: false,
        }
    }

    onSubmit = () => {
        const { navigation, form, storeSubjectID, selectedSubject, handleSubjectIdModal } = this.props;
        form.validateFields(async (error, values) => {
            if (!error) {
                const usubjectId = values.subjectId;
                const subject = {
                    ...selectedSubject,
                    usubjectId: usubjectId,
                    subjectConsent: {
                        status: 'CONSENTED',
                    }
                }
                const subjectConsent = {
                    id : selectedSubject.subjectConsentId
                }
                try {
                    this.setState({loading:true});
                    await api.put(
                        `/${ContextProperties.PRIMARY_ORG_CODE}/studies/${ContextProperties.STUDY_ID}/sites/${ContextProperties.SITE_ID}/subjects/${selectedSubject.id}`
                        , {subject, subjectConsent});
                    this.setState({loading:false});
                    storeSubjectID(usubjectId);
                    handleSubjectIdModal();
                } catch (error) {
                    this.setState({loading:false});
                    console.log(error);
                    if (error.response && error.response.status) {
                        const stats = error.response.status 
                        if (Number(stats) === 409) {
                            this.setState({ usubjectIdAlreadyExists: true});
                            setTimeout(() => {
                            this.setState({
                                usubjectIdAlreadyExists: false,
                            });
                          }, 5000);
                        } 
                      }
                }

            } else {
                console.log(error);
            }
        });

    }

    onSkip = () => {
        const { handleSubjectIdModal } = this.props;
        handleSubjectIdModal();
    }


    render() {
        const { form: { getFieldDecorator, getFieldError, textInputProps, getFieldProps }, 
                 handleSubjectIdModal, subjectIdModal, selectedSubject, isSessionActive } = this.props;
        const {loading, usubjectIdAlreadyExists} = this.state;
        

        return (
          
            <View>
                <Dialog
                    visible={subjectIdModal && isSessionActive}
                    dialogTitle={<DialogTitle title="Subject ID"  align ="left"
                                style ={{fontFamily:'Inter'}}
                                    />}
                    dialogAnimation={new ScaleAnimation({  })}
                >
                    <DialogContent>
                    <MyLoader active={loading}/>
                    <View >
                        <Text style={{fontSize:fontType('BasicFont'),padding:10, color:'gray', marginTop:10, fontFamily:'Inter' }}>Enter Subject ID carefully - as it cannot be updated after submission</Text>
                        <Text style={{fontSize:fontType('BasicFont'),paddingLeft:10, color:'gray', fontFamily:'Inter'}}>Subject's Name: {selectedSubject.fullName}</Text>
                        <Text style={{fontSize:fontType('BasicFont'),fontFamily:'Inter', marginTop:40}}>Subject ID:</Text>
                        
                    {getFieldDecorator('subjectId', {
                        rules: [
                            { required: true, message: ' Please enter Subject ID. ' },
                        ],
                    })(
                        <TextInputItem
                            autoFocus
                            error={getFieldError('subjectId')}
                        />
                    )}
                    { usubjectIdAlreadyExists && <Text style={styles.errorText}>This Subject ID already exists in this study. Please update try again. Contact the help desk if you need assistance.</Text> }
                        <View style ={{flexDirection:'row', justifyContent:'space-around', marginTop:70}}>
                            <TouchableOpacity bordered
                            style={constStyles.buttonStyle}
                             onPress={this.onSkip}
                            ><Text style= {constStyles.buttonTxt}>SKIP</Text></TouchableOpacity>
                            <TouchableOpacity 
                            onPress = {this.onSubmit}
                                style={constStyles.buttonStyle}
                            >
                            
                            <Text style= {constStyles.buttonTxt}>SUBMIT</Text></TouchableOpacity>
                        </View>
                    </View>
                    </DialogContent>
                </Dialog>
            </View>
        );
    }
}


export default createForm()(CaptureSubjectNumber);


const styles = StyleSheet.create({
    buttonSubmit: {
        backgroundColor: Colors.buttonwithbg,
       
        margin: wp('1%'),
        borderRadius: wp('3%'),
        minWidth: wp('16%'),
        // height: wp('7.5%'),
      },
      skipBtn: {
        borderColor: Colors.buttonwithbg,
        margin: wp('1%'),
        borderRadius: wp('3%'),
        minWidth: wp('16%'),
      },

     btnTxt: {
        color: Colors.textColorwithbg,
        marginLeft: wp('3%'),
        // textAlign: 'center',
        fontSize: wp('3%'),
        fontFamily:'Inter_Bold'
      }
      ,		
    errorText: {		
        fontSize: fontType('subFont'), 
        marginTop:3,		
        color: Colors.error,		
    }
   
  })
  
