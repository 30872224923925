import Constants from '../utils/constants';


const config = {
    screens: {
        AdminAuthStack: {
            path: Constants.CONTEXT_PATH+'/admin/auth',
            screens: {
                LoginPage: '/api/:primaryOrgCode/:orgCode/login',
                WebAppLandingPage: '/webappLanding/:loginRequestId',
                ChangePasswordPage: '/change/password',
                ForgotPasswordPage: '/forgot/password',
                ValidateOtpScreen: '/validate/otp',
                ResetPinScreen: '/reset/password',
                PageNotFound: '/*'
            },
        },
        Admin: {
            path: Constants.CONTEXT_PATH + "/admin", 
            screens: {
                StudyListPage: "/studylist", 
                DownloadVideosAndStoreData: "/download/metadata", 
                StudySiteAccessInfoScreen: "/studysiteinfo", 
                HomeTabs: { 
                    path: "/home", 
                    initialRouteName: "ConsentTab", 
                    screens: { 
                        ConsentTab: "/consent", 
                        ReConsentTab: "/reconsent" 
                    } 
                }, 
                SubjectDetails: "/subject/details", 
                DocumentSelection: "/subject/DocumentSelection",
                SiteStaffCommentsReviewScreen: '/subject/commentsReview',
                CounterSignatureScreen: "/countersign",
                WitnessSignatureScreen: "/witnesssign", 
                CounterSignDoc: "/counterdoc", 
                LanguageSelectScreen: "/selectlanguage", 
                HandoverToSubjectScreen: "/handovertosubjectscreen", 
                HandoverTabTosub: "/handovertabtosub", 
                SubjectEditScreen: "/subject/edit", 
                AddSubjectScreen: "/subject/add",
                ClientNotFound:"/clientNotFound",
                UserInactivated:"/userInactivated",
                ClientInactivated:"/clientInactivated",
                PageNotFound: '/*'
            },
        },
        Consent: {
            path: Constants.CONTEXT_PATH + "/consent", 
            screens: { 
                ConsentOverviewScreen: "/consentoverview", 
                StudyInfoScreen: "/studyinfo",
                DocumentsScreen: "/documents", 
                StudyVideoListScreen: "/studyvideo",
                KnowledgeReviewScreen: "/knowledgereview", 
                CongratulationsScreen: "/congratulations",
                CommentsReviewScreen: "/commentsReview", 
                SubjectRegistrationForm: "/registrationfrom", 
                AskForLarPage: "/askforlar", 
                DocumentListScreen: "/documentlist", 
                SubjectDocumentSignScreen: "/subjectdocumentsign", 
                SubjectSignatureScreen: "/subjectsign", 
                LARSignatureScreen: "/larsign", 
                CompletedScreen: "thankyou", 
                SubDocsPage: "/subdocspage", 
                AbortConsentScreen: "/stopmyparticipation",
                HeaderMenuPopup: "/menu",
                MetaDataLoading: "/metadataloading" ,
                CancelFlow: '/cancelFlow',
                PageNotFound: '/*'
            } 
        },
        SubjectAuthStack: {
            path: Constants.CONTEXT_PATH+'/subject',
            screens: {
                SubjectAuthPage: '/auth/:otp',
                SubjectSignInScreen:"/signin",
                SubjectSignUpScreen:"/signup",
                SubjectValidateOtpPage:"/validateotp",
                SubjectResetPasswordPage:"/resetpassword",
                PageNotFound: '/*'
            }
        }
    },
};  


export const linking = {
    prefixes: [Constants.PREFIX],
    config
};

