import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, ScrollView, Text, TouchableOpacity, Dimensions, ActivityIndicator} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {styles} from './styles/congratulationStyle';
import { onSelectingNextChapter} from '../../utils/consentData';
import Icon from 'react-native-vector-icons/AntDesign';
import showToast from '../../utils/toast';
import api from '../../utils/api';
import { Colors } from '../../constants/ui/colorScheme';
import ConfirmationPopup from '../abortConsent/ConfirmationPopup'
import {ConsentDataContext} from '../../providers/ConsentDataProvider';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';

export default class Congratulations extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stoppedParticipation: false,
            confirmPopupVisible: false
        }
    }

    // submitSubjectData = async () => {
    //     const { 
    //         subjectData,
    //          navigation, 
    //          storeSubjectStatus,
    //          updateConsentStatus, 
    //          studySiteId,
    //          screenProps:{t}
    //          } = this.props;
    //          this.setState({loading: true})
    //     const subject = {
    //         status: 'DISCONTINUED',
    //         consentId: subjectData.consentId,
    //         studySite :{
    //             id: studySiteId
    //         },
    //         languageId: subjectData.languageId,
    //     }
    //     this.setState({loading: true, stoppedParticipation: true})
    //     try {
    //         await api.post('/subject/abort', subject)
    //         this.setState({loading: false})
    //         updateConsentStatus(true);
    //         storeSubjectStatus('DISCONTINUED');
    //         navigation.navigate('CompletedScreen')
    //     }
    //     catch (error) {
    //         this.setState({loading: false});
    //         showToast(t('StopPartFailed'), 'danger', 4000);
    //         console.log(error);
    //     }
    // }

    handleConfirmationPopup = () => {
        this.setState(prevState => ({
            ...prevState,
            confirmPopupVisible: !prevState.confirmPopupVisible,
        }));
    }

    componentDidUpdate = (prevProps)  => {
        const { screenProps:{isSessionActive} } = this.props;
        if(prevProps.screenProps.isSessionActive !== isSessionActive && isSessionActive == false) {
            this.setState(prevState => ({
                ...prevState,
                confirmPopupVisible: false,
            })); 
        }
    }

    render() {
        const { screenProps:{t, isSessionActive},  subjectData, navigation, krDoneBy,
        storeSubjectStatus, updateConsentStatus,studySiteId,storeVisitedChapters, deviceNo, selectedSubject} = this.props;
        const { loading, stoppedParticipation, confirmPopupVisible } = this.state;
        const currChapterOrdinal = navigation.getParam('currChapterOrdinal');
        return (
            <Container style={styles.container}>
                <View style={{ flex: 5.4, padding:5, paddingLeft: 5}}>
                    <Text style={{ fontSize: fontType('Heading1'), fontFamily:'Inter', color: Colors.text, padding:15 }}><b>{t('Congratulations')}</b></Text>
                    <Text note style={styles.textNote} >{t('Cong1')}</Text>
                    <Text note style={styles.textNote} >{t('Cong2')}</Text>
                    {selectedSubject.registered || subjectData.isRegistered ? null : <Text note style={styles.textNote} >{t('Cong3')}</Text>}
                </View>
                <View style={{ flex: 0.6, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: 'white', }}>
                    <View style={{ backgroundColor: 'white', marginHorizontal:10 }}>
                    {selectedSubject.registered || subjectData.isRegistered ? null :
                        <TouchableOpacity onPress={() => this.handleConfirmationPopup()}
                            // style={{ backgroundColor: Colors.errorButton, size: 20, paddingHorizontal: wp('7%'), height: hp('5%'), borderRadius: 50,justifyContent:'center' }}
                            style={[constStyles.buttonStyle, { backgroundColor: 'red' }]}
                        >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                                <Text style={[constStyles.buttonTxt]}>
                                    <Icon
                                        name='exclamationcircleo'
                                        color='white'
                                        size={15}
                                        style={{ marginRight: 5 }}
                                    />
                                    <Text style={{ marginLeft: 4 }}>&nbsp;{t('StopMyPart')} </Text>
                                </Text>
                            </View>
                        </TouchableOpacity>
                    }
                    </View>
                    <View style={{ backgroundColor: 'white',marginHorizontal:20 }}>
                    <TouchableOpacity onPress={() => {
                            onSelectingNextChapter(this.context.languages,subjectData.language, currChapterOrdinal, navigation,subjectData.isRegistered,subjectData.signatureType,subjectData.isKnowledgeReviewDone,storeVisitedChapters, subjectData.visitedChapters, selectedSubject.subjectConsent.isKrDone, krDoneBy);
                        }}
                        disabled={stoppedParticipation}
                            style={[constStyles.buttonStyle, { paddingHorizontal:15}]}
                        >
                       <Text style={constStyles.buttonTxt}>{t('Continue')}</Text>
                    </TouchableOpacity>
                    </View>
                </View>
                <ConfirmationPopup 
                subjectData={subjectData}
                navigation={navigation}
                storeSubjectStatus={storeSubjectStatus}
                studySiteId={studySiteId}
                updateConsentStatus={updateConsentStatus}
                handleConfirmationPopup={this.handleConfirmationPopup}
                confirmPopupVisible={confirmPopupVisible && isSessionActive}
                t={t}
                deviceNo={deviceNo}
                />
             
            </Container>
        );
    }
}
