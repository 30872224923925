import React, { Component } from 'react';
import { Container, ListItem ,List} from 'native-base';
import { View, ActivityIndicator, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import api from '../../utils/api';
import { Colors } from '../../constants/ui/colorScheme';
import Signt from 'react-native-vector-icons/FontAwesome5';
import NavigationMenu from '../dialogMenus/navigationMenu';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getLanguageData   } from '../../utils/consentData';
import ConfirmationPopup from './ConfirmationPopup';
import {fontType} from '../../styles/font'

const canShowHeaderDialogue = true;
export default class AbortConsentScreen extends Component {
    constructor(props) {
        super(props);
        this.handleNavigation = this.handleNavigation.bind(this),
            this.state = {
                userValidate: true,
                visible: true,
                loading: false,
                confirmPopupVisible: false
            }
    }

    componentDidUpdate = (prevProps)  => {
        const { screenProps:{isSessionActive} } = this.props;
        if(prevProps.screenProps.isSessionActive !== isSessionActive && isSessionActive == false) {
            this.setState(prevState => ({
                ...prevState,
                confirmPopupVisible: false,
            })); 
        }
    }

    handleNavigation = (ordinal) => {
        var redirectPages = ["","StudyInfoScreen", "StudyVideoListScreen", "DocumentsScreen", "KnowledgeReviewScreen"];
        const { navigation, handleHeaderMenuDialoge } = this.props;
        handleHeaderMenuDialoge();
        var chapter= redirectPages[ordinal];
        navigation.navigate(chapter, {ordinal: redirectPages.indexOf(chapter)} );
    }

    //   submitSubjectDatasubmitSubjectData = async () => {
    //     const { subjectData, navigation, storeSubjectStatus, handleHeaderMenuDialoge,
    //          updateConsentStatus, studySiteId, clearSubjectNavTour } = this.props;
    //          this.setState({loading: true})
    //     const subject = {
    //         status: 'DISCONTINUED',
    //         consentId: subjectData.consentId,
    //         studySite: {
    //             id: studySiteId
    //         },
    //         languageId: subjectData.languageId,
    //     }
    //     try {
    //         const res = await api.post('/subject/abort', subject)
    //         this.setState({ loading: false })
    //         handleHeaderMenuDialoge();
    //         updateConsentStatus(true);
    //         storeSubjectStatus('DISCONTINUED');
    //         clearSubjectNavTour();
           
    //         navigation.navigate('CompletedScreen')
    //     }
    //     catch (error) {
    //         this.setState({ loading: false })
    //         console.log(error);
    //     }
    // }

    handleConfirmationPopup = () => {
        this.setState(prevState => ({
            ...prevState,
            confirmPopupVisible: !prevState.confirmPopupVisible,
        }));
    }

    render() {
        const { screenProps: { t, isSessionActive }, subjectData, language,navigationMenus, studySiteId, handleHeaderMenuDialoge, storeSubjectStatus, navigation, updateConsentStatus,isRegistered, languages , storeNavigationMenus, deviceNo} = this.props;
        const { loading, confirmPopupVisible } = this.state;
        const chapters = getLanguageData(languages,language);
        return (
            <View style={{ justifyContent: 'center', alignContent: 'center' }}>
                <Text style={{ color: Colors.coloredText, padding: 10, fontSize: fontType('BasicFont'), paddingTop: 10, fontFamily: 'Inter' }}>
                    {t('EndPart')}
                </Text>
                 <View> 
                    <List>
                     {/* {this.renderIcons()} */}
                    {/* {renderIcons(this, chapters)} */}
                    <NavigationMenu  navigationMenus={navigationMenus} isRegistered={isRegistered} chapters={chapters} navigation={navigation} handleHeaderMenuDialoge={handleHeaderMenuDialoge} noOfchapters={6} visitedChapters={subjectData.visitedChapters}/>
                    </List>
                </View>
                <View style={{ paddingTop: 10 }}>
                    <TouchableOpacity onPress={() => this.handleConfirmationPopup()}  full style={{ backgroundColor: Colors.errorButton, borderRadius: 5, alignSelf: 'center' }}>
                        <View style={{flexDirection:'row', paddingHorizontal:10, paddingVertical:5}}>
                            <Icon
                                name='exclamationcircleo'
                                color='white'
                                size={fontType('ChapterIcon')}
                            />
                            <Text style={{ alignSelf: 'center', color: 'white', fontSize: fontType('ButtonTxt'), paddingLeft: 10, fontFamily: 'Inter_Bold' }}>
                                {t('StopMyPart')}
                                </Text>
                                {loading ? <ActivityIndicator size="large" color={Colors.ActivityIndicator} animating={true} hidesWhenStopped={true} /> : null}
                        </View>
                    </TouchableOpacity>
                </View>
                <ConfirmationPopup 
                subjectData={subjectData}
                navigation={navigation}
                storeSubjectStatus={storeSubjectStatus}
                studySiteId={studySiteId}
                updateConsentStatus={updateConsentStatus}
                handleConfirmationPopup={this.handleConfirmationPopup}
                confirmPopupVisible={confirmPopupVisible && isSessionActive}
                handleHeaderMenuDialoge={handleHeaderMenuDialoge}
                canShowHeaderDialogue={canShowHeaderDialogue}
                deviceNo={deviceNo}
                t={t}/>
            </View>
        );
    }
}
const styles = StyleSheet.create({
    container: {
        justifyContent: 'space-between',
        backgroundColor: Colors.background,
    },
    text: {
        fontWeight: "bold",
        color: Colors.textColor,
    },
});
