import NavigateToAdmin from '../components/navigateToAdmin/NavigateToAdmin';
import React, { Component } from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import { storeSignatureType } from '../actions/subject';
import { bindActionCreators } from "redux";



class NavigateToAdminScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            showAdmin: false,
        }
    }
    showAdminAfterCompleteConsent =() => {
        this.setState(
            {
                showAdmin: true,
            }
        )
    }
    
    render() {
        const {subjectData, navigation, handleHeaderMenuDialoge,isRegistered, clearSubjectNavTour, languages, isSubSignsDone, isRemoteConsent, signatureType, currentUserType, isDocSignsCompleted, otp, screenProps, onStopConsentClick }=this.props;
        const {showAdmin} = this.state;
        return (
           <NavigateToAdmin
           isDocSignsCompleted={isDocSignsCompleted}
           isRegistered={isRegistered}
           showAdmin={showAdmin}
           subjectData={subjectData}
           navigation={navigation}
           handleHeaderMenuDialoge = {handleHeaderMenuDialoge}
           language={subjectData.language}
           signatureType={currentUserType}
           clearSubjectNavTour={clearSubjectNavTour}
           languages = {languages}
           isSubSignsDone = {isSubSignsDone}
           isRemoteConsent = {isRemoteConsent}
           otp={otp}
           screenProps={screenProps}
           onStopConsentClick={onStopConsentClick}
           />
        );
    }
}



export default NavigateToAdminScreen;
