import {
  CLIENT_USER_DATA_REQUEST,
  CLIENT_USER_DATA_SUCCESS,
  CLIENT_USER_DATA_FAILURE,
  SELECTED_CLIENT_USER,
  LOGIN_RETRIEVE_SUCCESS,
  LOGIN_RETRIEVE_FAILURE,
  RESET_VALIDATION,
  SET_ORGANIZATION_NAME,
  CLEAR_CLIENT_USER
} from '../actions/clientUser';
import initialState from '../store/initialState';
import _ from 'lodash';

export default (state = initialState.clientUser, action) => {
  switch (action.type) {
    case CLIENT_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_USER_DATA_SUCCESS:
      return {
        ...state,
        list: action.clientUserslist,
        loading: false,
      };
    case CLIENT_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SELECTED_CLIENT_USER:
      return {
        ...state,
        selectedUser: action.clientUser,
        loading: false,
      }
      case LOGIN_RETRIEVE_SUCCESS:
      return {
        ...state,
        isValid: true,
      };
    case LOGIN_RETRIEVE_FAILURE:
      return {
        ...state,
        isValid: false,
      };
      case RESET_VALIDATION:
      return {
        ...state,
        isValid: true,
      };
      case SET_ORGANIZATION_NAME: 
      return {
        ...state,
        organizationName: action.organizationName,
        organizationId: action.organizationId,
      }
      case CLEAR_CLIENT_USER: 
      return {
        ...state,
        list: [],
        selectedUser: {
          userName: "",
        },
        isValid: null,
        organizationName: null,
        organizationId: null,
      }
      
    default:
      return state;
  }

};
