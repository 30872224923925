import Congratulations  from '../components/congratulations/Congratulations';
import React, { Component } from "react";
import { Icon } from 'native-base';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {updateConsentStatus,storeSubjectStatus, storeVisitedChapters} from '../actions/subject'

class CongratulationsScreen extends Component {
    state={
    };
    
    render() {
        const { navigation, subjectData,
            studySiteId, storeSubjectStatus, krDoneBy,
           updateConsentStatus, screenProps, storeVisitedChapters, deviceNo, selectedSubject} = this.props;
        return (
           <Congratulations navigation={navigation}
           screenProps={screenProps}
           subjectData={subjectData}
           studySiteId={studySiteId}
           storeSubjectStatus={storeSubjectStatus}
           updateConsentStatus={updateConsentStatus}
           storeVisitedChapters= {storeVisitedChapters}
           deviceNo={deviceNo}
           selectedSubject ={selectedSubject}
           krDoneBy = { krDoneBy }
           />
        );
    }
}
const mapStateToProps = state => {
    return {
      isRegistered: state.subject.isRegistered,
      subjectData: state.subject,
      consentID: state.subject.consentId,
      consentCompleted: state.subject.consentCompleted,
      studySiteId: state.studySite.studySiteId,
      deviceNo: state.consentTokenData.deviceNo,
      selectedSubject: state.selectedSubject ,
      krDoneBy: state.consentFlow.krDoneBy,
    };
  };
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
      updateConsentStatus,
      storeSubjectStatus,
      storeVisitedChapters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CongratulationsScreen);


