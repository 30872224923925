

import {StyleSheet} from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';

export const commonComponentStyles= StyleSheet.create({
    inputText:{
        borderColor: '#bdbdbd',
        borderBottomWidth: 2,
        paddingVertical:5,
        textAlign:'left',
        fontFamily:'Inter',
        marginLeft: 3
      },
      buttonStyle: {
        padding: 4,
        backgroundColor: Colors.buttonwithbg,
        borderRadius: 5,
        justifyContent: "center",
        alignSelf: "center",
      },
      buttonTxtStyle: {
        minWidth: 70,
        padding: 5,
        marginHorizontal: 16,
        color: Colors.textColorwithbg,
        fontSize: fontType("ButtonTxt"),
        fontFamily: "Inter_Bold",
        textAlign: "center",
      },
})