import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';
import { constStyles } from '../../styles/constStyles';
import {  Image } from "react-native";

export default class NoAccessScreen extends Component {        
    render() {
        return (
            <Container>
                <View style={{ flex: 1, justifyContent: 'center', backgroundColor: '#f2f2f2' }} >
                    {/* -------------- Lock Icon ------------------ */}
                    <View style={{ alignContent: 'center', alignSelf: 'center', }} >
                    <Image
                         source={require("../../../assets/access-denied.png")}
                         style={{ height: 125, width: 125, paddingBottom: 10}}
                         resizeMode='contain'
                       />
                    </View>
                    {/* -------------- Message ------------------ */}
                    <View style={{ alignContent: 'center', alignSelf: 'center'}}>
                        <View style={{ flex: 1, justifyContent: 'center' }}>
                            <Text style={constStyles.errorPageText}>The user does not have sufficient privileges to access this module.</Text>
                            <Text style={constStyles.errorPageText}> jConcur WebApp is only available to users with privileges at the site level.</Text>
                            <Text style={constStyles.errorPageText}> Please contact your administrator for help.</Text>
                        </View>
                    </View>
                </View>
            </Container>
        );
    }
}

