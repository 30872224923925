import React, { Component } from "react";
import AbortConsent from '../components/abortConsent/AbortConsentScreen';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {updateConsentStatus} from '../actions/subject'

class AbortConsentScreen extends Component {
    state={};
    render() {
        const {navigation, subjectData, updateConsentStatus, storeSubjectStatus,
            studySiteId, consentID, showAdminAfterCompleteConsent, deviceNo,
            handleHeaderMenuDialoge, screenProps,isRegistered, clearSubjectNavTour, languages} = this.props;
        return (
           <AbortConsent
             isRegistered={isRegistered}
             navigationMenus={subjectData.navigationMenus}
             studySiteId={studySiteId}
             updateConsentStatus={updateConsentStatus}
             showAdminAfterCompleteConsent={showAdminAfterCompleteConsent}
             navigation={navigation}
             subjectData={subjectData}
             consentID={consentID}
             handleHeaderMenuDialoge={handleHeaderMenuDialoge}
             screenProps={screenProps}
             storeSubjectStatus={storeSubjectStatus}
             language={subjectData.language}
             clearSubjectNavTour={clearSubjectNavTour}
             languages = {languages}
             deviceNo={deviceNo}
             />
        );
    }
}


export default AbortConsentScreen;
