import {StyleSheet, Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export const documentStyles= StyleSheet.create({
  documentTitle:{
    color:Colors.text,
    textAlign:'center',
    fontSize:fontType('Heading1'),
    fontFamily:'Inter',
    backgroundColor: Colors.canvasbg,
    padding:3,
    
  },
  nextBtnTxt:{
    color: Colors.textColor,
     marginLeft: wp('2%'),
      textAlign: 'center'
  },
  prevBtnTxt:{
    color: Colors.textColor,
    marginRight: wp('2%'),
    textAlign: 'center'
  },
  footer:{
    backgroundColor: Colors.text,
    justifyContent: 'flex-end',
    alignItems:'center' 
  },
  signaturesButton: {
    backgroundColor: Colors.button,
    justifyContent:'center',
    alignSelf:'center',
    margin: wp('1%'),
    borderRadius: wp('5%'),
    borderColor: 'white',
    borderWidth: 1,
    width: wp('25%'),
    height: wp('5.5%'),
    marginRight: wp('2.5%')
  },

  signaturesButtonText: {
    color: 'white',
    marginLeft: wp('1%'),
    textAlign: 'center',
    fontSize: wp('2.5%'),
    paddingHorizontal: wp('4%'),
    fontFamily: 'Inter_Bold',
  }
 
 
})