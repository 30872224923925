import React, { Component } from 'react';
import { StyleSheet, View, Text } from 'react-native'
import TextInputItem from './TextInputItem';
export default class TextInputDecorator extends Component{
  constructor(props) {
    super(props);
    this.state = { text: '' };
}
  render() {
    const { form: { getFieldDecorator, getFieldError }, field:{ id }, t } = this.props;

    return (
      <View style={{width:'100%'}}>
        {getFieldDecorator(`${id}.value`, {
          rules: [
            { required: true, message: t('FillWarn') },
          ],
        })(
          <TextInputItem
            autoFocus
            error={getFieldError(`${id}.value`)}
            t={t}
          />
        )}
          {getFieldDecorator(`${id}.quizAttempts`)}

      </View>
    )
  }
}
