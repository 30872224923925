import ConsentSignature from '../components/signatureScreen/SignatureScreen';
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSubjectID } from '../actions/subject';
import CaptureSubjectNumber from '../components/subjectId/CaptureSubjectNumber';
import {BackHandler } from "react-native";


class CaptureSubjectNumberScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sign: null
        };
    };

    componentDidMount() {
        BackHandler.addEventListener('hardwareBackPress', this.onBackPress);
      }
    
      componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.onBackPress);
      }
    
      onBackPress = () => {
        return true;
      }

    render() {
         const {storeSubjectID, navigation, subjectIdModal,handleSubjectIdModal, selectedSubject, isSessionActive } = this.props;
        return (
            <CaptureSubjectNumber
            storeSubjectID = {storeSubjectID}
            navigation = {navigation}
            subjectIdModal = {subjectIdModal}
            handleSubjectIdModal = {handleSubjectIdModal}
            selectedSubject = {selectedSubject}
            isSessionActive={isSessionActive}
            />
        );
    }
}
const mapStateToProps = state => ({
    // selectedSubject: state.selectedSubject,
   

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSubjectID,
    },
    dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(CaptureSubjectNumberScreen);
