import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, Platform } from 'react-native';
import { Container, Content} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType, hintTextFont} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import _ from 'lodash'

class HintScreen extends Component {
    render() {
        const {handleShowHintModalVisibility, attempts, t, getCurrentField} = this.props;
        return (
            <View style={[styles.paragraph, { backgroundColor: attempts == 1 ? Colors.warning : Colors.error, marginLeft:Platform.OS == 'web' ? 20: 0, marginRight:Platform.OS == 'web' ? 20: 0}, ]}>
                <View style={{flex:4.5, justifyContent:'center', alignItems:'center'}}>
                    <Text style={styles.text}>
                        { !_.isEmpty(getCurrentField().hint) ?  t('HintText') :  t('WrongAnswer')}
                </Text>
                </View>
                {!_.isEmpty(getCurrentField().hint) &&             
                <View style={{flex:1.5, justifyContent: 'center',alignItems:'center'}}>
                    <TouchableOpacity style={[constStyles.buttonStyle, {backgroundColor:Colors.whiteBg,}]} onPress={() => handleShowHintModalVisibility()}>
                        <Text style={[constStyles.buttonTxt, {color:Colors.text,minWidth: '100%', marginLeft: 0}]}>{t('Hint')}</Text>
                    </TouchableOpacity>
                </View>
                }
            </View>
        )
    }
}
export default HintScreen;

const styles = StyleSheet.create(
    {
        button: {
            padding:20,
            // margin:wp('2%'),
            backgroundColor:Colors.whiteBg,
            // borderRadius:5,

        },
        // container:{
        //     flex:1,
        //     // padding: wp('2%'),
        //     marginBottom: wp('3%'),
        //     backgroundColor: bgclr,
        //     width: Dimensions.get('window').width,
        //     height: wp('15%'),
        //     marginLeft: wp('-4%')
        // },
        buttonTxt: {
            color: Colors.text,
            fontSize: fontType('ButtonTxt'),
            fontFamily:'Inter_Bold'
            // margin: wp('1%'),
            // padding: wp('1%')
        },
        paragraph: {
            flex:1,
            padding: 15,
            flexDirection: 'row',
            // justifyContent:'space-between',
            // alignItems: 'center'
        },
        text: {
            color: Colors.textColorwithbg,
            fontSize: hintTextFont(),
            fontFamily:'Inter'
        },
        highlightTxt: {
            fontWeight: 'normal',
            color: Colors.text,
        }
    }
)