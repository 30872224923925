import React, { Component } from "react";
import Dialog, {
  DialogContent,
} from "react-native-popup-dialog";
import {
  FontAwesome,
  AntDesign,
} from "@expo/vector-icons";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { View, Text, Modal, Platform, TouchableOpacity, Dimensions, ScrollView } from "react-native";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import StudyListScreen from '../../components/studyList/StudyListScreen';
import {
  Container,
  ListItem,
  Item,
  Input,
  Body
} from "native-base";
import {
  MaterialIcons,
} from '@expo/vector-icons';
import Header from "../common/header";
import { connect } from "react-redux";
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

class StudyListDialogue extends Component {
   
  onClose = () => {
      const { handleHeaderMenuDialoge } = this.props;
      handleHeaderMenuDialoge();        
  }

  render() {
    const {
      handleHeaderMenuDialoge,
      showDialog, selectedUser, storeStudySiteList,
      storeStudySite, screenProps, navigation          
    } = this.props;
    const { studySiteList , organizationId} = this.props;
    return (
      <View>
      {
        Platform.OS == "web"
          ? <Dialog
              containerStyle={{ justifyContent: "space-evenly", flex: 1 }}
              visible={showDialog}
              rounded={false}
              height={1}
              // contentStyle={{ margin: 0, padding: 0 }}
            >
              <DialogContent style={{ backgroundColor: "#fff", flex: 1, padding: 0  }}>
                <View
                  style={
                    SCREEN_WIDTH >= 1030
                      ? { width: 800, height: "100%" }
                      : { width: Platform.OS == 'web' ? SCREEN_WIDTH : '100%', padding: 10 }
                  }
                >
                  <View style={{ borderBottomColor: 'rgb(201, 201, 201)', borderBottomWidth: 1, borderStyle: "solid"}}>
                    <Header canShowBackIcon={false}  navigation = {() => navigation.goBack()}/>
                  </View>
                  <View style={{padding: 20 }}>
      
                      {/* ------------------ top bar ------------------- */}
                      <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 5.5 }}>
                          {/* <Text
                              style={{
                              fontSize: fontType("BasicFont"),
                              color: Colors.coloredText,
                              marginTop: 5,
                              marginLeft: 5,
                              fontFamily: "Inter"
                              }}
                          ></Text> */}
                          </View>
      
                          {/* -------------- cancel button ----------------- */}
                          <TouchableOpacity
                          // style={{ flex: 0.5 }}
                          onPress={() => this.onClose()}
                          >
                          <Text style={{ marginRight: wp("1%")}}>
                              <MaterialIcons
                              name="cancel"
                              color={Colors.discontinued}
                              size={fontType("StatusIcon")}
                              />
                          </Text>
                          </TouchableOpacity>
                      </View>
      
                      {/* ---------- study list ---------------- */}
                      <View
                          style={{
                          flexDirection: "row",
                          height:'100%',
                          justifyContent: "flex-start",
                          padding: 5
                          }}
                      >
                        {/* <ScrollView showsVerticalScrollIndicator={false}> */}
                          <StudyListScreen
                          navigation={navigation}
                          screenProps={screenProps}
                          studySiteList={studySiteList}
                          selectedUser={selectedUser}
                          storeStudySiteList={storeStudySiteList}
                          storeStudySite={storeStudySite}
                          handleHeaderMenuDialoge={handleHeaderMenuDialoge}
                          organizationId={organizationId}
                          />
                          {/* </ScrollView> */}
                      </View>
                  </View>
                </View>
              </DialogContent>
            </Dialog>
          : <View>
              {Platform.OS !== "web" &&
                <Modal
                  style={{ width: 250, height: 100, flex:1}}
                  animationType="slide"
                  supportedOritentions
                  // transparent={true}
                  visible={Platform.OS !== "web" && showDialog}
                  onBackdropPress={() => this.onClose()}
                  onRequestClose={() => this.onClose()}
                >
                  <View style = {{padding:10, marginTop:20}}>
                      {/* ------------------ top bar ------------------- */}
                      <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 5.5 }}>
                          <Text
                              style={{
                              fontSize: fontType("BasicFont"),
                              color: Colors.coloredText,
                              marginTop: 5,
                              marginLeft: 5,
                              fontFamily: "Inter"
                              }}
                          ></Text>
                          </View>
      
                          {/* -------------- cancel button ----------------- */}
                          <TouchableOpacity
                          style={{ flex: 0.5 }}
                          onPress={() => this.onClose()}
                          >
                          <Text style={{ marginRight: wp("1%") }}>
                              <MaterialIcons
                              name="cancel"
                              color={Colors.discontinued}
                              size={fontType("StatusIcon")}
                              />
                          </Text>
                          </TouchableOpacity>
                      </View>
      
                      {/* ---------- study list ---------------- */}
                      <View
                          style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          padding: wp("3%")
                          }}
                      >
                          <StudyListScreen
                          navigation={navigation}
                          screenProps={screenProps}
                          studySiteList={studySiteList}
                          selectedUser={selectedUser}
                          storeStudySiteList={storeStudySiteList}
                          storeStudySite={storeStudySite}
                          handleHeaderMenuDialoge={handleHeaderMenuDialoge}
                          organizationId={organizationId}
                          />
                      </View>
                      </View>
                </Modal>
              }
            </View>
        }
      </View>
    );
  }
}

export default connect(null, null)(StudyListDialogue);
