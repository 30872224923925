import React, { Component } from 'react';
import { View, Text, Dimensions,Image, TouchableOpacity} from 'react-native';
import { Footer, Container, Content, Icon,Header, Row } from 'native-base';
import ChapterPage from './ChapterPage';
// import { chapterButtonStyles } from './styles/styles';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import ChapterTitle from '../chapterTitle/ChapterTitle';
import { onSelectingNextChapter, prevButtonVisible} from '../../utils/consentData';
import { Colors } from '../../constants/ui/colorScheme';
import { NavigationEvents } from 'react-navigation';
import moment from 'moment';
import {getOverallTimeSpentBySubject, getSubjectPageExitTime, buildSubjectNavTour } from '../../utils/subjectNavTourUtils';
import CheckBox from 'react-native-check-box'
import {ConsentDataContext} from '../../providers/ConsentDataProvider';
import NoDataPage from '../emptyData/NoDataPage';
import {fontType} from '../../styles/font'
import {chapterButtonStyles} from '../../styles/constStyles'
import BottomSheet from '../common/bottomSheet/bottomSheet';
import CommentsPage from '../comments/commentsContainer';
import { ChapterType } from '../common/constants';
const childRef = React.createRef();
class ChapterPages extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
            modalVisible: false,
            currentOrdinal: 1,
            animateVisible: true,
            chapterTitle:'Study Info',
            subjectPageEntryTime: '',
            validateFormFields: null,
            scrollRef: null,
            openComments: false,
        }
    }

    componentDidMount() {
        const { chapter, navigation } = this.props;
        this._unsubscribe = navigation.addListener('focus', () => {
            if(chapter.pages.length > 0) {
                this.GotoInitialPage()
            }
        });
    }
    
    componentWillUnmount() {
        this._unsubscribe();
    }

    incrementCurrentOrdinal = () => {
        const { currentOrdinal } = this.state;
        const { chapter, navigation, onNextChapter,isRegistered, isKnowledgeReviewDone,storeVisitedChapters,
            subjectData,signatureType, onPreviousChapter ,minOrdinalPage, isAgreedForChapters, selectedSubject,
            screenProps: { t }, krDoneBy} = this.props;
        const currChapterOrdinal = navigation.getParam('ordinal');
        this.setState({ animateVisible: false })
        if(chapter.pages.length > 0) {
        setTimeout(() => {
            this.setState(
                {
                    subjectPageEntryTime: moment().format(),
                    currentOrdinal: currentOrdinal + 1,
                    animateVisible: true,
                }, () => this.setExitTimeAndStoreNavTour()
            )
        }, 150
        )}
        else {
            onSelectingNextChapter(this.context.languages,subjectData.language, currChapterOrdinal, navigation,isRegistered,signatureType,isKnowledgeReviewDone,storeVisitedChapters, subjectData.visitedChapters, selectedSubject.subjectConsent.isKrDone, krDoneBy)
        }
    }
    decrementCurrentOrdinal = () => {
        const { currentOrdinal } = this.state;
        this.setState({ animateVisible: false })
        setTimeout(() => {
            this.setState(
                {
                    subjectPageEntryTime: moment().format(),
                    currentOrdinal: currentOrdinal - 1,
                    animateVisible: true
                }, () => this.setExitTimeAndStoreNavTour()
            )
        }, 150)
    }

    setExitTimeAndStoreNavTour = () => {
        const {updateSubjectNavTour, chapter} = this.props;
        updateSubjectNavTour();
        if(chapter.pages.length > 0) {
            this.insertSubNavTour()
        }
    }

    callMount = () => {
        const { updateSubjectNavTour } = this.props;
        updateSubjectNavTour();
        this.insertSubNavTour();
    }

    insertSubNavTour = () => {
        const {chapter, storeSubjectNavTour} = this.props;
        const currentPage = this.getCurrentDoc();
        const  obj = buildSubjectNavTour(moment().format(), null, chapter.id, currentPage.id,0 )
        storeSubjectNavTour(obj)
    }
    
    setSubjectPageEntryTime = () => {
        this.setState({
            subjectPageEntryTime: moment().format()
        })
    }

    GotoInitialPage = () =>{
        this.setState({currentOrdinal:1}, ()=> this.callMount());
    }
    getButtonNameBasedOnCurrentOrdinal = () => {
        const { chapter: { pages }, screenProps: { t } } = this.props
        const { currentOrdinal } = this.state;
        if (pages.length === currentOrdinal || pages.length < 0) {
            return t('NextChapter');
        }
        else return t('Next');
    }
    getPreviousButtonNameBasedOnCurrentOrdinal = () => {
        const {  screenProps: { t }  } = this.props
        const { currentOrdinal } = this.state;
        if (currentOrdinal === 1) {
            return t('PreviousChapter');
        }
         return t('Previous');
    }

    hasPreviousPage = () => {
        const { currentOrdinal } = this.state;
        return currentOrdinal > 1 ? true : false;
    }

    hasPreviousChapter = () => {
        const { navigation } = this.props;
        const currChapterOrdinal = navigation.getParam('ordinal');
        const { screenProps: { locale }} = this.props;
        return prevButtonVisible(this.context.languages, locale, currChapterOrdinal);
    }

    getCurrentDoc = () => {
        const {chapter} = this.props;
        const {currentOrdinal} = this.state;
        const currentDoc = chapter.pages.filter((doc) => doc.ordinal === currentOrdinal)[0];
        return currentDoc;
    }


    setChapterPageValidation = (validateFormFields) => {
        this.setState({
            validateFormFields,
        })
    }

    checkIfFormFieldsFilled = () => {
        const { validateFormFields } = this.state;
        if(validateFormFields === null) {
            return true;
        } else {
            const validation = validateFormFields();
            return validation;
        }
    }

    setScrollRefToDocs = (ref) => {
        const {scrollRef} = this.state;
        if(scrollRef == null) {
            this.setState({
                scrollRef: ref,
            });
        }
    }

    onCommentClick = () => {
        if(this.state.openComments && childRef.current){
            childRef.current.onAdd();
        }
        this.setState(prevState => ({
            ...prevState,
            openComments: true
        }))
    }

    onCommentCancelClick = () => {
        this.setState(prevState => ({
            ...prevState,
            openComments: false
        }))
    }

    render() {
        const { currentOrdinal, animateVisible, scrollRef, openComments } = this.state;
        const { chapter, navigation, onNextChapter,isRegistered, isKnowledgeReviewDone,storeVisitedChapters,
            subjectData,signatureType, onPreviousChapter ,minOrdinalPage, isAgreedForChapters, selectedSubject,
            screenProps: { t }, location, krDoneBy, updateSubjectNavTour, screenProps} = this.props;
        const currentDoc = this.getCurrentDoc();
        const { chapterTitle } = chapter;
        const currChapterOrdinal = navigation.getParam('ordinal');
        //this condition for show checkbox when reach next chapter.
        if(chapter.pages.length > 0) {
        return (
            <Container style={{flex:1}}>
                {/* <NavigationEvents
                    onWillFocus={() => {
                        this.GotoInitialPage();
                    }}
                /> */}
                 <ChapterTitle chapterType="STUDY_INFORMATION" chapterTitle={chapterTitle} onCommentClick = {this.onCommentClick}/>
                 {/* Page title */}
                 <View style={{zIndex:2}}>
                    {currentDoc.pageTitle ? <Text style={chapterButtonStyles.documentTitle}>{currentDoc.pageTitle}</Text> : <Text></Text>}
                </View>
                {/* <Content ref={c => (this.component = c)} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                    <View style={{marginHorizontal:wp('4%')}}>
                        <ChapterPage minOrdinalPage={minOrdinalPage} animateVisible={animateVisible} currentOrdinal={currentOrdinal} currentDoc={currentDoc} />
                    </View>
                </Content> */}
                {/* <Content ref={c => (this.component = c)} style={{ paddingRight: 10, paddingTop: 10, flex:1 }}> */}
                    <View style={{flex:5.5}}>
                        <ChapterPage minOrdinalPage={minOrdinalPage}
                        animateVisible={animateVisible}
                        currentOrdinal={currentOrdinal}
                        currentDoc={currentDoc}
                        location={location}
                        t={t}
                        setScrollRefToDocs={this.setScrollRefToDocs}
                        setChapterPageValidation={this.setChapterPageValidation} />
                    </View>
                {/* </Content> */}
                <View>
                <BottomSheet open={openComments} onClose = {() => this.setState(prevState => ({ ...prevState, openComments: false}))}>
                    <CommentsPage ref={childRef} addNewComment = {true} onCommentCancelClick={this.onCommentCancelClick} chapterType={ChapterType.STUDY_INFORMATION.key} screenProps={screenProps}/>
                </BottomSheet>
                </View>
                <Footer style={chapterButtonStyles.footer}>
                    {(this.hasPreviousChapter() || this.hasPreviousPage()) && 
                    <TouchableOpacity transparent iconLeft  onPress={() => {
                        scrollRef._root.scrollToPosition(0, 0, false);
                        this.getPreviousButtonNameBasedOnCurrentOrdinal() === t('Previous') ? this.decrementCurrentOrdinal() : onPreviousChapter() }}
                    style={chapterButtonStyles.button} >
                        <Text style={chapterButtonStyles.buttonTxt}>{this.getPreviousButtonNameBasedOnCurrentOrdinal()}</Text>
                    </TouchableOpacity>}
                    <TouchableOpacity transparent 
                            onPress={() => {
                                scrollRef._root.scrollToPosition(0, 0, false);
                                if(this.checkIfFormFieldsFilled()) {
                                    this.getButtonNameBasedOnCurrentOrdinal() === t('Next') ? this.incrementCurrentOrdinal() : onSelectingNextChapter(this.context.languages,subjectData.language, currChapterOrdinal, navigation,isRegistered,signatureType,isKnowledgeReviewDone,storeVisitedChapters, subjectData.visitedChapters, selectedSubject.subjectConsent.isKrDone, krDoneBy);
                                }
                            }}
                        style={chapterButtonStyles.button}>
                        <Text 
                            style={[chapterButtonStyles.buttonTxt]}
                        >
                            {this.getButtonNameBasedOnCurrentOrdinal()}</Text>
                    </TouchableOpacity>
                </Footer>
            </Container>

        )}
        else {
            return(
                <Container >
                    <ChapterTitle chapterType="STUDY_INFORMATION" chapterTitle={chapterTitle}/>
                    <NoDataPage message={t('NoData')} />
                    <Footer style={chapterButtonStyles.footer}>
                    <TouchableOpacity transparent 
                            onPress={() => { 
                                this.getButtonNameBasedOnCurrentOrdinal() != t('Next') && updateSubjectNavTour();
                                this.getButtonNameBasedOnCurrentOrdinal() === t('Next') ? this.incrementCurrentOrdinal() : onSelectingNextChapter(this.context.languages,subjectData.language, currChapterOrdinal, navigation,isRegistered,signatureType,isKnowledgeReviewDone,storeVisitedChapters, subjectData.visitedChapters, selectedSubject.subjectConsent.isKrDone, krDoneBy); }}
                        style={chapterButtonStyles.button}>
                        <Text 
                            style={chapterButtonStyles.buttonTxt}
                        >{t('NextChapter')}</Text>
                        {/* <Icon name='arrow-forward' style={{ color:  Colors.textColor,fontSize: wp('3.5%')}} /> */}
                    </TouchableOpacity>
                </Footer>
                </Container>

            )
        }
    }
}
export default ChapterPages;