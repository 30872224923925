import React, { useEffect, useState } from 'react'
import Dialog, { DialogContent, DialogTitle } from 'react-native-popup-dialog';
import { View, TouchableOpacity, Dimensions } from 'react-native';
import ReasonForChange from '../common/ReasonForChange/ReasonForChange';
import { fontType } from '../../styles/font';
import { Colors } from '../../constants/ui/colorScheme';
import { FontAwesome } from "@expo/vector-icons";
import { computeChanges } from '../common/ReasonForChange/utils';
import { widthPercentageToDP as wp} from 'react-native-responsive-screen';

const { width: SCREEN_WIDTH } = Dimensions.get('window')

const SubjectDetailsReasonForChange = (props) => {
    const {showReasonForChangeModal, setModalVisible, onReasonForChangeSave, screenProps, 
      screenProps: { t }, oldObj, newObj, loading, getValueOfField } = props
    const [changes, setChanges] = useState([])

    useEffect(() => {
      if(showReasonForChangeModal){
        const loChanges = computeChanges(oldObj, newObj, getValueOfField)
        setChanges(loChanges)
      }
    },[showReasonForChangeModal])
    return(
        
        <Dialog
        containerStyle={{ justifyContent: 'center' }}
        rounded={true}
        visible={showReasonForChangeModal}
        width={SCREEN_WIDTH >= 1030 ? 700 : wp("70%") }
        dialogTitle={
          <DialogTitle
            title= {t('ReasonForChangeDetails')}
            align="left"
            style={{ fontFamily: 'Inter', fontSize: fontType('Heading1') }}
          />}
    >
      <TouchableOpacity onPress={() => setModalVisible(false)} style={{ position: 'absolute', top: 10, right: 10 }}>
        <FontAwesome name="times" size={20} color={Colors.borderColor} />
      </TouchableOpacity>
        <DialogContent >
          <View>
            <ReasonForChange loading={loading} screenProps={screenProps} changes = {changes} onReasonForChangeSave = {(val) => onReasonForChangeSave(val)} />
          </View>
        </DialogContent>
    </Dialog>
    )
}

export default SubjectDetailsReasonForChange