import React, { Component } from 'react';
import { Container, Content, Text, Card, Body, Button, Input } from 'native-base';
import { View, TextInput} from 'react-native';
import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import TextInputItem from './TextInputItem';
import PropTypes from 'prop-types';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';

// import { TextInput } from 'react-native-gesture-handler';

export default class LARForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
  }
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.array,
  };

  render() {
    const { form: { getFieldDecorator, getFieldError,textInputProps, getFieldProps } } = this.props;
    const {error, value, onChange, screenProps} = this.props;
    const {t} = screenProps;
    return (
      <View style={{padding:6,marginTop:10}}>
          <Text style={styles.textLable}>{t('LARName')}</Text>
        {getFieldDecorator('larName', {
          rules: [
            { required: true, message: t('FillWarn') },
          ],
        })(
          <TextInputItem
          autoFocus
          error={getFieldError('larName')}
          placeholderValue={t('TypeLARName')}
          />
        )}
          <Text style={styles.textLable}>{t('LARRelation')}</Text>
        
        {getFieldDecorator('larRelation', {
          rules: [
            { required: true, message: t('FillWarn') },
          ],
        })(
          <TextInputItem
            autoFocus
            error={getFieldError('larRelation')}
            placeholderValue={t('TypeLARRelation')}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textLable: {
    marginHorizontal:8, 
    marginVertical:10,
    fontSize:fontType('BasicFont'),
    color: Colors.coloredText,
    fontFamily:'Inter'
  },
});
