import i18n from 'i18n-js';
import en from '../constants/localization/locale-en';
import ru from '../constants/localization/locale-ru';
import fr from '../constants/localization/locale-fr';
import ja from '../constants/localization/locale-ja';
import es from '../constants/localization/locale-sa';

i18n.fallbacks = true;
i18n.translations = {
  'English': en,
  'Russian': ru,
  'French': fr,
  'Japanese': ja,
  'Spanish': es,
};

export default i18n;
