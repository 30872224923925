import {
   STORE_CLIENT_USER_DETAILS
} from '../actions/subject';
import initialState from '../store/initialState';


export default (state = initialState.clientUserDetails, action) => {
    switch (action.type) {
        case STORE_CLIENT_USER_DETAILS:
            return {
                ...state,
                email: action.data.email,
                phoneNo: action.data.phoneNo,
            };
            default:
                return state;
    }
}

