import createActionType from '../utils/action';
export const SET_SIGNATURE_TYPES = createActionType('SET_SIGNATURE_TYPES');
export const SET_CURRENT_USER_TYPE = createActionType('SET_CURRENT_USER_TYPE');

export const setSignatureTypes = (signatureTypes) => ({
    type: SET_SIGNATURE_TYPES,
    signatureTypes
});

export const setCurrentUserType = (currentUserType) => ({
    type: SET_CURRENT_USER_TYPE,
    currentUserType
});
