import createActionType from '../utils/action';
export const SET_SUBJECT_CONSENT_DOCUMENTS = createActionType('SET_SUBJECT_CONSENT_DOCUMENTS');
import api from '../utils/api';
import { setSignatureTypes } from './consentFlow';
import _ from 'lodash';
import constants from '../utils/constants'

const setSubjectConsentDocuments = (subjectConsentDocuments) => ({
    type: SET_SUBJECT_CONSENT_DOCUMENTS,
    subjectConsentDocuments
});

export const setDocumentsAndSignatures = (documents, larAsWitness) => async (dispatch, getState) => {
    let loDocuments = _.filter([...documents], d => !d.isDeleted && !d.skipped);
    loDocuments = _.map(loDocuments, d => {
        let signatures = _.filter(d.subjectDocumentSignatures, s => !s.isDeleted);
        signatures = _.sortBy(signatures, (n) => n.signatureType);
        d.subjectDocumentSignatures = signatures;
        return d;
    });
    
    let signatureTypes = [];
    _.map(loDocuments, scd => {
        _.map(scd.subjectDocumentSignatures, s => {
            if(!s.signatureFilePath){
            if(!larAsWitness){
                signatureTypes.push(s.documentSignature.signatureType);
            } else {
                if(s.documentSignature.signatureType != 4){
                    signatureTypes.push(s.documentSignature.signatureType);
                } else {
                    const sign = _.find(signatureTypes, sgn => _.isEqual(sgn, 2));
                    if(!sign){
                        signatureTypes.push(s.documentSignature.signatureType);
                    }
                }
            }
        }
        });
    });
    signatureTypes = _.filter(signatureTypes, s => s != 3);
    signatureTypes= _.uniqBy(signatureTypes, (n) => n);
    if(larAsWitness){
        const currentUserType = getState().consentFlow.currentUserType;
        const index = _.findIndex(signatureTypes, s => s == 2);
        if(index >= 0 || currentUserType == 2){
            _.remove(signatureTypes, s => s== 4);
        }
    }
    signatureTypes = _.sortBy(signatureTypes, (n) => n);
    dispatch(setSignatureTypes(signatureTypes));
    dispatch(setSubjectConsentDocuments(loDocuments));
}

export const filterAndSetUnsignedDocuments = (documents, larAsWitness) => async (dispatch, getState) => {
    let loDocuments = _.filter([...documents], d => !d.isDeleted && !d.skipped);
    loDocuments = _.map(loDocuments, d => {
        let signatures = _.filter(d.subjectDocumentSignatures, s => !s.isDeleted && s.signatureFilePath == null);
        signatures = _.sortBy(signatures, (n) => n.signatureType);
        d.subjectDocumentSignatures = signatures;
        return d;
    });
    
    let signatureTypes = [];
    _.map(loDocuments, scd => {
        _.map(scd.subjectDocumentSignatures, s => {
            if(!s.signatureFilePath){
            if(!larAsWitness){
                signatureTypes.push(s.documentSignature.signatureType);
            } else {
                if(s.documentSignature.signatureType != 4){
                    signatureTypes.push(s.documentSignature.signatureType);
                } else {
                    const sign = _.find(signatureTypes, sgn => _.isEqual(sgn, 2));
                    if(!sign){
                        signatureTypes.push(s.documentSignature.signatureType);
                    }
                }
            }
        }
        });
    });
    signatureTypes = _.filter(signatureTypes, s => s != 3);
    signatureTypes= _.uniqBy(signatureTypes, (n) => n);
    if(larAsWitness){
        const currentUserType = getState().consentFlow.currentUserType;
        const index = _.findIndex(signatureTypes, s => s == 2);
        if(index >= 0 || currentUserType == 2){
            _.remove(signatureTypes, s => s== 4);
        }
    }
    signatureTypes = _.sortBy(signatureTypes, (n) => n);
    dispatch(setSignatureTypes(signatureTypes));
    dispatch(setSubjectConsentDocuments(loDocuments));
}

export const setDocuments = (documents, larAsWitness) => async dispatch => {
    let loDocuments = _.filter([...documents], d => !d.isDeleted);
    loDocuments = _.map(loDocuments, d => {
        const signatures = _.filter(d.subjectDocumentSignatures, s => !s.isDeleted && s.signatureFilePath == null);
        d.subjectDocumentSignatures = signatures;
        return d;
    });
    dispatch(setSubjectConsentDocuments(loDocuments));
}


export const retrieveSubjectConsentDocuments = (subjectId, selectedSubject) => async dispatch => {
    try {
        const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/subjectConsents/${selectedSubject.subjectConsentId}/documents`);
        dispatch(setDocumentsAndSignatures(res.data, selectedSubject.larAsWitness));
    } catch(error) {
        console.log(error)
    }
    
}

export const skipDocument = (documentId) => async (dispatch, getState) => {
    try {
        const documents = getState().subjectConsentDocument.list;
        _.remove(documents, d => _.isEqual(_.toUpper(documentId), _.toUpper(d.id)));
        dispatch(setDocumentsAndSignatures(documents, getState().selectedSubject.larAsWitness));
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const updateSubjectDocumentSignatures = (signatures) => async (dispatch, getState) => {
    try {
        const documents = getState().subjectConsentDocument.list;
        const loDocuments = [...documents];
        _.map(signatures, s => {
            _.map(loDocuments, scd => {
                const index = _.findIndex(scd.subjectDocumentSignatures, sds => _.isEqual(_.toUpper(sds.id), _.toUpper(s.id)));
                if(index >= 0){
                    scd.subjectDocumentSignatures[index].signatureFilePath = s.signatureFilePath;
                    scd.subjectDocumentSignatures[index].signatureFileName = s.signatureFileName;
                    scd.subjectDocumentSignatures[index].signatureDate = s.signatureDate;
                }
                return scd;
            })
        })
        dispatch(setDocumentsAndSignatures(loDocuments, getState().selectedSubject.larAsWitness));
    } catch (error) {
        console.log(error);
    }
}

export const canSkipTheDocument = (userType, documentId) => (dispatch, getState) => {
        const documents = getState().subjectConsentDocument.list;
        const loDocuments = _.filter(documents, d => _.isEqual(_.toUpper(documentId), _.toUpper(d.chapterPage.id)));
        let signatureTypes = [];
        const larAsWitness = getState().selectedSubject.larAsWitness;
        _.map(loDocuments, scd => {
            _.map(scd.subjectDocumentSignatures, s => {
                if(!larAsWitness){
                    signatureTypes.push(s.documentSignature.signatureType);
                } else {
                    if(s.documentSignature.signatureType != 4){
                        signatureTypes.push(s.documentSignature.signatureType);
                    } else {
                        const sign = _.find(signatureTypes, sgn => _.isEqual(sgn, 2));
                        if(!sign){
                            signatureTypes.push(s.documentSignature.signatureType);
                        }
                    }
                }
            });
        });
        signatureTypes = _.filter(signatureTypes, s => s != 3);
        signatureTypes = _.sortBy(signatureTypes, (n) => n);
        switch (userType) {
            case 1:
                return true;
            case 4:
                return false;
            default: {
                const index = _.findIndex(signatureTypes,s =>  _.isEqual(s, userType));
                return index == 0;
            }
        }
}
