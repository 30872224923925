import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
export class Authorize extends React.Component {
  _hasAnyGrant = (grants, hasAnyGrant) =>
    _.intersection(grants, hasAnyGrant).length > 0;
  _hasAllGrants = (grants, hasAllGrants) =>
    _.intersection(grants, hasAllGrants).length === grants.length;
  render() {
    const { hasAnyGrant, hasAllGrants, children, grants, optionalChildren } =
      this.props;
    const { _hasAnyGrant, _hasAllGrants } = this;
    if (hasAnyGrant.length && _hasAnyGrant(grants, hasAnyGrant)) {
      return children;
    }
    if (hasAllGrants.length && _hasAllGrants(grants, hasAllGrants)) {
      return children;
    }
    return optionalChildren;
  }
}
const mapStateToProps = (state) => ({
  grants: state.clientUser.selectedUser.privileges,
});
export default connect(mapStateToProps)(Authorize);
Authorize.propTypes = {
  hasAnyGrant: PropTypes.arrayOf(String),
  hasAllGrants: PropTypes.arrayOf(String),
  grants: PropTypes.arrayOf(String),
  children: PropTypes.instanceOf(Object),
  optionalChildren: PropTypes.instanceOf(Object),
};
Authorize.defaultProps = {
  hasAnyGrant: [],
  hasAllGrants: [],
  grants: [],
  children: "",
  optionalChildren: null,
};
