import React, { Component } from 'react';
import DocumentsList from '../components/consentSignature/DocumentsList';
import _ from 'lodash';
import { connect } from "react-redux";
import { buildSubjectData, buildSubjectSignatureData, getConsentChapter } from '../utils/consentData';
import api from '../utils/api';
import showToast from "../utils/toast";
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import constants from "../utils/constants.js";
import PropTypes from 'prop-types'

class DocumentListScreen extends Component {
    static contextType = ConsentDataContext;
    state = {
        menuVisible: false,
        modalVisible: false,
        loading: false,
    };
   
    submitConsentData = async() => {
        const { subject, navigation , studySite, subNavTours, formDataList, subjectRemoteConsent, signatureTypes } = this.props;
        const {loading} = this.state;
        const data = buildSubjectData(subject, studySite);
        const fdKeys = Object.keys(formDataList);
        let formData = [];
        _.forEach(fdKeys, fdKey => {
            formData = [...formData, ...formDataList[fdKey]];
        });
         
        formData = _.map(formData, fd => {
            const subjectConsent = fd.subjectConsent || {id: null};
            const subjectConsentId = subjectConsent.id ? subjectConsent.id : subject.subjectConsent.id
            return {
                ...fd,
                subjectConsent: {
                    id: subjectConsentId,
                }
            }
        });

        let subSignData  = buildSubjectSignatureData(subject);
        subSignData = {
            ...subSignData,
            formData: formData,
        };
        const subjectConsentData = {
            status: 'NEEDS_COUNTER_SIGNATURE',
            consentVersion : {
                id: subject.consentVersionId,
            },
            navTours: subNavTours
        };
        
        const loSubjectRemoteConsent = {
            type: (subject.isRemoteConsent) ? subjectRemoteConsent.type : null,
            userType: signatureTypes[0],
            signStatus : 1,
            id : subjectRemoteConsent.subjectRemoteConsentId
        }
        try{
            if(loading === false ) {
                this.setState({loading: true});
                // await api.post('/consent/subject/submit', data);
                if(subject.id !== null ) {
                    await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subject.id}/subjectConsents/documents`, {subSignData, subjectConsentData, subjectRemoteConsent: loSubjectRemoteConsent});
                }
                this.setState({loading: false});
                navigation.navigate('CompletedScreen');
            }
        } catch(error) {
            this.setState({loading: false});
            console.log(error);
            showToast("Failed to save.", 'danger', 3000);
            // checkErrorStatusAndShowToast(error, "")
        }
    }

    render() {
        const { menuVisible, loading } = this.state;
        const { navigation, subject, isRegistered, screenProps } = this.props;
        const signature = getConsentChapter(this.context.languages, subject.language, 'SIGNATURE');
        const subjectDocumentSignatures = subject.subjectDocumentSignatures;
        
        const signType = parseInt(navigation.getParam('signatureType'));
        // let signatureData =  _.filter(signature.documentSignatures, { 'signatureType': signType });
        let signatureData =  _.filter(signature.documentSignatures, { 'signatureType': signType });

        if(subject.isReconsent) {
            signatureData = _.filter(signatureData, { 'isReconsentRequired': true });
        } else {
            signatureData = _.filter(signatureData, {  'isRequired': true  });
        }
        const disableSubmit = subjectDocumentSignatures.length === signatureData.length ? false : true;
        return (
            <DocumentsList
                menuVisible={menuVisible}
                navigation={navigation}
                isRegistered={isRegistered}
                handleModalClose={this.handleModalClose}
                subject={subject}
                documentSignatures={signatureData}
                submitConsentData={this.submitConsentData}
                disableSubmit={disableSubmit}
                loading = {loading}
                screenProps={screenProps}
       
            />
        );
    }
}

DocumentListScreen.defaultProps = {
    subject: {},
    studySite: {},
    isRegistered: false,
    subNavTours: [],
    formDataList: {},
    subjectRemoteConsent: {},
    signatureTypes: [],
    screenProps: {},
};

DocumentListScreen.propTypes = {
    subject: PropTypes.object,
    studySite: PropTypes.object,
    isRegistered: PropTypes.bool,
    subNavTours: PropTypes.array,
    formDataList: PropTypes.object,
    subjectRemoteConsent: PropTypes.object,
    signatureTypes: PropTypes.array,
    navigation: PropTypes.object.isRequired,
    screenProps: PropTypes.object,
};

const mapStateToProps = state => ({
    subject: state.subject,
    studySite: state.studySite,
    isRegistered: state.subject.isRegistered,
    signatureType: state.subject.signatureType,
    subNavTours: state.subject.navTour,
    formDataList: state.documents.formDataList,
    signatureTypes: state.consentFlow.signatureTypes,
    subjectRemoteConsent : state.subjectRemoteConsentDto,
    
});

export default connect(mapStateToProps,null)(DocumentListScreen);