import ConsentSignature from '../components/signatureScreen/SignatureScreen';
import React, { Component } from "react";
import { Icon } from 'native-base';
import _ from 'lodash';
import { getDocSignatureBySignType, getConsentVersionId, getVersionMetadata } from '../utils/consentData';
import { getConsentChapter, getDocumentSignature } from '../utils/consentData'
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storeSignatureData } from '../actions/subjectSignatureData';
import { saveDocumentSignature } from '../actions/documentSignature';
import { ConsentDataContext } from '../providers/ConsentDataProvider';
import { storeDeclarationFormData } from '../actions/documents';
import showToast from "../utils/toast";
import api from '../utils/api';
import { SIGNATURE_TYPE } from '../components/common/constants';


class CounterSignatureScreen extends Component {
    static contextType = ConsentDataContext;
    constructor(props) {
        super(props);
        this.state = {
            sign: null,
            isCredentialsValidated: false,
            isEsignRequired: false,
        };
    };
    static navigationOptions = ({ navigation }) => ({
        // title: "Signature",
        // headerRight: (
        //     <Icon name='menu' style={{
        //         fontSize: 27, color: 'white', padding: 10
        //     }} />
        // ),
    });


    async componentDidMount() {
        const { navigation, screenProps: { t }, documentSignatureList, consentVersionsMetadata } = this.props;
        const documentId = navigation.getParam('documentId');
        const documentSignatureId = navigation.getParam('documentSignatureId');
        const versionMetadata = getVersionMetadata(documentId,documentSignatureList, consentVersionsMetadata)
        const ds = getDocSignatureBySignType(!_.isEmpty(versionMetadata)? versionMetadata: this.context.languages, documentSignatureList, documentId, documentSignatureId);
        if(ds?.isEsignRequired) {
            try {
                this.setState(prevState => ({ ...prevState, isCredentialsValidated: true, isEsignRequired: true }));
            } catch (err) {
                console.log(err);
                this.setState(prevState => ({ ...prevState, isCredentialsValidated: false, isEsignRequired: true }));
            }
        } else {
            this.setState(prevState => ({ ...prevState, isEsignRequired: false }));
        }
        


    }

    captureSignature = async (sign, nothing, closeSignaturePopup) => {
        const { storeSignatureData, larDetails, navigation, submitDocumentSignature, selectedSubject, loading, documentSignatureList, formDataList, subject } = this.props;
        if (loading === false) {
            const documentSignatureId = navigation.getParam('documentSignatureId');
            const userName = navigation.getParam('fullName');
            const documentId = navigation.getParam('documentId');
            const sdsId = _.find(documentSignatureList[documentId], { 'signatureType': 3, })?.subjectDocumentSignatureId;
            const fileName = `ConsentSignature${moment().format('DDMMMYYYYHHMM')}.png`;
            const SubjectDocumentSignature = {
                id: sdsId,
                documentSignature: {
                    id: documentSignatureId,
                    signatureType: 3,
                    chapterPage: {
                        id: documentId,
                    }
                },
                subject: {
                    id: selectedSubject.id,
                },
                signedBy: userName,
                signatureFileName: fileName,
                signatureDate: moment(),
                imageUri: sign,
                isDeleted: false,
            }
            const subjectConsentData = {
                consentVersion: {
                    id: selectedSubject.consentVersionId
                },
            }
            //construction form data
            const fdKeys = Object.keys(formDataList);
            let formData = [];
            _.forEach(fdKeys, fdKey => {
                formData = [...formData, ...formDataList[fdKey]];
            });

            formData = _.map(formData, fd => {
                const subjectConsent = fd.subjectConsent || { id: null };
                let subjectConsentId = subjectConsent.id ? subjectConsent.id : selectedSubject.subjectConsentId
                if (_.isEmpty(subjectConsentId)) {
                    subjectConsentId = selectedSubject.subjectConsentId
                }
                return {
                    ...fd,
                    subjectConsent: {
                        id: subjectConsentId,
                    },
                    subject: {
                        id: selectedSubject.id
                    }
                }
            });

            const subject = {
                id: selectedSubject.id,
                formData: formData,
            }
            const res = await submitDocumentSignature(SubjectDocumentSignature, documentId, navigation, subjectConsentData, subject)
            if (res == true) {
                closeSignaturePopup();
                // setTimeout(() => this.onback(), 500);
            } else {
                closeSignaturePopup();
                showToast('Something went wrong.', 'danger', 4000);
                setTimeout(() => this.onback(), 500);
            }

        }
    };

    onback = () => {
        const { navigation } = this.props;
        const documentId = navigation.getParam('documentId');
        navigation.replace('CounterSignDoc', { documentId: documentId })
    }

    // getDocSignatureBySignType = (documentSignatureList, documentId, documentSignatureId) => {
    //     let documentSignatures = documentSignatureList[documentId];
    //     let mstrDocSignList = getConsentChapter(this.context.languages, documentSignatures[0].language, 'SIGNATURE').documentSignatures;
    //     mstrDocSignList = mstrDocSignList.filter(mds => mds.documentId.toLowerCase() === documentId.toLowerCase());
    //     documentSignatures = documentSignatures.map(ds => {
    //         return {
    //             ...ds,
    //             documentSignatureId: _.find(mstrDocSignList, mds => mds.signatureType === ds.signatureType).id,
    //         };
    //     });
    //     const ds = documentSignatures.filter(ds => ds.documentSignatureId === documentSignatureId)[0];
    //     return ds;
    // }

    render() {
        const { navigation, documentSignatureList, loading, showDialog, screenProps: { isSessionActive }, screenProps, storeDeclarationFormData, formDataList, selectedSubject, consentVersionsMetadata } = this.props;
        const documentSignatureId = navigation.getParam('documentSignatureId');
        const userName = navigation.getParam('fullName');
        const documentId = navigation.getParam('documentId');
        const versionMetadata = getVersionMetadata(documentId,documentSignatureList, consentVersionsMetadata)
        const ds = getDocSignatureBySignType(!_.isEmpty(versionMetadata)? versionMetadata : this.context.languages, documentSignatureList, documentId, documentSignatureId);
        let documentSignatures = documentSignatureList[documentId];
        let mstrDocSignList = getConsentChapter(!_.isEmpty(versionMetadata)? versionMetadata: this.context.languages, documentSignatures[0].language, 'SIGNATURE').documentSignatures;
        mstrDocSignList = mstrDocSignList.filter(mds => mds.documentId.toLowerCase() === documentId.toLowerCase());
        let declarationFormFields = _.find(mstrDocSignList, { 'documentId': ds.documentId, 'signatureType': ds.signatureType });
        const { isCredentialsValidated, isEsignRequired } = this.state;

        return (
            <ConsentSignature
                showDialog={showDialog}
                navigation={navigation}
                declaration={ds.declaration}
                declarationFormFields={declarationFormFields.formFields}
                documentId={declarationFormFields.documentId}
                documentSignatureId={declarationFormFields.id}
                captureSignature={this.captureSignature}
                signedBy={userName}
                isWitness={false}
                loading={loading}
                heading='Counter Signature'
                isSessionActive={isSessionActive}
                onback={this.onback}
                screenProps={screenProps}
                storeDeclarationFormData={storeDeclarationFormData}
                formDataList={formDataList}
                isCounterSignature
                isCredentialsValidated = {isCredentialsValidated}
                canShowToaster = { isEsignRequired }
                selectedSubject={selectedSubject}
                currentSignatureType={SIGNATURE_TYPE.COUNTER}
            />
        );
    }
}

// CounterSignatureScreen.defaultProps = {
//     user: {
//         name: 'santosh'
//     }
// }

const mapStateToProps = state => ({
    subject: state.subject,
    selectedSubject: state.selectedSubject,
    documentSignatureList: state.documents.documents,
    userName: state.clientUser.selectedUser.fullName,
    loading: state.loading,
    formDataList: state.documents.formDataList,
    consentVersionsMetadata: state.documents.consentVersionsMetadata,

});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeSignatureData,
        submitDocumentSignature: saveDocumentSignature,
        storeDeclarationFormData
    },
    dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(CounterSignatureScreen);
