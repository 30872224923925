import StudySiteInfo from "../components/studySiteInfo/StudySiteInfo";
import React, { Component } from "react";
import { Icon } from "native-base";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Text, Platform } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { clearSubjectData } from "../actions/subject";
import {retrieveLogin, resetValidation, clearStudySiteAccessCode} from '../actions/clientUser';
import {hasUpdated, hasNewAppUpdated, clearState,clearDocSignPosition } from '../actions/update';
import {clearDeviceSNo} from '../actions/consentToken';
import {fontType} from '../styles/font';

class StudySiteAccessInfoScreen extends Component {
  state = {};
  // static navigationOptions = ({ navigation }) => ({
  //   headerTitle: (
  //     <Text
  //       style={{
  //         flex: 1,
  //         fontSize: fontType('BasicFont'),
  //         textAlign: "center",
  //         color: "white",
  //         fontFamily: "Inter",
  //       }}
  //     >Study Site Info
  //     </Text>
  //   ),
  //   headerTitleStyle: {
  //     fontFamily: "Inter",
  //     alignSelf: 'center',
  //     marginLeft:  -60
  //   },
  // });


    componentDidMount() {
        const {clearSubjectData, clearDocSignPosition} = this.props;
        clearSubjectData();
        clearDocSignPosition();
    }

  render() {
    const {
      navigation,
      studySite,
      studySiteId,
      screenProps,
      hasUpdate,
      hasAppUpdate,
      hasUpdated,
      hasNewAppUpdated,
      appVersion,
      clearStudySiteAccessCode,
      clearDeviceSNo,
      newVersionUrl,
      newConsentVersion,
      selectedUser,
      organizationName
    } = this.props;
    return (
      <StudySiteInfo
        newConsentVersion={newConsentVersion}
        clearDeviceSNo={clearDeviceSNo}
        clearStudySiteAccessCode={clearStudySiteAccessCode}                
        navigation={navigation}
        studySiteId={studySiteId}
        hasUpdate={hasUpdate}
        newVersionUrl={newVersionUrl}
        hasAppUpdate={hasAppUpdate}
        studySite={studySite}
        screenProps={screenProps}
        hasUpdated={hasAppUpdate}
        hasNewAppUpdated={hasNewAppUpdated}
        appVersion={appVersion}
        selectedUser={selectedUser}
        organizationName={organizationName}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  studySite: state.studySite,
  newVersionUrl: state.studySite.newVersionUrl,
  studySiteId: state.studySite.studySiteId,
  hasAppUpdate: state.hasAppUpdate,
  hasUpdate: state.hasUpdate,
  // appVersion: state.studySite.stdSite.appVersion,
  newConsentVersion: state.studySite.newConsentVersion,
  selectedUser: state.clientUser.selectedUser,
  organizationName: state.clientUser.organizationName,

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSubjectData,
      hasUpdated,
      hasNewAppUpdated,
      clearStudySiteAccessCode,
      clearDeviceSNo,
      clearStudySiteAccessCode,
      retrieveLogin,
      resetValidation,
      clearDocSignPosition
    },
    dispatch
  );


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudySiteAccessInfoScreen);
