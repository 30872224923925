import { INC_SUB_SERIAL_DEVICEN0, SAVE_DEVICE_SNO_SUCCESS, CLEAR_DEVICE_SNO} from '../actions/consentToken';
import { LOGIN_RETRIEVE_SUCCESS } from '../actions/clientUser';
import initialState from '../store/initialState';


const consentTokenData = (state = initialState.consentTokenData, action) => {
  switch (action.type) {
      case INC_SUB_SERIAL_DEVICEN0:
      return {
        ...state,
        subSerialDeviceNo: action.subSerialDeviceNo,
      };
      case SAVE_DEVICE_SNO_SUCCESS:
      return {
        ...state,
        deviceNo: action.deviceNo,
      };
      case CLEAR_DEVICE_SNO:
      return {
        ...state,
        deviceNo: action.deviceNo,
      };
      case LOGIN_RETRIEVE_SUCCESS:
      return {
        ...state,
        siteNo: action.studySite.siteNumber,
      };
    default:
      return state;
  }
};

export default consentTokenData;
