import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import React, { Component } from 'react';
import { Container, Icon } from 'native-base';
import { View, ScrollView, Text, Platform, TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import { styles } from './styles/completedStyle';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';
import { constStyles, signatureButtonStyles } from '../../styles/constStyles'
import _ from 'lodash';

export default class CancelFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    
    render() {
        const { subjectStatus, navigation, screenProps: { t }, isRemoteConsent } = this.props;
        return (
            <Container >
                <View style={{ flex: 1, justifyContent: 'center' }} >
                    <Text style={{ fontSize: fontType('Heading1'), color: Colors.text, padding: 5, textAlign: 'center', fontFamily: 'Inter' }}>{t('CancelFlow')}</Text>
                    <View style={{ padding: 4, justifyContent: 'center' }}>
                        
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('Admin', {screen: 'SubjectDetails'})}
                                    style={[constStyles.buttonStyle]}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <View >
                                            <Text style={[constStyles.buttonTxt]}>
                                                {t('Next')}
                                            </Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </View>
                    </View>
            </Container>
        );
    }
}

