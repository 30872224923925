import React, { Component } from 'react';
import { View } from 'react-native';
import Dialog, { DialogContent, DialogFooter, DialogButton, ScaleAnimation, SlideAnimation, FadeAnimation } from 'react-native-popup-dialog';
import AbortConsentScreen from '../../containers/abortConsentPage';
import NavigateToAdmin from '../../containers/navigateToAdminPage';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {Dimensions} from 'react-native'
import StopConsentPopup from './StopConsentPopUp';
import { inOfficeLogout } from '../../actions/subject';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

class HeaderMenuPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
          logOutConfirmation : false,
        }
    }

    handleStopConsentDialogue = () => {
        this.setState({ logOutConfirmation: false });
    }

    onStopConsentClick = () => {
        const { handleHeaderMenuDialoge } = this.props;
        this.setState({ logOutConfirmation: true }); 
        handleHeaderMenuDialoge();
    }

    logOut = () => {
        const {inOfficeLogout}= this.props;
        this.setState({ logOutConfirmation: false });
        inOfficeLogout();
    }

    render() {
          const { isRegistered, studySiteId, consentID, navigation, updateConsentStatus,
            consentCompleted, showAdminAfterCompleteConsent, storeSubjectStatus, isSubSignsDone, deviceNo,
            handleHeaderMenuDialoge, showDialog, subjectData, screenProps, clearSubjectNavTour, languages, isRemoteConsent, currentUserType, isDocSignsCompleted, otp, screenProps:{t} } = this.props
            const { logOutConfirmation } = this.state;
        return (
            <View>
            <Dialog
                onTouchOutside={() => handleHeaderMenuDialoge()}
                containerStyle={{ justifyContent: 'flex-start' }}
                dialogStyle={{ marginTop: hp('2.5%'), marginLeft: SCREEN_WIDTH >= 1030 ?SCREEN_WIDTH/4: wp('48%') }}
                rounded={true}
                visible={showDialog}
                // width={0.5}
                footer={
                    <DialogFooter style={{ backgroundColor: Colors.background }}>
                        <DialogButton
                            text={screenProps.t('Close')}
                            textStyle={{fontFamily:'Inter_Bold', color: Colors.radioGroup}}
                            onPress={() => handleHeaderMenuDialoge()}>
                        </DialogButton>
                    </DialogFooter>
                }
            >
                <DialogContent>
                    
                    <NavigateToAdmin
                        isDocSignsCompleted={isDocSignsCompleted}
                       currentUserType={currentUserType}
                       isRegistered={isRegistered}
                       screenProps={screenProps}
                       navigation={navigation} 
                       handleHeaderMenuDialoge={handleHeaderMenuDialoge}
                       subjectData={subjectData}
                       clearSubjectNavTour={clearSubjectNavTour}
                       languages = {languages}
                       isSubSignsDone = {isSubSignsDone}
                       isRemoteConsent = {isRemoteConsent}
                       otp = {otp}
                       onStopConsentClick ={this.onStopConsentClick}
                       {...this.props}
                    />
                </DialogContent>
            </Dialog>
            <StopConsentPopup
             handleStopConsentDialogue ={this.handleStopConsentDialogue}
             logOut={this.logOut}
             t = {t}
             logOutConfirmation={logOutConfirmation}
            /> 
            </View>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        inOfficeLogout,
      },
      dispatch
    );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderMenuPopup);
