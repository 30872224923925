import React from 'react';
import { View,  Text , TouchableOpacity} from 'react-native';
import { constStyles } from '../../styles/constStyles'
import { Colors } from '../../constants/ui/colorScheme';
import Dialog, { DialogContent  } from 'react-native-popup-dialog';

const PrintAndSignDocPopup = (props) => {
    const {visible, handlePrintAndSign, handleClose, t,  messageType } = props
    return(
        <View>
            <Dialog
                containerStyle={{ justifyContent: 'center' }}
                rounded={true}
                visible={visible}
                
             >
                <DialogContent >
                    <View style={{ flexDirection: 'column', alignSelf: 'center', alignItems: 'center', padding: 15, width:400 }}>
                        <View>
                            <View style={{ marginVertical: 20, alignContent: 'center' }}>
                                <Text style={[constStyles.textStyle, {padding: 5}]}>
                                   {t(`${messageType}`)} 
                                </Text>
                            </View>
                            {/* ---------- buttonss ------------ */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                                <TouchableOpacity
                                     onPress={() =>handleClose()}
                                    style={[constStyles.buttonStyle, {backgroundColor: '#ffffff',borderWidth: 1, borderColor: Colors.radioGroup, marginHorizontal:15}]}
                                >
                                    <Text style={[constStyles.buttonTxt, {color:Colors.radioGroup }]}>CANCEL </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => handlePrintAndSign()}
                                    style={[constStyles.buttonStyle, {backgroundColor: '#ffffff',borderWidth: 1, borderColor: Colors.radioGroup, marginHorizontal:15}]}
                                >
                                    <Text style={[constStyles.buttonTxt, {color:Colors.radioGroup }]}>OK </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </DialogContent>
            </Dialog>

        </View>
    )
}

export default PrintAndSignDocPopup