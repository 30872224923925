import React, { Component, useState } from "react";
import { View, Text, ActivityIndicator, Platform, TouchableOpacity } from "react-native";
import _ from "lodash";
import { Colors } from "../../constants/ui/colorScheme";
import styles from './styles/subjectDetailsStyles';
import Dialog, { DialogContent, DialogFooter, DialogButton, DialogTitle } from 'react-native-popup-dialog';
import api from '../../utils/api';
import { fontType } from '../../styles/font';
import { constStyles } from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';
import showToast from '../../utils/toast';
import { ListItem, CheckBox, Body } from 'native-base';
import RadioButton from '../../components/documents/RadioButton';
import constants from "../../utils/constants";



class EmailConsentPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isForSign: false,
            loading: false
        };
      }

      setToggleCheckBox = () => {
        this.setState(prevState => ({
            isForSign: !prevState.isForSign
          }));
      }

    clearCheckBox = () => {
        this.setState({ isForSign: false });
    }

    filterDocumentsAndSignatures = (documents, larAsWitness) => {
        let loDocuments = _.filter([...documents], d => !d.isDeleted && !d.skipped);
        loDocuments = _.map(loDocuments, d => {
            let signatures = _.filter(d.subjectDocumentSignatures, s => !s.isDeleted && s.signatureFilePath == null);
            signatures = _.sortBy(signatures, (n) => n.signatureType);
            d.subjectDocumentSignatures = signatures;
            return d;
        });
        let signatureTypes = [];
        _.map(loDocuments, scd => {
            _.map(scd.subjectDocumentSignatures, s => {
                if(!larAsWitness){
                    signatureTypes.push(s.documentSignature.signatureType);
                } else {
                    if(s.documentSignature.signatureType != 4){
                        signatureTypes.push(s.documentSignature.signatureType);
                    } else {
                        const sign = _.find(signatureTypes, sgn => _.isEqual(sgn, 2));
                        if(!sign){
                            signatureTypes.push(s.documentSignature.signatureType);
                        }
                    }
                }
            // }
            });
        });
        signatureTypes = _.filter(signatureTypes, s => s != 3);
        signatureTypes= _.uniqBy(signatureTypes, (n) => n);
        signatureTypes = _.sortBy(signatureTypes, (n) => n);
       return signatureTypes;
    }

    onSendClick = async () => {
        const { selectedSubject, navigation, handleEmailConsentPopup, subjectConsentDocuments } = this.props;
        const signTypes = this.filterDocumentsAndSignatures(subjectConsentDocuments, selectedSubject.larAsWitness)
        const larFullName = selectedSubject.larFirstName ? selectedSubject.larFirstName+ ' ' +selectedSubject.larLastName : null;
        const witnessFullName = selectedSubject.witnessFirstName ? selectedSubject.witnessFirstName+ ' ' +selectedSubject.witnessLastName : null;
        const subject = {
            id: selectedSubject.id,
            email: selectedSubject.email,
            fullName: selectedSubject.fullName,
            larEmail: selectedSubject.larEmail,
            witnessEmail: selectedSubject.witnessEmail,
            larFullName,
            larFirstName : selectedSubject.larFirstName,
            larLastName : selectedSubject.larLastName,
            witnessFirstName : selectedSubject.witnessFirstName,
            witnessLastName: selectedSubject.witnessFirstName,
            witnessFullName
        }
        const subjectRemoteConsent = {
            type: 'SIGNATURE',
            userType: signTypes[0],
            subjectConsent: {
                id: selectedSubject.subjectConsent.id,
                consentName: selectedSubject.subjectConsent.consentName
            },
            signStatus: 0,
        }
        try {
            this.setState({loading: true})
            const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${selectedSubject.id}/subjectConsents/${selectedSubject.subjectConsent.id}/remoteConsents`, { subject, subjectRemoteConsent });
            this.setState({loading: false})
            handleEmailConsentPopup();
            this.setToggleCheckBox(false)
            if (res.status) {
                showToast('Email sent successfully.', "success", 3000); //Popup message was corrected
            }
        } catch (err) {
            console.log(err);
            this.setState({loading: false})
            showToast('Network error. Please try again.', 'danger', 3000);
        }
    }

  
render() {
    const { isShowEmailPopUp, handleEmailConsentPopup } = this.props;
    const { isForSign, loading } = this.state;
    return (
        <View >
            <Dialog
                containerStyle={{ justifyContent: 'center' }}
                rounded={true}
                visible={isShowEmailPopUp}
                dialogTitle={
                    <DialogTitle
                        title="Email – Consent"
                        align="left"
                        style={{ fontFamily: 'Inter', fontSize: fontType('Heading1') }}
                    />}
            >
                <DialogContent >
                    <View style={{ flexDirection: 'column', alignSelf: 'center', alignItems: 'center', padding: 15, }}>
                        <View>
                            <View style={{ marginVertical: 20, alignContent: 'center' }}>
                                <ListItem style={{borderColor:'#fff'}}>
                                    <RadioButton
                                        isSelected={isForSign}
                                        isCurrentFieldDisabled={false}
                                        onOptionSelected={() => this.setToggleCheckBox()
                                        }
                                    />
                                    <Body>
                                         <Text style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter' }}>&nbsp; For Signature</Text>
                                    </Body>
                                </ListItem>
                                {/* <View style={styles.checkboxContainer}>
                                    <CheckBox
                                        // disabled={false}
                                        checked={isForSign}
                                        onPress={(newValue) => this.setToggleCheckBox(newValue)}
                                        // style={{ color: 'blue' }}
                                    />
                                    <Text style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter' }}>&nbsp; For Signature</Text>
                                </View> */}
                            </View>
                            {/* ----buttonss ------------ */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop:50 }}>
                                <TouchableOpacity
                                    onPress={() =>{ handleEmailConsentPopup(); this.clearCheckBox() }}
                                    style={[constStyles.buttonStyle, { backgroundColor: Colors.errorButton, marginHorizontal: 15 }]}
                                >
                                    <Text style={[constStyles.buttonTxt]}>CANCEL </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    disabled={!isForSign}
                                    style={[constStyles.buttonStyle, { backgroundColor:!isForSign ? 'grey' : Colors.buttonwithbg, marginHorizontal: 15 }]}
                                    onPress={this.onSendClick}
                                >
                                    <MyLoader active={loading} />
                                    <Text style={[constStyles.buttonTxt]}>SEND
                                        
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </DialogContent>
            </Dialog>

        </View>
    );
}
}

export default EmailConsentPopup;
