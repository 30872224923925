import React, {Component} from 'react';
import {
  View,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Text,
} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  MaterialIcons,
  MaterialCommunityIcons,
  AntDesign,
  Feather,
} from '@expo/vector-icons';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';

// import {styles} from './styles/helpWindowStyles';

export default class HelpWindow extends Component {
  render () {
    const {handleShowHintModalVisibility, getCurrentField, t} = this.props;
    return (
      <View style={{flex: 1}}>
        <View
          style={{flex: 5.7, backgroundColor: Colors.background, padding: wp ('2%')}}
        >
          <View style={{alignItems: 'flex-end'}} />
          {/* Heading View */}
          <View
            style={{
              flex: 0.5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          ><View style={{ flex: 5.5}}><Text
                  style={{
                    fontSize: fontType('BasicFont'),
                    color: Colors.coloredText,
                    marginTop: wp ('2%'),
                    marginLeft:wp('2%'),
                    fontFamily:'Inter'
                  }}
                >
                  {t ('Help')}
                </Text></View>
            <TouchableOpacity style={{ flex: 0.5}} onPress={() => handleShowHintModalVisibility ()}>
              <Text style={{marginRight: wp ('1%')}}>
                <MaterialIcons name="cancel" color={Colors.discontinued} size={45} />
              </Text>
            </TouchableOpacity>
          </View>
          {/* Hint View */}
          <View style={styles.paragraph}>
            <Text style={styles.text}>{getCurrentField ().hint}</Text>
          </View>
          <View style={styles.bottom}>

            <TouchableOpacity
              style={styles.button}
              onPress={() => handleShowHintModalVisibility ()}
            >
              <Text style={{alignSelf: 'center', fontSize: fontType('BasicFont'),color:Colors.textColorwithbg, fontFamily:'Inter_Bold'}}>
                {t ('Close')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create ({
  button: {
    width: Dimensions.get ('window').width,
    height: 60,
    // paddingVertical: 15,
    backgroundColor: Colors.buttondisabled,
    justifyContent: 'center',
  },
  buttonTxt: {
    color: Colors.textColor,
    // fontSize:20
  },
  paragraph: {
    paddingLeft: 20,
    paddingTop: 10,
    flex: 4,
    justifyContent: 'flex-start',
    fontFamily:'Inter'
  },
  text: {
    color: Colors.hintText,
    fontSize: fontType('BasicFont'),
    fontFamily:'Inter'

  },
  highlightTxt: {
    fontWeight: 'bold',
    color: Colors.highLightText,
  },
  bottom: {
    flex: 1,
    height: 50,
    justifyContent: 'flex-end',
    bottom: 0,
    position: 'absolute',
    width: '100%',
    height: 50,
  },
});
