import {StyleSheet} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType } from '../../../styles/font';


export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: Colors.background,
    },
    text: {
        fontWeight: "bold",
        color: Colors.textColor,
    },
    qnText:{
        fontSize: fontType('Heading1'),
        color: Colors.question,
        // padding: 5,
        fontFamily:'Inter' 
    },
    optTxt : {
        fontSize: fontType('BasicFont'),
        color:'#3f3f3f',
        marginLeft:('1%'),
        flexWrap:'wrap',
        fontFamily:'Inter' 
    },
    sbmtBtn: {
        alignSelf:'center',
        width:wp('25%'),
        backgroundColor:Colors.buttonwithbg,
        marginTop:wp('5%'),
        borderRadius:wp('25'),
    },
    sbmtBtnDisable : {
        alignSelf:'center',
        width:wp('25%'),
        backgroundColor: Colors.buttondisabled,
        marginTop:wp('5%'),
        borderRadius:wp('25'),
    },
    btnTxt : {
        marginLeft:wp('8%'),
        color: Colors.textColorwithbg, 
        fontSize:fontType('ButtonTxt'),
        fontFamily:'Inter_Bold'
    }
});