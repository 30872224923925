import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import React from "react";
import { Container } from "native-base";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { Colors } from "../../constants/ui/colorScheme";
import { fontType } from "../../styles/font";
import { Image } from "react-native";
import Constants from "../../utils/constants";
import api from "../../utils/api";
import { constStyles } from "../../styles/constStyles";

export default ({ clientName, t, navigation, isRemoteConsent, otp }) => {
  const onRedirectLogin = () => {
    const loginRequestId = window.sessionStorage.getItem("loginRequestId");
    delete api.defaults.headers.common.Authorization;
    if (isRemoteConsent) {
      navigation.navigate("SubjectAuthStack", {
        screen: "SubjectAuth",
        otp: otp,
      });
    } else {
      setTimeout(() => {
        window.location.assign(
          `${Constants.ADMIN_PORTAL_BASE_URL}/saml/logout/${loginRequestId}?redirectUrl=${Constants.ADMIN_PORTAL_BASE_URL}/api/webapp/logout`
        );
      }, 100);
    }
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: "#f2f2f2",
        }}
      >
        <View style={{ alignContent: "center", alignSelf: "center" }}>
          <Image
            source={require("../../../assets/Session_expired.png")}
            style={{ height: 150, width: 200, paddingBottom: 10 }}
            resizeMode="contain"
          />
        </View>
        <View
          style={{
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text style={constStyles.errorPageText}>
              Your session has timed out
            </Text>
            <Text style={constStyles.errorPageText}>
              <TouchableOpacity onPress={onRedirectLogin}>
                <Text
                  style={{
                    textDecoration: "none",
                    color: Colors.radioGroup,
                    cursor: "pointer",
                  }}
                >
                  Click
                </Text>
              </TouchableOpacity>{" "}
              to log in again.
            </Text>
          </View>
        </View>
      </View>
    </Container>
  );
};
