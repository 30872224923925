import { encode as btoa } from "base-64";
import "text-encoding-polyfill";

function bytesToBase64(bytes) {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
  return btoa(binString);
}

export function generateBasicAuthToken(userName, password) {
  return `Basic ${bytesToBase64(new TextEncoder().encode(`${userName}:${password}`))}`;
}

export function generateBasicAuthHeader(userName, password) {
  return `Authorization: ${generateBasicAuthToken(userName, password)}`;
}

export function isValidString(stringMaybe) {
  return Boolean(typeof stringMaybe === 'string' && stringMaybe.length);
}
