import React from 'react';
import { StyleSheet, Text, View, Image, ScrollView } from 'react-native';
import { connect } from "react-redux";
import { Container, Footer} from 'native-base';
import { bindActionCreators } from "redux";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import HtmlParserEg from '../components/htmlView/htmlparserEg';
import htmldata , { smallHtml } from '../components/htmlView/config';
import HtmlTest from './htmlTestScreen';
class InitialScreen extends React.Component {
  state = {

  };

  

  componentDidMount() {
    const { navigation, accessCode, deviceNo } = this.props;
      if (accessCode === "" || accessCode == null ) {
        navigation.navigate('AdminAuthStack', { screen: 'LoginPage' });
      } else if (deviceNo === "" || deviceNo === null) {
        navigation.navigate('AdminAuthStack', { screen: 'LoginPage' });
      } else {
        // navigation.navigate('LoginPage');
        navigation.navigate('AdminAuthStack', { screen: 'LoginPage' })
      }
  }

  render() {
    return (
      // <HtmlTest/> 
      <View/>
    );
  }
}

const mapStateToProps = state => ({
  accessCode : state.studySite.accessCode,
  deviceNo: state.consentTokenData.deviceNo,
});

export default connect(mapStateToProps, null)(InitialScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});