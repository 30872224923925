import React, {Component} from 'react';
import {StyleSheet, View, Text, Platform, TextInput} from 'react-native';
import {Colors} from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';
import SubjectInitialInput from './SubjectInitialInput';
import HtmlParserEg from '../htmlView/htmlparserEg';

export default class SubjectInitialInputDecorator extends Component {
  constructor (props) {
    super (props);
    this.state = {text: '', content: <Text />};
  }

  componentDidMount () {
    // const {formField} = this.props;
    // let content = formField.fieldName.split ('\n').join ('');
    // content = content.split (' ');
    // content = content.map (word => (
    //   <Text
    //     style={
    //       Platform.OS === 'web'
    //         ? {fontFamily: 'Inter'}
    //         : {fontFamily: 'Inter', fontSize: 17}
    //     }
    //   >
    //     {word}{' '} 
    //   </Text>
    // ));
    // this.setState ({content});
  }

  // componentDidUpdate(prevProps) {
  //   const { form, formField, focusInvalidField, fieldKey, scrollToField } = this.props;
  //   const { form: prevForm } = prevProps;
  //   const error = form.getFieldError(`${formField.fieldOid}`);
  //   const prevError = prevForm.getFieldError(`${formField.fieldOid}`);
  //   if(error && focusInvalidField !== prevProps.focusInvalidField && focusInvalidField) {
  //     scrollToField(fieldKey)
  //   }
  //   // else if(error !== prevError && !error) {
  //   //   removedInValidFieldsToFocus(fieldKey);
  //   // }
  // }

  setFieldValue = async value => {
    const {form, formField, onValueChange} = this.props;
    await form.setFieldsValue ({
      [formField.fieldOid]: value,
    });
    onValueChange ();
  };

  render () {
    const {form, formField, t, location, scrollToField, fieldKey, focusInvalidField, currentUserType} = this.props;
    const {content} = this.state;
    if (form.getFieldError (`${formField.fieldOid}`) && focusInvalidField) {
      scrollToField (fieldKey);
    }
    // let content = formField.fieldName.split("\n").join('');
    return (
      <View style={{ flexDirection: 'row', flex: 1, paddingTop: 5}}>
        <View style = {{ flex:0.7, paddingLeft: 10 }}>
          {form.getFieldDecorator (`${formField.fieldOid}`, {
            initialValue: formField.formData.fieldValue,
            rules: [
              {
                required: formField.isRequired,
                message: t ('SubjIniWarn'),
              },
            ],
          }) (
            <SubjectInitialInput
              // content={formField.fieldName}
              setFieldValue={this.setFieldValue}
              error={form.getFieldError (`${formField.fieldOid}`)}
              location={location}
              t={t}
              key={formField.fieldOid}
              currentUserType={currentUserType}
            />
          )}
          {form.getFieldError (`${formField.fieldOid}`)
            ? <Text
                style={{
                  fontSize: 12,
                  color: '#f44336',
                  alignSelf: 'flex-start',
                  //   marginTop: 4,
                  //   marginLeft: 4,
                  borderRadius: 5,
                  //   padding: 5
                }}
              >
                {form.getFieldError (`${formField.fieldOid}`)}
              </Text>
            : <Text />
            }
            </View>
            <View style={{ flex: 5.3,paddingLeft:10, marginTop: '-2px'}}>
                <HtmlParserEg currentDoc={`<span>${formField.fieldName}</span>`}/>
            </View>
        </View>
    );
  }
}

const styles = StyleSheet.create ({
  errorinfoText: {
    color: Colors.error,
    // fontFamily: 'WorkSans-Light',
    fontSize: fontType ('subFont'),
    marginTop: 5,
  },
});
