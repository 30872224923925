import createActionType from '../utils/action';
import _ from 'lodash';


export const LAR_FORM_DATA = createActionType('LAR_FORM_DATA')


export const storeLARData = (larDetails) => ({
    type: LAR_FORM_DATA,
    larDetails,
});
