import React, { Component } from "react";
import { View, Text, Platform,  TouchableOpacity,Dimensions} from "react-native";
import { Colors } from "../constants/ui/colorScheme";
import Dialog, { DialogContent, DialogFooter, DialogButton, DialogTitle } from 'react-native-popup-dialog';
import {constStyles} from '../styles/constStyles';
import {fontType} from '../styles/font';
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

class NoRemoteConsentMailPopUp extends React.Component {



    render() {
        const  {isNoMail, currentUser, onOk } = this.props;
        return (
            <Dialog
                containerStyle={{ justifyContent: 'center' }}
                rounded={true}
                visible={isNoMail}
            >
                <DialogContent>
                    <View style={{ flexDirection: 'column', alignSelf: 'center', alignItems: 'center', padding: 10 }}>
                        <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: "center", padding: 15 }}>
                            <Text style={{ fontSize: fontType('Heading1'), fontFamily: 'Inter', color: Colors.iconColor, }}> The {currentUser} does not have an email address.</Text>
                            <Text style={{ fontSize: fontType('Heading1'), fontFamily: 'Inter', color: Colors.iconColor, }}> Please add a valid email address to the subject profile.</Text>

                        </View>
                        <View style={{ flexDirection: 'row', }}>
                            <TouchableOpacity
                                style={[constStyles.buttonStyle, { backgroundColor: Colors.fieldSubmit, marginHorizontal: 15 }]}
                                onPress={() => {
                                    onOk()
                                }}
                            >
                                <Text style={[constStyles.buttonTxt]}> OK </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </DialogContent>
            </Dialog>)
    }
}

export default NoRemoteConsentMailPopUp;