import { View } from 'react-native'
import React from 'react'
import RenderHTML from 'react-native-render-html'
import CollapsableVersionDocuments from './CollapsableVersionDocuments'

const VersionWiseDocuments = (props) => {
    const {documentList, onDocumentSelected}  = props

    //Sorting documents based on consent Version number
    const getCategorizedDocumentsByVersion = (documentList) => {
        let documents = [];
        let children = _.groupBy(documentList, "consentVersionNumber");
        let index = 0
        _.forEach(children, (doc, key) => {
          let lochild = {
            title: key,
            content: doc,
            index: index,
          };
          documents.push(lochild);
          index++;
        });
        return documents;
    };

    let lodocumentList = getCategorizedDocumentsByVersion(documentList);


  return (
    <View>
        {/* --------------All version documents ---------------- 
        * Showing collapsable icon for each version to show respective documents
        */}
        <CollapsableVersionDocuments documents = {lodocumentList} onDocumentSelected={onDocumentSelected}/>
    </View>
  )
}
export default VersionWiseDocuments