import createActionType from '../utils/action';
import _ from 'lodash';

export const STORE_QUIZ_ATTEMPT_DATA = createActionType('STORE_QUIZ_ATTEMPT_DATA')


export const storeQuizAttemptData = (quizAttempts) => (dispatch, getState) => dispatch({
    type: STORE_QUIZ_ATTEMPT_DATA,
    quizAttempts,
    doneBy: getState().consentFlow.currentUserType
});

