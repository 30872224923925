import initialState from '../store/initialState';
import { HAS_NEW_APP_UPDATE, HAS_NEW_APP_UPDATED } from '../actions/update'


const hasAppUpdate = (state = initialState.hasAppUpdate, action) => {
  switch (action.type) {
    case HAS_NEW_APP_UPDATE:
      return true;
    case HAS_NEW_APP_UPDATED:
      return false;
    default:
      return state;
  }
};

export default hasAppUpdate;