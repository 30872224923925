import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
  Text,
  Dimensions
} from 'react-native';
import RadioButton from './RadioButton';
import HtmlParserEg from '../htmlView/htmlparserEg';
import SubjectInitialInput from './SubjectInitialInput';
import _ from 'lodash';

const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')



export default class RadioInitialsFormInput extends Component{

    // onRadioSelection = (option) => {
    //     const { field, setFieldValue, sectionId, isCheckBoxChecked, values } = this.props;
    //         setFieldValue(field.dcffOid, option.optionId);
    // }
    state = {
        value1: null,
        value2: null
    }

    componentDidMount() {
        const { value, formField, t } = this.props;
        if(value) {
            const value1 = JSON.parse(value)?.value1;
            let optionOid = null
            if(!_.isEmpty(value1)){
                optionOid = value1.optionOid;
            }
            const value2 = JSON.parse(value)?.value2;
            let index = _.filter(formField.dictionary, option => option.oid === optionOid )[0]?.index;
            if(typeof index === 'number') {
                this.setState({
                    value1,
                    value2: {
                        [index]: value2,
                    },
                    label: t('Initials')
                })
            }
         }
    }

    onRadioSelected = (optionOid, index) => {
        const { formField } = this.props;
        const selectedOption = _.filter(formField.dictionary, option => option.oid === optionOid )[0];
        this.setState(prevState=>({
            ...prevState,
            value1: {
                value: selectedOption.option,
                optionOid: optionOid
            },
            value2: prevState.value1 !== null && prevState.value1.optionOid !== optionOid ? null : prevState.value2
        }), () => {
           this.changeFieldValue(index);
        })
    }

    isHTML = (str) => {
      const fragment = document.createRange().createContextualFragment(str);
      
      // remove all non text nodes from fragment
      fragment.querySelectorAll('*').forEach(el => el.parentNode.removeChild(el));
      
      // if there is textContent, then not a pure HTML
      return !(fragment.textContent || '').trim();
    }

    onInitialsChanged = (value, index) => {
        const { formField } = this.props;
        const { value1 } = this.state;
        const selectedOption = _.filter(formField.dictionary, option => option.oid === value1?.optionOid )[0];
        const hasSelectedOption = !_.isEmpty(selectedOption);
        if((hasSelectedOption && index === selectedOption.index) || _.isEmpty(value1)) {
            this.setState(prevState=>({
                ...prevState,
                value2: {
                    [index]: value,
                },
            }), () => {
               this.changeFieldValue(index);
            })
        }
    }

    changeFieldValue = (index) => {
        const { setFieldValue, t } = this.props;
        const { value1, value2 } = this.state;
        
        let fieldValue = {
            value1,
            value2: value2 ? value2[index] : null,
            label: t('Initials')
        }
        fieldValue = JSON.stringify(fieldValue)

        setFieldValue(fieldValue)
    }

    calMaxWidth = () => {
        if(SCREEN_WIDTH >= 1030) {
            return 550;
        } else {
            return '65%';
        }
    }

    render(){
        const { formField, location, t, currentUserType } = this.props;
        let { value1, value2 } = this.state;

        return (
          <View
            style={{
              flexDirection: "row",
              // paddingHorizontal: 10,
              // paddingTop: 5,
            }}
          >
    
            <View style={{flex: 1 }}>
              <View style={{paddingLeft: 10, paddingRight: 10}}>
              <HtmlParserEg currentDoc={this.isHTML(formField.fieldName) ? `<span>${formField.fieldName}</span>` : `<span><p>${formField.fieldName}</p></span>`}/>
              </View>
              {formField.dictionary.map((option, index) => (
                <View
                  style={{ flexDirection: "row", paddingLeft: 5}}
                  key={index + "radioView"}
                >
                  <View style={{flex: 0.25}}>
                  <RadioButton
                    isSelected={value1 ? value1.optionOid === option.oid : false}
                    isCurrentFieldDisabled={
                      (location === "CounterSignDocument" || currentUserType == 4) ? true : false
                    }
                    onOptionSelected={() => this.onRadioSelected(option.oid, index)}
                  />
                  </View>
                  <View style={{flex: 5.75,paddingLeft:10, flexDirection: 'row', alignItems: 'flex-start'}}>
                  <View style={{ flexShrink: 1, marginTop: '-16px'}}>
                    <HtmlParserEg currentDoc={this.isHTML(option.option) ? `<span>${option.option}</span>` : `<span><p>${option.option}</p></span>` }/>
                  </View>
                  <View style={{flexDirection: 'row', paddingLeft: 5, marginTop: '-14px'}}>
                    <SubjectInitialInput
                      setFieldValue={(value) => this.onInitialsChanged(value, index)}
                      error={''}
                      location={location}
                      t={t}
                      value={value2 ? value2[option.index] : null}
                      currentUserType={currentUserType}
                    />
                    <Text style={{ paddingLeft: 2,  marginTop: 14 }}>({option.label})</Text>
                  </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
        );
    }
}

let styles = StyleSheet.create({
    error :{
        color:'red',
        fontSize:15,
    },
  container:{
	  flexGrow: 1,
	  flexDirection: 'row',
	   padding: 10,
  },
  radio:{
	  alignItems: 'center',
	  justifyContent: 'center',
  },
  item: {
    marginLeft: 5,
    alignItems: 'center',
	justifyContent: 'center',
  }
})
