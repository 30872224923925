import React, { Component } from "react";
import { ActivityIndicator, Text, View, Platform } from "react-native";
import Dialog, { DialogContent } from "react-native-popup-dialog";
import Spinner from "react-native-loading-spinner-overlay";
import { Colors } from "../constants/ui/colorScheme";
import { store } from "../store/configureStore";
import { Provider } from "react-redux";
import APPLoader from "./appLoader";

export default function MyLoader({ active, children }) {
  return (
    <Provider store={store}>
      <APPLoader active={active}></APPLoader>
    </Provider>
  );
}
