
import { StyleSheet, Text, View } from 'react-native'
import React, { Component, useState } from 'react'
import Accordion from "react-native-collapsible/Accordion";
import { Entypo } from '@expo/vector-icons';
import { fontType } from '../../../styles/font';
import Documents from './Documents';

const styles= StyleSheet.create({
    headerContainer: {
        backgroundColor: "#e4e5e6",
        padding: 15,
        borderWidth: 1,
        borderColor: "white",
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
    textContainer: {
        textAlign: "left", fontSize: 20, color: "black", fontFamily:"Inter" 
    }
   
});

class CollapsableVersionDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSections: [],
    };
  }

  //Showing header of the collapsable section
  _renderHeader = (section) => {
    const {activeSections} = this.state
    return (
      <View style={styles.headerContainer} >
        <Text style={styles.textContainer}>{section.title}</Text>
        <Entypo name={!_.isEmpty(activeSections)&& activeSections[0] == section.index? "chevron-down" : "chevron-right"} size={fontType("Heading")} color="black" />
      </View>
    );
  };

  //Showing documents as content inside collapsable section
  _renderContent = (section) => {
    const {onDocumentSelected} = this.props
    return (<Documents documentList={section.content} onDocumentSelected={onDocumentSelected}/>);
  };

  //Updates the index of Section with activeSections  which is currently active or expanded currently
  //activeSections always have one elements only
  _updateSections = (activeSections) => {
    this.setState({ activeSections });
  };

  render() {
    const {documents} = this.props
    return (
      <View>
         {/* -------------All version documents with rollup icon(Collapsable functionality) ------------------- */}
          <Accordion
              sections={documents}
              activeSections={this.state.activeSections}
              renderHeader={this._renderHeader}
              renderContent={this._renderContent}
              onChange={this._updateSections}
            />
      </View>
    );
  }
}

export default CollapsableVersionDocuments