import createActionType from '../utils/action';
import api from '../utils/api';

export const STORE_STUDY_SITE_LIST_SUCCESS = createActionType('STORE_STUDY_SITE_LIST_SUCCESS')
export const STORE_STUDY_SITE_SUCCESS = createActionType('STORE_STUDY_SITE_SUCCESS')

export const storeStudySiteList = (studySiteList) => ({
    type: STORE_STUDY_SITE_LIST_SUCCESS,
    studySiteList,
});


export const storeStudySite = (studySite) => ({
    type: STORE_STUDY_SITE_SUCCESS,
    studySite,
});