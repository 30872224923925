import SubjectEdit from '../components/subjectList/SubjectEdit';
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AddSubject from '../components/subjectList/AddSubject';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { getLanguageList } from '../utils/consentData';
import { storeSelectedSubject, storeSelectedSubjectDetailsinSubject } from '../actions/subject';
import { getHeaderTitle } from './NavigationScreens';
import { Platform } from 'react-native';
import { HeaderBackButton } from '@react-navigation/stack';


class AddSubjectScreen extends Component {
    static contextType = ConsentDataContext;
   constructor(props) {
      super(props);
      this.state = {
   
      };
   }
   componentDidMount = () => {
    
  }

  static navigationOptions = (props) => {
   const {screenProps: { languages, studySite}, navigation } = props;
   return  {   
      headerTitle:() => (getHeaderTitle(languages,false,true,studySite)),
      headerTitleStyle: { alignSelf: 'center' },
      // headerLeft:() => (<HeaderBackButton tintColor='white' onPress={()=>{navigation.replace('HomeTabs')}}/>),
   }
};
   render() {
      const { navigation,screenProps, studySiteId,
         deviceNo, storeSelectedSubjectDetailsinSubject, storeSelectedSubject } = this.props;
      const languages = getLanguageList(this.context.languages);
        const data = "";
  
      return (
         <AddSubject
         data={data}      
         languages={languages}  
         navigation = {navigation}
         screenProps={screenProps}
         studySiteId={studySiteId}
         deviceNo={deviceNo}
         storeSelectedSubjectDetailsinSubject={storeSelectedSubjectDetailsinSubject}
         storeSelectedSubject={storeSelectedSubject}
         />
      
      );
   }
}

const mapStateToProps = state => {
   return {
   
     studySiteId: state.studySite.studySiteId,
     deviceNo: state.consentTokenData.deviceNo,
   };
 };

 const mapDispatchToProps = dispatch => bindActionCreators(
   {
     storeSelectedSubjectDetailsinSubject,
     storeSelectedSubject,
   },
   dispatch,
 );

 export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectScreen);





