import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';
import {Item, Input} from 'native-base';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font'


export default class TextInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
  render() {
    const { error, value, onChange, placeholderValue, keyboardType,maxLength} = this.props;
    return (
      <View >
          <TextInput
            placeholder={placeholderValue}
            placeholderTextColor="#c9c9c9"
            style={styles.txtInput}
            value={value || ''}
            onChangeText={onChange}
            fontSize={fontType('BasicFont')}
            keyboardType={keyboardType || 'default'}
            maxLength = {maxLength}
          />
          <View style={styles.errorinfoText}>{this.getError(error)}</View>
      </View>
    )
  }

}
const styles = StyleSheet.create({
  errorinfoText: {
    color: Colors.error,
    // fontFamily: 'WorkSans-Light',
    fontSize: fontType('subFont'), 
    marginBottom:wp('1%'),
    fontFamily:'Inter'

  },

  txtInput: {
    borderBottomWidth:wp('0.1%'),
    borderBottomColor:'#006699',
    fontFamily:'Inter',
    textAlign:'left',
     minWidth:'50%', 
  },
})
