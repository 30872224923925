import React, { Component } from "react";
import { Container, List, ListItem, Text ,Item, Input, Icon, Body } from 'native-base';
import { View,ScrollView, Dimensions, Platform } from 'react-native';
import { styles } from "./styles/languageSelectStyle";
import SearchInput, { createFilter } from 'react-native-search-filter';
import { MaterialIcons, AntDesign, Feather } from '@expo/vector-icons';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from "../../constants/ui/colorScheme";
import {fontType} from '../../styles/font'

const KEYS_TO_FILTERS = ['language', 'languageName'];

export default class LanguageSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ''
        }
    }
    searchUpdated(term) {
        this.setState({ searchTerm: term })
    }

    // generateSubjectSno = (subSerialDeviceNo) => {
    //     if(subSerialDeviceNo.length === 1) {
    //         return '00'+subSerialDeviceNo;
    //     } else if(subSerialDeviceNo.length === 2) {
    //         return '0'+subSerialDeviceNo;
    //     } return subSerialDeviceNo;

    // }

    // generateConsentId = () => {
    //     const {consentTokenData, storeConsentTokenData,incrementSubSerialDevNo, navigation} = this.props;
    //     let subSerialDeviceNo = Number(consentTokenData.subSerialDeviceNo);
    //     subSerialDeviceNo++;
    //     subSerialDeviceNo = '' + subSerialDeviceNo;
    //     const consentTokenId = consentTokenData.deviceNo+consentTokenData.siteNo+this.generateSubjectSno(subSerialDeviceNo);
    //     storeConsentTokenData(consentTokenId);
    //     incrementSubSerialDevNo(subSerialDeviceNo);
    //     navigation.navigate('HandoverToSubjectScreen');
    // }

    onLanguageSelect = (language) => {
        const { selectLanguage, screenProps: { setLocale }, navigation } = this.props;
        setLocale(language.language);
        selectLanguage(language);
        // this.generateConsentId(language.id);
        navigation.navigate('HandoverToSubjectScreen');
    }

    render() {
        const { languages } = this.props;
        const filteredSearch = languages.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
        const viewStyles = Platform.OS === 'web' ? { backgroundColor: '#fff' } : {flex:1, backgroundColor: '#fff'};
        return (
            <ScrollView style={{backgroundColor:'#fff'}}>
            <View style={viewStyles}>
                 <View style={styles.title}>
                    <Text style={{fontSize:fontType('Heading1'),color:Colors.text, fontFamily:'Inter'}}>Select language for subject</Text>
                </View>
                <View style={styles.searchbarContainer}>
                    <Item searchBar rounded >
                        <Icon name="ios-search" style={{fontSize:fontType('BasicFont'), padding: 3}}  />
                        <Input placeholder="Search" style={{fontSize:fontType('BasicFont'), fontFamily:'Inter'}}
                            autoCorrect={false}  
                            onChangeText={(term) => { this.searchUpdated(term) }} />
                            <MaterialIcons name="translate" size={fontType('BasicFont')} style={{padding: 7}}/>
                    </Item>
                </View>
                {filteredSearch.length > 0 ? 
                <ScrollView alwaysBounceVertical={true}>
                    {filteredSearch.map(language => {
                        return (
                                <List key={language.language} style={styles.list}>
                                    <ListItem onPress={() => this.onLanguageSelect(language)}>
                                        <Body>
                                            <View style={{ justifyContent: 'flex-start' }}>
                                                <Text style={{ fontSize: fontType('BasicFont'), fontWeight: 'normal', fontFamily: 'Inter' }}>{language.languageName}</Text>
                                                {language.irbApprovedVersion ?
                                                    <Text style={{ color: Colors.iconColor, fontSize: fontType('subFont'), fontFamily: 'Inter' }}>IRB Approved Version: {language.irbApprovedVersion}</Text>
                                                    : <Text></Text>}
                                            </View>
                                        </Body>
                                    </ListItem>
                                </List>
                        )
                    })}
                </ScrollView>: <View style={{flex:1, flexDirection:'column', alignItems:'center', marginTop: wp('15%') }}>
                        <Text style={{ fontSize: fontType('Heading1'), color: Colors.textdisabled}}>No data.</Text>
                    </View>}
            </View>
            </ScrollView>
        );
    }

}