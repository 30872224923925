import {StyleSheet, Dimensions} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../../constants/ui/colorScheme';
import {fontType} from '../../../styles/font';

export default StyleSheet.create({
    buttonSbmt: {
      backgroundColor: Colors.button,
      margin: wp('1%'),
      borderRadius: wp('5%'),
      borderColor:'white',
      borderWidth:1,
      minWidth: wp('16%'),
      height: wp('5.5%'),
     },
     buttonTxt: {
      color: 'white',
      marginLeft: wp('1%'),
      textAlign: 'center',
      fontSize: fontType('ButtonTxt'),
      paddingHorizontal: wp('4%'),
      fontFamily:'Inter_Bold'
     },
     Footer: {
        backgroundColor: Colors.background,
        justifyContent: 'center',
        alignItems: 'center',
        height: wp('10%')
     },
     documentTxt: {
        fontSize:fontType('subFont'),
     },
     pageTitle:{
     color:Colors.text,
     textAlign:'center',
     fontStyle:'normal',
     padding: wp('2%'),
     fontSize: fontType('Heading1'),
     fontFamily:'Inter'
     }
})

