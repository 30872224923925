import React, {useState } from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import { constStyles } from '../../../styles/constStyles';
import CustomTable from '../CustomTable';
import _ from 'lodash'
import { fontType } from '../../../styles/font';
import { ReasonForChangeColumns } from './Constants';
import { Colors } from '../../../constants/ui/colorScheme';

const ReasonForChange = (props) =>  {
  const {onReasonForChangeSave, changes, screenProps: { t }, loading} = props
  const [reason, setReason] = useState(null);
  const [showError, setShowError] = useState(false);

  const onSave = () => {
    if(!_.isEmpty(reason)){
      onReasonForChangeSave(reason)
    }
    else{
      setShowError(true)
    }
  }

  const setReasonForChange = (value) => {
    if(!showError && _.isEmpty(value)){
      setShowError(true)
    }
    else if(showError){
      setShowError(false)
    }
    setReason(value)
  }
  
    return (
        <View>
	        <Text style={[styles.textLable, {marginBottom: 15}]}>{t('ChangingDataLabel')}</Text>
            <CustomTable coloumns = {ReasonForChangeColumns} data = {_.map(changes, item => [item.key, item.currentValue, item.previousValue])} />
            <View style={{paddingRight: 80, paddingLeft: 80,  marginTop: 40}}>
				    <Text style={styles.textLable}>{t('ReasonForChangeLabel')} <span style={{color: Colors.needSign}}>*</span></Text>
            <View style={{marginTop:5}}></View>
				    <TextInput maxLength={100} onChange={(e) => setReasonForChange(e.target.value)} value={reason} style={[styles.textLable, constStyles.inputStyles]} placeholder={t('ReasonForChange')}></TextInput>
            {showError && <Text style={[styles.textLable, { color: Colors.needSign }]}>{t('ReasonForChangeWarning')}</Text>}
            </View>
				    <View style={ { justifyContent:'center',flexDirection: "row",flexGrow : 1,
              marginVertical: 4,
              padding: 2,
              alignItems: 'center' }}>
                <TouchableOpacity
                  disabled={loading}
                  onPress={() => onSave()}
                  style={[constStyles.buttonStyle, { marginTop: 10,marginBottom:20 }]}
                >
                  <Text style={[constStyles.buttonTxt, { marginHorizontal: 50 }]} >
                    {t('SaveChanges')}
                  </Text>
                </TouchableOpacity>
            </View>
            <Text style={[styles.textLable, {fontStyle: 'italic'}]}>{t('ReasonForChangeNote')}</Text>
			    </View>
    )

}

export default ReasonForChange;

const styles = StyleSheet.create({
  textLable: {
    fontSize: fontType('BasicFont'),
    fontFamily: 'Inter',
  },
});