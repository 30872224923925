import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from 'native-base';
import {
    TouchableOpacity, View, Text
} from "react-native";
import DocumentPages from '../components/documents/DocumentPages';
import { storeVisitedChapters, storeisAgreedForChapters, storeSubjectNavTour, clearSubjectNavTour, updateSubjectNavTour  } from '../actions/subject';
import {getConsentChapter, getFilteredDocuments} from '../utils/consentData';
import {ConsentDataContext} from '../providers/ConsentDataProvider';
import { initializeDocuments, updateVisitedDocumentStatus } from '../actions/documentChapter';
import { skipDocument, canSkipTheDocument } from '../actions/subjectConsentDocument';
import {updateDocsReview} from '../actions/subject';
import _ from 'lodash';
import {clearDocSignPosition} from '../actions/update'
class DocumentsScreen extends Component {
    static contextType = ConsentDataContext;

        onNextChapter = () => {
            const { navigation } = this.props;
            navigation.navigate('KnowledgeReviewScreen');
        }

        onPreviousChapter = () => {
            const { navigation } = this.props;
            navigation.navigate('StudyVideoListScreen');
        }

    render() {
        const {icfDocumentsList, navigation, isRegistered, storeVisitedChapters, krDoneBy,
            subjectData, isAgreedForChapters, storeisAgreedForChapters, screenProps, storeSubjectNavTour, selectedSubject: { isKnowledgeReviewDone }, signatureType, 
            language, visitedChapters, updateSubjectNavTour, subjectNavTours, formDataList, updateDocsReview, canSkipTheDocument,
            documentChapter, updateVisitedDocumentStatus, initializeDocuments, selectedSubject, currentUserType,
            subjectConsentDocuments, skipDocument: loSkipDocument, clearSubjectNavTour,clearDocSignPosition} = this.props;
        const documentsChapter = getConsentChapter(this.context.languages, subjectData.language, 'ICF_DOCUMENTS');
        let pages = getFilteredDocuments(documentsChapter, currentUserType, subjectConsentDocuments, selectedSubject.larAsWitness);
        pages = _.uniqBy(pages,  p => p.id);
        documentsChapter.pages = pages;
        return (
            <View style={{ flex: 1 }}>
            <DocumentPages
            clearSubjectNavTour={clearSubjectNavTour}
            isRegistered={isRegistered}
            subjectData={subjectData}
            onNextChapter={this.onNextChapter}
            onPreviousChapter = {this.onPreviousChapter}
            icfDocumentsList = {icfDocumentsList}
            navigation = {navigation}
            chapter={documentsChapter}
            screenProps={screenProps}
            signatureType={signatureType}
            isKnowledgeReviewDone={isKnowledgeReviewDone}
            storeVisitedChapters = {storeVisitedChapters} 
            visitedChapters={visitedChapters}
            language={language}       
            storeSubjectNavTour={storeSubjectNavTour}            
            updateSubjectNavTour={updateSubjectNavTour}
            isAgreedForChapters ={isAgreedForChapters}
            storeisAgreedForChapters = {storeisAgreedForChapters}
            documentChapter={documentChapter}
            initializeDocuments={initializeDocuments}
            updateVisitedDocumentStatus={updateVisitedDocumentStatus}
            selectedSubject={selectedSubject}
            subjectNavTours = {subjectNavTours}
            formDataList = {formDataList}
            updateDocsReview = {updateDocsReview}
            krDoneby = { krDoneBy }
            skipDocument = { loSkipDocument }
            currentUserType = { currentUserType }
            canSkipTheDocument = { canSkipTheDocument }
            clearDocSignPosition = { clearDocSignPosition }
            />
            </View>
        );
    }
}

const mapStateToProps = state => ({
    isRegistered: state.subject.isRegistered,
    signatureType: state.subject.signatureType,
    isKnowledgeReviewDone: state.subject.isKnowledgeReviewDone,
    language: state.subject.language,
    visitedChapters: state.subject.visitedChapters,
    subjectData: state.subject,
    isAgreedForChapters: state.subject.isAgreedForChapters,
    documentChapter: state.documentChapter,
    selectedSubject: state.selectedSubject,
    subjectNavTours: state.subject.navTour,
    formDataList: state.documents.formDataList,
    currentUserType: state.consentFlow.currentUserType,
    subjectConsentDocuments: state.subjectConsentDocument.list,
    krDoneBy: state.consentFlow.krDoneBy,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        storeVisitedChapters,
        clearSubjectNavTour,
        storeSubjectNavTour,
        updateSubjectNavTour,
        storeisAgreedForChapters,
        initializeDocuments,
        updateVisitedDocumentStatus,
        updateDocsReview,
        skipDocument,
        canSkipTheDocument,
        clearDocSignPosition,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScreen);