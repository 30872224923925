import axios from "axios";
import JSOG from "jsog";
import { Platform } from "react-native";
import * as RootNavigation from "../containers/RootNavigation.js";
import  Constants  from "./constants.js";
import _ from "lodash";
import { sessionExpired } from "../actions/session.js";
import { store } from "../store/configureStore.js";

const axiosConfig = axios.create({
  // baseURL: 'https://ezpro.acs-360.com/eicc/api',
  // baseURL: 'http://34.233.30.17:26147/eicc-test/api',
  baseURL: `${Constants.ADMIN_PORTAL_BASE_URL}/api/v2`,
  headers: { "Access-Control-Allow-Origin": "*" },
  // auth: {
  //     username: 'otpuser',
  //     password: 'Asdx#123',
  // https://jconcur-build-webapp.ut1.tryallabs.com/webapp
  //   },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) =>
    JSOG.decode(data)
  ),
});

axiosConfig.interceptors.request.use(
  (config) => {
    const contextBasedUrl = getContextBasedUrl(config.url);
    config.url = contextBasedUrl;
    if (Platform.OS == "web") {
      const token = window.sessionStorage.getItem("token");
      if (token) {
        config.headers.Authorization = token;
      }
      config.headers['Content-Type'] = 'application/json';
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 440) {
      store.dispatch(sessionExpired());
      _.delay(
        RootNavigation.navigate("Admin", {
          screen: "SessionExpiryPage",
          params: {},
        }),
        500,
        "sessiontimeout"
      );
      return Promise.reject(error);
    } else if (error?.response?.status === 403) {
      RootNavigation.navigate("Admin", {
        screen: "UnauthorizedScreen",
        params: {},
      });
    } else {
      return Promise.reject(error);
    }
  }
);

const getContextBasedUrl = (url) => {
  const state = store.getState();
  let contextBasedUrl = url;
  contextBasedUrl = _.replace(contextBasedUrl, Constants.ContextProperties.PRIMARY_ORG_CODE, _.get(state.session,'primaryOrgCode', null))
  contextBasedUrl = _.replace(contextBasedUrl, Constants.ContextProperties.STUDY_ID, _.get(state.session,'study', null))
  contextBasedUrl = _.replace(contextBasedUrl, Constants.ContextProperties.SITE_ID, _.get(state.session,'site', null))
  contextBasedUrl = _.replace(contextBasedUrl, Constants.ContextProperties.CONSENT_ID, _.get(state.selectedSubject,'subjectConsent.consentVersion.consent.id', null))
  contextBasedUrl = _.replace(contextBasedUrl, Constants.ContextProperties.CONSENT_VERSION_ID, _.get(state.selectedSubject,'currentConsentVersionId', null))
  return contextBasedUrl;
}



export default axiosConfig;
