import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity,} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import Icon from 'react-native-vector-icons/AntDesign';
import Signt from 'react-native-vector-icons/FontAwesome5';
import {fontType} from '../../styles/font'
import { MaterialIcons } from '@expo/vector-icons'
import TryalIcon from '../common/icon';
import { IconType } from '../common/constants';

class ChapterTitle extends Component {

  constructor(props) {
      super(props);
      this.state = {
        
      };
  }

  renderIcons = (term) => {
    if (term === 'STUDY_VIDEO') {
        return <Icon
            name='videocamera'
            color= {Colors.text}
            size={fontType('ChapterIcon')}
        />
    }
    else if (term === 'COMMENT_REVIEW'){
        return (
        <View style = {{alignItems: 'center'}}>
            <TryalIcon 
            iconName='comment-check' 
            iconType={IconType.MATERIAL_COMMUNITY} 
            color={Colors.text} 
            size={fontType('StatusIcon')}/>
        </View>
        )
    }
    else if (term === 'ICF_DOCUMENTS') {
        return <Icon
            name='filetext1'
            color={Colors.text}
            size={fontType('ChapterIcon')}
        />
    }
    else if (term === 'KNOWLEDGE_REVIEW') {
        return <Icon
            name='questioncircleo'
            color={Colors.text}
            size={fontType('ChapterIcon')}
        />
    }
    else if (term === 'STUDY_INFORMATION') {
        return <Icon
            name='infocirlceo'
            color={Colors.text}
            size={fontType('ChapterIcon')}
        />
    }
    else if (term === 'REGISTRATION_FORM'){
        return <Icon
            name='solution1'
            color={Colors.text}
            size={fontType('ChapterIcon')}
        />
    }
    else if (term === 'SIGNATURE'){
        return <Signt
            name='signature'
            color={Colors.text}
            size={fontType('ChapterIcon')}
        />
    } 
    else return <></>
}

  render() {
    const {chapterTitle, chapterType, onCommentClick, displayComments=true} = this.props;
    return (
      <View style={{
        paddingLeft:wp('2%'),
        padding:wp('1%'),
        backgroundColor:'#fff',
        zIndex: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <View style = {{ flexDirection: 'row'}}>
       <Text style={{ fontSize: fontType('BasicFont'), fontFamily:'Inter_Bold', color: Colors.text }}>
        {this.renderIcons(chapterType)} </Text>
        <Text style={{ fontSize: fontType('BasicFont'), fontFamily:'Inter_Bold', color: Colors.text}}>{chapterTitle}</Text>
        </View>
       { displayComments && 
       <TryalIcon iconName='comment-plus' iconType={IconType.MATERIAL_COMMUNITY} color={Colors.buttonwithbg} size={26} onClick={onCommentClick}/>
  }
       </View>
    );
  }
}
export default ChapterTitle;