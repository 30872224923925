import React from 'react';
import ColorCode from "../utils/dropdowncolorconstants"
import { constStyles } from '../styles/constStyles';
import TryalDropdown from '../components/common/dropdown';

    const LanguageSelection = (props) => {
      const {
        items, multi, selectedValue, onItemSelect
      } = props
      
    const onSelect = (e) => {
        onItemSelect(e);
    }

    return (
        <TryalDropdown
        items={items}
        containerStyle = {{position: 'absolute', width: '100%'}}
        multi={multi}
        onItemSelect={onSelect}
        itemStyle={{
          backgroundColor: ColorCode.bgcolor,
          borderColor: ColorCode.borderColor,
          borderBottomWidth: 0.5,
          textAlign: 'left',
          height: 30,
          marginLeft: 0,
          paddingLeft: 10
        }}
        itemTextStyle={{ color: ColorCode.color }}
        itemsContainerStyle={{ maxHeight: 140, backgroundColor: ColorCode.bgcolor,borderColor: ColorCode.borderColor,
        borderWidth: 1, width:"100%", zIndex:999,  marginTop:40, opacity:1 }}
        textInputProps={
          {
            placeholder: 'Select Language',
            underlineColorAndroid: ColorCode.textInputunderlineColorAndroid,
            style: constStyles.inputStyles,
            value: selectedValue,  
          }
        }
        noOptionsText = 'No Options'
        />
      );
  }
  export default LanguageSelection
