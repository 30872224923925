import React, { Component } from "react";
import { Header, Card, Input, Item } from "native-base";
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  TouchableOpacity,
  ScrollView,
  Platform
} from "react-native";
import { styles } from "./styles/StudySiteInfoStyles";
import {constStyles} from '../../styles/constStyles';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { getConsentName, getConsentVersionName } from "../../utils/consentData";
import { NavigationEvents } from "react-navigation";
import { Colors } from "../../constants/ui/colorScheme";
import { ConsentDataContext } from "../../providers/ConsentDataProvider";
import Dialog, {
  DialogContent,
  ScaleAnimation,
} from "react-native-popup-dialog";
import { Linking } from 'expo';
import api from '../../utils/api';


export default class StudySiteInfo extends Component {
  static contextType = ConsentDataContext;

  state={
    newAppVersion: '',
  }

  componentDidMount() {
      const { navigation, screenProps: { validateSession } } = this.props;
      this._unsubscribe = navigation.addListener('focus', () => {
          validateSession(true);
      });
  }

  componentWillUnmount() {
      this._unsubscribe();
  }

  // getAppVersion = async() => {
  //   const {studySiteId} = this.props;
  //   try{
  //     const res = await api.get(`/getAppVersion/${studySiteId}`); 
  //     this.setState({newAppVersion: res.data});
  //   }catch(error) {
  //     console.log(error);
  //   }
  // }

  updateApp = async() => {
      const { studySiteId, navigation,
              hasUpdated, hasNewAppUpdated,
              hasAppUpdate, hasUpdate,
              newVersionUrl, clearStudySiteAccessCode, clearDeviceSNo } = this.props;
      const accessCode = '';
        try {
            if(hasAppUpdate) {
              await hasNewAppUpdated();
              await clearStudySiteAccessCode(accessCode);
              await clearDeviceSNo();
              Linking.openURL(newVersionUrl);
            }else if(hasUpdate) {
              await hasUpdated();
              navigation.navigate('DownloadVideosAndStoreData', {studySiteId: studySiteId});
            }
        } catch (error) {
            console.log(error);
        }

    }

  render() {
    const {
      hasUpdate,
      hasAppUpdate,
      studySite,
      newConsentVersion,
      screenProps: { validateSession },
      selectedUser,
      organizationName
    } = this.props;
    const { newAppVersion } = this.state;
    return (
      <ScrollView style={{
        backgroundColor: Colors.background,
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.background,
          justifyContent: "flex-start",
          padding: Platform.OS ==='web' ? 10 : wp("3%"),
        }}
      >
        {/* <NavigationEvents
          onWillFocus={() => {
            validateSession(true);
          }}
        /> */}
        <Dialog
          visible={hasUpdate || (hasAppUpdate)} 
          dialogAnimation={new ScaleAnimation({})}
        >
          <DialogContent style={{ height: hp("24%"), width: wp("65%") }}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              {hasAppUpdate ? (
                <View style={{ margin: 10 }}>
                  <Text
                    style={{
                      color: Colors.header,
                      fontSize: wp("3.5%"),
                      fontFamily: "Inter",
                    }}
                  >
                    App Update
                  </Text>
                  <Text
                    style={{
                      fontSize: wp("3%"),
                      color: Colors.text,
                      marginVertical: 15,
                      fontFamily: "Inter",
                    }}
                  >
                    To continue using this app please update to the current
                    version {newAppVersion}
                  </Text>
                </View>
              ) : (
                <View>
                  <Text
                    style={{
                      color: Colors.header,
                      fontSize: wp("4%"),
                      fontFamily: "Inter",
                    }}
                  >
                    Consent Update
                  </Text>
                  <Text
                    style={{
                      fontSize: wp("3%"),
                      color: Colors.text,
                      marginVertical: 5,
                      fontFamily: "Inter",
                    }}
                  >
                    {/* A new Consent version {newConsentVersion} is available. */}
                    To continue using this app please update to the current version {newConsentVersion}
                  </Text>
                </View>
              )}
            </View>
            <View>
              <TouchableOpacity
                onPress={this.updateApp}
                style={{
                  backgroundColor: Colors.buttonwithbg,
                  borderRadius: 5,
                  alignSelf: "flex-end",
                }}
              >
                <Text
                  style={{
                    alignSelf: "center",
                    color: Colors.textColorwithbg,
                    paddingHorizontal: wp("3%"),
                    paddingVertical: wp("1.5%"),
                    fontSize: wp("2%"),
                  }}
                >
                  {" "}
                  Update{" "}
                </Text>
              </TouchableOpacity>
            </View>
          </DialogContent>
        </Dialog>

        {/* <Card style={{ minHeight: hp('35%'), justifyContent: 'space-evenly', alignContent: 'center', alignSelf: 'center', padding: wp('4%') }}> */}
        <View>
          <View style={{ paddingVertical: 2 }}>
            <Text style={styles.headerStyle}><b>{studySite.studyNumber}</b></Text>
          </View>
          <View style={{ flexDirection: "row", paddingVertical: 10 }}>
            <View>
              <Text style={styles.leftheaderStyle}>Study Sponsor : </Text>
            </View>
            <View>
              <Text style={styles.rightheaderStyle}>
                {studySite.sponsorName}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingVertical: 5 }}>
            <View>
              <Text style={styles.leftheaderStyle}>Site Name : </Text>
            </View>
            <View >
              <Text style={styles.rightheaderStyle}>{organizationName}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingVertical: 5 }}>
            <View>
              <Text style={styles.leftheaderStyle}>Consent Name : </Text>
            </View>
            <View >
              <Text style={styles.rightheaderStyle}>
                {getConsentName(this.context.languages, "English")}
              </Text>
            </View>
          </View>
          {/* <View style={{ flexDirection: "row", paddingVertical: 5 }}>
            <View>
              <Text style={styles.leftheaderStyle}>Build Version : </Text>
            </View>
            <View >
              <Text style={styles.rightheaderStyle}>
                {getConsentVersionName(this.context.languages, "English")}
              </Text>
            </View>
          </View> */}
          <View style={{ flexDirection: "row", paddingVertical: 5 }}>
            <View>
              <Text style={styles.leftheaderStyle}>Site Location : </Text>
            </View>
            <View>
              <Text style={styles.rightheaderStyle}>{selectedUser?.client?.region}</Text>
            </View>
          </View>
        </View>
        <View style={{ padding: wp("2%"), flex: 1 }}>
          <TouchableOpacity
            style={constStyles.buttonStyle}
            onPress={() => this.props.navigation.navigate("HomeTabs")}
          >
            <Text style={constStyles.buttonTxt}>GET STARTED</Text>
          </TouchableOpacity>
        </View>
        {/* </Card> */}
      </View>
      </ScrollView>
    );
  }
}
