import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
// import { Bubbles, DoubleBounce, Bars, Pulse } from 'react-native-loader';

class Loading extends React.Component {
  state = {

  };

  render() {
    return (
      <View  style={{flex:1, alignItems:'center',justifyContent:'center',}}>
          <Text style={{fontSize:wp('4%'), color:'#006699',padding:10}}>Please wait until the data has been loaded</Text>
      </View>
    );
  }
}


export default Loading;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
      },
      lottie: {
        width: 100,
        height: 100
      }
});