import React, { Component } from 'react';
import { Container } from 'native-base';
import { View, Text, TouchableOpacity } from 'react-native';
import { Colors } from '../../constants/ui/colorScheme';
import { fontType } from '../../styles/font';
import { constStyles, signatureButtonStyles } from '../../styles/constStyles'
import _ from 'lodash';

export default class HandOverDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        const { screenProps: { t }, navigation } = this.props;
        return (
            <Container >
                <View style={{ flex: 1, justifyContent: 'center' }} >
                    <Text style={{ 
                        fontSize: fontType('Heading1'), 
                        color: Colors.coloredText, 
                        padding: 5, 
                        fontFamily: 'Inter', flexWrap: 'wrap' }}>
                    {t('ReturnToSiteStaff')}
                    </Text>
                    
                    <View style={{ padding: 10, justifyContent: 'center' }}>
                        
                            <TouchableOpacity
                                onPress={() => navigation.replace('HomeTabs')}
                                style={[constStyles.buttonStyle]}
                            >
                                
                                        <Text style={[constStyles.buttonStyle, {color: '#ffffff'}]}>
                                            {t('SubjectList')}
                                        </Text>
                            </TouchableOpacity>
                    </View>
                </View>
            </Container>
        );
    }
}

