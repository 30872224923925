import React, { Component } from "react";
import { Container } from "native-base";
import { View, Text } from "react-native";
import { Image } from "react-native";
import { constStyles } from "../../styles/constStyles";

export default class ClientInactivated extends Component {
  render() {
    return (
      <Container>
        <View style={{ flex: 1, justifyContent: "center", backgroundColor: '#f2f2f2' }}>
          {/* -------------- Lock Icon ------------------ */}
          <View style={{ alignContent: "center", alignSelf: "center", marginBottom: 10 }}>
            <Image
              source={require("../../../assets/access-denied.png")}
              style={{ height: 125, width: 125, paddingBottom: 10 }}
              resizeMode="contain"
            />
          </View>
          {/* -------------- Message ------------------ */}
          <View style={{ alignContent: "center", alignSelf: "center" }}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={constStyles.errorPageText}>
              Account is disabled. Contact your Administrator.
              </Text>
            </View>
          </View>
        </View>
      </Container>
    );
  }
}
