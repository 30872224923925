import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../constants/ui/colorScheme';
const colorRegex = /\d+/g;
const bgColorRegex = /(background-color.*\(.*?\))/g;

export const getColor = (formattedText) => {

  let foundColor = formattedText.match(colorRegex);
  if (foundColor) {
    foundColor = foundColor.map(val => Number(val));
    return `rgb(${foundColor[0]},${foundColor[1]},${foundColor[2]})`
  }
  return Colors.textFormatColor;
}

export const getBgColor = (formattedText) => {

  let foundColor = formattedText.match(bgColorRegex);
  foundColor = foundColor ? foundColor[0].match(colorRegex) : foundColor;
  if (foundColor) {
    foundColor = foundColor.map(val => Number(val));
    return `rgb(${foundColor[0]},${foundColor[1]},${foundColor[2]})`
  }
  return 'rgba(0,0,0,0)'
}


const boldStyle = { fontFamily:'Inter_Bold' };
const italicStyle = { fontStyle: 'italic' };
const underlineStyle = { textDecorationLine: 'underline', fontFamily:'Inter' };
const strikethroughStyle = { textDecorationLine: 'line-through' };

export const styles = StyleSheet.create({
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  ins: underlineStyle,
  s: strikethroughStyle,
  strike: strikethroughStyle,
  a: {
    fontWeight: '500',
    color: Colors.tagAColor,
  },
  h1: { fontWeight: '500', fontSize: 36, fontFamily:'Inter' },
  h2: { fontWeight: '500', fontSize: 30, fontFamily:'Inter' },
  h3: { fontWeight: '500', fontSize: 24, fontFamily:'Inter' },
  h4: { fontWeight: '500', fontSize: 18, fontFamily:'Inter' },
  h5: { fontWeight: '500', fontSize: 14, fontFamily:'Inter' },
  h6: { fontWeight: '500', fontSize: 12, fontFamily:'Inter' },
  sub: { fontSize: 12, lineHeight: 17 },
  sup: { fontSize: 12, lineHeight: 50 },
  p: { fontSize: wp('3.5%'), fontFamily:'Inter' },
  ul: {fontSize: wp('3.5%'), fontFamily:'Inter'},
  ol: {fontSize: wp('3.5%'), fontFamily:'Inter'},
  div: {fontSize: wp('3.5%'),},
})

