import createActionType from '../utils/action';
import _ from 'lodash';
import api from '../utils/api';
import { generateBasicAuthToken } from '../utils/util';
import {checkErrorStatusAndShowToast} from '../utils/errorMessageUtils';
import { hasNewUpdate, hasNewAppUpdate } from './update';
import { getConsentVersionId } from '../utils/consentData';
import { create } from 'uuid-js';

export const CLIENT_USER_DATA_REQUEST = createActionType('CLIENT_USER_DATA_REQUEST')
export const CLIENT_USER_DATA_SUCCESS = createActionType('CLIENT_USER_DATA_SUCCESS')
export const CLIENT_USER_DATA_FAILURE = createActionType('CLIENT_USER_DATA_FAILURE')
export const LOGIN_RETRIEVE_REQUEST = createActionType('LOGIN_RETRIEVE_REQUEST')
export const LOGIN_RETRIEVE_SUCCESS = createActionType('LOGIN_RETRIEVE_SUCCESS')
export const LOGIN_RETRIEVE_FAILURE = createActionType('LOGIN_RETRIEVE_FAILURE')
export const SELECTED_CLIENT_USER = createActionType('SELECTED_CLIENT_USER')
export const STORE_STUDY_SITE_ACCESS_CODE_SUCCESS = createActionType('STORE_STUDY_SITE_ACCESS_CODE_SUCCESS');
export const STORE_STUDY_SITE_ACCESS_CODE_REQUEST = createActionType('STORE_STUDY_SITE_ACCESS_CODE_REQUEST')
export const STORE_STUDY_SITE_ACCESS_CODE_FAILURE = createActionType('STORE_STUDY_SITE_ACCESS_CODE_FAILURE')
export const RESET_VALIDATION = createActionType('RESET_VALIDATION')
export const STORE_SITE_ID = createActionType('STORE_SITE_ID')
export const CLEAR_STUDY_SITE_ACCESS_CODE = createActionType('CLEAR_STUDY_SITE_ACCESS_CODE')
export const SET_ORGANIZATION_NAME = createActionType('SET_ORGANIZATION_NAME')
export const CLEAR_CLIENT_USER = createActionType('CLEAR_CLIENT_USER')

const retrieveClientUserDataRequest = () => ({
    type: CLIENT_USER_DATA_REQUEST,
});

const retrieveClientUserDataFailure = () => ({
    type: CLIENT_USER_DATA_FAILURE,
});

const retrieveClientUserDataSuccess = (data) => ({
    type: CLIENT_USER_DATA_SUCCESS,
    clientUserslist: data,
});

const storeStudySiteAccessCodeSuccess = (accessCode) => ({
    type: STORE_STUDY_SITE_ACCESS_CODE_SUCCESS,
    accessCode: accessCode
});

const storeStudySiteAccessCodeRequest = () => ({
    type: STORE_STUDY_SITE_ACCESS_CODE_REQUEST,
});

const storeStudySiteAccessCodeFailure = () => ({
    type: STORE_STUDY_SITE_ACCESS_CODE_FAILURE,
});

const retrieveLoginRequest = () => ({
    type: LOGIN_RETRIEVE_REQUEST,
})

export const retrieveLoginSuccess = (res) => ({
    type: LOGIN_RETRIEVE_SUCCESS,
    studySite: res.data
})

export const resetValidation = () => ({
    type: RESET_VALIDATION,
})

export const retrieveLoginFailure = () => ({
    type: LOGIN_RETRIEVE_FAILURE,
})
export const storeSelectedClientUser = (clientUser) => ({
    type: SELECTED_CLIENT_USER,
    clientUser,
})

const storeStudySite = (studySite) => ({
    type: STORE_SITE_ID,
    stdSite: studySite,
})

export const clearStudySiteAccessCode = (accessCode) => ({
    type: CLEAR_STUDY_SITE_ACCESS_CODE,
    accessCode: accessCode
});

export const retrieveClientUserData = (studySiteAccessCode, navigation) => async dispatch => {
    dispatch(storeStudySiteAccessCodeRequest()); 
    try {
        const res = await api.post(`/studySiteAccessCode/${studySiteAccessCode}`);
        if(res.data) {
            dispatch(storeStudySiteAccessCodeSuccess(studySiteAccessCode));
            const studySiteId = res.data.id;
            navigation.navigate('DownloadVideosAndStoreData', {studySiteId: studySiteId});
        }
        // dispatch(retrieveClientUserDataSuccess(res));
        dispatch(storeStudySite(res.data));
    } catch(error) {
        console.log(error);
        checkErrorStatusAndShowToast(error, "Please enter valid access code.");
        dispatch(storeStudySiteAccessCodeFailure());
    }
}

export const retrieveLogin = (userName, userPin, navigation, studySiteId, appVerion, consentVersionId) => async dispatch => {
    // const consentVersionId = getConsentVersionId();
    dispatch(retrieveLoginRequest());
    try {
        api.defaults.headers.common.Authorization = generateBasicAuthToken(userName, userPin);
        const res = await api.post('/clientUser/login', {id: studySiteId, appVersion:appVerion, consentVersion: { id: consentVersionId}});
        dispatch(retrieveLoginSuccess(res));
        // alert(JSON.stringify(res.data.pinStatus));
        if(res.data && res.data.pinStatus=='NONE'){
            navigation.replace('ResetPinScreen');
                
        } else if (res.data && res.data.pinStatus=='ACTIVE'){
            navigation.replace('StudySiteAccessInfoScreen');
        
        }
        if(res.data && res.data.newAppVersionAvailable) {
            dispatch(hasNewAppUpdate());
        }
        else if(res.data && res.data.newVersionAvailable) {
          dispatch(hasNewUpdate());
        } else if(res.data) {
            
            navigation.replace('StudySiteAccessInfoScreen');
        }
        return 'CorrectPIN'
    } catch(error) {
        delete api.defaults.headers.common.Authorization;
        console.log(error)
        dispatch(retrieveLoginFailure());
        return error.response ? error.response.status : 'NetworkError';
    }
}

export const retriveClientUsers = (studySiteId) => async dispatch => {
    dispatch(retrieveClientUserDataRequest()); 
    try {
        const response = await api.get(`/clientUsers/${studySiteId}`);
        if(response.data) {
            dispatch(retrieveClientUserDataSuccess(response.data));
        }
    } catch(error) {
        console.log(error);
        dispatch(retrieveClientUserDataFailure());
    }
}
 
export const setOrganizationName = (organizationName, organizationId) => ({
    type: SET_ORGANIZATION_NAME,
    organizationName,
    organizationId,
})


export const clearClientUserDetails = () => ({
    type: CLEAR_CLIENT_USER,
})

