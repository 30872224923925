import React, {Component} from 'react';
import { View, StyleSheet, Text, TextInput, Platform, PlatformColor } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';
import { backgroundColor } from '../../containers/NavigationScreens';


export default class TextInputItem extends Component{

    getError = error => {
        if (error) {
          return error.map(info => {
            return (
              <Text style={styles.errorinfoText} key={info}>
                {info}
              </Text>
            );
          });
        }
        return null;
      };
     
  render() {
    const { error, value, onChange, placeholderValue, maxLength, secureTextEntry, disabled } = this.props;
    const editable = disabled ? false : true;
    return (
      <View style={styles.inputView} >
          <TextInput 
            editable={editable}
            placeholder={placeholderValue}
            style={styles.inputText}
            value={value || ''}
            onChangeText={onChange}
            fontSize={fontType('BasicFont')}
            maxLength={maxLength || null}
            secureTextEntry={ secureTextEntry || false}
          /> 
          <View style={styles.errorinfoText}>{this.getError(error)}</View>
      </View>
    )
  }

}
const styles = StyleSheet.create({

  inputView:{
    // width:"100%",
    flex: 1,
    paddingHorizontal:15,
    justifyContent: 'center'
  },

  inputText:{
    borderColor: '#bdbdbd',
    // borderRadius: 7,
    borderBottomWidth: 2,
    ...Platform.select({
      ios : {width:wp('50%')},
      web : {width:wp('30%')}
    }),
    maxWidth:'100%',
    padding:5,
    textAlign:'left',
    fontFamily:'Inter',
    marginLeft: 3
  },

  errorinfoText: {
    color: Colors.error,
    fontFamily:'Inter',
    fontSize: fontType('subFont'), 
    marginTop:2,
    marginBottom:2,
    // alignSelf:'left'
  }
})
