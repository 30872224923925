import React, { Component } from "react";
import { Card } from "native-base";
import { Text, View, ScrollView, Platform, Dimensions, TouchableOpacity } from "react-native";
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { styles } from "./styles/forgotPasswordStyles";
import { KeyboardAvoidingView, Image } from "react-native";
import { createForm } from "rc-form";
import TextInputItem from './TextInputItem';
import { Colors } from "../../constants/ui/colorScheme";
import api from "../../utils/api";
import showToast from '../../utils/toast';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import MyLoader from '../../utils/webLoader';


const {width:SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window')

class ForgotPasswordScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userName: null,
            email: null,
            phoneNo: null,
            disabled: false,
        };
    }

    encryptValue = (value) => {
        var encryptedValue = '';
        const hidablePart = value.substring(0, value.length - 4);
        const visiblePart = value.substring(value.length - 4);
        for (let i = 0; i < hidablePart.length; i += 1) {
          hidablePart.charAt(i);
          encryptedValue = encryptedValue.concat(hidablePart.charAt(i).replace(hidablePart.charAt(i), 'X'));
        }
        encryptedValue = encryptedValue.concat(visiblePart);
        return encryptedValue;
    }

    sendOtp = async otpType => {
        const { navigation } = this.props;
        const { userName } = this.state;
        const obj = {
            userName: userName,
            otpType: otpType
        };        
        try {
            this.setState({ loading: true });
            const res = await api.post("/sendOtp", obj);
            showToast(
                `OTP has been sent to ${otpType == "sms" ? "Phone" : "Email"}.`,
                "success",
                2000
            );
            this.setState({ loading: false });
            navigation.navigate("ValidateOtpScreen", { 
                OtpId: res.data.id,
                otpType: otpType,
                userName: userName
            });  
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
            if (error.response) {
                showToast(`Failed to send OTP to ${otpType == "sms" ? "Phone" : "Email"}.`, "danger", 2000);
            } else {
                showToast("Network Error.", "danger", 2000);
            }
        }
    };
    

    onSubmit = () => {
        const { navigation, form } = this.props;
        const subjectForgot = navigation.getParam('subjectForgot');
        form.validateFields(async (error, values) => {
            if (!error) {
                const clientUser = {
                    userName: values.username,
                }
                let subject={};
                if(subjectForgot){
                    const subjectId = navigation.getParam('subjectId');
                    subject = {
                        id: subjectId,
                        userName: values.username
                    }
                }
                try {
                    this.setState({ loading: true });
                    let res = null;
                    {subjectForgot ? 
                         res = await api.post("/subject/verifyUser", subject)
                        :
                         res = await api.post("/verifyUser", clientUser);
                    }
                                           
                    const data = res.data;
                    var email = null;
                    var phoneNo = null;
                    if(data.email) {
                        const loEmail = data.email.split('@', 2);  
                        loEmail.map((val, ind) => {
                            if (ind === 0 && val.length > 4) {
                                email = this.encryptValue(val);
                            } else if (ind === 0 && val.length < 4) {
                                email = val;
                            } else if (ind === 1) {
                                email = email.concat(`${'@'}${val}`);
                            }
                        });
                    }
                    if(data.phoneNo) {
                        phoneNo = this.encryptValue(data.phoneNo);
                    }
                    this.setState({ 
                        loading: false,
                        userName: data.userName,
                        email: email,
                        phoneNo: phoneNo,
                        disabled: true
                    });                                                                                            
                } catch(err) {
                    this.setState({ loading: false });
                    console.log(err);
                    const result = err.response ? err.response.status : 'NetworkError';
                    if (result === 404) {
                        showToast("User does not exist.", 'danger', 4000);
                    } else if (result === 500) {
                        showToast("Something went wrong.", 'danger', 4000);
                    } else {
                        showToast('Network Error.', 'danger', 2000);
                    }
                }
            } else {
                console.log(error);
            }
        });
    };

    render() {
        const { form: { getFieldDecorator, getFieldError} } = this.props;
        const { loading, email, phoneNo, disabled } = this.state;
        return (
            <View style={{flex:1,
                flexDirection:'row',
                // alignItems:'center',
                justifyContent:'center'
                }}>
                <MyLoader  active={loading}/>
                     <View >
                        {/* --------------------- Logo --------------------------- */}
                                <View
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 10,
                                        flex:1
                                    }}
                                >
                                    <Image
                                        source={require("../../../assets/bio.png")}
                                        style={{ height: hp("7%"), width: wp("25%") }}
                                        resizeMode='contain'
                                    />
                                </View>


                                {/* --------------------- Card ---------------------------- */}

                           <Card style={Platform.OS !=='web' ? styles.card : {flex:5, padding:6}} >

                                {/* ------------------ Label ---------------------- */}
                                <View
                                    style={{
                                        flex:1,
                                        justifyContent: "center"
                                    }}
                                >
                                    <Text style={styles.headerStyle}>Forgot Password</Text>
                                </View>

                                {/* ------------------ Username ---------------------- */}
                                <View style={{flex:2, padding:3,
                                 justifyContent: "center", alignItems:'center'
                                 }}>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            { required: true, message: 'Please enter Username.' },
                                        ],
                                    })(
                                        <TextInputItem
                                            error={getFieldError('username')}
                                            placeholderValue={'Username'}
                                            disabled={disabled}
                                        />
                                    )}

                                    {/* ------------- Verify user button --------------------- */}
                                    <View style={{ justifyContent: 'flex-end', marginTop: 20 }}>
                                        <TouchableOpacity
                                            disabled={disabled}
                                            success
                                            style={[
                                                constStyles.buttonStyle,
                                                {
                                                // backgroundColor: 'grey'
                                                backgroundColor:
                                                    disabled == true
                                                    ? "grey"
                                                    : Colors.buttonwithbg
                                                }
                                            ]}
                                            onPress={this.onSubmit}
                                        >
                                            <Text style={constStyles.buttonTxt}>VERIFY USER</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>

                                <View style={{flex:1, padding:3}}>    
                               {/* ------------------ Email ---------------------- */}
                                { email != null && email != ''
                                    ? 
                                        <View style={{flex:1,flexWrap:'wrap'}}>
                                            <Text style={{fontWeight: "bold", fontSize:fontType('subFont')}}>Email: <Text style={{fontWeight: "normal"}}>{email}</Text></Text>
                                        </View>
                                    : <View></View>
                                }

                                {/* ------------------ Phone no ---------------------- */}
                                { phoneNo != null && phoneNo != ''
                                    ?   <View
                                            style={{
                                                flex: 1, 
                                                flexWrap: 'wrap',
                                                marginVertical:4
                                            }}
                                        >
                                            <Text style={{fontWeight: "bold", fontSize:fontType('subFont')}}>Phone Number: <Text style={{fontWeight: "normal"}}>{phoneNo}</Text></Text>
                                        </View>
                                    :   <View></View>
                                }
                                </View>

                                <View style={{flex:2, padding:3}}>
                                    {/* ------------- Email button--------------------- */}
                                    { email != null && email != ''
                                        ?   <View style={{marginVertical:15}}>
                                                <TouchableOpacity
                                                    success
                                                    style={constStyles.buttonStyle}
                                                    onPress={() => {
                                                        this.sendOtp("email");
                                                    }}
                                                >
                                                    <Text style={constStyles.buttonTxt}>SEND OTP TO EMAIL</Text> 
                                                </TouchableOpacity>
                                            </View>
                                        : <View></View>
                                    }

                                    {/* ------------- Phone no button --------------------- */}
                                    { phoneNo != null && phoneNo != ''
                                        ?   <View>
                                                <TouchableOpacity
                                                    success
                                                    style={constStyles.buttonStyle}
                                                    onPress={() => {
                                                        this.sendOtp("sms");
                                                    }}
                                                >
                                                    <Text style={constStyles.buttonTxt}>SEND OTP TO PHONE</Text> 
                                                </TouchableOpacity>
                                            </View>
                                        :   <View></View>
                                    }
                                </View>

                        </Card>
                        <View style={{flex:1}}/>
                    </View>
            </View>
        );
    }
}

export default createForm()(ForgotPasswordScreen);
