import initialState from '../store/initialState';
import { SET_SUBJECT_CONSENT_DOCUMENTS } from '../actions/subjectConsentDocument'


const setSubjectConsentDocuments = (state = initialState.consentFlow, action) => {
  switch (action.type) {
    case SET_SUBJECT_CONSENT_DOCUMENTS:
      return {
          ...state,
          list: action.subjectConsentDocuments,
      }
    default:
      return state;
  }
};

export default setSubjectConsentDocuments;