import React, {Component} from 'react';

export const validateAnswer = (field, userAnswer) => {
    const answer = field.answer;
    if (field.answerType === 'num') {
        const numberValue = userAnswer;
        return validateNumberInput(answer, numberValue);
    }
    else if (field.answerType === 'singleslct') {
        const selectedOption = field.options[userAnswer];
        return validateSingleSelect(answer, selectedOption);
    }
    else
       return true;
}

export const validateNumberInput = (answer, numberValue) => {
    const filteredAnswers =  _.filter(_.split(answer, ','), ans => _.isEqual(Number(ans), Number(numberValue)))
    if (filteredAnswers.length > 0) {
        return true;
    }
    else return false
}

export const validateSingleSelect = (answer, selectedOption) => {
   const filteredAnswers =  _.filter(_.split(answer, ','), ans => _.isEqual(_.toLower(ans), _.toLower(selectedOption)))
    if (_.isEmpty(answer) || filteredAnswers.length > 0){
        return true;
    }
    else return false
}
