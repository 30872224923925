import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Dimensions, Platform } from 'react-native';
import Dialog, { DialogContent } from 'react-native-popup-dialog';
import { Colors } from '../../constants/ui/colorScheme';
import {fontType} from '../../styles/font';
import {constStyles} from '../../styles/constStyles';
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
const {width:SCREEN_WIDTH} = Dimensions.get('window')
class StopConsentPopup extends Component {
    constructor(props) {
      super(props);
    }
    
      render() {
        const { t, logOut, handleStopConsentDialogue, logOutConfirmation } = this.props;
        return (
            <View>
                <Dialog
                  containerStyle={{ justifyContent: 'center' }}
                  rounded={true}
                  visible={logOutConfirmation}
                  width={SCREEN_WIDTH >= 1030 ? 500 : wp("70%") }
                >
                  <DialogContent>
                    <View style={{flexDirection:'column',alignSelf:'center',alignItems:'center', padding:15 }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'center', padding:20 }}>
                        <Text style={{ fontSize: fontType('BasicFont'), fontFamily: 'Inter', color: Colors.iconColor, }}>{t('StopConsentPopup')}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', }}>
                        <TouchableOpacity 
                          onPress={() => handleStopConsentDialogue()}
                          style={[constStyles.buttonStyle, {margin:10}]}
                        >
                          <Text 
                              style={constStyles.buttonTxt}
                          >
                            {t('No')}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                          style={[constStyles.buttonStyle, {margin:10}]}
                          onPress={() => logOut()}
                        >
                          <Text 
                          style={constStyles.buttonTxt}
                          >
                          {t('Yes')}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </DialogContent>
                </Dialog>
            </View>
        );
      }
}
export default StopConsentPopup;