import initialState from "../store/initialState";
import { STORE_SUBJECT_REMOTE_CONSENT_DTO } from "../actions/subjectRemoteConsentDto";

export default (state = initialState.subjectRemoteConsentDto, action) => {
  switch (action.type) {
    case STORE_SUBJECT_REMOTE_CONSENT_DTO:
      return {
        ...state,
        ...action.subjectRemoteConsentDto,
        otp: action.otp,
      };
    default:
      return state;
  }
};
